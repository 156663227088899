import {Analytics} from '@/domain/analytics';

import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import RouterSecurity from '@/domain/security/router';

import Login from '@/pages/User/Login/Index.vue';
import LoginForm from '@/pages/User/Login/Form.vue';
import LoginPin from '@/pages/User/Login/Pin.vue';
import LoginImpersonate from '@/pages/User/Login/Impersonate.vue';

import Registration from '@/pages/User/Registration/Index.vue';
import RegistrationToS from '@/pages/User/Registration/ToS.vue';
import RegistrationName from '@/pages/User/Registration/Name.vue';

import General from '@/router/general';
import Page from '@/router/page';
import BonusCard from '@/router/bonusCard';
import Coupon from '@/router/coupon';
import User from '@/router/user';
import Customer from '@/router/customer';
import CustomerPublic from '@/router/customerPublic';

Vue.use(Router);

let router = new Router({
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
            redirect: {name: 'LoginForm'},
            children: [
                {
                    path: 'form',
                    name: 'LoginForm',
                    component: LoginForm,
                    meta: {
                        pageHeaderState: PAGE_STATE.hidden,
                        pageFooterState: PAGE_STATE.hidden,
                    }
                },
                {
                    path: 'pin/:phone',
                    name: 'LoginPin',
                    component: LoginPin,
                    meta: {
                        pageHeaderState: PAGE_STATE.hidden,
                        pageFooterState: PAGE_STATE.hidden,
                    }
                },
                {
                    path: 'impersonate',
                    name: 'LoginImpersonate',
                    component: LoginImpersonate,
                    meta: {
                        pageHeaderState: PAGE_STATE.hidden,
                        pageFooterState: PAGE_STATE.hidden,
                    }
                }
            ]
        },
        {
            path: '/registration',
            name: 'Registration',
            component: Registration,
            redirect: {name: 'RegistrationName'},
            meta: {
                requireAuth: true,
            },
            children: [
                {
                    path: 'tos',
                    name: 'RegistrationToS',
                    component: RegistrationToS,
                    meta: {
                        pageHeaderState: PAGE_STATE.hidden,
                        pageFooterState: PAGE_STATE.hidden,
                    }
                },
                {
                    path: 'name',
                    name: 'RegistrationName',
                    component: RegistrationName,
                    meta: {
                        pageHeaderState: PAGE_STATE.hidden,
                        pageFooterState: PAGE_STATE.hidden,
                    }
                }
            ]
        },
        ...General,
        ...Page,
        ...BonusCard,
        ...Coupon,
        ...User,
        ...Customer,
        ...CustomerPublic
    ]
});

router.beforeEach((to, from, next) => {
    if (!store.state.general.hasSeenIntroductionSlides && to.name !== 'Introduction') {
        next({name: 'Introduction'});
        return;
    }

    let routerSecurity = new RouterSecurity(to, from);
    routerSecurity.checkAccessAndDetermineRedirect()
        .then((nextRoute) => {
            if (store.state.view.activeDrawers.length || document.body.classList.contains('c3-drawer-open')) {
                // Hide drawer.
                // Only called if there's a drawer to improve performance
                store.commit('view/removeDrawerAll');
            }

            if (store.state.view.isLoading) {
                // Hide loading icon
                // Only called if isLoading is true to improve performance
                store.commit('view/hideLoading');
            }

            if (true !== nextRoute) {
                next(nextRoute);
            } else {
                // Set pageHeader and pageFooter state
                if (to.meta.pageHeaderState) {
                    store.commit('view/updatePageHeaderState', to.meta.pageHeaderState);
                } else {
                    store.commit('view/updatePageHeaderState', PAGE_STATE.default);
                }

                if (to.meta.pageFooterState) {
                    store.commit('view/updatePageFooterState', to.meta.pageFooterState);
                } else {
                    store.commit('view/updatePageFooterState', PAGE_STATE.default);
                }

                if (null === to.meta.pageHeaderLeft || typeof to.meta.pageHeaderLeft === 'object') {
                    store.commit('view/updatePageHeaderLeft', to.meta.pageHeaderLeft)
                } else {
                    store.commit('view/updatePageHeaderLeft', VIEW.PAGE_HEADER_LEFT_DEFAULT)
                }

                if (null === to.meta.pageHeaderInnerRight || typeof to.meta.pageHeaderInnerRight === 'object') {
                    store.commit('view/updatePageHeaderInnerRight', to.meta.pageHeaderInnerRight)
                } else {
                    store.commit('view/updatePageHeaderInnerRight', VIEW.PAGE_HEADER_INNER_RIGHT_DEFAULT)
                }

                if (null === to.meta.pageHeaderRight || typeof to.meta.pageHeaderRight === 'object') {
                    store.commit('view/updatePageHeaderRight', to.meta.pageHeaderRight)
                } else {
                    store.commit('view/updatePageHeaderRight', VIEW.PAGE_HEADER_RIGHT_DEFAULT)
                }

                next();
            }
        })
        .catch((e) => {
            console.error('checkAccessAndDetermineRedirect failed', e);
        })
});

router.afterEach((to) => {
    Analytics.logPageOpen();
});

export default router;
