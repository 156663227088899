import Form from '@/components/form/Form.vue';

import FormInput from '@/components/form/fields/Input.vue';
import FormInputUrl from '@/components/form/fields/InputUrl.vue';
import FormDate from '@/components/form/fields/Date.vue';
import FormTimeRange from '@/components/form/fields/TimeRange.vue';
import FormText from '@/components/form/fields/Text.vue';
import FormSelect from '@/components/form/fields/Select.vue';
import FormSelectPhoneNumber from '@/components/form/fields/SelectPhoneNumber.vue';
import FormSwitch from '@/components/form/fields/Switch.vue';
import FormCouponImageUpload from '@/components/form/fields/coupon/ImageUpload.vue';

import FormSubmit from '@/components/form/fields/Submit.vue';

import vSelect from 'vue-select'

export default {
    install: function (Vue, options) {
        Vue.component('c3-form', Form);
        Vue.component('c3-form-input', FormInput);
        Vue.component('c3-form-input-url', FormInputUrl);

        Vue.component('c3-form-date', FormDate);
        Vue.component('c3-form-time-range', FormTimeRange);

        Vue.component('c3-form-text', FormText);

        Vue.component('c3-form-select', FormSelect);
        Vue.component('c3-form-select-phone-number', FormSelectPhoneNumber);
        Vue.component('c3-form-switch', FormSwitch);
        Vue.component('c3-form-coupon-image-upload', FormCouponImageUpload);
        Vue.component('c3-form-submit', FormSubmit);

        // External components
        Vue.component('v-select', vSelect);
    }
};