import Vue from 'vue';
import Vuex from 'vuex';

import {Analytics} from '@/domain/analytics';
import {C3Object} from '@/domain/utility/object';

import invoice from './invoice';
import bonusCard from './bonusCard';
import coupon from './coupon';
import customer from './customer';
import geocoding from './geocoding';
import general from './general';
import membership from './membership';
import notification from './notification';
import staticContent from './staticContent';
import user from './user';
import userBonusCard from './user/bonusCard';
import userPayment from './user/payment';
import userShop from './user/shop';
import userStore from './user/store';
import view from './view';
import viewBonusCardList from './view/bonusCardList';
import viewCouponList from './view/couponList';
import voucher from './voucher';

Vue.use(Vuex);

// initial state
const initialState = {
    invoice: C3Object.deepClone(invoice.state),
    bonusCard: C3Object.deepClone(bonusCard.state),
    coupon: C3Object.deepClone(coupon.state),
    customer: C3Object.deepClone(customer.state),
    geocoding: C3Object.deepClone(geocoding.state),
    general: C3Object.deepClone(general.state),
    membership: C3Object.deepClone(membership.state),
    notification: C3Object.deepClone(notification.state),
    staticContent: C3Object.deepClone(staticContent.state),
    user: C3Object.deepClone(user.state),
    userBonusCard: C3Object.deepClone(userBonusCard.state),
    userPayment: C3Object.deepClone(userPayment.state),
    userShop: C3Object.deepClone(userShop.state),
    userStore: C3Object.deepClone(userStore.state),
    view: C3Object.deepClone(view.state),
    viewBonusCardList: C3Object.deepClone(viewBonusCardList.state),
    viewCouponList: C3Object.deepClone(viewCouponList.state),
    voucher: C3Object.deepClone(voucher.state),
};

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        invoice,
        bonusCard,
        coupon,
        customer,
        geocoding,
        general,
        membership,
        notification,
        staticContent,
        user,
        userBonusCard,
        userPayment,
        userShop,
        userStore,
        view,
        viewBonusCardList,
        viewCouponList,
        voucher
    },
    mutations: {
        reset(state) {
            let analyticsId = Analytics.getAnalyticsId();
            // Reset local storage and vuex storage
            window.localStorage.clear();
            Analytics.restoreAnalyticsId(analyticsId);
            Object.keys(state).forEach(key => {
                Object.assign(state[key], C3Object.deepClone(initialState[key]))
            });
        }
    }
});
