import {Analytics} from '@/domain/analytics';

export const CouponMixin = {
    data() {
        return {}
    },
    computed: {},
    methods: {
        redeemCoupon: function (coupon, qrToken) {
            this.$store.commit('view/showLoading', 'Einen Moment bitte, wir lösen den Coupon für dich ein.');
            this.$store.dispatch('coupon/redeem', {coupon: coupon, token: qrToken})
                .then(() => {
                    this.$c3.successAlert('', 'Der Coupon wurde erfolgreich eingelöst');
                    Analytics.logRedeemCoupon(coupon)
                })
                .catch(this.$c3.handleErrorsWithAlert)
                .finally(() => {
                    this.$store.commit('view/hideLoading');
                    this.$store.dispatch('coupon/loadCoupon', coupon);
                });
        },
        removeCoupon() {
            this.$c3.confirmYesNo(
                'coupon.confirmDeleteAlert.title',
                'coupon.confirmDeleteAlert.message',
                async () => {
                    try {
                        this.$store.commit('view/showLoading');
                        await this.$store.dispatch('coupon/removeOneCoupon', this.coupon);
                        if (this.isStateDraft) {
                            this.$router.push({name: 'CustomerCouponListDraft'});
                        } else {
                            this.$router.push({name: 'CustomerCouponListPublished'});
                        }
                    } catch (e) {
                        console.error('removeCoupon failed', e);
                        this.$c3.errorAlert('error.generic.title', 'error.generic.message');
                    }
                    this.$store.commit('view/hideLoading');
                },
                null,
                {type: 'warning'}
            );
        }
    },
};
