import {DateUtility} from '@/domain/utility/date';
import {Location} from '@/domain/location';
import {HTTP} from '@/domain/api/http';

const MAX_LIST_AGE = 600;

function needsUpdate(name, forceReload, items, lastItemsUpdate) {
    try {
        if (forceReload) {
            return true;
        }

        if (!Array.isArray(items) || 0 === items.length) {
            return true;
        }

        if (!lastItemsUpdate || (DateUtility.time() - lastItemsUpdate) > MAX_LIST_AGE) {
            return true;
        }

        return false;
    } catch (e) {
        // Force update on error, probably our cache data got corrupted
        console.error('[viewCouponList/action] needsUpdate failed', e);
        return true;
    }
}

export const position = async (context, data) => {
    let name = data.name;

    await Location.getCurrentPosition(6000, data.addressTimeout);
    let userCoordinate = context.rootGetters['user/positionAsCoordinate'];

    if (!data.disableLocationFilterUpdate && context.getters.useCurrentLocation(name) && userCoordinate) {
        let address = context.rootGetters['geocoding/address'](userCoordinate);

        if (!address) {
            // Address is not available, use coordinates
            address = userCoordinate.latitude + ', ' + userCoordinate.longitude;
            console.warn('No address for coordinates found', userCoordinate);
        }

        context.commit(
            'updateFilter',
            {
                name: name,
                filter: {
                    locationName: address.locality ? address.locality : address.postalCode,
                    locationCenter: userCoordinate
                }
            }
        );
    }
};

export const loadItems = async (context, data) => {
    let name = data.name;
    let forceReload = data.forceReload;

    if (!needsUpdate(name, forceReload, context.getters['items'](name), context.getters['lastItemsUpdate'](name))) {
        return context.getters['items'](name);
    }

    let filter = context.getters.filter(name);
    let url = API_BASE_URL + '/coupon/filter?';
    let queryParams = [];

    if (Object.keys(filter.activeCategories).length) {
        queryParams.push({name: 'categories', value: Object.keys(filter.activeCategories).join(',')});
    }

    if (filter.locationCenter) {
        queryParams.push({name: 'locationCenter', value: filter.locationCenter.latitude + ',' + filter.locationCenter.longitude});
    }

    queryParams.push({name: 'locationRadius', value: filter.locationRadius});
    // Force set deprecated latestCouponsOnly to overwrite cached filter settings
    queryParams.push({name: 'latestCouponsOnly', value: 0});

    url += queryParams.map(param => param.name + '=' + encodeURIComponent(param.value)).join('&');

    try {
        let response = await HTTP.get(url);
        let items = response.data.data;
        let latestItems = response.data.included.latestCoupons.data;

        if (Array.isArray(items) && items.length) {
            let ids = items.map(item => item.id);
            latestItems.forEach(item => ids.push(item.id));
            await context.dispatch('coupon/loadCouponById', ids, {root: true});
        }

        context.commit('updateItems', {name: name, items: items});
        context.commit('updateLatestItems', {name: name, items: latestItems});

    } catch (e) {
        console.error('[couponList/action] loadItems', e);
    }
};
