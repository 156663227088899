<template>
    <div class="c3-icon-wrapper">
        <svg version="1.1" viewBox="0 0 312.52 312.52" xmlns="http://www.w3.org/2000/svg">
            <path :fill="color1"
                  d="m156.26 4.9913e-4a156.26 156.26 0 1 0 156.26 156.26 156.23 156.23 0 0 0-156.26-156.26zm136.1 156.26a136.1 136.1 0 1 1-136.1-136.1 136.09 136.09 0 0 1 136.1 136.1z"/>
            <path :fill="color1"
                  d="m198.38 156.26h-17.522a8.03 8.03 0 0 1-7.75-8.284v-2.663c0-7.7 2.338-15.032 5.649-21.873a28.3 28.3 0 0 0 2.382-17c-1.781-10.918-10.126-19.896-20.338-21.796a24.955 24.955 0 0 0-4.541-0.422c-13.958 0-25.272 12.094-25.272 27.014a27.991 27.991 0 0 0 2.285 11.135c3.512 8.121 6.139 16.642 6.139 25.587v0.017a8.03 8.03 0 0 1-7.75 8.284h-17.523c-13.957 0-25.272 12.095-25.272 27.015v9c0 4.972 3.772 9 8.424 9v18.018c0 4.972 3.772 9 8.424 9h101.09c4.652 0 8.424-4.032 8.424-9v-18.009c4.652 0 8.424-4.032 8.424-9v-9c0-14.929-11.315-27.023-25.273-27.023zm8.424 63.033h-101.09v-18.01h101.09zm8.424-27.014h-117.94v-9c0-9.93 7.558-18.009 16.848-18.009h17.522c8.919 0 16.174-7.755 16.174-17.306 0-9.123-2.2-18.454-6.916-29.364a18.448 18.448 0 0 1-1.508-7.359c1e-3 -9.935 7.556-18.019 16.849-18.019a17.061 17.061 0 0 1 3.1 0.29c6.645 1.235 12.318 7.322 13.484 14.469a18.863 18.863 0 0 1-1.566 11.315c-4.433 9.165-6.589 17.675-6.589 26.012v2.662c0 9.534 7.255 17.289 16.174 17.289h17.522c9.29 0 16.848 8.079 16.848 18.009z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
