export default {
    bonusCards: {
        items: {},
        redeemState: {},
        favoritesByBonusCard: {},
        bonusCardTemplates: [],
        maxBonusCardLimit: null,
        activeBonusCardCount: null
    }
};
