<template>
    <div class="c3-main c3-coupons">
        <div class="c3-page-content">
            <div class="c3-coupon-detail-wrapper">

                <div class="c3-coupon-detail-header-section">
                    <c3-item-media v-if="isCurrentUserTheOwner" :item="coupon">
                        <div slot="actionList">
                            <router-link v-if="!isStateReview && !isStatePublishedAndExpired" :to="{name:'CouponCreateWizardBaseData',params:{id:coupon.id, action: !isDraft ? 'edit' : ''}}"
                                         class="c3-coupon-item-action-list-item">
                                <c3-icon-edit primaryColor="white"/>
                            </router-link>
                            <div v-else class="c3-coupon-item-action-list-item" @click.prevent="editNotAllowed">
                                <c3-icon-edit primaryColor="white"/>
                            </div>
                        </div>
                    </c3-item-media>
                    <c3-item-media v-else :item="coupon" :isFavorite="isFavorite" @toggleFavorite="toggleFavorite"/>
                    <div class="c3-coupon-detail-header-section-content-wrapper">
                        <div class="c3-coupon-detail-header-section-tear-off">
                            <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-top-left"></div>
                            <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-bottom-left"></div>
                            <span>{{ $t('pages.coupon.detail.title') }}</span>
                        </div>
                        <div class="c3-coupon-detail-header-section-content">
                            <div class="c3-coupon-detail-header-section-content-text">
                                <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-top-right"></div>
                                <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-bottom-right"></div>

                                <h1 class="c3-coupon-detail-title">
                                    {{ coupon.titleWithTemplate }}
                                </h1>
                                <div class="c3-coupon-detail-description">{{ coupon.description }}</div>

                                <div class="c3-coupon-detail-redeem-state-wrapper">
                                    <span><strong>{{ $t('coupon.validUntil') }}: </strong>{{ formatDateShort(coupon.validUntil) }}</span>
                                </div>
                                <div v-if="isNative" @click="shareCoupon" class="c3-coupon__share">
                                    <c3-icon-share-alt primaryColor="quinary"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="c3-coupon-detail-redeem-wrapper">
                    <template v-if="isRedeemable">
                        <c3-button :label="$t('pages.coupon.detail.redeem')" @click="scanCouponQrCode"/>
                        <c3-button v-if="isOnlineRedeemable" :label="$t('pages.coupon.detail.redeemOnline')" styling="2" class="c3-coupon-detail__redeem-online" @click="redeemOnline"/>
                    </template>
                    <c3-button v-else-if="isDraft && isCurrentUserTheOwner" :label="$t('pages.coupon.detail.publish')" styling="action" @click="publishCoupon"/>
                    <c3-button v-else-if="isInReview" :label="$t('label.in_examination')" styling="disabled"/>
                    <c3-button v-else-if="isNotYetValid" :label="$t('pages.coupon.detail.notYetActive')" styling="disabled"/>
                    <c3-button v-else-if="isExpired" :label="$t('pages.coupon.detail.expired')" styling="disabled"/>
                    <c3-button v-else :label="$t('pages.coupon.detail.alreadyRedeemed')" styling="disabled"/>
                    <span v-if="lastRedeem">{{ $t('pages.coupon.detail.lastRedeemedOn') }} {{ lastRedeemFormatted }}</span>
                    <div v-else @click="showExplanation" class="c3-coupon-redeem-explanation">
                        <c3-icon-question-circle primaryColor="quinary"/>
                        <span>{{ $t('pages.coupon.detail.explanation') }}</span>
                    </div>
                </div>

                <hr>

                <div class="c3-coupon-detail-section">
                    <h2 class="c3-coupon-detail-company-name">{{ coupon.shop ? coupon.shop.name : coupon.user.company }}</h2>
                    <div v-if="coupon.shop" class="c3-coupon-detail__shopping-center">{{ coupon.user.company }}</div>
                    <c3-store v-for="store in storeCollection" :key="store.id" :customer="coupon.user" :store="store"/>
                </div>

                <hr/>

                <div class="c3-coupon-detail-section" v-if="coupon.legalNotice">
                    <c3-column-one-with-icon icon="c3-icon-info-circle" class="c3-customer-important-information">
                        <template slot="first">
                            <strong>{{ $t('pages.coupon.detail.info') }}</strong><br>
                            <span>{{ coupon.legalNotice }}</span>
                        </template>
                    </c3-column-one-with-icon>
                </div>

                <div class="c3-coupon-detail-section">
                    <div class="c3-coupon-detail-section-action-button">
                        <router-link v-if="coupon.user.id" :to="{name:'CustomerPublicCouponList',params:{id: coupon.user.id}}">
                            <c3-button :label="$t('label.showAllCoupons')" styling="2"/>
                        </router-link>
                        <router-link v-if="coupon.user.id" :to="{name:'CustomerPublicBonusCardList',params:{id: coupon.user.id}}">
                            <c3-button :label="$t('label.showAllBonusCard')" styling="2"/>
                        </router-link>
                    </div>
                </div>
            </div>
            <c3-qr-code-reader :qrCodeScanActive="qrCodeScanActive" @encoded="redeem" @cancel="qrCodeScanActive = false" @close="qrCodeScanActive = false"/>
        </div>
    </div>
</template>

<script>
    import {AffiliateService} from '@/domain/service/affiliateService';
    import {Analytics} from '@/domain/analytics';
    import {Logger} from '@/domain/logger';
    import {CouponStateMixin} from '@/domain/mixin/local/couponState';
    import {PublishCouponMixin} from '@/domain/mixin/local/publishCoupon';
    import {OpeningTimeMixin} from '@/domain/mixin/local/openingTime';
    import {CouponMixin} from '@/domain/mixin/local/coupon';
    import {CouponRedeemStateMixin} from '@/domain/mixin/local/couponRedeemState';
    import {CouponFavoriteMixin} from '@/domain/mixin/local/couponFavorite';
    import {ReverseRedeemMixin} from '@/domain/mixin/local/reverseRedeem';

    import C3QrCodeReader from '@/components/content/qrCode/reader/Index.vue';
    import C3ItemMedia from '@/components/content/coupon/ItemMedia.vue';
    import C3ColumnOneWithIcon from '@/components/layout/column/OneWithIcon.vue';
    import C3CustomerOpeningTimeShort from '@/components/content/customer/OpeningTimeShort.vue';
    import C3Button from '@/components/form/Button.vue';
    import {DateUtility} from "@/domain/utility/date";
    import C3Store from '@/components/content/customer/Store';
    import { TYPE_NORMAL } from '@/types';

    export default {
        mixins: [CouponStateMixin, CouponMixin, CouponRedeemStateMixin, CouponFavoriteMixin, OpeningTimeMixin, PublishCouponMixin, ReverseRedeemMixin],
        components: {
            C3QrCodeReader,
            C3ItemMedia,
            C3Button,
            C3ColumnOneWithIcon,
            C3CustomerOpeningTimeShort,
            C3Store
        },
        data() {
            return {
                loginRoute: {
                    name: 'LoginForm',
                    query: {
                        'redirectAfterLogin':
                        this.$router.resolve({name: this.$route.name, params: this.$route.params, query: {redeem: 1}}).resolved.fullPath
                    }
                },
                qrCodeScanActive: false,
            }
        },
        computed: {
            coupon() {
                let coupon = this.$store.getters['coupon/singleCoupon'](this.$route.params.id, {});
                let localCoupon = Object.assign({}, coupon);
                localCoupon.user = this.$store.getters['customer/singleCustomer'](coupon.user, {});
                localCoupon.category = this.$store.getters['coupon/category'](coupon.category, {});
                return localCoupon;
            },
            storeCollection() {
                return this.$store.getters['userStore/storeListOrderByZipByUser'](this.coupon.user.id);
            },
            isCurrentUserTheOwner() {
                return this.isLoggedIn && this.coupon.user.id === this.currentUser.id;
            },
        },
        methods: {
            formatDateShort: DateUtility.formatDateShort.bind(DateUtility),
            showExplanation() {
                const contentId = this.coupon.type !== TYPE_NORMAL ? 29 : 20;
                this.$c3.staticContentAlertById(contentId);
                Analytics.logCouponExplanation(this.coupon.id);
            },
            redeem(qrToken) {
                this.redeemCoupon(this.coupon.id, qrToken);
            },
            redeemOnline() {
                this.$c3.confirmHtml(
                    'pages.coupon.detail.redeemOnline',
                    this.$t('pages.coupon.detail.confirm.text') + ':<br><strong>' + this.coupon.onlineRedeemCode + '</strong>',
                    () => {
                        if (this.hasInAppBrowser) {
                            window.cordova.InAppBrowser.open(this.coupon.onlineRedeemUrl, '_system');
                        } else {
                            window.open(this.coupon.onlineRedeemUrl, '_blank')
                        }
                    },
                    null,
                    [this.$t('pages.coupon.detail.confirm.shop'), this.$t('label.cancel')],
                )
            },
            scanCouponQrCode() {
                if (this.isLoggedIn) {
                    Analytics.logRedeemCouponInit(this.coupon.id);
                    this.qrCodeScanActive = this.coupon.type === TYPE_NORMAL;
                    if (!this.qrCodeScanActive) {
                        this.showReverseRedeemCode();
                    }
                } else {
                    this.qrCodeScanActive = false;
                    this.$c3.confirmLoginRedirect(
                        () => this.$router.push(this.loginRoute)
                    );
                }
            },
            editNotAllowed() {
                this.$c3.alert('', 'pages.coupon.detail.editNotAllowed');
            },
            async shareCoupon() {
                Analytics.logShareCouponInit(this.coupon.id);
                this.$store.commit('view/showLoading', this.$t('pages.coupon.detail.shareCoupon.loadingMessagePrepareCouponLink'));
                try {
                    let url = await AffiliateService.createDeepLink(
                        this.$route.fullPath,
                        this.$route.fullPath,
                        this.coupon.titleWithTemplate,
                        this.coupon.description,
                        window.MEDIA.COUPON_BASE_URL + '/' + window.MEDIA.SHARE_SIZE + '/' + this.coupon.image,
                        'shareCoupon',
                        '#' + this.coupon.id + ' - ' + this.coupon.titleWithTemplate
                    );

                    if (!url) {
                        throw new Error('AffiliateService.createDeepLink returned an invalid result: ' + JSON.stringify(url));
                    }

                    Analytics.logShareCoupon(this.coupon.id, url);

                    console.debug('[Coupon - Detail] shareCoupon - Start shareWithOptions');
                    let options = {
                        subject: this.$t('pages.coupon.detail.shareCoupon.subject') + ': ' + this.coupon.titleWithTemplate,
                        message: this.$t('pages.coupon.detail.shareCoupon.subject') + ': ' + this.coupon.titleWithTemplate + ' ' + this.$t('label.from') + ' ' + this.coupon.user.company + ' in ' + this.coupon.user.zip + ' ' + this.coupon.user.city,
                        url: url,
                        files: [window.MEDIA.COUPON_BASE_URL + '/' + window.MEDIA.SHARE_SIZE + '/' + this.coupon.image]
                    };

                    await new Promise((resolve, reject) => window.plugins.socialsharing.shareWithOptions(options, resolve, reject));
                } catch (e) {
                    console.error(e);
                    this.$c3.errorAlert('pages.coupon.detail.shareCoupon.createDeepLinkFailed.title', 'pages.coupon.detail.shareCoupon.createDeepLinkFailed.message');
                    Logger.captureException(e);
                }
                this.$store.commit('view/hideLoading');
            }
        },
        async mounted() {
            this.$store.commit('view/showLoading');
            await this.$store.dispatch('coupon/loadCoupon', this.$route.params.id);
            console.debug('Coupon', this.coupon.user);
            await this.$store.dispatch('userStore/loadStoreByUser', {userId: this.coupon.user.id});
            if (this.$route.query.redeem) {
                this.scanCouponQrCode();
            }
            this.$store.commit('view/hideLoading')
        }
    };
</script>
<style lang="scss" scoped>
    $corner-size: 6px;

    .c3-main {
        background-color: $nonary-color;
    }

    .c3-page-content {
        // Fix iOS white screen during scroll: https://stackoverflow.com/q/9807620
        -webkit-transform: translate3d(0, 0, 0);
    }

    .c3-coupon-detail-header-section {
        margin-bottom: 30px;
    }

    .c3-coupon-detail-header-section-content-wrapper {
        position: relative;
        display: flex;
        max-width: calc(100% - 2 * var(--page-content-horizontal-spacing));
        margin: -70px auto 0 auto;

        @media(min-width: $breakpointTablet+1) {
            width: 70vw;
            max-width: 700px;
        }
    }

    .c3-coupon-detail-header-section-tear-off {
        position: relative;
        width: 35px;
        padding: $corner-size 0;
        background-color: $quinary-color;
        background-clip: content-box;
        color: $white-color;

        span {
            position: absolute;
            bottom: 30px;
            left: -15px;
            transform: rotate(-90deg);
            @include montserratMedium();
            font-size: 16px;
        }
    }

    .c3-coupon-detail-header-section-content {
        position: relative;
        flex: 1;
        min-height: 120px;
        padding: $corner-size 0;
        background-clip: content-box;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: $corner-size;
            display: block;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            @include boxShadow();
            pointer-events: none;
            z-index: -1;
        }
    }

    .c3-coupon-detail-header-section-content-text {
        padding: 10px 15px 10px 15px;
        background-color: $white-color;
    }

    .c3-coupon-detail-header-section-corner {
        position: absolute;
        display: block;
        height: 10px;
        width: 100%;
        background-clip: content-box;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            height: $corner-size * 4;
            width: $corner-size * 4;
            border-radius: 50%;
            border: $corner-size solid transparent;
        }

        &.c3-coupon-detail-header-section-corner-top-left {
            top: 0;
            left: 0;
            padding-right: $corner-size;
            border-top-left-radius: 5px;
            background-color: $quinary-color;

            &:after {
                top: -1 * $corner-size * 2;
                right: -1 * $corner-size * 2;
                border-color: $quinary-color;
            }
        }

        &.c3-coupon-detail-header-section-corner-top-right {
            top: 0;
            right: 0;
            padding-left: $corner-size;
            border-top-right-radius: 5px;
            background-color: $white-color;

            &:after {
                top: -1 * $corner-size * 2;
                left: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }

        &.c3-coupon-detail-header-section-corner-bottom-right {
            bottom: 0;
            right: 0;
            padding-left: $corner-size;
            border-bottom-right-radius: 5px;
            background-color: $white-color;

            &:after {
                bottom: -1 * $corner-size * 2;
                left: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }

        &.c3-coupon-detail-header-section-corner-bottom-left {
            bottom: 0;
            left: 0;
            padding-right: $corner-size;
            border-bottom-left-radius: 5px;
            background-color: $quinary-color;

            &:after {
                bottom: -1 * $corner-size * 2;
                right: -1 * $corner-size * 2;
                border-color: $quinary-color;
            }
        }
    }

    .c3-coupon-detail-section {
        padding-left: var(--page-content-horizontal-spacing);
        padding-right: var(--page-content-horizontal-spacing);
    }

    .c3-coupon-detail-title {
        @include openSansBold();
        font-size: 18px;
        color: $quinary-color;
    }

    .c3-coupon-detail-subtitle {
        @include subtitle();
    }

    .c3-coupon-detail-description {
        margin-top: 10px;
    }

    .c3-coupon-detail-redeem-state-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        white-space: nowrap;
        color: $fifth-color;
    }

    .c3-coupon__share {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 28px;
        padding-bottom: 14px;
        box-sizing: content-box;
        padding-right: 18px;
    }

    .c3-coupon-detail-redeem-wrapper {
        width: 100%;
        max-width: 250px;
        margin: 20px auto 20px;
        text-align: center;

        span {
            font-size: 12px;
            color: $senary-color;
        }
    }

    .c3-coupon-detail__redeem-online {
        margin-top: 15px;
    }

    .c3-coupon-redeem-explanation {
        padding-top: 10px;

        .c3-icon-wrapper {
            position: relative;
            top: 3px;
            height: 16px;
            margin-right: 5px;
        }

        span {
            color: $primary-color;
            font-size: 12px;
        }
    }

    .c3-navigation-link {
        display: block;
    }

    .c3-coupon-detail-company-name {
        font-size: 18px;
    }

    .c3-coupon-detail__shopping-center {
        font-size: 13px;
        color: $senary-color;
    }

    .c3-customer-address {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .c3-customer-important-information {
        margin-bottom: 30px;

        strong {
            display: inline-block;
            @include openSansBold();
            padding-bottom: 10px;
        }
    }

    .c3-coupon-detail-section-action-button {
        a, div {
            display: block;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
