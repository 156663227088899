<template>
    <div class="c3-main">
        <div class="c3-page-content">
            <c3-list v-if="items.length" :title="title" :items="items" :name="$route.name"/>
            <c3-no-item-create-now-with-icon v-else :title="$t('pages.customer.publicBonusCard.title')" icon="c3-icon-building">
                <template slot="button">
                    <router-link :to="{name:'BonusCardList'}">
                        <c3-button :label="$t('pages.customer.publicBonusCard.button')"/>
                    </router-link>
                </template>
            </c3-no-item-create-now-with-icon>
        </div>
    </div>
</template>

<script>
    import C3List from '@/components/content/bonusCard/list/Index';
    import C3NoItemCreateNowWithIcon from '@/components/content/coupon/list/NoItemCreateNowWithicon.vue';
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {
            C3List,
            C3NoItemCreateNowWithIcon,
            C3Button
        },
        data() {
            return {}
        },
        computed: {
            title() {
                return this.$t('label.bonusCardFrom') + ' ' + this.customer.company;
            },
            customer() {
                let customer = this.$store.getters['customer/singleCustomer'](Number.parseInt(this.$route.params.id));
                if (null !== customer && typeof customer === 'object') {
                    return customer;
                }
                return {};
            },
            items() {
                let items = this.$store.getters['bonusCard/bonusCardByUser'](Number.parseInt(this.$route.params.id));
                if (Array.isArray(items)) {
                    return items;
                }
                return [];
            },
        },
        methods: {
            loadData() {
                this.$store.commit('view/showLoading');
                this.$store.dispatch('bonusCard/loadBonusCardByCustomer', Number.parseInt(this.$route.params.id))
                    .finally(() => this.$store.commit('view/hideLoading'));
            }
        },
        activated() {
            this.loadData();
        },
        created() {
            this.loadData();
        }
    };
</script>
<style lang="scss" scoped>
    .c3-main {
        background-color: $nonary-color;
    }

    .c3-bonus-card-list-wrapper {
        margin-top: 30px;
    }
</style>

