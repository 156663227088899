/**
 * Modified version of https://github.com/voltraco/localstorage
 */
export const LocalStorage = function (namespace) {
  let _namespace = namespace;
  let _store = window.localStorage;
  let _sep = '\x00';

  let self = {};

  function notFoundException (key) {
    throw 'Key ' + key + ' not found in storage ' + _namespace;
  }

  function namespacedKey (key) {
    return [_namespace, key].join(_sep);
  }

  self.get = (key) => {
    if (!self.has(key)) {
      notFoundException(key);
    }

    const k = [_namespace, key].join(_sep);
    return JSON.parse(_store.getItem(k));
  };

  self.set = (key, value) => {
    if (typeof value === 'undefined') {
      console.error(`Invalid parameters to put, ('${key}', undefined)`);
      return;
    }

    try {
      const k = namespacedKey(key);
      _store.setItem(k, JSON.stringify(value));
    } catch (err) {
      console.error(`Couldn't write value to ${key}: ` + JSON.stringify(value));
    }
  };

  self.has = (key) => {
    return null !== _store.getItem(namespacedKey(key));
  };

  self.remove = (key) => {
    if (self.has(key)) {
      _store.removeItem(namespacedKey(key));
    }
  };

  return self;
};

export default LocalStorage;