require('@/constants');

import Coupons from '@/pages/Coupon/Index.vue';
import CouponList from '@/pages/Coupon/List.vue';

import CouponDetail from '@/pages/Coupon/Detail.vue';

export default [
    {
        path: '/coupon',
        component: Coupons,
        children: [
            {
                path: 'list',
                alias: '/',
                name: 'CouponList',
                component: CouponList,
                meta: {
                    enableCouponFilter: true,
                    pageHeaderLeft: null,
                    pageHeaderInnerRight: {
                        icon: 'c3-icon-redo',
                        event: EVENT.REFRESH.NAME
                    }
                }
            },
            {
                path: ':id',
                name: 'CouponDetail',
                component: CouponDetail,
            },
        ]
    },
];
