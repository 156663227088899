<template>
    <c3-field-wrapper class="c3-form-field-group-switch" :showLabel="false">
        <label :class="className">
            <span class="c3-form-label c3-label-before" v-if="$slots.labelBefore"><slot name="labelBefore"></slot></span>
            <input type="checkbox"
                   class="v-switch-input"
                   :checked="value"
                   @change.stop="toggle">
            <div class="v-switch-core">
                <div class="v-switch-button"/>
            </div>
            <template v-if="labels">
    <span class="v-switch-label v-left"
          v-if="toggled"
          v-html="labelChecked"/>
                <span class="v-switch-label v-right"
                      v-else
                      v-html="labelUnchecked"/>
            </template>
            <span class="c3-form-label c3-label-after" v-if="$slots.labelAfter"><slot name="labelAfter"></slot></span>
        </label>
    </c3-field-wrapper>
</template>

<script>
    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';
    import C3FieldWrapper from '@/components/form/FieldWrapper.vue';

    const constants = {
        labelChecked: 'on',
        labelUnchecked: 'off',
    };

    const contains = (object, title) => {
        return typeof object === 'object' && object.hasOwnProperty(title)
    };

    export default {
        mixins: [FormFieldErrorMixin],
        components: {C3FieldWrapper},
        name: 'ToggleButton',
        props: {
            value: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            styling: {
                type: String,
                validator: value => ['default', 'light'].indexOf(value) > -1,
                default: 'default'
            },
            labels: {
                type: [Boolean, Object],
                default: false,
                validator(value) {
                    return typeof value === 'object'
                        ? (value.checked || value.unchecked)
                        : typeof value === 'boolean'
                }
            },
        },
        computed: {
            className() {
                let {toggled, disabled} = this;

                return ['vue-js-switch', {toggled, disabled}]
            },

            labelChecked() {
                return contains(this.labels, 'checked')
                    ? this.labels.checked
                    : constants.labelChecked
            },
            labelUnchecked() {
                return contains(this.labels, 'unchecked')
                    ? this.labels.unchecked
                    : constants.labelUnchecked
            },
        },
        watch: {
            value(value) {
                this.toggled = !!value
            }
        },
        data() {
            return {
                toggled: !!this.value
            }
        },
        methods: {
            toggle(event) {
                this.toggled = !this.toggled;
                this.$emit('input', this.toggled);
                this.$emit('change', {
                    value: this.toggled,
                    srcEvent: event
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    $height: 26px;
    $width: 50px;
    $margin: 4px;
    $buttonRadius: $height - $margin * 2;

    .vue-js-switch {
        display: flex;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        user-select: none;
        cursor: pointer;
    }

    .v-switch-input {
        opacity: 0;
        position: absolute;
        width: 1px;
        height: 1px;
    }

    .c3-form-label {
        display: inline-block;
        flex: 1;
    }

    .c3-label-before {
        margin-right: auto;
        padding-right: 5px;
    }

    .c3-label-after {
        margin-left: auto;
    }

    .v-switch-label {
        position: absolute;
        top: 0;
        line-height: $height;

        &.v-left {
            left: 10px;
        }

        &.v-right {
            right: 10px;
        }
    }

    .v-switch-core {
        position: relative;
        display: block;

        height: $height;
        width: $width;

        outline: 0;
        margin: 0;

        border-radius: $height / 2;

        background-color: $octonary-color;

        transition: border-color .3s, background-color .3s;
        user-select: none;
    }

    .v-switch-button {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: $buttonRadius;
        height: $buttonRadius;
        overflow: hidden;

        transform: translate3d($margin, $margin, 0);
        transition: 0.4s;
        border-radius: 100%;
        background-color: $white-color;
    }

    .toggled {
        .v-switch-core {
            background-color: $quinary-color;
        }

        .v-switch-button {
            transform: translate3d($width - $height + $margin, $margin, 0);
        }
    }

    .disabled {
        pointer-events: none;
        opacity: 0.6;
    }
</style>
