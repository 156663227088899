<template>
    <div class="c3-coupon-slider">
        <div v-if="title" class="c3-coupon-slider__title">{{title}}</div>
        <div class="c3-coupon-slider__items">
            <c3-slider-item v-for="item in items" :item="item" :key="item.id"/>
        </div>
    </div>
</template>
<script>
  import C3SliderItem from '@/components/content/coupon/slider/Item';

  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
      },
    },
    components: {
      C3SliderItem,
    },
  };
</script>
<style lang="scss" scoped>
    .c3-coupon-slider {
        touch-action: pan-x pan-y;
    }

    .c3-coupon-slider__items {
        display: flex;

        overflow-y: hidden;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;

        @media(max-width: $breakpointTablet + 1) {
            @include hideScrollbar();
        }
    }

    .c3-coupon-slider__item {
        border-left: var(--page-content-horizontal-spacing) solid transparent;
        // Prevent collision of scrollbar with item border/shadow
        margin-bottom: 2px;

        min-width: 1px;
        flex: 0 0 240px;

        &:last-child {
            flex: 0 0 calc(240px + var(--page-content-horizontal-spacing));
            border-right: var(--page-content-horizontal-spacing) solid transparent;
        }
    }

    .c3-coupon-slider__title {
        margin-bottom: 5px;
        margin-left: var(--page-content-horizontal-spacing);
        @include openSansSemiBold();
        font-size: 16px;
    }
</style>
