<template>
    <div class="c3-main c3-page-general-coupocket_for_company">
        <div class="c3-page-content">
            <c3-page-image name="coupocketForCompany"/>
            <div class="c3-page-content-horizontal-padding c3-header-image-button">
                <router-link :to="{name:'CustomerRegistration'}">
                    <c3-button :label="buttonLabel"/>
                    <div class="c3-form-field-additional-information-small">{{buttonAdditionalInformation}}</div>
                </router-link>
            </div>
            <div class="c3-page-content-horizontal-padding c3-page-content-top-padding">
                <h2 v-html="$t('pages.general.coupocketForCompany.title')"></h2>
                <c3-icon-list>
                    <c3-icon-list-item>{{ $t('pages.general.coupocketForCompany.feature1') }}</c3-icon-list-item>
                    <c3-icon-list-item>{{ $t('pages.general.coupocketForCompany.feature2') }}</c3-icon-list-item>
                    <c3-icon-list-item>{{ $t('pages.general.coupocketForCompany.feature3') }}</c3-icon-list-item>
                    <c3-icon-list-item>{{ $t('pages.general.coupocketForCompany.feature4') }}</c3-icon-list-item>
                    <c3-icon-list-item>{{ $t('pages.general.coupocketForCompany.feature5') }}</c3-icon-list-item>
                </c3-icon-list>

                <h2 class="c3-title-spacing-top" v-html="$t('pages.general.coupocketForCompany.title2')"></h2>
                <p class="c3-text-center">{{ $t('pages.general.coupocketForCompany.message') }}</p>

                <h2 class="c3-title-spacing-top">{{ $t('pages.general.coupocketForCompany.title3') }}</h2>
            </div>
            <div>
                <c3-text-with-icon :title="$t('pages.general.coupocketForCompany.list1.title')" icon="c3-icon-check-circle">
                    {{ $t('pages.general.coupocketForCompany.list1.message') }}
                </c3-text-with-icon>

                <c3-text-with-icon :title="$t('pages.general.coupocketForCompany.list2.title')" icon="c3-icon-check-circle">
                    {{ $t('pages.general.coupocketForCompany.list2.message') }}
                </c3-text-with-icon>

                <!--<c3-text-with-icon title="100% flexibel" icon="c3-icon-check-circle">-->
                    <!--Du kannst deine Mitgliedschaft jederzeit ändern oder je nach Abo monatlich kündigen-->
                <!--</c3-text-with-icon>-->

                <c3-text-with-icon :title="$t('pages.general.coupocketForCompany.list3.title')" icon="c3-icon-check-circle">
                    {{ $t('pages.general.coupocketForCompany.list3.message') }}
                </c3-text-with-icon>

                <div class="c3-page-content-horizontal-padding">
                    <router-link :to="{name:'CustomerRegistration'}">
                        <c3-button :label="buttonLabel"/>
                        <div class="c3-form-field-additional-information-small">{{buttonAdditionalInformation}}</div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import C3PageImage from '@/components/media/PageImage';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3IconList from '@/components/layout/list/icon/Index';
    import C3IconListItem from '@/components/layout/list/icon/Item';
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {
            C3PageImage,
            C3TextWithIcon,
            C3Button,
            C3IconList,
            C3IconListItem
        },
        computed: {
            buttonStaticContent() {
                return this.$store.getters['staticContent/singleContent'](27);
            },
            buttonLabel() {
                return this.buttonStaticContent ? this.buttonStaticContent.title : '';
            },
            buttonAdditionalInformation() {
                return this.buttonStaticContent ? this.buttonStaticContent.text : '';
            }
        },
        data() {
            return {
                mounted() {
                    this.$store.dispatch('staticContent/loadBulkContent', [27]);
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .c3-header-image-button {
        margin-top: -22px;
    }

    h2 {
        text-align: center;
        margin-bottom: 10px;
    }

    .c3-title-spacing-top {
        margin-top: 30px;
    }

    .c3-content-text-with-icon /deep/ h2 {
        margin-top: 10px;
    }
</style>