import moment from 'moment';

export const DateUtility = {
    time() {
        return Number.parseInt(moment().format('X'));
    },
    /**
     *
     * @param {Date|string} date
     * @param {string} format
     * @param {string|*} [defaultValue='']
     * @returns {string|*}
     */
    format(date, format, defaultValue) {
        if (typeof date === 'string') {
            date = moment(date);
        }

        if (moment.isMoment(date)) {
            return date.format(format);
        }

        if (undefined === defaultValue) {
            return '';
        }
        return defaultValue;
    },
    /**
     *
     * @param {Date|string} date
     * @param {string|*} [defaultValue='']
     * @returns {string}
     */
    formatDateTimeShort: function (date, defaultValue) {
        return this.format(date, DATE.FORMAT.DATETIME_SHORT, defaultValue);
    },
    /**
     *
     * @param {Date|string} date
     * @param {string|*} [defaultValue='']
     * @returns {string}
     */
    formatDateTime(date, defaultValue) {
        return this.format(date, DATE.FORMAT.DATETIME_LONG, defaultValue);
    },
    /**
     *
     * @param {Date|string} date
     * @param {string|*} [defaultValue='']
     * @returns {string}
     */
    formatDateShort: function (date, defaultValue) {
        return this.format(date, DATE.FORMAT.DATE_SHORT, defaultValue);
    },
    /**
     *
     * @param {Date|string} date
     * @param {string|*} [defaultValue='']
     * @returns {string}
     */
    formatDate(date, defaultValue) {
        return this.format(date, DATE.FORMAT.DATE_LONG, defaultValue);
    },
    /**
     *
     * @param {number} totalMinutes
     * @returns {string}
     */
    formatMinutesToHoursAndMinutes(totalMinutes) {
        let hours = Math.floor(totalMinutes / 60);
        if (hours < 10) {
            hours = '0' + hours;
        }

        let minutes = Math.floor(totalMinutes % 60);
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return hours + ':' + minutes;
    },
    /**
     *
     * @param {number} minutes
     * @param {string} type
     * @returns {Date}
     */
    convertMinutesToDate(minutes, type) {
        return this.convertHoursAndMinutesToDate(this.formatMinutesToHoursAndMinutes(minutes), type);
    },
    /**
     *
     * @param {string} hoursAndMinutes
     * @returns {number}
     */
    convertHoursAndMinutesToMinutes(hoursAndMinutes) {
        let parts = hoursAndMinutes.split(':');
        return Number.parseInt(parts[0], 10) * 60 + Number.parseInt(parts[1], 10);
    },
    /**
     * Convert minutes (0 - 1440) to Date object with the equal time. The current day is used as date
     * &nbsp;
     * **ATTENTION**:
     * 1440 is not a valid time (24:00) so it's converted to 23:59 with 999 milliseconds.
     * This special case is handled in dateToMinutes, but you should be careful with own converting methods!
     *
     * @param {string} string
     * @param {string} type
     * @returns {Date}
     */
    convertHoursAndMinutesToDate(string, type) {
        if (typeof string !== 'string') {
            return null;
        }

        let parts = string.split(':');
        let hours = Number.parseInt(parts[0], 10);
        let minutes = Number.parseInt(parts[1], 10);
        let milliseconds = 0;

        if (hours === 24) {
            hours = 23;
            minutes = 59;
            milliseconds = 999;
        }

        let date = new Date();

        date.setHours(hours);
        date.setMinutes(minutes);

        switch (type) {
            case 'start':
                date.setSeconds(0);
                break;
            case 'end':
                date.setSeconds(59);
                break;
        }
        date.setMilliseconds(milliseconds);

        return date;
    },
    /**
     * Convert Date object to minutes (0 - 1440) - The date part is ignored
     * &nbsp;
     * **ATTENTION**:
     * A Date object with time 23:59 and milliseconds 999 is handled as 24:00 = 1440 minutes!
     *
     * @param {Date|string} date
     * @returns {?number}
     */
    convertDateToMinutes(date) {
        if (date instanceof Date) {
            date = moment(date);
        } else if (typeof date === 'string' && date.indexOf('T') && date.indexOf('+')) {
            date = moment(date);
        }

        if (moment.isMoment(date)) {
            let minutes = this.convertHoursAndMinutesToMinutes(date.format('H:m'));
            return 1439 === minutes && 999 === date.milliseconds() ? 1440 : minutes;
        }

        return null;
    },
    generateTimeOptions(minuteInterval) {
        let options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += minuteInterval) {
                options.push(hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0'));
            }
        }

        return options;
    }
};
