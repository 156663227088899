
const getLocation = (isIos= false) => {
    return isIos ? cordova.file.documentsDirectory : cordova.file.externalRootDirectory;
};

const getFileSystem = async(isIos) => {
    const storageLocation = getLocation(isIos);
    return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(storageLocation, resolve, reject);
    });
};

const getDirectory = async(fileSystem) => {
    return new Promise((resolve, reject) => {
        fileSystem.getDirectory('Download', {
            create: true,
            exclusive: false
        },
        resolve,
        reject);
    });
};

const getFileEntry = async(directory, fileName) => {
    return new Promise((resolve, reject) => {
        directory.getFile(fileName, { create: true, exclusive: false }, resolve, reject);
    });
};

const getWriter = async(fileEntry) => {
    return new Promise((resolve, reject) => {
        fileEntry.createWriter((writer) => resolve({ writer, fileEntry }), reject);
    });
};

const writeToFile = async(writer, fileName, data, fileEntry) => {
    return new Promise((resolve, reject) => {
        writer.onwriteend = () => {
            const fileInfo = {
                name: fileName,
                size: formatBytes(writer.length),
                localUrl: fileEntry.nativeURL,
            };
            resolve(fileInfo);
        };

        writer.seek(0);
        writer.write(data);
    });
};

export const saveToFile = (fileName, data, isIos) => {
    return new Promise((returnResolve, returnReject) => {
        return getFileSystem(isIos)
        .then(fileSystem => getDirectory(fileSystem))
        .then(directory => getFileEntry(directory, fileName))
        .then(fileEntry => getWriter(fileEntry))
        .then(({ writer, fileEntry }) => writeToFile(writer, fileName, data, fileEntry))
        .then(returnResolve)
        .catch((err) => {
            console.log(err);
            returnReject(err);
        });
    });
};

export const formatBytes = (bytes, decimals = 1) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const openPdf = (localUrl) => {
    cordova.plugins.fileOpener2.showOpenWithDialog(
        localUrl,
        'application/pdf',
        {
            error : function(e) {
                console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
            },
            position : [0, 0]
        }
    );
}
