<template>
    <div class="c3-one-column-with-icon-wrapper">
        <div class="c3-column c3-column-icon">
            <slot name="icon">
                <component :is="icon" primaryColor="quinary"/>
            </slot>
        </div>
        <div class="c3-column">
            <slot name="first"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String
            },
        },
        computed: {},
        methods: {}
    };
</script>
<style lang="scss" scoped>
    $icon-column-width: 30px;
    .c3-one-column-with-icon-wrapper {
        display: flex;
    }

    .c3-column {
        width: 100%;
    }

    .c3-column-icon {
        width: $icon-column-width;
        height: 18px;
    }
</style>