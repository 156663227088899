import {HTTP} from '@/domain/api/http';
import {DateUtility} from '@/domain/utility/date';

const GEOCODING_MAX_AGE = 1200;

export const address = async (context, coordinate) => {
    let cachedResult = context.getters['address'](coordinate);
    if (null === cachedResult || DateUtility.time() - GEOCODING_MAX_AGE > cachedResult.lastUpdate) {
        let lat = coordinate.latitude.toFixed(POSITION.PRECISION);
        let lng = coordinate.longitude.toFixed(POSITION.PRECISION);
        let response = await HTTP.get(API_BASE_URL + '/geocoding/' + lat + ',' + lng + '/address');
        let address = response.data.data;
        context.commit('updateGeocoding', {coordinate: coordinate, address: address});
    }
};