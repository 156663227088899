<template>
    <div class="c3-form-field-group c3-form-field-group-time-range">
        <div class="c3-form-field-wrapper c3-form-field-wrapper-time-range">
            <div class="c3-form-field-time-range">
                <label :for="startInputId" class="c3-form-field-time-range-start-label">{{ $t('label.from') }}</label>
                <input v-if="useNativeTimeInput" type="time" :value="localStart" @input="debounceStartInput" :id="startInputId"/>
                <select v-else v-model="localStart" :id="startInputId">
                    <option v-for="timeOption in timeOptionsStart" :key="timeOption">{{timeOption}}</option>
                </select>

                <label :for="endInputId" class="c3-form-field-time-range-end-label">{{ $t('label.until') }}</label>
                <input v-if="useNativeTimeInput" type="time" :value="localEnd" @input="debounceEndInput" :id="endInputId"/>
                <select v-else v-model="localEnd" :id="endInputId">
                    <option v-for="timeOption in timeOptionsEnd" :key="timeOption">{{timeOption}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import {DateUtility} from '@/domain/utility/date';
    import {debounce} from "debounce";

    const TIME_OPTION_INTERVAL = 15;

    let DEBOUNCE_TIME = 0;

    export default {
        props: {
            id: {
                type: String
            },
            label: {
                type: String
            },
            start: {
                type: Number,
                required: true
            },
            end: {
                type: Number,
                required: true
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 1440
            },
            step: {
                type: Number,
                default: 15
            },
            tooltip: {
                typ: Boolean,
                default: true
            },
        },
        data() {
            return {
                useNativeTimeInput: null,
                timeOptionsStart: DateUtility.generateTimeOptions(TIME_OPTION_INTERVAL),
                timeOptionsEnd: DateUtility.generateTimeOptions(TIME_OPTION_INTERVAL).slice(1).concat(['23:59'])
            }
        },
        computed: {
            localStart: {
                get() {
                    let value = this.formatMinutesForNativeInput(this.start, this.min);
                    if (!this.useNativeTimeInput && -1 === this.timeOptionsStart.indexOf(value)) {
                        // Value is not available in timeOptionsStart so we prepend the value to avoid an empty select field
                        this.timeOptionsStart.push(value);
                        this.timeOptionsStart.sort();
                    }
                    return value;
                },
                set(value) {
                    if (this.isValidHoursAndMinutes(value)) {
                        value = DateUtility.convertHoursAndMinutesToMinutes(value);
                    } else {
                        value = this.min;
                    }

                    this.$emit('update:start', value);

                    // Force end to be larger than start
                    if (value >= this.end) {
                        if (this.useNativeTimeInput) {
                            this.$emit('update:end', value + 1 <= this.max ? value + 1 : this.max);
                        } else {
                            this.$emit('update:end', value + TIME_OPTION_INTERVAL <= this.max ? value + TIME_OPTION_INTERVAL : this.max);
                        }
                    }
                }
            },
            localEnd: {
                get() {
                    let value = this.formatMinutesForNativeInput(this.end, this.max);
                    if (!this.useNativeTimeInput && -1 === this.timeOptionsEnd.indexOf(value)) {
                        // Value is not available in timeOptionsStart so we prepend the value to avoid an empty select field
                        this.timeOptionsEnd.push(value);
                        this.timeOptionsEnd.sort();
                    }
                    return value;
                },
                set(value) {
                    if (this.isValidHoursAndMinutes(value)) {
                        value = DateUtility.convertHoursAndMinutesToMinutes(value);
                    } else {
                        value = this.max;
                    }

                    if (0 === value) {
                        // Handle 24:00
                        value = this.max;
                    }
                    this.$emit('update:end', value);

                    // Force start to be smaller than end
                    if (value <= this.start) {
                        if (this.useNativeTimeInput) {
                            this.$emit('update:start', value - 1 >= this.min ? value - 1 : this.min);
                        } else {
                            this.$emit('update:end', value - TIME_OPTION_INTERVAL <= this.min ? value - TIME_OPTION_INTERVAL : this.min);
                        }
                    }
                }
            },
            startInputId() {
                return this.guid + '_start';
            },
            endInputId() {
                return this.guid + '_end';
            }
        },
        methods: {
            debounceStartInput(e) {
                this.localStart = e.target.value;
            },
            debounceEndInput(e) {
                this.localEnd = e.target.value;
            },
            formatMinutesForNativeInput(minutes, defaultMinutes) {
                let value = DateUtility.formatMinutesToHoursAndMinutes(minutes);
                if (!this.isValidHoursAndMinutes(value)) {
                    value = DateUtility.formatMinutesToHoursAndMinutes(defaultMinutes);
                }

                if (['24:00'].indexOf(value) > -1) {
                    value = '00:00';
                }

                return value;
            },
            isValidHoursAndMinutes(value) {
                if (typeof value !== 'string' || value.length !== 5) {
                    return false;
                }

                if (value === '24:00') {
                    // Allow 24:00 because 23:59 looks ugly
                    return true;
                }

                return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
            },
            /**
             * Check if browser supports input type "time"
             * @see https://stackoverflow.com/a/10199306
             */
            checkNativeTimeSupport() {
                if (this.isApp) {
                    // Always use nativeTimeInput in app
                    this.useNativeTimeInput = true;
                    return;
                }

                let supportsNativeTimeSupport;
                try {
                    let input = document.createElement('input');
                    input.setAttribute('type', 'time');

                    let invalidTimeValue = 'invalid-time-value';
                    input.setAttribute('value', invalidTimeValue);

                    supportsNativeTimeSupport = input.value !== invalidTimeValue;
                } catch (e) {
                    supportsNativeTimeSupport = false;
                }

                this.useNativeTimeInput = supportsNativeTimeSupport;
            },
        },
        created() {
            this.checkNativeTimeSupport();
            if (!this.isApp && this.useNativeTimeInput) {
                DEBOUNCE_TIME = 700;

                this.debounceStartInput = debounce((e) => {
                    this.localStart = e.target.value;
                }, DEBOUNCE_TIME);

                this.debounceEndInput = debounce((e) => {
                    this.localEnd = e.target.value;
                }, DEBOUNCE_TIME);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .c3-form-field-time-range-start-label {
        display: block;
        margin-right: 15px;
    }

    .c3-form-field-time-range-end-label {
        display: block;
        margin-left: 15px;
        margin-right: 15px;
    }

    .c3-form-field-time-range {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            flex: 1;

            @include baseFieldFont();
            -webkit-appearance: none;
            border: 1px solid #ccc;
            border-radius: 0;
            box-shadow: none;
            outline: none;
            background-color: #fff;

            text-align: center;

            &::-webkit-clear-button,
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                display: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                margin: 0;
            }
        }

        select {
            flex: 1;
            height: 26px;

            @include baseFieldFont();
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 0;
            box-shadow: none;
            outline: none;

            &, option {
                text-align: center;
                text-align-last: center;
            }

        }
    }
</style>
