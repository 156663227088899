export default {
    install: function (Vue, options) {
        Vue.mixin({
            computed: {
                currentUser() {
                    let user = this.$store.getters['user/user'];
                    if (null === user || typeof user !== 'object') {
                        return {};
                    }
                    return user;
                },
                originalUser() {
                    return this.$store.getters['user/originalUser']
                },
                isLoggedIn() {
                    return this.$store.getters['user/isLoggedIn'];
                },
                isImpersonate() {
                    return this.$store.getters['user/isImpersonate'];
                },
                hasUserImpersonates() {
                    return this.$store.getters['user/hasUserImpersonates'];
                },
                isCustomer() {
                    return this.$store.getters['user/isCustomer'];
                },
                isShoppingCenter() {
                    return this.$store.getters['user/hasRole'](USER_ROLE.SHOPPING_CENTER)
                }
            }
        })
    },
};
