<template>
    <div class="c3-form-field-group c3-form-field-group-date">
        <div class="c3-form-field-wrapper c3-form-field-wrapper-date" :class="cssClasses">
            <label :for="id" class="c3-form-label">{{label}}</label>

            <input :type="useNativeDate ? 'date' :'text'" :id="id" ref="input"
                   :value="localValue" @input="update"
                   @focus="hasFocus = true" @blur="hasFocus = false"
                   class="c3-form-field-date" :class="{'c3-form-field-date--native':useNativeDate,'c3-form-field-date--flatpickr':!useNativeDate}"/>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';

    export default {
        mixins: [FormFieldErrorMixin],
        props: {
            id: {
                type: String
            },
            label: {
                type: String
            },
            value: {
                type: [String, Date, Object]
            },
            min: {
                type: String
            },
            max: {
                type: String
            },
            errorMessage: {
                type: String,
                default: 'error.invalidValue'
            },
        },
        data() {
            return {
                hasFocus: false,
                useNativeDate: null,
                isFlatpickrLoaded: false,
            }
        },
        computed: {
            dateFormat() {
                return this.useNativeDate ? 'YYYY-MM-DD' : 'DD.MM.YYYY';
            },
            localValue() {
                let value = this.value;

                if (value instanceof Date) {
                    value = moment(value);
                }

                if (moment.isMoment(value)) {
                    value = value.format(this.dateFormat);
                }

                return value;
            },
            isEmpty() {
                return null === this.value || (!this.value instanceof Date && 0 === this.value.length);
            },
            cssClasses() {
                return {
                    'c3-form-field-group--has-error': this.isFaulty,
                    'c3-form-field-group-date--not-empty': !this.isEmpty,
                    'c3-form-field-group-date--focus': this.hasFocus
                };
            }
        },
        methods: {
            checkNativeDateSupport() {
                let input = document.createElement('input');
                let value = 'a';
                input.setAttribute('type', 'date');
                input.setAttribute('value', value);
                this.useNativeDate = input.value !== value;
            },
            update() {
                let date = this.$refs.input.value;
                let dateObject = moment(date, this.dateFormat);
                this.$emit('input', dateObject);
            }
        },
        created() {
            this.checkNativeDateSupport();
        },
        mounted() {
            if (!this.useNativeDate) {
                import(/* webpackChunkName: "flatpickr" */ 'flatpickr').then(m => {
                    this.isFlatpickrLoaded = true;
                    m.default(this.$refs.input, {
                        dateFormat: "d.m.Y",
                    });
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .c3-form-field-date--native {
        &::-webkit-inner-spin-button,
        &::-webkit-clear-button {
            -webkit-appearance: none;
            display: none;
        }
    }

    .c3-form-field-date--flatpickr {
        -webkit-appearance: none;

        &::-webkit-inner-spin-button,
        &::-webkit-clear-button {
            -webkit-appearance: none;
            display: none;
        }
    }
</style>
