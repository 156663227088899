export const IconMixin = {
    props: {
        primaryColor: {
            type: String,
            default: ''
        },
        secondaryColor: {
            type: String,
            default: ''
        },
    },
    computed: {
        color1() {
            return this.determineColor(this.primaryColor, COLORS.PRIMARY);
        },
        color2() {
            return this.determineColor(this.secondaryColor, COLORS.SECONDARY);
        }
    },
    methods: {
        determineColor(color, defaultColor) {
            if (typeof color !== 'string' || 0 === color.length) {
                return defaultColor;
            }
            color = color.toUpperCase();

            if (color.indexOf('RGB') > -1) {
                return color;
            }

            if (color === COLORS.TRANSPARENT) {
                return COLORS.TRANSPARENT;
            }

            if (COLORS.hasOwnProperty(color)) {
                return COLORS[color];
            }

            return '#' + color.replace('#', '');
        }
    }
};