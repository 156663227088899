<template>
    <div class="c3-form-field-group" :class="groupClasses">
        <div class="c3-form-field-wrapper c3-form-field-wrapper-input" :class="wrapperClasses">
            <label v-if="showLabel && $parent.label" :for="$parent.id" class="c3-form-label">{{$parent.label}}</label>
            <slot></slot>
            <c3-error-icon :isVisible="$parent.showErrorIcon"/>
        </div>
        <div v-if="$parent.description" class="c3-form-field-group__description">{{$parent.description}}</div>
        <div v-if="$parent.showErrorMessage" class="c3-form-field-group-error-message">{{$t($parent.errorMessage)}}</div>
    </div>
</template>

<script>
    /**
     * This component directly access $parent properties to avoid props boilerplate.
     * Normally $parent should be a form field component, be careful if you use it in other places!
     * Expected props:
     * - id
     * - label
     * - description
     * - type
     * - styling
     * - disabled
     * - isEmpty
     * - hasFocus
     * - hasError
     * - showErrorMessage
     * - showErrorIcon
     * - errorMessage
     */

    import C3ErrorIcon from '@/components/form/ErrorIcon.vue';

    export default {
        components: {C3ErrorIcon},
        props: {
            showLabel: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            groupClasses() {
                let classes = [];

                if (this.$parent.type) {
                    classes.push('c3-form-field-group-' + this.$parent.type);
                }
                if (this.$parent.disabled) {
                    classes.push('c3-form-field-group--disabled');
                }
                if (this.$parent.labelStyling) {
                    classes.push('c3-form-field-group--label-styling-' + this.$parent.labelStyling);
                }
                if (this.$parent.styling) {
                    classes.push('c3-form-field-group--styling-' + this.$parent.styling);
                }
                if (this.$parent.hasError) {
                    classes.push('c3-form-field-group--error');
                }
                if (this.$parent.isEmpty) {
                    classes.push('c3-form-field-group--empty');
                } else {
                    classes.push('c3-form-field-group--not-empty');
                }
                if (this.$parent.hasFocus) {
                    classes.push('c3-form-field-group--focus');
                }

                return classes.join(' ');
            },
            wrapperClasses() {
                let classes = [];

                if (this.$parent.type) {
                    classes.push('c3-form-field-wrapper-' + this.$parent.type);
                }
                if (this.$parent.labelStyling) {
                    classes.push('c3-form-field-wrapper--label-styling-' + this.$parent.labelStyling);
                }
                if (this.$parent.styling) {
                    classes.push('c3-form-field-wrapper--styling-' + this.$parent.styling);
                }
                if (this.$parent.hasError) {
                    classes.push('c3-form-field-wrapper--error');
                }
                if (this.$parent.isEmpty) {
                    classes.push('c3-form-field-wrapper--empty');
                } else {
                    classes.push('c3-form-field-wrapper--not-empty');
                }
                if (this.$parent.hasFocus) {
                    classes.push('c3-form-field-wrapper--focus');
                }

                return classes.join(' ');
            },
        },
    };
</script>
