import moment from 'moment';
import baseApiConverter from '@/domain/api/converter/base';
import userConverter from '@/domain/api/converter/user';
import {StoreConverter} from '@/domain/api/converter/store';

export const BonusCardConverter = {
    fromApi: function (item) {
        item = baseApiConverter.modifiedAndCreatedFromApi(item);

        if (item.publicationDate) {
            item.publicationDate = moment(item.publicationDate);
        }

        if (item.validFrom) {
            item.validFrom = moment(item.validFrom);
        }

        if (item.validUntil) {
            item.validUntil = moment(item.validUntil);
        }

        return item;
    },
    fromApiCollection: function (items) {
        return items.map(item => this.fromApi(item));
    },
    extractIncluded(included) {
        const data = {};
        if (typeof included === 'object') {
            if (included.user) {
                data.user = userConverter.fromApiCollection(included.user.data);
            }
            if (included.store) {
                data.store = StoreConverter.fromApiCollection(included.store.data);
            }
        }
        return data;
    },
    extractIncludedFromCollection: function (included) {
        return this.extractIncluded(included);
    },
    toApi: function (item) {
        item = baseApiConverter.modifiedAndCreatedToApi(item);

        if (item.hasOwnProperty('id')) {
            delete item.id;
        }

        if (item.hasOwnProperty('state')) {
            delete item.state;
        }

        if (item.hasOwnProperty('validFrom')) {
            item.validFrom = item.validFrom.format('DD.MM.YYYY HH:mm');
        }

        if (item.hasOwnProperty('validUntil')) {
            item.validUntil = item.validUntil.format('DD.MM.YYYY HH:mm');
        }

        if (item.hasOwnProperty('publicationDate')) {
            delete item.publicationDate;
        }

        if (typeof item.image === 'object' && item.image.publicName) {
            item.image = item.image.publicName;
        }

        return item;
    }
};
