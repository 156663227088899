<template>
    <div class="c3-title-with-subtitle">
        <h2 v-if="title">{{title}}</h2>
        <h3 v-if="subtitle">{{subtitle}}</h3>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            title: {
                type: String
            },
            subtitle: {
                type: String
            }
        },
    };
</script>
<style lang="scss" scoped>
    h2 {
        @include openSansSemiBold();
        font-size: 18px;
        color: $primary-color;
    }

    h3 {
        @include openSansBold();
        font-size: 14px;
        color:$quinary-color;
    }
</style>