<template>
    <div class="c3-bonus-card-item-media">
        <c3-bonus-card-image :bonusCard="item" :isVisible="isMediaVisible"/>

        <div class="c3-bonus-card-item-action-list">
            <slot name="actionList">
                <div class="c3-bonus-card-item-action-list-item" @click.prevent="$emit('toggleFavorite')">
                    <c3-icon-c3-heart-full :primaryColor="iconFavoritePrimaryColor" :secondaryColor="iconFavoriteSecondaryColor"/>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    import C3BonusCardImage from '@/components/content/bonusCard/Image.vue';

    export default {
        components: {
            C3BonusCardImage
        },
        props: {
            isFavorite: {
                type: Boolean
            },
            isMediaVisible: {
                type: Boolean,
                default: true
            },
            item: Object
        },
        data() {
            return {}
        },
        computed: {
            iconFavoritePrimaryColor() {
                return this.isFavorite ? 'tertiary' : 'transparent';
            },
            iconFavoriteSecondaryColor() {
                return 'white'
            }
        }
    };
</script>
<style lang="scss" scoped>
    .c3-bonus-card-item-media {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .c3-bonus-card__badge {
        width: 130px;

        position: absolute;
        top: 63px;
        left: -28px;

        padding: 5px 0;
        background-color: $quinary-color;

        text-align: center;

        transform: rotate(-45deg);
        transform-origin: 0 0;

        @include boxShadow();
    }

    .c3-bonus-card__badge-content {
        transform: translateZ(0);
        @include openSansBold();
        font-size: 14px;
        color: $white-color;
    }

    .c3-bonus-card-item-action-list {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 5;
    }

    .c3-bonus-card-item-action-list-item {
        position: relative;
        display: block;
        height: calc(30px + 5px + var(--page-content-horizontal-spacing));
        padding: var(--page-content-horizontal-spacing) var(--page-content-horizontal-spacing) 5px 5px;

        &:after {
            content: "";
            position: absolute;
            top: -120%;
            right: -120%;
            height: 270%;
            width: 270%;
            background-image: radial-gradient(circle at center center, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
            filter: blur(13px);
            border-radius: 50%;
            opacity: 0.7;
        }

        & /deep/ .c3-icon-wrapper,
        & /deep/ .c3-icon {
            // Above background effect
            position: relative;
            z-index: 1;
        }
    }
</style>
