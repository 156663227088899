<template>
    <div class="c3-customer-address">
        <c3-two-with-icon icon="c3-icon-map-marker-alt">
            <template slot="first">
                {{ store.address.street }}<br>
                {{ store.address.zip }} {{ store.address.city }}
                <template v-if="additionalText">
                    <br>
                    <span class="c3-customer-address__additional-text">{{ additionalText }}</span>
                </template>
                <slot v-else name="additionalText"></slot>
            </template>
            <template slot="second">
                <div class="c3-customer-address__action-list">
                    <a v-if="phoneUrl" :href="phoneUrl" class="c3-customer-address__action-icon">
                        <c3-icon-phone primaryColor="quinary"/>
                    </a>
                    <c3-external-link v-if="customer.website" :to="customer.website" :nativeBrowser="true" class="c3-customer-address__action-icon">
                        <c3-icon-globe primaryColor="quinary"/>
                    </c3-external-link>
                    <a :href="navigationUrl" target="_blank" class="c3-customer-address__action-icon" @click="handleNavigationClick">
                        <c3-icon-map-marker-alt primaryColor="quinary"/>
                    </a>
                </div>
            </template>
        </c3-two-with-icon>
    </div>
</template>

<script>
    import C3ExternalLink from '@/components/link/ExternalLink';
    import C3TwoWithIcon from '@/components/layout/column/TwoWithIcon.vue';

    export default {
        components: {
            C3ExternalLink,
            C3TwoWithIcon
        },
        props: {
            customer: {
                type: Object,
                required: true
            },
            store: {
                type: Object,
                required: true
            },
            additionalText: {
                type: String,
                required: false,
                default: null
            }
        },
        computed: {
            address() {
                if (this.store && this.store.address && this.store.address.street) {
                    return this.store.address.street + ' ' + this.store.address.zip + ' ' + this.store.address.city;
                }
            },
            phoneUrl() {
                return this.store.phone ? `tel:${this.store.phone}` : '';
            },
            navigationUrl() {
                let userPosition = this.$store.getters['user/position'];
                if (null !== userPosition.lastUpdate) {
                    let from = userPosition.coordinates.latitude + ',' + userPosition.coordinates.longitude;
                    return this.$c3.determineNavigationLink(from, this.address);
                }
                return this.$c3.determineNavigationLink(null, this.address);
            },
        },
        methods: {
            handleNavigationClick(e) {
                try {
                    launchnavigator.navigate(this.address);
                    e.preventDefault();
                } catch (e) {
                }
            },
        }
    };
</script>
<style lang="scss" scoped>
    .c3-customer-address {
        display: block;
        color: $primary-color;
        font-size: 14px;
    }

    .c3-customer-address /deep/ .c3-column-last {
        position: relative;
        flex: 0 0 24px;
        overflow: hidden;
    }

    .c3-customer-address__action-list {
        margin-top: -15px;
    }

    .c3-customer-address__action-icon {
        display: block;
        height: 24px;
        margin-top: 15px;
        text-align: center;
    }
</style>
