import {Analytics} from '@/domain/analytics';

export const BonusCardFavoriteMixin = {
    computed: {
        isFavorite() {
            if (this.bonusCard) {
                return this.$store.getters['bonusCard/isFavoriteByBonusCard'](this.bonusCard.id);
            }
            return null;
        },
    },
    methods: {
        toggleFavorite() {
            if (!this.isLoggedIn) {
                let redirectAfterLogin = this.$router.resolve({name: this.$route.name, params: this.$route.params}).resolved.fullPath;

                this.$c3.confirmLoginRedirect(
                    () => this.$router.push({name: 'Login', query: {redirectAfterLogin: redirectAfterLogin}})
                );
                return;
            }

            if (this.isFavorite) {
                this.$store.dispatch('bonusCard/removeOneFavoriteBonusCard', this.bonusCard.id);
                // Analytics.logRemoveBonusCardFavorite(this.bonusCard.id);
            } else {
                this.$store.dispatch('bonusCard/addOneFavoriteByBonusCard', this.bonusCard.id);
                // Analytics.logAddBonusCardFavorite(this.bonusCard.id);
            }
        }
    },
};
