export const isFavoriteByBonusCard = (state) => (bonusCardId) => {
    return state.bonusCards.favoritesByBonusCard.hasOwnProperty(bonusCardId);
};

export const bonusCardByUser = (state) => (userId) => {
    return Object.values(state.bonusCards.items).filter(item => item.user === userId);
};

export const bonusCardTemplates = (state) => {
    return state.bonusCards.bonusCardTemplates;
};

export const activeBonusCardCount = (state) => state.bonusCards.activeBonusCardCount;

export const maxBonusCardLimit = (state) => state.bonusCards.maxBonusCardLimit;
