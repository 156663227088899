<template>
    <div class="c3-bonus-card-list-wrapper">
        <slot name="header">
            <c3-bonus-card-list-header :name="name" :title="title"/>
        </slot>
        <div class="c3-bonus-card-list">
            <template v-if="items && items.length > 0">
                <template v-for="(item,index) in items">
                    <template v-if="showStaticItems && index === 3">
                        <component :is="loadStaticItemComponent(staticItemComponentName)" :key="`static-${index}`"/>
                    </template>
                    <slot :item="item">
                        <c3-item :item="item" :key="item.id"/>
                    </slot>
                </template>
            </template>
            <div v-else class="c3-bonus-card-simple-list--no-entries">
                <slot name="noEntries">
                    <c3-no-item/>
                </slot>
            </div>
        </div>
        <portal to="bonus_card_location">
            <c3-bonus-card-location-drawer/>
        </portal>
    </div>
</template>

<script>
    import C3BonusCardListHeader from '@/components/content/bonusCard/list/Header';
    import C3Item from '@/components/content/bonusCard/list/Item.vue';
    import C3NoItem from '@/components/content/bonusCard/list/NoItem.vue';
    import C3BonusCardLocationDrawer from '@/components/content/bonusCard/locationDrawer/Index';

    export default {
        components: {
            C3BonusCardListHeader,
            C3Item,
            C3NoItem,
            C3BonusCardLocationDrawer
        },
        props: {
            name: {
                type: String,
                required: true
            },
            title: {
                type: String
            },
            noEntries: {
                type: String,
                default: 'coupon.noEntries'
            },
            items: {
                type: Array
            },
            showStaticItems: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {staticItemComponents: {}}
        },
        computed: {
            isActiveCustomerAndHasActiveCoupons() {
                if (!this.isLoggedIn || !this.isCustomer) {
                    return false;
                }

                if (!this.$store.getters['user/hasMembershipAndPayment']) {
                    return false;
                }
                return this.$store.getters['coupon/couponByStatePublishedAndActiveAndCustomer'](this.currentUser.id).length > 0;
            },
            staticItemComponentName() {
                if (this.isActiveCustomerAndHasActiveCoupons) {
                    return 'ActiveCustomerItem';
                }
                if (this.isCustomer) {
                    return 'CustomerItem';
                }
                return 'UserItem';
            },
        },
        methods: {
            loadStaticItemComponent(name) {
                if (this.staticItemComponents.hasOwnProperty(name)) {
                    return this.staticItemComponents[name];
                }
                this.$set(this.staticItemComponents, name, () => import(`@/components/content/bonusCard/list/item/${name}`));
                return this.staticItemComponents[name];
            }
        },
        mounted() {
            if (this.isLoggedIn) {
                // Load user data to determine which static items is shown
                this.$store.dispatch('coupon/loadCurrentUserCouponWithPublishedState');
                this.$store.dispatch('user/loadCurrentPayment');
                this.$store.dispatch('user/loadActiveMemberships');
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-bonus-card-list {
        margin-top: calc(-1 * var(--grid-gutter));
        margin-left: calc(-1 * var(--grid-gutter));
    }

    .c3-bonus-card-list-item {
        margin-top: var(--grid-gutter);
        margin-left: var(--grid-gutter);
    }

    @media(min-width: $breakpointTablet) {
        .c3-coupon-simple-list--no-entries {
            width: 100%;
        }
    }
</style>
