<template>
    <div id="app" :class="cssClasses">
        <c3-page-header v-if="pageHeaderVisible"/>
        <transition :name="transition" @after-leave="afterLeave">
            <keep-alive include="Affiliate,CouponList,BonusCardList,GeneralNotificationList,Help,Share,UserFavoriteCouponList">
                <router-view/>
            </keep-alive>
        </transition>
        <c3-page-footer v-if="pageFooterVisible"/>
        <c3-main-navigation/>
        <c3-customer-my-coupon-navigation/>
        <c3-customer-my-bonus-card-navigation/>
        <c3-outdated/>
        <portal-target name="app_end" slim/>
        <portal-target name="coupon_filter" slim/>
        <portal-target name="coupon_location" slim/>
        <portal-target name="bonus_card_location" slim/>
        <portal-target name="invoice_download" slim/>

        <div class="c3-page-loader-wrapper" v-show="isLoading">
            <c3-loader color="white" size="l"/>
            <div class="c3-page-loader-message" v-if="loadingMessage">{{loadingMessage}}</div>
        </div>
    </div>
</template>

<script>
    require('@/constants');
    import {DelegationEventListener} from '@/utility/dom/delegationEventListener';
    import {ViewportHeight} from '@/utility/dom/viewportHeight';

    import {AffiliateService} from '@/domain/service/affiliateService';

    import C3MainNavigation from '@/components/pages/MainNavigation';
    import C3CustomerMyCouponNavigation from '@/components/pages/drawer/CustomerMyCouponNavigation';
    import C3CustomerMyBonusCardNavigation from '@/components/pages/drawer/CustomerMyBonusCardNavigation';
    import C3Outdated from '@/components/content/maintenance/Outdated'

    import C3PageHeader from '@/components/pages/Header.vue';
    import C3PageFooter from '@/components/pages/Footer.vue';

    const SLIDE_LEFT = 'slide-left';
    const SLIDE_RIGHT = 'slide-right';

    const SLIDE_DIRECTION_CONFIG = {
        'CouponList': 100,
        'UserProfileFavorite': 150,
        'BonusCardList': 175,
        'Share': 200,
        'Help': 250
    };

    export default {
        components: {
            C3MainNavigation,
            C3CustomerMyCouponNavigation,
            C3CustomerMyBonusCardNavigation,
            C3Outdated,
            C3PageHeader,
            C3PageFooter
        },
        data() {
            return {
                transition: SLIDE_LEFT
            };
        },
        computed: {
            pageHeaderVisible() {
                return this.$store.state.view.pageHeaderState !== PAGE_STATE.hidden;
            },
            pageFooterVisible() {
                return this.$store.state.view.pageFooterState !== PAGE_STATE.hidden;
            },
            isLoading() {
                return this.$store.state.view.isLoading;
            },
            loadingMessage() {
                return this.$store.state.view.loadingMessage;
            },
            cssClasses() {
                let classes = [];
                if (this.isLoading) {
                    classes.push('c3-page-loading');
                }
                if (!this.pageHeaderVisible) {
                    classes.push('c3-no-page-header');
                }
                if (!this.pageFooterVisible) {
                    classes.push('c3-no-page-footer');
                }
                return classes.join(' ');
            }
        },
        methods: {
            afterLeave() {
                // Reset transition
                this.transition = SLIDE_LEFT;
            }
        },
        events: {
            [EVENT.BACK_BUTTON.NAME]: {
                handler(event, params) {
                    this.transition = SLIDE_RIGHT;
                    this.$router.go(-1);
                },
                priority: EVENT.BACK_BUTTON.PRIORITY.APP
            },
            [EVENT.SHOW_MESSAGE.NAME]: {
                handler(event, params) {
                    this.$router.push({name: 'NotificationList'})
                },
                priority: EVENT.SHOW_MESSAGE.PRIORITY.APP
            },
            [EVENT.COUPON_ADD.NAME]: {
                handler(event, params) {
                    this.$router.push({name: 'CouponCreateWizard'})
                },
                priority: EVENT.COUPON_ADD.PRIORITY.APP
            },
            [EVENT.BONUS_CARD_ADD.NAME]: {
                handler(event, params) {
                    this.$router.push({name: 'BonusCardWizard'})
                },
                priority: EVENT.BONUS_CARD_ADD.PRIORITY.APP
            }
        },
        created() {
            document.body.classList.add('c3-platform-' + this.getPlatform);
        },
        mounted() {
            let self = this;
            let element = null;
            DelegationEventListener.add(document.body, 'click', 'a', function (event) {
                element = this;
                try {
                    self.handleDynamicLinkClick(element, event);
                } catch (e) {
                    if (element && element.href) {
                        console.error('[App.vue] handleDynamicLinkClick failed', e, {href: element.href});
                    } else {
                        console.error('[App.vue] handleDynamicLinkClick failed', e);
                    }
                }
            });

            if (this.isBrowser && /(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
                document.body.classList.add('c3-platform-browser-' + window.PLATFORM.BROWSER);
            }

            ViewportHeight.init();

            document.body.classList.add('c3-initial-loader-wrapper--hidden');
            // Remove initial loader after fade out
            setTimeout(function () {
                let loaderWrapper = document.querySelector('.c3-initial-loader-wrapper');
                if (null !== loaderWrapper) {
                    loaderWrapper.remove();
                }
            }, 2000);

            AffiliateService.updateAdvertiserFromRoute(this.$route);
        },
        watch: {
            $route: function (to, from) {
                if (SLIDE_DIRECTION_CONFIG[from.name] && SLIDE_DIRECTION_CONFIG[to.name]) {
                    this.transition = SLIDE_DIRECTION_CONFIG[from.name] < SLIDE_DIRECTION_CONFIG[to.name] ? SLIDE_LEFT : SLIDE_RIGHT;
                }

                AffiliateService.updateAdvertiserFromRoute(this.$route);
                try {
                    let scrollElement = document.querySelector('.c3-main');
                    if (null !== scrollElement) {
                        scrollElement.scrollTop = 0;
                    }
                } catch (e) {
                    console.error('Reset scroll position failed', e);
                }

            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-page-loading {
        pointer-events: none;
    }

    .c3-page-loader-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(51, 51, 51, 0.8);
        z-index: 1000;

        .c3-page-loader-message {
            position: absolute;
            top: 50%;
            left: 50%;

            width: 80%;
            max-width: 300px;

            margin-top: 80px;
            transform: translateX(-50%);

            color: #fff;
            font-weight: bold;
            text-align: center;
        }
    }
</style>
