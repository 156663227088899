<template>
    <div class="c3-main c3-page-user-affiliate">
        <div class="c3-page-content c3-page-content-bottom-padding">
            <c3-page-image name="share"/>
            <div class="c3-page-content-horizontal-padding">

                <template v-if="invitedUserCount">
                    <div class="c3-page-user-affiliate-detail">
                        <c3-text-with-icon :title="$t('pages.user.affiliate.title')" icon="c3-icon-chart-line"/>

                        <h1>€ {{ earnedBonus }}</h1>
                        <h3>{{ $t('pages.user.affiliate.bonusEarned') }}</h3>

                        <div class="c3-page-user-affiliate-statics">
                            <div class="c3-page-user-affiliate-invited">
                                <div class="c3-page-user-affiliate-count">{{ invitedUserCount }}</div>
                                <div class="c3-page-user-affiliate-label">{{ $t('pages.user.affiliate.invited') }}</div>
                            </div>
                            <div class="c3-page-user-affiliate-registered">
                                <div class="c3-page-user-affiliate-count">{{ regiteredUserCount }}</div>
                                <div class="c3-page-user-affiliate-label">{{ $t('pages.user.affiliate.registered') }}</div>
                            </div>
                            <div class="c3-page-user-affiliate-complete">
                                <div class="c3-page-user-affiliate-count">{{ eligibleUserCount }}</div>
                                <div class="c3-page-user-affiliate-label">{{ $t('pages.user.affiliate.eligible') }}</div>
                            </div>
                        </div>
                    </div>

                    <router-link :to="{name:'Affiliate'}" class="c3-share-link">
                        <c3-button :label="$t('pages.user.affiliate.inviteMoreCompany')" />
                    </router-link>
                </template>
                <template v-else>
                    <c3-text-with-icon :title="$t('pages.user.affiliate.title')" icon="c3-icon-chart-line">
                        {{ $t('pages.user.affiliate.emptyReferral') }}
                    </c3-text-with-icon>
                    <router-link :to="{name:'Affiliate'}" class="c3-share-link">
                        <c3-button :label="$t('pages.user.affiliate.inviteCompany')"/>
                    </router-link>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import C3PageImage from '@/components/media/PageImage';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3Button from '@/components/form/Button';

    export default {
        components: {
            C3PageImage,
            C3TextWithIcon,
            C3Button
        },
        data() {
            return {
                isLoading: true,
                affiliateState: null
            }
        },
        computed: {
            earnedBonus() {
                return this.$store.getters['user/earnedAffiliateBonus'];
            },
            invitedUserCount() {
                return this.$store.getters['user/affiliates'].length;
            },
            regiteredUserCount() {
                return this.$store.getters['user/registerdAffiliates'].length;
            },
            eligibleUserCount() {
                return this.$store.getters['user/eligibleAffiliates'].length;
            }
        },
        methods: {
            async loadData() {
                this.$store.commit('view/showLoading');
                try {
                    await this.$store.dispatch('user/loadAffiliates');
                    this.$store.commit('view/hideLoading');
                } catch(e) {
                    this.$store.commit('view/hideLoading');
                }
            }
        },
        created() {
            this.loadData();
        },
        activated() {
            this.loadData();
        }
    };
</script>
<style lang="scss" scoped>
    .c3-loader {
        margin-top: 30px;
    }

    .c3-page-content-horizontal-padding {
        text-align: center;
    }

    .c3-content-text-with-icon {
        padding-left: 0;
        padding-right: 0;

        & /deep/ .c3-content-text-with-icon-title {
            margin-top: 5px;
        }
    }

    .c3-share-link {
        display: block;
        margin-top: 20px;
    }

    .c3-page-user-affiliate-pay-out {
        margin-top: 45px;
    }

    .c3-page-user-affiliate-detail {
        .c3-content-text-with-icon {
            padding-bottom: 15px;
        }

        h2 {
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 5px;
        }
    }

    .c3-page-user-affiliate-statics {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
    }

    .c3-page-user-affiliate-label {
        font-weight: 600;
    }

    .c3-page-user-affiliate-count {
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
    }
</style>