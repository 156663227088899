<template>
    <c3-field-wrapper>
        <input :value="value" @input="input" type="url"
               @focus="focus" @blur="blur"
               :id="id" :autocomplete="autocomplete" class="c3-form-field-input"
               placeholder="http://"/>
    </c3-field-wrapper>
</template>

<script>
    import BaseField from '@/components/form/fields/BaseField.vue';
    import C3FieldWrapper from '@/components/form/FieldWrapper.vue';

    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';


    export default {
        extends: BaseField,
        mixins: [FormFieldErrorMixin],
        components: {
            C3FieldWrapper,
        },
        props: {
            value: {
                type: [String, Number]
            },
            placeholder: {
                type: String
            },
            labelStyling: {
                type: String,
                default: 'normal'
            },
            styling: {
                type: String
            },
        },
        data() {
            return {
                hasFocus: false
            }
        },
        computed: {
            isEmpty() {
                return null === this.value || 0 === this.value.length;
            },
            cssClasses() {
                return {
                    'c3-form-field-group--has-error': this.hasError,
                    'c3-form-field-group-input--not-empty': !this.isEmpty,
                    'c3-form-field-group-input--focus': this.hasFocus
                };
            }
        },
        methods: {
            input(event) {
                let value = event.target.value;
                if (typeof value === 'string') {
                    value = value.trim();
                }
                this.$emit('input', value);
            },
            blur() {
                this.hasFocus = false;
                this.$emit('input', this.prefixValue(this.value));
                this.$emit('blur');
            },
            focus() {
                this.hasFocus = true;
                this.$emit('focus');
            },
            prefixValue(value) {
                let prefixRegExp = new RegExp('^[a-zA-Z]+://', 'g');
                // Prepend http:// so chrome url validation accepts the value
                if (typeof value === 'string' && value.length && !value.match(prefixRegExp)) {
                    // Remove partial http like p:// before adding prefix
                    value = value.replace(prefixRegExp, '');
                    value = 'http://' + value;
                }
                return value;
            }
        }
    };
</script>