import {AbstractBaseFactory} from '@/domain/factory/abstractBaseFactory';
import {Config} from '@/config';
import {required, requiredIf, email, maxLength} from 'vuelidate/lib/validators'
import {checkVAT, austria, germany, switzerland} from 'jsvat';
import phoneNumberService from '@/domain/service/phoneNumberService';

export default {
    create: (fields) => {
        let user = {
            id: null,
            firstName: '',
            lastName: '',
            email: '',
            street: '',
            zip: '',
            city: '',
            country: null,
            company: '',
            profileImage: null,
            companyPhone: '',
            companyEmail: '',
            hasOpeningTimes: true,
            openingTimes: null,
            openingTimesComment: '',
            vatId: '',
            website: '',
            description: '',
            couponRedeemNotification: false,
            dailyCouponRedeemOverview: false,
            weeklyCouponRedeemOverview: false,
            monthlyCouponRedeemOverview: false,
        };

        if (Array.isArray(fields) && fields.length) {
            return AbstractBaseFactory.fill(fields, {}, user);
        }

        return user;
    },
    createValidation: (fields) => {
        let validation = {
            id: {
                required
            },
            firstName: {},
            lastName: {},
            email: {
                email,
            },
            street: {
                required
            },
            zip: {
                required
            },
            city: {
                required
            },
            country: {
                required
            },
            company: {
                required
            },
            profileImage: {
                required
            },
            companyPhone: {
                required,
                phoneNumber: phoneNumberService.validate
            },
            companyEmail: {
                required,
                email
            },
            hasOpeningTimes: {},
            openingTimes: {
                required: requiredIf('hasOpeningTimes')
            },
            openingTimesComment: {
                maxLength: maxLength(USER.OPENING_TIMES_COMMENT_LENGTH)
            },
            website: {},
            description: {
                required,
                maxLength: maxLength(USER.DESCRIPTION_LENGTH)
            },
            couponRedeemNotification: {},
            dailyCouponRedeemOverview: {},
            weeklyCouponRedeemOverview: {},
            monthlyCouponRedeemOverview: {},
        };

        switch (Config.getTenant()) {
            case 'coupocket':
                validation.vatId = {
                    checkVat(val) {
                        if (val.trim().length === 0) {
                            // VAT ID is optional in coupocket
                            return true;
                        }

                        if (typeof val === 'string') {
                            // Remove all special characters for validation
                            // Otherwise something like CHE-334.420.732 wouldn't be valid, even if it's the official format from https://www.uid.admin.ch/
                            val = val.replace(/[^0-9a-zA-Z]/g, '');
                        }
                        return checkVAT(val, [austria, germany, switzerland]).isValid;
                    },
                };
                break;
            case 'sammelpass':
                validation.vatId = {
                    required,
                    checkVat(val) {
                        if (typeof val === 'string') {
                            // Remove all special characters for validation
                            // Otherwise something like CHE-334.420.732 wouldn't be valid, even if it's the official format from https://www.uid.admin.ch/
                            val = val.replace(/[^0-9a-zA-Z]/g, '');
                        }
                        return checkVAT(val, [austria, germany, switzerland]).isValid;
                    },
                };
                break;
        }

        if (Array.isArray(fields) && fields.length) {
            return AbstractBaseFactory.fill(fields, {}, validation);
        }

        return validation;
    }
};
