export const singleCustomer = (state) => (id, defaultValue) => {
    defaultValue = undefined !== defaultValue ? defaultValue : null;

    if (state.customers.hasOwnProperty(id)) {
        return state.customers[id];
    }
    return defaultValue;
};

export const customer = (state) => (id) => {
    return state.customers[id];
};
export const qrToken = (state) => {
    return state.qrToken;
};