import axios from 'axios';
import store from '@/store';
import {Config} from '@/config';

let GeocodingInternal = function() {
  const GEOCODING_URL = Config.getAutocompleteApiUrl() + '/autocomplete.php?key=LpSj2TwxyO2IFRwns8KnwPdfxY4NHu';
  const CACHE = {};
  const CLIENT = axios.create({timeout: 6000});

  this.autocomplete = async function(query) {
    try {
      let url = GEOCODING_URL + `&query=${encodeURIComponent(query)}`;
      let userCoordinate = store.getters['user/positionAsCoordinate'];
      if (userCoordinate) {
        url += `&location=${userCoordinate.latitude},${userCoordinate.longitude}`;
      }
      const data = await cachedFetch(url);
      return Array.isArray(data) ? data : [];
    } catch (e) {
      console.error('[Geocoding] autocomplete failed', e);
      return [];
    }
  };

  this.geocodeFuzzy = async function(query) {
    try {
      let url = GEOCODING_URL + `&query=${encodeURIComponent(query)}`;
      let userCoordinate = store.getters['user/positionAsCoordinate'];
      if (userCoordinate) {
        url += `&location=${userCoordinate.latitude},${userCoordinate.longitude}`;
      }
      let data = await cachedFetch(url);
      return Array.isArray(data) && data.length ? data[0] : null;
    } catch (e) {
      console.error('[Geocoding] geocodeFuzzy failed', e);
      return null;
    }
  };

  this.coordinateById = async function(id) {
    let url = GEOCODING_URL + '&id=' + id;
    return await cachedFetch(url);
  };

  async function cachedFetch(url) {
    if (CACHE.hasOwnProperty(url)) {
      return CACHE[url];
    }

    const response = await CLIENT.get(url);
    if (response && response.data && response.data.data) {
      CACHE[url] = response.data.data;
    }
    return response.data.data;
  }
};

export const Geocoding = new GeocodingInternal();
