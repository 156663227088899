import moment from 'moment';
export default {
    fromApi: function (user) {

        if (user.hasOwnProperty('openingTimes') && null !== user.openingTimes && typeof user.openingTimes === 'object') {
            Object.keys(user.openingTimes).forEach(dayKey => {
                if (!Array.isArray(user.openingTimes[dayKey])) {
                    return;
                }

                user.openingTimes[dayKey].forEach((dayEntry, index) => {
                    if (dayEntry.start) {
                        user.openingTimes[dayKey][index].start = moment(dayEntry.start);
                    }
                    if (dayEntry.end) {
                        user.openingTimes[dayKey][index].end = moment(dayEntry.end);
                    }
                })
            });
        }

        return user;
    },
    fromApiCollection: function (users) {
        return users.map(user => this.fromApi(user));
    },
    toApi: function (user) {
        if (null === user || typeof user !== 'object') {
            return user;
        }

        if (user.hasOwnProperty('openingTimes') && null !== user.openingTimes && typeof user.openingTimes === 'object') {
            Object.keys(user.openingTimes).forEach(dayKey => {
                if (Array.isArray(user.openingTimes[dayKey])) {
                    user.openingTimes[dayKey].forEach((dayEntry, index) => {
                        if (moment.isMoment(dayEntry.start)) {
                            user.openingTimes[dayKey][index].start = dayEntry.start.format();
                        }
                        if (moment.isMoment(dayEntry.end)) {
                            user.openingTimes[dayKey][index].end = dayEntry.end.format();
                        }
                    })
                }
            });
        }

        return user;
    }
};