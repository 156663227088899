import baseApiConverter from '@/domain/api/converter/base';

export default {
    fromApi: function (payment) {
        payment = baseApiConverter.modifiedAndCreatedFromApi(payment);
        return payment;
    },
    fromApiCollection: function (payments) {
        return payments.map(payment => this.fromApi(payment));
    },
    toApi: function (payment) {
        return payment;
    }
};