    <template>
        <div class="c3-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path :fill="color1" d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM32 96h64c0 35.3-28.72 64-64 64V96zm576 224c-52.94 0-96 43.06-96 96h32c0-35.3 28.72-64 64-64v64H32v-64c35.28 0 64 28.7 64 64h32c0-52.94-43.06-96-96-96V192c52.94 0 96-43.06 96-96h384c0 52.94 43.06 96 96 96v128zm0-160c-35.28 0-64-28.7-64-64h64v64zm-288-16c-53.02 0-96 50.14-96 112 0 61.85 42.98 112 96 112 53 0 96-50.13 96-112 0-61.86-42.98-112-96-112zm0 192c-35.29 0-64-35.89-64-80s28.71-80 64-80 64 35.89 64 80-28.71 80-64 80z"/></svg>
<!--
Font Awesome Pro 5.2.0 by @fontawesome - https://fontawesome.com
License - https://fontawesome.com/license (Commercial License)
-->
        </div>
    </template>
    <script>
        import {IconMixin} from './mixins/icon';

        export default {
            mixins: [IconMixin],
        };
    </script>

