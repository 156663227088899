import {Analytics} from '@/domain/analytics';

export const CouponFavoriteMixin = {
    computed: {
        isFavorite() {
            if (this.coupon) {
                return this.$store.getters['user/isFavoriteByCoupon'](this.coupon.id);
            }
            return null;
        },
    },
    methods: {
        toggleFavorite() {
            if (!this.isLoggedIn) {
                let redirectAfterLogin = this.$router.resolve({name: this.$route.name, params: this.$route.params}).resolved.fullPath;

                this.$c3.confirmLoginRedirect(
                    () => this.$router.push({name: 'Login', query: {redirectAfterLogin: redirectAfterLogin}})
                );
                return;
            }

            if (this.isFavorite) {
                this.$store.dispatch('user/removeFavoriteByCoupon', this.coupon.id);
                Analytics.logRemoveCouponFavorite(this.coupon.id);
            } else {
                this.$store.dispatch('user/addFavoriteByCoupon', this.coupon.id);
                Analytics.logAddCouponFavorite(this.coupon.id);
            }
        }
    },
};