<template>
    <c3-bottom-drawer :name="name" @close="reset">
        <div slot="headerLeft">
            <div class="c3-location-drawer__title">{{$t('label.location')}}</div>
            <div class="c3-location-drawer__current-location" v-if="locationName">
                {{locationName}}
            </div>
            <div v-else class="c3-location-drawer__current-location">
                {{ $t('locationDrawer.title') }} - <span class="c3-text-color-quinary" @click="useCurrentPosition">{{ $t('locationDrawer.button') }}</span>
            </div>
        </div>

        <div class="c3-filter">
            <div class="c3-filter__location-center">
                <button class="c3-filter__location-center-button" @click="useCurrentPosition">
                    <c3-icon-location primaryColor="white"/>
                </button>
                <div class="c3-filter__location-center-field">
                    <input type="text" v-model="locationCenterInput" @input="autocompleteLocationName" @focus="addFocus" @blur="removeFocus"
                           :placeholder="$t('label.zipPlaceholder')" class="c3-filter__location-center-input"/>
                    <c3-loader v-if="suggestionLoading" color="quinary" size="xs"/>
                    <div v-show="suggestions.length" class="c3-filter__location-center-suggestion-list">
                        <!-- This has to be an anchor tag, so iOS fires a click event -->
                        <a href="#" v-for="suggestion in suggestions" class="c3-filter__location-center-suggestion-list-item" @click.prevent="selectSuggestion(suggestion)">
                            {{suggestion.label}}
                        </a>
                    </div>
                </div>
            </div>
            <div @click="useCurrentPosition" class="c3-filter__use-current-location c3-text-color-quinary">{{ $t('locationDrawer.subtitle') }}</div>
            <div class="c3-filter__location-radius" v-if="false">
                <div class="c3-filter__location-radius-label">{{filter.locationRadius / 1000}} km</div>
                <c3-range v-model="filter.locationRadius" :min="10000" :max="50000" :step="10000" :label="$t('coupon.filter.locationRadius')"/>
            </div>
        </div>

        <div slot="footer" class="c3-filter-apply" @click="apply">
            <c3-button :label="$t('label.apply')"/>
        </div>
    </c3-bottom-drawer>
</template>

<script>
  import {Geocoding} from '@/domain/geocoding';

  import C3BottomDrawer from '@/components/layout/navigation/BottomDrawer.vue';
  import C3IconLocation from '@/components/icon/Location';
  import C3Range from '@/components/form/fields/Range';
  import C3Button from '@/components/form/Button';

  export default {
    components: {
      C3BottomDrawer,
      C3IconLocation,
      C3Range,
      C3Button,
    },
    props: {},
    data() {
      return {
        name: window.DRAWER.COUPON_LOCATION_DRAWER,
        locationCenterInput: '',
        locationCenterInputHasFocus: false,
        suggestions: [],
        suggestionLoading: false,
        filter: {
          useCurrentLocation: this.$store.getters['viewCouponList/useCurrentLocation'](this.$route.name),
          locationName: this.$store.getters['viewCouponList/locationName'](this.$route.name),
          locationCenter: this.$store.getters['viewCouponList/locationCenter'](this.$route.name),
          locationRadius: this.$store.getters['viewCouponList/locationRadius'](this.$route.name),
        },
      };
    },
    computed: {
      locationName() {
        return this.$store.getters['viewCouponList/locationName'](this.$route.name);
      },
    },
    methods: {
      async selectSuggestion(suggestion) {
        this.suggestionLoading = true;

        this.suggestions = [];
        if (!suggestion.id) {
          suggestion = await Geocoding.geocodeFuzzy(suggestion.label);
        }

        if (!suggestion) {
          this.$c3.errorAlert(this.$t('error.noGeocodingResult.title'), this.$t('error.noGeocodingResult.message', {query: label}));
          this.suggestionLoading = false;
          return;
        }

        this.filter.useCurrentLocation = false;
        this.filter.locationName = suggestion.label;
        this.filter.locationCenter = await Geocoding.coordinateById(suggestion.id);
        this.locationCenterInput = this.filter.locationName;

        this.suggestionLoading = false;
      },
      async autocompleteLocationName($event) {
        if ($event.target && $event.target.value && typeof $event.target.value === 'string' && $event.target.value.length > 1) {
          this.suggestionLoading = true;
          this.suggestions = [{label: this.locationCenterInput}];
          let suggestions = await Geocoding.autocomplete($event.target.value);
          if (suggestions.length) {
            this.suggestions = suggestions.slice(0, 3);
          }
          this.suggestionLoading = false;
        } else {
          this.suggestions = [];
          this.suggestionLoading = false;
        }
      },
      async addFocus(event) {
        console.debug('Set focus');
        this.locationCenterInputHasFocus = true;
        await this.autocompleteLocationName(event);
      },
      removeFocus() {
        console.debug('Remove focus');
        this.locationCenterInputHasFocus = false;
      },
      async useCurrentPosition() {
        this.$store.commit('view/showLoading', this.$t('label.updatePositionLoading'));

        try {
          await Promise.all([
            this.$store.dispatch('viewCouponList/position', {name: this.$route.name, disableLocationFilterUpdate: true, addressTimeout: 5000}),
            new Promise(resolve => setTimeout(resolve, 1200)),
          ]);

          let userCoordinate = this.$store.getters['user/positionAsCoordinate'];
          let address = this.$store.getters['geocoding/address'](userCoordinate);
          this.filter.useCurrentLocation = true;
          if (address) {
            this.filter.locationName = address.locality ? address.locality : address.postalCode;
          } else {
            this.filter.locationName = userCoordinate.latitude + ', ' + userCoordinate.longitude;
          }

          this.filter.locationCenter = userCoordinate;
          this.locationCenterInput = this.filter.locationName;
        } catch (e) {
          this.$c3.staticContentAlertById(15);
        }

        this.$store.commit('view/hideLoading');
      },
      async apply() {
        this.$store.commit(
            'viewCouponList/updateFilter', {
              name: this.$route.name,
              filter: {
                useCurrentLocation: this.filter.useCurrentLocation,
                locationName: this.filter.locationName,
                locationCenter: this.filter.locationCenter,
                locationRadius: this.filter.locationRadius,
              },
            },
        );
        this.$store.commit('view/hideBottomDrawer');
        this.$store.commit('view/showLoading');
        this.suggestions = [];
        await this.$store.dispatch('viewCouponList/loadItems', {name: this.$route.name});
        this.$store.commit('view/hideLoading');
        this.suggestionLoading = false;
        this.locationCenterInput = '';
      },
      reset() {
        this.suggestions = [];
        this.suggestionLoading = false;
      },
    },
    created() {
      this.$store.commit('viewCouponList/createList', this.$route.name);
    },
  };
</script>
<style lang="scss" scoped>
    .c3-location-drawer__title {
        font-size: 14px;
        font-weight: 600;
    }

    .c3-location-drawer__current-location {
        color: $senary-color;
        font-size: 12px;
    }

    .c3-filter {
        max-width: 350px;
        margin-top: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .c3-filter__location-radius {
        position: relative;

        & /deep/ .c3-form-label {
            padding-left: 0;
        }
    }

    .c3-filter__location-radius-label {
        position: absolute;
        right: 0;
    }

    .c3-filter__location-center {
        display: flex;
        position: relative;
        @include boxShadow();
    }

    .c3-filter__use-current-location {
        display: inline-block;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .c3-filter__location-center-field {
        flex: 1;

        .c3-loader {
            position: absolute;
            right: 8px;
            top: 14px;
        }
    }

    .c3-filter__location-center-input {
        @include baseField();
        @include baseInput();

        box-shadow: none;

        border-radius: 0;
    }

    .c3-filter__location-center-suggestion-list {
        position: absolute;
        bottom: 100%;
        right: 0;
        left: 0;

        border-bottom: 1px solid $octonary-color;
        box-shadow: 5px -2px 5px 1px rgba(0, 0, 0, 0.3);

        background-color: #f9f9f9;
    }

    .c3-filter__location-center-suggestion-list-item {
        display: block;
        border-top: 1px solid $octonary-color;
        padding: 10px 8px;
        // Fix missing iOS click event without cursor: pointer;
        cursor: pointer;

        &:first-of-type {
            border-top: 0;
        }
    }

    .c3-filter__location-center-button {
        @include baseButton();
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;

        box-shadow: none;

        border-radius: 0;
        background-color: $quinary-color;

        .c3-icon-wrapper {
            height: 20px;
        }
    }

    .c3-filter-apply {
        margin-top: 10px;
    }
</style>
