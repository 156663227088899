<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path :fill="color1"
                      d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm16 400c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h352c8.822 0 16 7.178 16 16v352zm-72-96c0 30.928-25.072 56-56 56s-56-25.072-56-56c0-3.305.303-6.538.852-9.686l-53.609-26.805C169.618 307.316 157.358 312 144 312c-30.928 0-56-25.072-56-56s25.072-56 56-56c13.358 0 25.618 4.684 35.243 12.49l53.609-26.805A56.292 56.292 0 0 1 232 176c0-30.928 25.072-56 56-56s56 25.072 56 56-25.072 56-56 56c-16.107 0-30.615-6.81-40.832-17.696l-49.744 24.872a56.217 56.217 0 0 1 0 33.647l49.744 24.872C257.385 286.81 271.893 280 288 280c30.928 0 56 25.072 56 56zm-56-184c-13.234 0-24 10.766-24 24s10.766 24 24 24 24-10.766 24-24-10.766-24-24-24m-144 80c-13.234 0-24 10.766-24 24s10.766 24 24 24 24-10.766 24-24-10.766-24-24-24m144 80c-13.234 0-24 10.766-24 24s10.766 24 24 24 24-10.766 24-24-10.766-24-24-24"/>
            </svg>

        </svg>
    </div>
</template>

<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
