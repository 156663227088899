import {Config} from '@/config';
import {Crypto} from '@/utility/crypto';
import {LocalStorage} from '@/domain/utility/localStorage';
import {Logger} from '@/domain/logger';
import store from '@/store';

const notificationServiceLocalStorage = LocalStorage('notificationService');

const STORE_REGISTRATION_ID = 'registrationId';

let NotificationServiceInternal = function () {
    let registrationData = null;
    let listener = [];

    this.hasPermission = async function () {
        if (!Config.isNativeRealDevice() || window.device.platform !== window.PLATFORM.IOS) {
            // All platforms except iOS have notification permission without asking the user
            return true;
        }
        return new Promise((resolve) => {
            window.FirebasePlugin.hasPermission(hasPermission => resolve(hasPermission));
        });
    };

    this.askPermission = async function () {
        if (Config.isNativeRealDevice() && window.device.platform === window.PLATFORM.IOS) {
            window.FirebasePlugin.grantPermission();
        }
    };

    this.register = async function () {
        try {
            if (Config.isNativeRealDevice()) {
                await startRegistration();
                window.FirebasePlugin.onMessageReceived(triggerListener, error => console.error('[notificationService] error in pushHandler', error));
            } else {
                console.debug('window.PushNotification not available, generate uuid');
                if (!notificationServiceLocalStorage.has(STORE_REGISTRATION_ID) || !notificationServiceLocalStorage.get(STORE_REGISTRATION_ID)) {
                    notificationServiceLocalStorage.set(STORE_REGISTRATION_ID, 'browser_' + Crypto.generateUuid() + '_' + Crypto.generateUuid());
                }
                let registrationId = notificationServiceLocalStorage.get(STORE_REGISTRATION_ID);
                registrationData = {registrationId: registrationId, registrationType: 'browser'};
            }
        } catch (e) {
            console.error('[notificationService] error during register', e);
            throw new Error('[notificationService] error during register');
        }
    };

    this.addListener = function (name, cb) {
        if (typeof cb === 'function') {
            listener.push({name: name, cb: cb});
        } else {
            console.error('[notificationService] addListener failed because callback is no function');
        }
    };

    this.removeListener = function (name) {
        listener = listener.filter(item => item.name !== name);
    };

    this.hasRegistrationData = function () {
        return null !== registrationData;
    };

    this.getRegistrationData = function () {
        return registrationData;
    };

    function startRegistration() {
        return new Promise((resolve, reject) => {
            window.FirebasePlugin.onTokenRefresh(updateFromToken, console.error);

            window.FirebasePlugin.getToken(
                token => {
                    if (updateFromToken(token)) {
                        resolve();
                    } else {
                        reject();
                    }
                },
                error => {
                    console.error(error);
                    reject(error);
                }
            );
        });
    }

    function updateFromToken(token) {
        if (!token) {
            Logger.captureMessage('Notification token is empty', token);
            return false;
        }

        registrationData = {registrationId: token, registrationType: 'FCM'};
        store.commit('notification/updatePushId', token);
        store.commit('notification/updatePushType', 'FCM');
        return true;
    }

    function triggerListener(data) {
        if (data.hasOwnProperty('url')) {
            return;
        }
        listener.forEach(item => {
            item.cb(data);
        })
    }
};

export const NotificationService = new NotificationServiceInternal();
