<template>
    <div class="c3-content-text-with-icon">
        <component v-if="icon" :is="icon" class="c3-content-text-with-icon-icon" primaryColor="quinary"/>
        <h2 class="c3-content-text-with-icon-title">{{title}}</h2>
        <div v-if="$slots.default" class="c3-content-text-with-icon-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            icon: {
                type: String,
                required: true
            },
            title: {
                type: String
            },
        },
    };
</script>
<style lang="scss" scoped>
    .c3-content-text-with-icon {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 30px;
    }

    .c3-content-text-with-icon-icon {
        height: 40px;
        margin-top: 25px;
    }

    h2 {
        @include title();
        margin-top: 25px;
    }

    .c3-content-text-with-icon-content {
        margin-top: 15px;
    }
</style>