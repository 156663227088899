import objectPath from 'object-path';
import {C3Object} from '@/domain/utility/object';

export const AbstractBaseFactory = {
    /**
     * Fill "object" with the values of "values", but only for the fields given in "fields".
     * This is useful to fill a form data object from vuex (or an API request).
     *
     * @param {Array} fields - List of fields that we should copy, s. https://www.npmjs.com/package/object-path for all syntax possibilities (e.g. nested fields like address.street)
     * @param {Object} object
     * @param {Object} values
     * @return {Object} The filled object
     *
     * @see https://www.npmjs.com/package/object-path
     */
    fill(fields, object, values) {
        // Avoid accidental change of values by reference
        values = C3Object.deepClone(values, true);
        fields.forEach(field => {
            const value = objectPath.get(values, field);
            objectPath.set(object, field, value)
        });
        return object;
    }
}
