require('@/constants');

export default {
    pageHeaderTitle: '',
    isLoading: false,
    loadingMessage: '',
    pageHeaderLeft: VIEW.PAGE_HEADER_LEFT_DEFAULT,
    pageHeaderInnerRight: VIEW.PAGE_HEADER_INNER_RIGHT_DEFAULT,
    pageHeaderRight: VIEW.PAGE_HEADER_RIGHT_DEFAULT,
    pageHeaderState: 'visible',
    pageFooterState: 'visible',

    activeDrawers: [],
    activeBottomDrawer: null,
    scrollPosition: {}
};