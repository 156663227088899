<template>
    <div class="c3-customer-opening-time-short">
        <c3-two-with-icon :icon="icon">
            <template slot="first">
                <strong>
                    <span v-if="isOpen">{{ $t('openingTimes.isCurrentlyOpen') }}</span>
                    <span v-else>{{ $t('openingTimes.isCurrentlyClosed') }}</span>
                </strong>
            </template>
            <template slot="second">{{stateText}}</template>
        </c3-two-with-icon>
    </div>
</template>

<script>
    import C3TwoWithIcon from '@/components/layout/column/TwoWithIcon.vue';

    export default {
        components: {
            C3TwoWithIcon
        },
        props: {
            isOpen: {
                type: Boolean
            },
            stateText: {
                type: String
            },
            noIcon: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            icon() {
                if (this.noIcon) {
                    return '';
                }
                return 'c3-icon-clock';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .c3-customer-opening-time-short {

    }

    strong {
        @include openSansBold();
    }
</style>