<template>
    <div class="c3-main c3-page-general-help">
        <div class="c3-page-content">
            <c3-page-image name="share"/>
            <div class="c3-page-general-help-header">
                <c3-text-with-icon :title="$t('pages.general.help.title')" icon="c3-icon-question-circle"/>
            </div>
            <div class="c3-page-content-padding" v-if="faqItems && faqItems.length">
                <template v-for="faqItem in faqItems">
                    <c3-faq :title="faqItem.title">
                        <template v-for="item in faqItem.entries">
                            <c3-faq-item v-if="item" :id="item.id" :title="item.title" :content="item.text"/>
                        </template>
                    </c3-faq>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {ShareMixin} from '@/domain/mixin/local/share';

    import C3PageImage from '@/components/media/PageImage';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3Button from '@/components/form/Button';
    import C3Faq from '@/components/layout/faq/Index';
    import C3FaqItem from '@/components/layout/faq/Item';

    export default {
        name: 'Help',
        mixins: [ShareMixin],
        components: {
            C3PageImage,
            C3TextWithIcon,
            C3Button,
            C3Faq,
            C3FaqItem
        },
        data() {
            return {
                faqIds: []
            }
        },
        computed: {
            faqItems() {
                return this.faqIds.map(entry => {
                    return {
                        title: entry.title,
                        entries: entry.entries.map(id => this.$store.getters['staticContent/singleContent'](id)).filter(entry => null !== entry)
                    }
                })
            }
        },
        methods: {},
        beforeMount() {
            this.$store.commit('view/showLoading');
            this.$store.dispatch('staticContent/loadFaq')
                .then(result => {
                    let ids = [];
                    result.forEach(entry => entry.entries.forEach(id => ids.push(id)));
                    this.$store.dispatch('staticContent/loadBulkContent', ids);
                    this.faqIds = result;
                })
                .finally(() => this.$store.commit('view/hideLoading'))
        }
    };
</script>
<style lang="scss" scoped>
    .c3-page-content {
        @media (min-width: $breakpointTablet) {
            max-width: 768px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c3-image-wrapper {
        @media (min-width: $breakpointTablet) {
            display: none;
        }
    }

    .c3-page-general-help-header {

    }

    .c3-faq-wrapper {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }
</style>