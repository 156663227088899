import {Config} from '@/config';

export default {
    install: function (Vue, options) {
        Vue.mixin({
            computed: {
                hasInAppBrowser() {
                    return Config.isNative();
                }
            },
            methods: {
                handleDynamicLinkClick(element, event) {
                    if (null === element) {
                        console.error('element in handleDynamicLinkClick is null');
                        return;
                    }

                    // No inAppBrowser or local link
                    if (!this.hasInAppBrowser || typeof element.href !== 'string' || 0 === element.href.indexOf('#')) {
                        console.debug('[handleDynamicLinkClick] No InAppBrowser or internal link', element.href);
                        return;
                    }

                    // Static content
                    if (element.closest('.c3-static-content') !== null) {
                        console.debug('[handleDynamicLinkClick] Open link in InAppBrowser', element.href);
                        event.preventDefault();
                        cordova.InAppBrowser.open(element.href, '_blank');
                    }
                }
            }
        })
    }
};
