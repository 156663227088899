require('@/constants');

const Customer = () => import(/* webpackChunkName: "Customer" */ '@/pages/Customer/Index');

const CustomerProfileBaseData = () => import(/* webpackChunkName: "CustomerProfile" */ '@/pages/Customer/Profile/BaseData');
const CustomerProfileOpeningTime = () => import(/* webpackChunkName: "CustomerProfile" */ '@/pages/Customer/Profile/OpeningTime');
const CustomerProfilePayment = () => import(/* webpackChunkName: "CustomerProfile" */ '@/pages/Customer/Profile/Payment');
const CustomerProfileMembership = () => import(/* webpackChunkName: "CustomerProfile" */ '@/pages/Customer/Profile/Membership');
const CustomerProfileQrCode = () => import(/* webpackChunkName: "CustomerProfile" */ '@/pages/Customer/Profile/QrCode');

const CustomerShopManagement = () => import(/* webpackChunkName: "CustomerShopManagement" */ '@/pages/Customer/ShopManagement/Index');

const CustomerCoupon = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/Index');
const CustomerCouponList = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/List/Index');
const CustomerCouponListDraft = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/List/Draft');
const CustomerCouponListReview = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/List/Review');
const CustomerCouponListScheduled = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/List/Scheduled');
const CustomerCouponListPublished = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/List/Published');
const CustomerCouponListArchive = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/List/Archive');
const CustomerCouponListStatistic = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/List/Statistic');

const CouponCreateWizard = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/Wizard/Index');
const CouponCreateWizardRequirement = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/Wizard/Requirement');
const CouponCreateWizardBaseData = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/Wizard/BaseData');
const CouponCreateWizardToS = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/Wizard/ToS');
const CouponCreateWizardOnline = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/Wizard/Online');
const CouponCreateWizardFinish = () => import(/* webpackChunkName: "CustomerProfileCoupon" */ '@/pages/Customer/Coupon/Wizard/Finish');

const BonusCard = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/Index');
const BonusCardWizard = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/Wizard/Index');
const BonusCardWizardBaseData = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/Wizard/BaseData');
const BonusCardList = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/List/Index');
const BonusCardListDraft = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/List/Draft');
const BonusCardListReview = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/List/Review');
const BonusCardListScheduled = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/List/Scheduled');
const BonusCardListActive = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/List/Active');
const BonusCardListArchive = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/List/Archive');
const BonusCardListStatistic = () => import(/* webpackChunkName: "CustomerProfileBonusCard" */ '@/pages/Customer/BonusCard/List/Statistic');

const CustomerRegistration = () => import(/* webpackChunkName: "CustomerRegistration" */ '@/pages/Customer/Registration/Index');
const CustomerRegistrationCompany = () => import(/* webpackChunkName: "CustomerRegistration" */ '@/pages/Customer/Registration/Company');
const CustomerRegistrationOpeningTime = () => import(/* webpackChunkName: "CustomerRegistration" */ '@/pages/Customer/Registration/OpeningTime');
const CustomerRegistrationFinished = () => import(/* webpackChunkName: "CustomerRegistration" */ '@/pages/Customer/Registration/Finished');
const CustomerRegistrationMembership = () => import(/* webpackChunkName: "CustomerRegistration" */ '@/pages/Customer/Registration/Membership');
const CustomerRegistrationPayment = () => import(/* webpackChunkName: "CustomerRegistration" */'@/pages/Customer/Registration/Payment');

const CustomerStore = () => import(/* webpackChunkName: "CustomerStoreWizard" */ '@/pages/Customer/Store/Index');
const CustomerStoreWizard = () => import(/* webpackChunkName: "CustomerStoreWizard" */ '@/pages/Customer/Store/Wizard/Index');
const CustomerStoreWizardBaseData = () => import(/* webpackChunkName: "CustomerStoreWizard" */ '@/pages/Customer/Store/Wizard/BaseData');
const CustomerStoreList = () => import(/* webpackChunkName: "CustomerStoreWizard" */ '@/pages/Customer/Store/List/Index');

let authRequireCustomerOrRedirectToCustomerRegistration = {
    requiredRole: USER_ROLE.CUSTOMER,
    missingRoleRoute: {name: 'CoupocketForCompany'}
};

let authRequireShoppingCenterOrRedirectToHome = {
    requiredRole: USER_ROLE.SHOPPING_CENTER,
    missingRoleRoute: {name: 'Home'}
};

export default [
    {
        path: '/customer',
        component: Customer,
        redirect: {name: 'CustomerProfileBaseData'},
        meta: {auth: authRequireCustomerOrRedirectToCustomerRegistration},
        children: [
            {
                path: 'profile/base-data',
                name: 'CustomerProfileBaseData',
                component: CustomerProfileBaseData,
            },
            {
                path: 'profile/opening-time',
                name: 'CustomerProfileOpeningTime',
                component: CustomerProfileOpeningTime,
            },
            {
                path: 'profile/payment',
                name: 'CustomerProfilePayment',
                component: CustomerProfilePayment,
            },
            {
                path: 'profile/membership',
                name: 'CustomerProfileMembership',
                component: CustomerProfileMembership,
            },
            {
                path: 'profile/qr-code',
                name: 'CustomerProfileQrCode',
                component: CustomerProfileQrCode,
            },
        ]
    },
    {
        path: '/customer/shop-management/',
        name: 'CustomerShopManagement',
        component: CustomerShopManagement,
        meta: {
            auth: authRequireShoppingCenterOrRedirectToHome
        }
    },
    {
        path: '/customer/coupon/',
        name: 'CustomerCoupon',
        component: CustomerCoupon,
        redirect: {name: 'CouponCreateWizard'},
        meta: {
            auth: authRequireCustomerOrRedirectToCustomerRegistration
        },
        children: [
            {
                path: 'wizard',
                name: 'CouponCreateWizard',
                component: CouponCreateWizard,
                redirect: {name: 'CouponCreateWizardRequirement'},
                children: [
                    {
                        path: 'requirement',
                        name: 'CouponCreateWizardRequirement',
                        component: CouponCreateWizardRequirement,
                    },
                    {
                        path: 'base-data/:id',
                        name: 'CouponCreateWizardBaseData',
                        component: CouponCreateWizardBaseData,
                    },
                    {
                        path: 'tos/:id',
                        name: 'CouponCreateWizardToS',
                        component: CouponCreateWizardToS,
                    },
                    {
                        path: 'online/:id',
                        name: 'CouponCreateWizardOnline',
                        component: CouponCreateWizardOnline
                    },
                    {
                        path: 'finish/:id',
                        name: 'CouponCreateWizardFinish',
                        component: CouponCreateWizardFinish,
                    },
                ]
            },
            {
                path: 'list',
                name: 'CustomerCouponList',
                component: CustomerCouponList,
                redirect: {name: 'CustomerCouponListDraft'},
                children: [
                    {
                        path: 'draft',
                        name: 'CustomerCouponListDraft',
                        component: CustomerCouponListDraft,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.COUPON_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'review',
                        name: 'CustomerCouponListReview',
                        component: CustomerCouponListReview,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.COUPON_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'scheduled',
                        name: 'CustomerCouponListScheduled',
                        component: CustomerCouponListScheduled,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.COUPON_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'published',
                        name: 'CustomerCouponListPublished',
                        component: CustomerCouponListPublished,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.COUPON_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'archive',
                        name: 'CustomerCouponListArchive',
                        component: CustomerCouponListArchive,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.COUPON_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'statistic',
                        name: 'CustomerCouponListStatistic',
                        component: CustomerCouponListStatistic,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.COUPON_ADD.NAME
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/customer/bonus-card/',
        name: 'CustomerBonusCard',
        component: BonusCard,
        redirect: {name: 'BonusCardWizard'},
        meta: {
            auth: authRequireCustomerOrRedirectToCustomerRegistration
        },
        children: [
            {
                path: 'wizard',
                name: 'BonusCardWizard',
                component: BonusCardWizard,
                redirect: {name: 'BonusCardWizardBaseData'},
                children: [
                    {
                        path: 'base-data/:id?',
                        name: 'BonusCardWizardBaseData',
                        component: BonusCardWizardBaseData,
                    },
                ]
            },
            {
                path: 'list',
                name: 'CustomerBonusCardList',
                component: BonusCardList,
                redirect: {name: 'CustomerBonusCardListDraft'},
                children: [
                    {
                        path: 'draft',
                        name: 'CustomerBonusCardListDraft',
                        component: BonusCardListDraft,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.BONUS_CARD_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'review',
                        name: 'CustomerBonusCardListReview',
                        component: BonusCardListReview,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.BONUS_CARD_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'scheduled',
                        name: 'CustomerBonusCardListScheduled',
                        component: BonusCardListScheduled,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.BONUS_CARD_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'active',
                        name: 'CustomerBonusCardListActive',
                        component: BonusCardListActive,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.BONUS_CARD_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'archive',
                        name: 'CustomerBonusCardListArchive',
                        component: BonusCardListArchive,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.BONUS_CARD_ADD.NAME
                            }
                        }
                    },
                    {
                        path: 'statistic',
                        name: 'CustomerBonusCardListStatistic',
                        component: BonusCardListStatistic,
                        meta: {
                            pageHeaderInnerRight: {
                                icon: 'c3-icon-plus-circle',
                                event: EVENT.BONUS_CARD_ADD.NAME
                            }
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/customer/registration',
        name: 'CustomerRegistration',
        component: CustomerRegistration,
        redirect: {name: 'CustomerRegistrationCompany'},
        meta: {
            auth: {
                requiredRole: USER_ROLE.USER
            }
        },
        children: [
            {
                path: 'company',
                name: 'CustomerRegistrationCompany',
                component: CustomerRegistrationCompany,
            },
            {
                path: 'opening-time',
                name: 'CustomerRegistrationOpeningTime',
                component: CustomerRegistrationOpeningTime,
            },
            {
                path: 'finished',
                name: 'CustomerRegistrationFinished',
                component: CustomerRegistrationFinished,
            },
            {
                path: 'membership',
                name: 'CustomerRegistrationMembership',
                component: CustomerRegistrationMembership,
            },
            {
                path: 'payment',
                name: 'CustomerRegistrationPayment',
                component: CustomerRegistrationPayment,
            },
        ]
    },
    {
        path: '/customer/store',
        name: 'CustomerStore',
        component: CustomerStore,
        redirect: {name: 'CustomerStoreList'},
        meta: {
            auth: authRequireCustomerOrRedirectToCustomerRegistration
        },
        children: [
            {
                path: 'wizard',
                name: 'CustomerStoreWizard',
                component: CustomerStoreWizard,
                redirect: {name: 'CustomerStoreWizardBaseData'},
                children: [
                    {
                        path: 'base-data/:id?',
                        name: 'CustomerStoreWizardBaseData',
                        component: CustomerStoreWizardBaseData,
                    },
                ]
            },
            {
                path: 'list',
                name: 'CustomerStoreList',
                component: CustomerStoreList
            }
        ]
    },
];
