export default () => {
    let self = {};

    const QUALITY = 85;

    function determineDevice() {
        if (!window.device || !window.device.platform || 'browser' === window.device.platform || !window.navigator.camera) {
            return 'browser';
        }

        return window.device.platform;
    }

    self.takePicture = () => {
        return new Promise((resolve, reject) => {
            let device = determineDevice();

            if (window.PLATFORM.ANDROID === device) {
                // Let user choose if camera or gallery
                navigator.notification.confirm(
                    'Möchtest du ein neues Foto aufnehmen oder ein bestehendes aus deiner Galerie wählen?',
                    buttonIndex => {
                        let type = null;
                        switch (buttonIndex) {
                            case 1:
                                type = window.Camera.PictureSourceType.PHOTOLIBRARY;
                                break;
                            case 2:
                                type = window.Camera.PictureSourceType.CAMERA;
                                break;
                        }

                        if (type !== window.Camera.PictureSourceType.PHOTOLIBRARY && type !== window.Camera.PictureSourceType.CAMERA) {
                            type = window.Camera.PictureSourceType.PHOTOLIBRARY;
                        }
                        takeAndroidPicture(resolve, reject, type);
                    },
                    '',
                    ['Galerie', 'Kamera']
                );
            } else {
                takeBrowserPicture(resolve, reject);
            }
        });
    };

    function takeAndroidPicture(resolve, reject, type) {
        window.navigator.camera.getPicture(
            fileUri => handleLocalFileURI(resolve, fileUri),
            error => handleError(reject, 'Camera', error),
            getPictureOptions(type)
        );
    }

    function takeBrowserPicture(resolve, reject) {
        // Use dummy HTML5 input element
        let inputElement = document.createElement('input');
        inputElement.type = 'file';
        inputElement.accept = 'image/*';
        inputElement.style = 'position:absolute; left:-9999px;visibility:hidden;opacity:0;pointer-events: none;';
        inputElement.tabIndex = -1;
        inputElement.setAttribute('aria-hidden', 'true');
        inputElement.addEventListener('change', () => {
            let picture = inputElement.files[0];
            if (picture) {
                resolve(picture);
            } else {
                reject('No image selected');
            }
            inputElement.remove();
        });
        document.body.appendChild(inputElement);
        inputElement.click();

        // Can't detect cancel event. Remove input element after 15 minutes of inactivity
        setTimeout(() => {
            reject('Timeout');
            inputElement.remove();
        }, 15 * 60 * 1000);
    }

    function handleLocalFileURI(resolve, fileUri) {
        {
            window.resolveLocalFileSystemURL(
                fileUri,
                fileEntry => {
                    fileEntry.file(file => {
                        let reader = new FileReader();
                        reader.onloadend = function () {
                            resolve(new Blob([this.result], {type: 'image/jpeg'}));
                        };
                        reader.onerror = (error) => handleError('LocalFileSystem', error);
                        reader.onabort = (error) => handleError('LocalFileSystem', error);
                        reader.readAsArrayBuffer(file);
                    });
                },
                () => handleError('LocalFileSystem', 'Resolve local file system URL failed')
            );
        }
    }

    function handleError(reject, type, errors) {
        if (!Array.isArray(errors)) {
            errors = [errors];
        }
        reject({
            type: type,
            entries: errors
        });
    }

    function getPictureOptions(type) {
        return {
            quality: QUALITY,
            targetWidth: 2048,
            targetHeight: 2048,
            sourceType: type,
            destinationType: window.Camera.DestinationType.FILE_URI,
            encodingType: window.Camera.EncodingType.JPEG,
            correctOrientation: true
        };
    }

    return self;
};