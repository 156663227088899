<template>
    <div class="c3-main c3-page c3-page-user-notification-list">
        <div class="c3-page-content c3-page-content-bottom-padding">
            <div class="c3-page__title">{{ $t('pages.general.notificationList.title') }}</div>
            <c3-notification-list v-if="notifications.length" :notifications="notifications"/>
            <div v-else class="c3-page__body">{{ $t('pages.general.notificationList.noMessage') }}</div>
        </div>
    </div>
</template>

<script>
    import {NotificationService} from '@/domain/service/notificationService';

    import C3NotificationList from '@/components/content/notification/list/Index';

    export default {
        name: 'GeneralNotificationList',
        components: {
            C3NotificationList
        },
        data() {
            return {
                isLoading: true,
                showMissingNotificationPermissionWarning: false
            }
        },
        computed: {
            notifications() {
                return this.$store.getters['notification/findNotification'];
            }
        },
        methods: {
            loadData() {
                this.$store.dispatch('notification/loadNotifications');
            },
            async checkAndAskNotificationPermission() {
                let hasPermission = await NotificationService.hasPermission();
                console.debug('checkAndAskNotificationPermission - hasPermission: ' + hasPermission + ' hasSeenNotificationPermissionDialog: ' + this.$store.state.user.flags.hasSeenNotificationPermissionDialog);
                if (!hasPermission) {
                    if (!this.$store.state.user.flags.hasSeenNotificationPermissionDialog) {
                        // First time we ask for permission
                        this.$c3.alert(
                            'notification.askPermissionFirstTime.title',
                            'notification.askPermissionFirstTime.message',
                            NotificationService.askPermission
                        );
                        this.$store.commit('user/updateHasSeenNotificationPermissionDialog', true);
                    } else {
                        // User declined our first request...try again with explanation
                        this.$c3.htmlAlertWithTranslation(
                            'notification.askPermissionNextTime.title',
                            'notification.askPermissionNextTime.message'
                        );
                    }
                }
            }
        },
        // Page is in keep alive list, so activated is call
        activated() {
            this.loadData();
            this.checkAndAskNotificationPermission();
        },
        deactivated() {
            this.$store.dispatch('notification/markBulkAsRead', this.notifications.filter(notification => true === notification.unread));
        },
        beforeDestroy() {
            this.$store.dispatch('notification/markBulkAsRead', this.notifications.filter(notification => true === notification.unread));
        }
    };
</script>
<style lang="scss" scoped>
    .c3-page__title {
        margin-top: 20px;

        @include montserratBold();
        font-size: 20px;
        text-align: center;
    }

    .c3-page__title + .c3-notification-list {
        padding-top: 20px;
    }

    .c3-page__settings {
        display: flex;
        justify-content: center;
        align-items: center;

        padding-top: 15px;
        padding-bottom: 20px;

        overflow: hidden;

        .c3-icon-wrapper {
            height: 18px;
            width: 18px;
        }
    }

    .c3-page__settings-title {
        margin-left: 10px;
    }

    .c3-page__body {
        text-align: center;
    }

    .c3-page-user-notification-list {
        background-color: $eighth-color;
    }
</style>
