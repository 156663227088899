<template>
    <c3-drawer :name="name" :fullWidth="true">
        <c3-grid-tile>
            <c3-grid-tile-link-item @click="handleClick" :to="determineRequiredLoginTo('UserProfileBaseData')" :label="$t('label.me')" icon="c3-icon-user"/>
            <template v-if="isCustomer">
                <c3-grid-tile-link-item @click="handleClick" :to="{name: 'CustomerProfileBaseData'}" :label="$t('navigation.customer.company')" icon="c3-icon-building"/>
                <c3-grid-tile-link-item @click="handleClick" :to="{name: 'CustomerStore'}" :label="$t('navigation.customer.stores')" icon="c3-icon-store"/>
                <c3-grid-tile-item @click="handleMyCoupons" :label="$t('navigation.customer.coupons')" icon="c3-icon-tag"/>
                <template v-if="currentUser.isBonusCardEnabled">
                    <c3-grid-tile-item @click="handleMyBonusCard" :label="$t('navigation.customer.bonusCards')" icon="c3-icon-c3-bonus-card"/>
                </template>
                <template v-if="isShoppingCenter">
                    <c3-grid-tile-link-item @click="handleClick" :to="{name: 'CustomerShopManagement'}" :label="$t('navigation.shoppingCenter.management')" icon="c3-icon-store"/>
                </template>
                <c3-grid-tile-link-item @click="handleClick" :to="{name: 'CustomerProfileQrCode'}" :label="$t('navigation.customer.myQrCode')" icon="c3-icon-qr-code"/>
                <c3-grid-tile-link-item @click="handleClick" :to="{name: 'CustomerProfileMembership'}" :label="$t('label.membership')" icon="c3-icon-user"/>
                <c3-grid-tile-link-item @click="handleClick" :to="{name: 'CustomerProfilePayment'}" :label="$t('navigation.customer.paymentMethod')" icon="c3-icon-credit-card"/>
            </template>

            <template v-if="!isCustomer">
                <c3-grid-tile-link-item @click="handleClick" :to="determineRequiredLoginTo('CustomerRegistration')" :label="$t('navigation.user.registerCompany')"
                                        icon="c3-icon-building"/>
            </template>

            <template v-if="isCustomer">
                <c3-grid-tile-link-item @click="handleClick" :to="{name: 'Invoices'}" :label="$t('navigation.customer.invoices')" icon="c3-icon-file-invoice"/>
            </template>

            <c3-grid-tile-link-item @click="handleClick" :to="{name: isLoggedIn ? 'UserProfileAffiliate': 'Share' }" :label="$t('navigation.user.earnMoney')" icon="c3-icon-piggy-bank"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name: 'Help'}" :label="$t('navigation.help')" icon="c3-icon-question-circle"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name: 'PrivacyPolicy'}" :label="$t('navigation.dataPrivacy')" icon="c3-icon-user-secret"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name: 'ToS'}" :label="$t('navigation.tos')" icon="c3-file-alt"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name: 'Imprint'}" :label="$t('navigation.imprint')" icon="c3-icon-envelope"/>
            <template v-if="!isLoggedIn">
                <c3-grid-tile-link-item :to="{name: 'Login'}" :label="$t('navigation.login')" icon="c3-icon-sign-in"/>
            </template>
            <template v-if="hasUserImpersonates || isImpersonate">
                <c3-grid-tile-link-item @click="handleClick" :to="{name: 'LoginImpersonate'}" :label="$t('navigation.impersonate')" icon="c3-icon-people-arrows"/>
            </template>

            <template v-if="isLoggedIn">
                <c3-grid-tile-item @click="handleLogout" :label="$t('navigation.logout')" icon="c3-icon-sign-out"/>
            </template>
        </c3-grid-tile>
    </c3-drawer>
</template>

<script>
    import C3Drawer from '@/components/layout/navigation/Drawer.vue';
    import C3GridTile from '@/components/layout/grid/tile/Index';
    import C3GridTileLinkItem from '@/components/layout/grid/tile/LinkItem';
    import C3GridTileItem from '@/components/layout/grid/tile/Item';

    export default {
        components: {
            C3Drawer,
            C3GridTile,
            C3GridTileLinkItem,
            C3GridTileItem
        },
        data() {
            return {
                name: DRAWER.MAIN_NAVIGATION,
            }
        },
        methods: {
            handleClick() {
                // Close drawer on click, even if route doesn't change
                this.$store.commit('view/removeDrawer', this.name)
            },
            handleLogout() {
                this.$c3.confirm(
                    'label.logout',
                    'confirm.logout.message',
                    () => this.$router.push({name: 'Logout'}),
                    null,
                    ['label.logout', 'label.cancel']
                );
            },
            handleMyCoupons() {
                this.$store.commit('view/addDrawer', DRAWER.CUSTOMER_MY_COUPON_NAVIGATION)
            },
            handleMyBonusCard() {
                this.$store.commit('view/addDrawer', DRAWER.CUSTOMER_MY_BONUS_CARD_NAVIGATION)
            },
            determineRequiredLoginTo(name) {
                return this.determineTo(name, true, true, true);
            },
            determineTo(name, needsLogin, skipLoginAlert, redirectAfterLogin) {
                let to = {name: '', query: {}};
                if (needsLogin && !this.isLoggedIn) {
                    to.name = 'Login';

                    if (skipLoginAlert) {
                        to.query.skipLoginAlert = true;
                    }

                    if (redirectAfterLogin) {
                        redirectAfterLogin = this.$router.resolve({name: name}).resolved.fullPath;
                        to.query.redirectAfterLogin = redirectAfterLogin;
                    }
                } else {
                    to.name = name;
                }
                return to;
            }
        },
    };
</script>
