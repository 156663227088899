<template>
    <div class="c3-two-column-with-icon-wrapper">
        <div class="c3-column c3-column-icon" v-if="icon">
            <slot name="icon">
                <component :is="icon" primaryColor="quinary"/>
            </slot>
        </div>
        <div class="c3-column">
            <slot name="first"></slot>
        </div>
        <div class="c3-column c3-column-last" :class="{'c3-column-pull-right':lastColumnToRight}">
            <slot name="second"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String
            },
            lastColumnToRight: {
                type: Boolean,
                default: true,
            }
        },
        computed: {},
        methods: {}
    };
</script>
<style lang="scss" scoped>
    $icon-column-width: 30px;
    .c3-two-column-with-icon-wrapper {
        display: flex;
    }

    .c3-column {
    }

    .c3-column-icon {
        width: $icon-column-width;
        height: 18px;
    }

    .c3-column-pull-right {
        margin-left: auto;
        text-align: right;
    }
</style>