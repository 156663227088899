import IconAngleLeft from '@/components/icon/AngleLeft';
import IconArchive from '@/components/icon/Archive';
import IconBooks from '@/components/icon/Books';
import IconBoxHeart from '@/components/icon/BoxHeart';
import IconBuilding from '@/components/icon/Building';
import IconCar from '@/components/icon/Car';
import IconChartLine from '@/components/icon/ChartLine';
import IconCheckCircle from '@/components/icon/CheckCircle';
import IconChevronLeft from '@/components/icon/ChevronLeft';
import IconChevronRight from '@/components/icon/ChevronRight';
import IconClock from '@/components/icon/Clock';
import IconCog from '@/components/icon/Cog';
import IconComments from '@/components/icon/Comments';
import IconCopy from '@/components/icon/Copy';
import IconCreditCard from '@/components/icon/CreditCard';
import IconCreditCardFront from '@/components/icon/CreditCardFront';
import IconCrosshairs from '@/components/icon/Crosshairs';
import IconEdit from '@/components/icon/Edit';
import IconEnvelope from '@/components/icon/Envelope';
import IconExchange from '@/components/icon/Exchange';
import IconFileAlt from '@/components/icon/FileAlt';
import IconFileInvoice from '@/components/icon/FileInvoice';
import IconGift from '@/components/icon/Gift';
import IconGlobe from '@/components/icon/Globe';
import IconGripVertical from '@/components/icon/GripVertical';
import IconHandshake from '@/components/icon/Handshake';
import IconHeart from '@/components/icon/Heart';
import IconHome from '@/components/icon/Home';
import IconHomeHeart from '@/components/icon/HomeHeart';
import IconHotel from '@/components/icon/Hotel';
import IconImage from '@/components/icon/Image';
import IconInfoCircle from '@/components/icon/InfoCircle';
import IconInfoSquare from '@/components/icon/InfoSquare';
import IconMap from '@/components/icon/Map';
import IconMapMarkerAlt from '@/components/icon/MapMarkerAlt';
import IconMoneyBill from '@/components/icon/MoneyBill';
import IconPeopleArrows from '@/components/icon/PeopleArrows';
import IconPhone from '@/components/icon/Phone';
import IconPiggyBank from '@/components/icon/PiggyBank';
import IconPlusCircle from '@/components/icon/PlusCircle';
import IconPlusSquare from '@/components/icon/PlusSquare';
import IconQrCode from '@/components/icon/QrCode';
import IconQuestionCircle from '@/components/icon/QuestionCircle';
import IconRedo from '@/components/icon/Redo';
import IconRoute from '@/components/icon/Route';
import IconSearch from '@/components/icon/Search';
import IconShareAlt from '@/components/icon/ShareAlt';
import IconShareAltSquare from '@/components/icon/ShareAltSquare';
import IconShoppingBag from '@/components/icon/ShoppingBag';
import IconSignIn from '@/components/icon/SignIn';
import IconSignOut from '@/components/icon/SignOut';
import IconSlidersV from '@/components/icon/SlidersV';
import IconSort from '@/components/icon/Sort';
import IconSpa from '@/components/icon/Spa';
import IconStore from '@/components/icon/Store';
import IconStoreAlt from '@/components/icon/StoreAlt';
import IconSwimmer from '@/components/icon/Swimmer';
import IconTag from '@/components/icon/Tag';
import IconThumbsUp from '@/components/icon/ThumbsUp';
import IconTimesCircle from '@/components/icon/TimesCircle';
import IconTrophy from '@/components/icon/Trophy';
import IconUser from '@/components/icon/User';
import IconUserSecret from '@/components/icon/UserSecret';
import IconUtensils from '@/components/icon/Utensils';

import IconC3BonusCard from '@/components/icon/C3BonusCard';
import IconC3BonusCardActive from '@/components/icon/C3BonusCardActive';
import IconC3BonusCardPending from '@/components/icon/C3BonusCardPending';
import IconC3BonusCardStamp from '@/components/icon/C3BonusCardStamp';
import IconC3CouponCheck from '@/components/icon/C3CouponCheck';
import IconC3CouponClock from '@/components/icon/C3CouponClock';
import IconC3Error from '@/components/icon/C3Error';
import IconC3HeartFull from '@/components/icon/C3HeartFull';
import IconC3PersonNavigation from '@/components/icon/C3PersonNavigation';
import IconC3StampCircle from '@/components/icon/C3StampCircle';

export default {
    install: function (Vue, options) {
        Vue.component('c3-icon-c3-bonus-card', IconC3BonusCard);
        Vue.component('c3-icon-c3-bonus-card-active', IconC3BonusCardActive);
        Vue.component('c3-icon-c3-bonus-card-pending', IconC3BonusCardPending);
        Vue.component('c3-icon-c3-bonus-card-stamp', IconC3BonusCardStamp);
        Vue.component('c3-icon-c3-coupon-check', IconC3CouponCheck);
        Vue.component('c3-icon-c3-coupon-clock', IconC3CouponClock);
        Vue.component('c3-icon-c3-error', IconC3Error);
        Vue.component('c3-icon-c3-heart-full', IconC3HeartFull);
        Vue.component('c3-icon-c3-person-navigation', IconC3PersonNavigation);
        Vue.component('c3-icon-c3-person-navigation', IconC3PersonNavigation);
        Vue.component('c3-icon-c3-stamp-circle', IconC3StampCircle);

        Vue.component('c3-file-alt', IconFileAlt);
        Vue.component('c3-icon-file-invoice', IconFileInvoice);
        Vue.component('c3-icon-archive', IconArchive);
        Vue.component('c3-icon-arrow-left', IconAngleLeft);
        Vue.component('c3-icon-books', IconBooks);
        Vue.component('c3-icon-box-heart', IconBoxHeart);
        Vue.component('c3-icon-building', IconBuilding);
        Vue.component('c3-icon-car', IconCar);
        Vue.component('c3-icon-chart-line', IconChartLine);
        Vue.component('c3-icon-check-circle', IconCheckCircle);
        Vue.component('c3-icon-chevron-left', IconChevronLeft);
        Vue.component('c3-icon-chevron-right', IconChevronRight);
        Vue.component('c3-icon-clock', IconClock);
        Vue.component('c3-icon-cog', IconCog);
        Vue.component('c3-icon-comments', IconComments);
        Vue.component('c3-icon-copy', IconCopy);
        Vue.component('c3-icon-credit-card', IconCreditCard);
        Vue.component('c3-icon-credit-card-front', IconCreditCardFront);
        Vue.component('c3-icon-crosshairs', IconCrosshairs);
        Vue.component('c3-icon-edit', IconEdit);
        Vue.component('c3-icon-envelope', IconEnvelope);
        Vue.component('c3-icon-exchange', IconExchange);
        Vue.component('c3-icon-gift', IconGift);
        Vue.component('c3-icon-globe', IconGlobe);
        Vue.component('c3-icon-grip-vertical', IconGripVertical);
        Vue.component('c3-icon-handshake', IconHandshake);
        Vue.component('c3-icon-heart', IconHeart);
        Vue.component('c3-icon-home', IconHome);
        Vue.component('c3-icon-home-heart', IconHomeHeart);
        Vue.component('c3-icon-hotel', IconHotel);
        Vue.component('c3-icon-image', IconImage);
        Vue.component('c3-icon-info-circle', IconInfoCircle);
        Vue.component('c3-icon-info-square', IconInfoSquare);
        Vue.component('c3-icon-map', IconMap);
        Vue.component('c3-icon-map-marker-alt', IconMapMarkerAlt);
        Vue.component('c3-icon-money-bill', IconMoneyBill);
        Vue.component('c3-icon-people-arrows', IconPeopleArrows);
        Vue.component('c3-icon-phone', IconPhone);
        Vue.component('c3-icon-piggy-bank', IconPiggyBank);
        Vue.component('c3-icon-plus-circle', IconPlusCircle);
        Vue.component('c3-icon-plus-square', IconPlusSquare);
        Vue.component('c3-icon-qr-code', IconQrCode);
        Vue.component('c3-icon-question-circle', IconQuestionCircle);
        Vue.component('c3-icon-redo', IconRedo);
        Vue.component('c3-icon-route', IconRoute);
        Vue.component('c3-icon-search', IconSearch);
        Vue.component('c3-icon-share-alt', IconShareAlt);
        Vue.component('c3-icon-share-alt-square', IconShareAltSquare);
        Vue.component('c3-icon-shopping-bag', IconShoppingBag);
        Vue.component('c3-icon-sign-in', IconSignIn);
        Vue.component('c3-icon-sign-out', IconSignOut);
        Vue.component('c3-icon-sliders-v', IconSlidersV);
        Vue.component('c3-icon-sort', IconSort);
        Vue.component('c3-icon-spa', IconSpa);
        Vue.component('c3-icon-store', IconStore);
        Vue.component('c3-icon-store-alt', IconStoreAlt);
        Vue.component('c3-icon-swimmer', IconSwimmer);
        Vue.component('c3-icon-tag', IconTag);
        Vue.component('c3-icon-thumbs-up', IconThumbsUp);
        Vue.component('c3-icon-times-circle', IconTimesCircle);
        Vue.component('c3-icon-trophy', IconTrophy);
        Vue.component('c3-icon-user', IconUser);
        Vue.component('c3-icon-user-secret', IconUserSecret);
        Vue.component('c3-icon-utensils', IconUtensils);
    }
};
