export default {
    token: null,
    position: {
        coordinates: {
            latitude: null,
            lng: null,
            accuracy: null
        },
        lastUpdate: null,
        error: null,
    },

    user: null,
    advertiser: null,
    payment: null,
    memberships: [],
    flags: {
        noCouponOnHomeAlertShown: true,
        isCompany: null,
        hasSeenNotificationPermissionDialog: false
    },
    visibleFavoriteCoupon: [],
    redeemStates: {},
    ownerRedeem: [],
    userRedeem: [],
    /**
     * If we currently impersonate a user, this is the token of the original user.
     * Storing this allows us to easily go back after impersonating someone else.
     */
    tokenOriginalUser: null,
    originalUser: null,
    affiliates: [],
    affiliateToken: null,
};
