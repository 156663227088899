<template>
    <c3-field-wrapper>
        <input :value="value" @input="$emit('input', $event.target.value)"
               @focus="focus" @blur="blur"
               :id="id" :type="type" :autocomplete="autocomplete" class="c3-form-field-input"
               :placeholder="placeholder" :maxlength="maxLength"/>
        <div v-if="maxLength" class="c3-form-field-input__counter">{{value.length}}/{{maxLength}}</div>
    </c3-field-wrapper>
</template>

<script>
    import BaseField from '@/components/form/fields/BaseField.vue';
    import C3FieldWrapper from '@/components/form/FieldWrapper.vue';

    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';

    export default {
        extends: BaseField,
        mixins: [FormFieldErrorMixin],
        components: {
            C3FieldWrapper,
        },
        props: {
            value: {
                type: [String, Number]
            },
            type: {
                type: String,
                default: 'text'
            },
            maxLength: {
                type: Number,
                default: null
            },
            labelStyling: {
                type: String,
                default: 'normal'
            },
            styling: {
                type: String
            },
        },
        data() {
            return {
                hasFocus: false
            }
        },
        methods: {
            blur() {
                this.hasFocus = false;
                this.$emit('blur');
            },
            focus() {
                this.hasFocus = true;
                this.$emit('focus');
            }
        }
    };
</script>
