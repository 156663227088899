<template>
    <div @click="$emit('click')" class="c3-form-field-group c3-form-field-group-submit" :class="groupClasses">
        <button :type="type" class="c3-form-field-button" :class="buttonClasses" :disabled="disabled">
            <span>{{ label }}</span>
        </button>
        <div class="c3-form-field-loader">
            <c3-loader :color="loadingIconColor" size="s" v-if="isLoading"></c3-loader>
        </div>
        <div v-if="hasError" class="c3-form-field-group-error-message">{{ $t('error.invalidFields') }}</div>
    </div>
</template>

<script>

    export default {
        name: 'Submit',
        props: {
            label: {
                type: String,
                required: true
            },
            invert: {
                type: Boolean
            },
            isLoading: {
                type: Boolean
            },
            disabled: {
                type: Boolean,
                default: false
            },
            hasError: {
                type: Boolean
            },
            styling: {
                type: String,
                default: 'default'
            },
            type: {
                type: String,
                required: false,
                default: 'submit'
            }
        },
        computed: {
            groupClasses() {
                let classes = [];

                if (this.invert) {
                    classes.push('c3-form-field-group--invert c3-form-field-group-submit-invert');
                }
                if (this.disabled) {
                    classes.push('c3-form-field-group--disabled c3-form-field-group-submit--disabled')
                }
                if (this.styling) {
                    classes.push('c3-form-field-group-submit-styling-' + this.styling);
                }

                return classes.join(' ');
            },
            buttonClasses() {
                let classes = [];

                if (this.invert) {
                    classes.push('c3-form-field-button-invert');
                }
                if (this.disabled) {
                    classes.push('c3-form-field-button--disabled')
                }
                if (this.styling) {
                    classes.push('c3-form-field-button-styling-' + this.styling);
                }

                return classes.join(' ');
            },
            loadingIconColor() {
                switch (this.styling) {
                    case 'action':
                        return 'white';
                }
                return 'quinary';
            }
        },
        data() {
            return {};
        }
    };
</script>

<style lang="scss" scoped>
    .c3-form-field-group {
        position: relative;
    }

    .c3-form-field-loader {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
</style>
