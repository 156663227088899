import Vue from 'vue';

import LoaderFlashingCircle from '@/components/loader/FlashingCircle.vue';

import C3CouponMessageListIcon from '@/components/pages/header/MessageListIcon';

import ContentBranding from '@/components/content/Branding.vue';

import Image from '@/components/media/Image.vue';
import BackgroundImage from '@/components/media/BackgroundImage.vue';

export default {
    registerComponents() {
        Vue.component('c3-loader', LoaderFlashingCircle);

        // Page header components
        Vue.component('c3-message-list-icon', C3CouponMessageListIcon);

        Vue.component('c3-content-branding', ContentBranding);

        Vue.component('c3-image', Image);
        Vue.component('c3-background-image', BackgroundImage);
    },
};
