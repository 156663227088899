import Vue from 'vue';
import Swal from 'sweetalert2'

import {Translator} from '@/domain/translator';

let instance = null;

export default {
    install: function (Vue, options) {
        if (typeof Vue.prototype.$c3 !== 'object') {
            Vue.prototype.$c3 = {};
        }

        Vue.mixin({
            beforeCreate() {
                if (null === instance) {
                    instance = this;
                }
            }
        });

        Vue.prototype.$c3.alert = this.alert;
        Vue.prototype.$c3.htmlAlert = this.htmlAlert;
        Vue.prototype.$c3.htmlAlertWithTranslation = this.htmlAlertWithTranslation;
        Vue.prototype.$c3.staticContentAlert = this.staticContentAlert;
        Vue.prototype.$c3.staticContentAlertById = this.staticContentAlertById;
        Vue.prototype.$c3.successAlert = this.successAlert;
        Vue.prototype.$c3.errorAlert = this.errorAlert;
        Vue.prototype.$c3.confirm = this.confirm;
        Vue.prototype.$c3.confirmHtml = this.confirmHtml;
        Vue.prototype.$c3.confirmYesNo = this.confirmYesNo;
        Vue.prototype.$c3.confirmLoginRedirect = this.confirmLoginRedirect;
        Vue.prototype.$c3.confirmNeutral = this.confirmNeutral;
    },
    alert: (title, msg, callback, type, userOptions) => {
        let defaultOptions = {
            titleText: Translator.t(title),
            text: Translator.t(msg),
            confirmButtonText: Translator.t('label.ok'),
            confirmButtonColor: COLORS.QUINARY,
            onAfterClose: callback,
            type: type
        };

        if (null === userOptions || typeof userOptions !== 'object') {
            userOptions = {};
        }

        return Swal(Object.assign(defaultOptions, userOptions));
    },
    successAlert: (title, msg, callback) => {
        return Vue.prototype.$c3.alert(title, msg, callback, 'success');
    },
    errorAlert: (title, msg, callback) => {
        return Vue.prototype.$c3.alert(title, msg, callback, 'error');
    },
    htmlAlert: (title, msg, callback, userOptions) => {
        let defaultOptions = {
            title: title,
            html: msg,
            confirmButtonText: Translator.t('label.ok'),

            confirmButtonColor: COLORS.QUINARY,
            showCloseButton: true,
        };

        if (null === userOptions || typeof userOptions !== 'object') {
            userOptions = {};
        }

        return Swal(Object.assign(defaultOptions, userOptions))
            .then((result) => {
                if (typeof callback === 'function') {
                    callback(result);
                }
            });
    },
    htmlAlertWithTranslation: (title, msg, callback, userOptions) => {
        return Vue.prototype.$c3.htmlAlert(Translator.t(title), Translator.t(msg), callback, userOptions);
    },
    staticContentAlert: (title, msg, callback, userOptions) => {
        return Vue.prototype.$c3.htmlAlert(title, '<div class="c3-static-content">' + msg + '</div>', callback, userOptions);
    },
    staticContentAlertById: function (id, callback, userOptions) {
        let content = instance.$store.getters['staticContent/singleContent'](id);
        if (null !== content) {
            return Vue.prototype.$c3.staticContentAlert(content.title, content.text, callback, userOptions);
        } else {
            return Vue.prototype.$c3.errorAlert('error.generic.title', 'error.generic.message')
        }
    },
    confirm: (title, msg, okCallback, cancelCallback, labels, userOptions) => {
        if (!Array.isArray(labels)) {
            labels = ['label.ok', 'label.cancel'];
        }

        let options = {
            titleText: Translator.t(title),
            text: Translator.t(msg),
            showCancelButton: true,
            confirmButtonText: Translator.t(labels[0]),
            cancelButtonText: Translator.t(labels[1]),
            confirmButtonColor: COLORS.QUINARY,
        };

        if (null === userOptions || typeof userOptions !== 'object') {
            userOptions = {};
        }

        options = Object.assign(options, userOptions);

        return Swal(options).then((result) => {
            if (result.hasOwnProperty('value') && result.value) {
                if (typeof okCallback === 'function') {
                    okCallback(result);
                }
            } else {
                if (typeof cancelCallback === 'function') {
                    cancelCallback(result);
                }
            }
        });
    },
    confirmHtml: (title, html, okCallback, cancelCallback, labels, userOptions) => {
        if (!Array.isArray(labels)) {
            labels = ['label.ok', 'label.cancel'];
        }

        let options = {
            title: Translator.t(title),
            html: html,
            showCancelButton: true,
            confirmButtonText: Translator.t(labels[0]),
            cancelButtonText: Translator.t(labels[1]),
            confirmButtonColor: COLORS.QUINARY,
        };

        if (null === userOptions || typeof userOptions !== 'object') {
            userOptions = {};
        }

        options = Object.assign(options, userOptions);

        return Swal(options).then((result) => {
            if (result.hasOwnProperty('value') && result.value) {
                if (typeof okCallback === 'function') {
                    okCallback(result);
                }
            } else {
                if (typeof cancelCallback === 'function') {
                    cancelCallback(result);
                }
            }
        });
    },
    confirmYesNo: (title, msg, okCallback, cancelCallback, userOptions) => {
        return Vue.prototype.$c3.confirm(title, msg, okCallback, cancelCallback, [Translator.t('label.yes'), Translator.t('label.no')], userOptions);
    },
    confirmLoginRedirect: (okCallback, cancelCallback) => {
        return Vue.prototype.$c3.confirm(
            'confirm.login.title',
            'confirm.login.message',
            okCallback,
            cancelCallback,
            ['label.login', 'label.cancel']
        );
    },
    confirmNeutral: (title, msg, okCallback, cancelCallback, labels, userOptions) => {
        if (null === userOptions || typeof userOptions !== 'object') {
            userOptions = {};
        }

        userOptions.confirmButtonColor = window.COLORS.WHITE;

        if (userOptions.hasOwnProperty('customClass')) {
            userOptions.customClass = userOptions.customClass + ' c3-neutral';
        } else {
            userOptions.customClass = 'c3-alert--neutral';
        }

        return Vue.prototype.$c3.confirm(title, msg, okCallback, cancelCallback, labels, userOptions);
    }
};
