import {LocalStorage} from '@/domain/utility/localStorage';

const generalStorage = LocalStorage('general');
const STORAGE_HAS_SEEN_INTRODUCTION_SLIDES = 'has_seen_introduction_slides';

export const initialize = (state) => {
    if (generalStorage.has(STORAGE_HAS_SEEN_INTRODUCTION_SLIDES)) {
        state.hasSeenIntroductionSlides = generalStorage.get(STORAGE_HAS_SEEN_INTRODUCTION_SLIDES);
    }
};

export const updateApiCheck = (state, apiCheck) => {
    state.apiCheck = apiCheck;
};

export const updateFeatureFlags = (state, featureFlags) => {
    state.featureFlags = featureFlags;
};

export const updateHasSeenIntroductionSlides = (state, hasSeenIntroductionSlides) => {
    state.hasSeenIntroductionSlides = hasSeenIntroductionSlides;
    generalStorage.set(STORAGE_HAS_SEEN_INTRODUCTION_SLIDES, hasSeenIntroductionSlides);
};
