export default {
    label: {
        invest: 'Anlegen',
        active: 'Aktiv',
        in_examination: 'In Prüfung',
        planned: 'Geplant',
        drafts: 'Entwürfe',
        archive: 'Archiv',
        redeemed: 'Bei dir eingelöst',
        login: 'Anmelden',
        logout: 'Abmelden',
        send: 'Absenden',
        next: 'Weiter',
        save: 'Speichern',
        update: 'Aktualisieren',
        publish: 'Veröffentlichen',
        moreAboutCompany: 'mehr zum Unternehmen',
        showAllCoupons: 'alle Coupons anzeigen',
        showAllBonusCard: 'alle Sammelpässe anzeigen',
        apply: 'Anwenden',
        reset: 'Zurücksetzen',
        remove: 'Löschen',
        saveAsDraft: 'Als Entwurf speichern',
        cancel: 'Abbrechen',
        ok: 'OK',
        yes: 'Ja',
        no: 'Nein',
        download: 'Herunterladen',
        share: 'Teilen',
        tos: 'AGB',
        noInvoice: 'Keine Rechnung vorhanden',
        privacyPolicy: 'Datenschutz',
        call: 'Anrufen',
        email: 'E-Mail',
        website: 'Web',
        lastUpdate: 'Zuletzt aktualisiert',
        location: 'Standort',
        membership: 'Abonnement',
        pleaseSelectOption: 'Bitte wählen',

        updatePositionLoading: 'Wir bestimmen deinen Standort, einen Moment bitte...',

        // Form steps
        step1Of2: 'Schritt 1 von 2',
        step2Of2: 'Schritt 2 von 2',

        step1Of3: 'Schritt 1 von 3',
        step2Of3: 'Schritt 2 von 3',
        step3Of3: 'Schritt 3 von 3',

        continueToStep2: 'weiter zu Schritt 2',
        continueToStep3: 'weiter zu Schritt 3',

        // Payment
        creditCard: 'Kreditkarte',
        upload: 'Hochladen',
        redeem: 'Einlösen',
        tos: {
            terms: 'Ich akzeptiere die AGB',
            policy: 'Ich akzeptiere die Datenschutzbestimmungen'
        },
        firstname: 'Vorname',
        lastName: 'Nachname',
        profileUpdate: 'Profil aktualisieren',
        myProfile: 'Mein Profil',
        profileFrom: 'Profil von',
        saveBillingAddress: 'Rechnungsadresse speichern',
        bonusCardFrom: 'Sammelpässe von',
        from: 'von',
        until: 'bis',
        priceWithTax: 'Preis exkl. MwSt.',
        activeUntil: 'aktiv bis',
        close: 'Schließen',
        closeBtn: 'Abschließen',
        determineCurrentLocation: 'Position jetzt bestimmen',
        appName: 'Coupocket',
        uploadNewImage: 'Neues Bild hochladen',
        changeImage: 'Bild tauschen',
        noEntries: 'Keine Einträge gefunden.',
        zipPlaceholder: 'Stadt oder PLZ',
        noCategoryTitle: 'Keine Kategorien gefunden.',
        after: 'danach',
        me: 'Ich',
        moreCategory: 'weitere<br>Kategorien',
        contact: 'Kontakt',
        qrCode: 'QR-Code',
        sendToContact: 'An Kontakt schicken',
        couponCode: 'Gutschein-Code',
        toNext: 'Zum nächsten Schritt',
        skip: 'Überspringen',
        createNow: 'Jetzt anlegen',
        paymentType: 'Zahlungsart',
        savePayment: 'Zahlungsdaten speichern',
        holder: 'Inhaber',
        cardHolder: 'Karteninhaber',
        accountHolder: 'Kontoinhaber',
        pin: 'PIN',
        chooseCategory: 'Wähle eine Kategorie',
        more: 'Mehr erfahren',
        all: 'Alle',
        my: 'Meine',
        chars: 'Zeichen',
        openFile: 'öffnen',
        downloadFailed: 'Gescheitert',
        downloadComplete: 'Vollständig herunterladen',
        templateTitle: 'Vollständiger Titel',
        chooseTemplate: 'Vorlage auswählen',
        noTemplate: 'Keine Vorlage',
        phone: 'Mobilnummer',
        deleteAccount: 'Account löschen',
        optionsType: {
            Normal: 'Kunde scant QR-code',
            QR: 'Unternehmen scant QR-code',
            Barcode: 'Unternehmen scant Barcode'
        }
    },
    navigation: {
        home: 'Home',
        login: 'Anmelden',
        logout: 'Abmelden',
        impersonate: 'Benutzer wechseln',
        imprint: 'Impressum',
        tos: 'AGB',
        help: 'Hilfe',
        dataPrivacy: 'Datenschutz',
        user: {
            registerCompany: 'Unternehmen anmelden',
            earnMoney: 'Geld verdienen',
            affiliate: 'Affiliate'
        },
        customer: {
            company: 'Mein Unternehmen',
            openingTimes: 'Öffnungszeiten',
            stores: 'Filialen',
            profile: 'Profil',
            coupons: 'Meine Coupons',
            bonusCards: 'Meine Sammelpässe',
            myQrCode: 'Mein QR-Code',
            paymentMethod: 'Zahlungsmittel',
            invoices: 'Rechnungen',
        },
        shoppingCenter: {
            management: 'Shopverwaltung',
        }
    },
    footer: {
        coupons: 'Coupons',
        favorites: 'Favoriten',
        bonusCards: 'Sammelpässe',
        share: 'Teilen',
        navigation: 'Menü',
    },
    weekdays: {
        long: {
            monday: 'Montag',
            tuesday: 'Dienstag',
            wednesday: 'Mittwoch',
            thursday: 'Donnerstag',
            friday: 'Freitag',
            saturday: 'Samstag',
            sunday: 'Sonntag'
        },
        short: {
            monday: 'Mo',
            tuesday: 'Di',
            wednesday: 'Mi',
            thursday: 'Do',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'So'
        }
    },
    user: {
        pleaseEnterPhoneNumberForLogin: 'Bitte gib deine Telefonnummer ein, um dich anzumelden.',
        sendLoginPinLoadingMessage: 'Einen Moment bitte, wir versenden jetzt die Login-PIN.',
        loginCode: 'Login-Code',
        sentLoginPin: 'Wir haben dir eine Login-PIN per SMS geschickt.',
        phoneLabel: 'Meine Telefonnummer',
        impersonate: {
            title: 'Wähle deinen Benutzer',
            name: 'Name',
            firm: 'Firma',
        }
    },
    customer: {
        company: 'Firmenname',
        name: 'Name',
        address: 'Straße',
        zip: 'Postleitzahl',
        city: 'Stadt',
        country: 'Land',
        companyPhone: {
            label: 'Telefon (im Unternehmen)',
            description: 'Internationales Format. Beispiel: +439876',
        },
        companyEmail: 'Email (im Unternehmen)',
        website: 'Website (optional)',
        vatId: 'UID-Nummer (optional)',
        openingTimesComment: 'Besondere Öffnungszeiten',

        alternateBillingAddress: 'Abweichende Rechnungsadresse',

        sendQrCodeAsMail: 'Per E-Mail an mich senden',

        profileTitle: 'Unternehmen',
        baseData: 'Unternehmen',
        openingTime: 'Öffnungszeiten',

        editBaseData: 'Unternehmen bearbeiten',
        editOpeningTime: 'Öffnungszeiten bearbeiten',
        goToCustomerStoreList: 'Meine Filialen',

        store: {
            address: 'Straße',
            zip: 'Postleitzahl',
            city: 'Stadt',
            country: 'Land',
            phone: {
                label: 'Telefon (in der Filiale)',
                description: 'Internationales Format. Beispiel: +439876',
            },
            email: 'E-Mail (in der Filiale)',
            hasOpeningTimes: 'Diese Filiale hat Öffnungszeiten',
        },

        payment: {
            sepaMandate: 'Ich ermächtige die Coupocket GmbH und deren Zahlungsprovider Stripe Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Coupocket GmbH auf mein Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedinungen.'
        },
        cancelAlert: {
            title: '',
            message: 'Wir haben deine Kündigung erhalten. Dein aktuelles Abo endet am <strong>%{membershipExpirationDate}</strong>. Noch ausstehende Kosten werden bis dahin automatisch abgebucht.<br><br>Es kann 1-2 Tage dauern, bis die Kündigung in der App sichtbar wird.'
        }
    },
    bonusCard: {
        name: 'Sammelpass',
        image: 'Bild',
        title: 'Name / Titel',
        shop: 'Shop',
        template: 'Vorlage',
        description: 'Beschreibung',
        titleExample: 'Beispiel: Pizza 10+1',
        legalNotice: 'Zusätzliche Gültigkeitshinweise',
        validFrom: 'Gültig ab',
        validUntil: 'Gültig bis',
        necessaryRedeemCount: 'Anzahl der Stempel bis zum Bonus',
        necessaryRedeemCountExplanation: 'Beispiel: 6 Stempel - Der Nutzer muss 6 Stempel sammeln, bevor er den Bonus erhält (6+1).',
        necessaryRedeemCountReadOnlyAfterPublished: 'Die Anzahl der Stempel kann nach der Veröffentlichung nicht mehr geändert werden.',

        loadingList: 'Aktualisiere Sammelpässe',
        create: 'Neuen Sammelpass anlegen',
        type: 'Scanvorgang',
        content: 'Deine Code Nummer eingaben',
        contentHint: 'Geben Sie den Code Ihres Barcode ein, oder denn Inhalt des QR-codes',
        publishAlert: {
            title: 'Abonnement notwendig',
            message: 'Um einen Sammelpass zu veröffentlichen musst du ein Abonnement auswählen und deine Zahlungsdaten eingeben. Möchtest du dies jetzt tun?'
        },
        listItems: {
            activeCustomer: {
                title: 'Beliebig viele Sammelpässe anlegen',
                message: 'Mehr Sammelpässe erreichen mehr Nutzer',
                button: 'Neuen Coupon anlegen'
            },
            customer: {
                title: 'Jetzt Sammelpass für<br>{company}<br>anlegen',
                message: 'Erreiche neue Kunden in deiner Umgebung',
                button: 'Sammelpass anlegen'
            },
            user: {
                title: 'Du bist Unternehmer?',
                message: 'Erreiche neue Kunden in deiner Umgebung',
                button: 'Unternehmen anmelden'
            },
        },
        affiliate: {
            title: 'Kein Sammelpass in deiner Nähe?',
            message: 'Unternehmen einladen und Geld verdienen',
            button: 'So funktioniert\'s'
        },
        noEntries: {
            title: 'Kein Sammelpass gefunden',
            message: 'Unternehmen einladen und Geld verdienen',
            explanation: 'So funktioniert\'s'
        },
        limitReachedAlert: {
            title: 'Sammelpass-Limit erreicht',
            message: 'Möchtest du jetzt dein Abonnement upgraden, um mehr Sammelpässe gleichzeitig veröffentlichen zu können?',
        },
    },
    coupon: {
        title: 'Titel / Couponwert',
        shop: 'Shop',
        template: 'Vorlage',
        description: 'Beschreibung',
        category: 'Kategorie',
        validity: 'Gültigkeit',
        online: 'Online',
        validFrom: 'Gültig von',
        validUntil: 'Gültig bis',
        totalCount: 'Gesamtanzahl Coupons',
        perUserCount: 'Coupons pro Benutzer',
        legalNotice: 'Zusätzliche Gültigkeitshinweise',
        onlineRedeemUrl: 'Link zum Onlineshop',
        onlineRedeemCode: 'Einlösecode',
        noFavorites: 'Du hast noch keine Favoriten hinterlegt.',
        valid: 'Gültig',
        create: 'Neuen Coupon anlegen',
        type: 'Scanvorgang',
        content:{
            qr: 'QR code Inhalt eingeben',
            barcode: 'Barcode Nummer eingeben',
        },
        contentHint: 'Geben Sie den Code Ihres Barcode ein, oder denn Inhalt des QR-codes',
        listItems: {
            activeCustomer: {
                title: 'Beliebig viele Coupons anlegen',
                message: 'Mehr Coupons erreichen mehr Nutzer',
                button: 'Neuen Coupon anlegen'
            },
            customer: {
                title: 'Jetzt Coupon für<br>{company}<br>anlegen',
                message: 'Erreiche neue Kunden in deiner Umgebung',
                button: 'Coupon anlegen'
            },
            user: {
                title: 'Du bist Unternehmer?',
                message: 'Erreiche neue Kunden in deiner Umgebung',
                button: 'Unternehmen anmelden'
            },
        },
        affiliate: {
            title: 'Kein Coupon in deiner Nähe?',
            message: 'Hol dir € 50 für jede Empfehlung!',
            button: 'So funktioniert\'s'
        },

        noEntries: {
            title: 'Keine Coupons gefunden',
            message: 'Hol dir € 50 für jede Empfehlung!',
            button: 'So funktioniert\'s'
        },
        redeem: {
            cameraTooltip: 'Bitte scanne den QR-Code des Unternehmers'
        },
        newCouponBadgeText: 'NEU',
        filter: {
            filterButton: 'Filter',
            selectCategory: 'Kategorien wählen',
            onlyLatestCoupons: 'Zeige nur Neue',
            locationRadius: "Im Umkreis von"
        },
        loadingList: 'Aktualisiere Coupons',
        confirmDeleteAlert: {
            title: 'Gutschein löschen',
            message: 'Möchten Sie den Coupon wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!'
        },
        limitReachedAlert: {
            title: 'Coupon-Limit erreicht',
            message: 'Möchtest du jetzt dein Abonnement upgraden, um mehr Coupons gleichzeitig veröffentlichen zu können?',
        },
    },
    locationDrawer: {
        title: 'Kein Standort verfügbar',
        button: 'Jetzt bestimmen',
        subtitle: 'Meinen Standort bestimmen'
    },
    voucher: {
        loadingRedeem: 'Einen Moment bitte, wir prüfen deinen Gutschein',
        redeemSuccessful: {
            title: '',
            message: 'Die Einlösung war erfolgreich, die vergünstigten Tarife stehen jetzt zur Auswahl bereit'
        }
    },
    shop: {
        email: 'E-Mail des Shops'
    },
    error: {
        generic: {
            title: 'Fehler',
            message: 'Es ist ein unerwarteter Fehler aufgetreten, bitte versuche es später erneut'
        },
        noConnection: {
            title: 'Keine Internetverbindung',
            message: 'Bitte überprüfe deine Internetverbindung und versuche es erneut'
        },
        rateLimit: {
            title: 'Zu viele Anfragen',
            message: 'Du hast zu viele Anfragen an unseren Server gesendet. Bitte warte ein paar Minuten, bevor du deine Reise fortsetzt.'
        },
        noConnectionLogin: {
            title: 'Keine Internetverbindung',
            message: 'Wir konnten dir leider keine Login-PIN senden, da keine Internetverbindung vorhanden ist.'
        },
        loginPinRateLimit: {
            title: '',
            message: 'Du hast zu viele Login-PIN angefordert. Bitte warte bis %{nextRequest} Uhr, bevor du es erneut versuchst.'
        },
        smsDeliveryFailed: {
            title: 'SMS-Versand fehlgeschlagen',
            message: 'Wir konnten dir keine SMS mit einer Login-PIN senden, bitte überprüfe deine Telefonnummer'
        },
        couponRedeem: {
            iosCameraPermission: {
                title: 'QR-Code nicht lesbar',
                message: 'Wir konnten den QR-Code leider nicht lesen. Bitte versuche es erneut oder prüfe die Kameraberechtigungen von Coupocket in deinen iOS Einstellungen. "Einstellungen" > "Coupocket" > "Kamera"'
            },
            qrCodeInvalid: {
                title: 'QR-Code nicht lesbar',
                message: 'Konnte QR-Code nicht lesen, bitte erneut versuchen'
            }
        },
        invalidUserStateForBonusCardRedeem: {
            title: 'Stempeln fehlgeschlagen',
            message: 'Der Sammelpass oder dessen Besitzer sind nicht aktiv, weshalb kein Stempeln möglich ist'
        },
        noGeocodingResult: {
            title: 'Kein Standort gefunden',
            message: 'Für die Suche "{query}" konnte leider kein Standort gefunden werden. Bitte prüfe die eingegebene Adresse und deine Internetverbindung.',
        },
        atLeastOneStoreRequired: {
            title: 'Die Filiale konnte nicht gelöscht werden',
            message: 'Mögliche Gründe sind, dass jeder Kunde mindestens eine Filiale benötigt oder eine fehlgeschlagene Verbindung zum Server.',
        },
        genericMessage: 'Wir konnten keine Verbindung zu unserem Server herstellen, bitte überprüfe deine Internetverbindung.',
        genericApiMessage: 'Wir konnten keine Verbindung zu unserem Server herstellen, bitte überprüfe deine Internetverbindung.',
        wrongRedeemToken: 'Ungültiger QR-Code, keine Einlösung möglich',
        invalidLoginPin: 'Diese Login-PIN ist leider ungültig.',
        invalidPhoneNumber: 'Bitte trage eine gültige Telefonnummer ein',
        invalidValue: 'Bitte trage einen gültigen Wert ein',
        invalidVoucher: 'Der Gutschein-Code ist leider ungültig',
        invalidSelect: 'Bitte wähle einen Wert aus',
        invalidCategory: 'Bitte wähle eine Kategorie aus',
        invalidShop: 'Bitte wähle einen Shop aus',
        invalidUrl: 'Bitte trage den Link inkl. http:// oder https:// ein',
        invalidImage: 'Bitte wähle ein Bild aus',
        invalidDate: 'Bitte trage ein gültiges Datum ein',
        invalidDateTime: 'Bitte trage gültige Werte (Datum und Uhrzeit) ein',
        invalidEmail: 'Bitte trage eine gültige E-Mail ein',
        invalidAddress: 'Bitte trage eine gültige Adresse ein',
        invalidUserData: 'Bitte prüfe die eingegebenen Nutzerdaten.',
        invalidVatId: 'Bitte trage eine gültige UID-Nummer ein oder lasse das Feld leer, falls du keine UID-Nummer besitzt',
        invalidForm: 'Bitte prüfe die eingegebenen Daten',
        redeemLimitReached: 'Du hast den Coupon bereits eingelöst',
        fileTooLarge: 'Die gewählte Datei ist leider zu groß. Bitte verkleinere Sie vor dem Hochladen oder wähle eine andere Datei.',
        policyError: 'Bitte akzeptiere unsere AGB und Datenschutzbestimmungen',
        couponCreateError: 'Leider konnten deine Daten nicht gespeichert werden. Bitte prüfen deine Internetverbindung.',
        desktopDeviceError: 'Diese Ansicht ist mobil leider nicht verfügbar. Bitte öffne Coupocket an deinem Desktop/Notebook.',
        shopDeleteError: 'Shop kann nicht gelöscht werden, da noch aktive Coupons vorhanden sind',
        invalidEmailError: 'Konnte Mail nicht versenden. Bitte prüfe, ob du eine gültige E-Mailadresse in deinem Unternehmsprofil hinterlegt hast.',
        couponWizardError: 'Es ist leider ein Fehler aufgetreten, bitte versuche es später erneut.',
        invalidFields: 'Bitte korrigiere die fehlerhaften Felder.',
        invalidOpeningHours: 'Bitte trage gültige Öffnungszeiten ein.',
        invalidPin: 'Ungültige PIN',
        invalidPaymentDetails: 'Bitte trage gültige Zahlungsdaten ein'
    },
    success: {
        baseData: {
            message: 'Daten gespeichert'
        },
        dataUpdated: {
            title: 'Erfolg',
            message: 'Deine Daten wurden aktualisiert'
        },
        paymentDataUpdated: {
            title: 'Erfolg',
            message: 'Deine Zahlungsdaten wurden gespeichert'
        },
        logout: {
            message: 'Erfolgreich ausgeloggt'
        },
        emailSent: 'E-Mail wurde verschickt',
        openingHoursUpdated: 'Öffnungszeiten aktualisiert'
    },
    confirm: {
        login: {
            title: 'Anmeldung erforderlich',
            message: 'Für diese Aktion musst du dich anmelden. Möchtest du fortfahren oder auf der aktuellen Seite bleiben?'
        },
        sendPin: {
            title: 'Nummer prüfen',
            message: 'Die Login-PIN wird an {phone} gesendet. Ist die Nummer korrekt?'
        },
        tos: {
            title: 'Seite wirklich verlassen?',
            message: 'Du hast die AGB und Datenschutzbestimmungen noch nicht akzeptiert. Möchtest du die Seite wirklich verlassen?'
        },
        store: {
            deleteBranch: {
                title: 'Filiale wirklich löschen?',
                message: 'Dieser Vorgang kann nicht rückgängig gemacht werden!'
            }
        },
        bonusCardDelete: {
            title: 'Sammelpass löschen',
            message: 'Möchtest du den Sammelpass wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden!'
        },
        logout: {
            message: 'Möchtest du dich wirklich abmelden?'
        },
        shop: {
            title: 'Wirklich löschen?',
            message: 'Möchtest du {name}" wirklich löschen? Dies ist nur möglich, wenn der Shop keinen aktiven Coupons besitzt.'
        },
        membership: {
            message: 'Um Coupons zu veröffentlichen, benötigen wir noch deine Zahlungsdaten. Möchtest du diese nun angeben?',
            text: 'Möchtest du wirklich {title} auswählen?'
        },
        deleteAccount: {
            title: 'Account löschen',
            text: 'Sind Sie sicher, dass Sie diesen Account löschen wollen? Aktive Mitgliedschaften werden beendet und erstellte Coupons werden gelöscht.',
            button: 'Löschung Bestätigen'
        }
    },
    pages: {
        bonusCard: {
            detail: {
                description: 'Beschreibung',
                legalNotice: 'Wichtige Infos',
                alreadyReceivedBonuses: 'Bereits erhaltene Boni',
                notYetActive: 'Noch nicht aktiv',
                expired: 'Abgelaufen',
                inReview: 'In Prüfung',
                publish: 'Sammelpass veröffentlichen',
                redeemLoading: 'Einen Moment bitte, wir prüfen den QR-Code.',
                redeem: 'Stempel holen',
                redeemSuccess: {
                    title: '',
                    message: 'Der Sammelpass wurde erfolgreich gestempelt.'
                },
                collectBonus: 'Jetzt Bonus holen',
                collectBonusSuccess: {
                    title: '',
                    message: 'Erfolgreich eingelöst. Viel Spaß mit deinem Bonus!'
                },
                share: {
                    loadingMessagePrepareLink: 'Einen Moment bitte, wir generieren einen Link zum Teilen...',
                    createDeepLinkFailed: {
                        title: '',
                        message: 'Wir konnten leider keinen Link zu diesem Sammelpass generieren, eventuell wurde die Verbindung unterbrochen. Bitte versuche es erneut.'
                    },
                    subject: 'Jetzt bei sammelpass einlösen',
                },
                explanation: 'So funktioniert\'s'
            },
            list: {
                title: 'Sammelpässe für dich'
            },
            draftList: {
                title: 'Meine Entwürfe',
                empty: 'Keine Entwürfe vorhanden'
            },
            reviewList: {
                title: 'Sammelpässe im Prüfprozess',
                empty: 'Keine Sammelpässe in Prüfung'
            },
            scheduledList: {
                title: 'Meine geplanten Sammelpässe',
                empty: 'Keine geplanten Sammelpässe'
            },
            activeList: {
                title: 'Veröffentlichte Sammelpässe',
                empty: 'Keine Sammelpässe aktiv'
            },
            archiveList: {
                title: 'Archivierte Sammelpässe',
                empty: 'Keine archivierten Sammelpässe vorhanden'
            },
            wizardBaseData: {
                title: 'Sammelpass anlegen',
                text: 'Um deinen Sammelpass zu veröffentlichen, benötigen wir ein paar Basisdaten'
            },
            stastistic: {
                title: 'Bei dir eingelöste Coupons',
                button: 'Aktuelle Ansicht exportieren',
                tableColumn1: 'Coupon',
                tableColumn2: 'Von',
                tableColumn3: 'Datum',
                empty: 'Keine Treffer für die aktuellen Filtereinstellungen gefunden.',
                alert: 'Wir haben dir eine Übersicht der Einlösungen per E-Mail gesendet',
                last50: 'Letzten 50 Einlösungen',
                currentYear: 'Aktuelles Jahr ({year})',
                lastYear: 'Letztes Jahr ({year})'
            }
        },
        coupon: {
            list: {
                title: 'Coupons für dich',
                closeBy: 'In der Nähe von',
            },
            detail: {
                title: 'Coupon',
                shareCoupon: {
                    loadingMessagePrepareCouponLink: 'Einen Moment bitte, wir generieren einen Link zum Teilen...',
                    createDeepLinkFailed: {
                        title: '',
                        message: 'Wir konnten leider keinen Link zu diesem Coupon generieren, eventuell wurde die Verbindung unterbrochen. Bitte versuche es erneut.'
                    },
                    subject: 'Jetzt bei Coupocket einlösen'
                },
                latestCoupons: 'Neuste Coupons',
                editNotAllowed: 'Der Coupon wird aktuell überprüft oder ist bereits abgelaufen und kann daher nicht editiert werden.',
                redeem: 'Diesen Coupon einlösen',
                redeemOnline: 'Online einlösen',
                publish: 'Coupon veröffentlichen',
                expired: 'Abgelaufen',
                notYetActive: 'Noch nicht aktiv',
                alreadyRedeemed: 'bereits eingelöst',
                lastRedeemedOn: 'Zuletzt eingelöst am',
                info: 'Wichtige Infos',
                confirm: {
                    text: 'Dein Gutscheincode',
                    shop: 'Zum Onlineshop'
                },
                explanation: 'So funktioniert\'s'
            },
            empty: {
                title: 'Keine Coupons vorhanden',
                button: 'Jetzt Coupons finden',
                text: 'Kein Coupon gefunden'
            },
            stastistic: {
                title: 'Bei dir eingelöste Coupons',
                button: 'Aktuelle Ansicht exportieren',
                tableColumn1: 'Coupon',
                tableColumn2: 'Shop',
                tableColumn3: 'Von',
                tableColumn4: 'Datum',
                empty: 'Keine Treffer für die aktuellen Filtereinstellungen gefunden.',
                alert: 'Wir haben dir eine Übersicht der Einlösungen per E-Mail gesendet',
                last50: 'Letzten 50 Einlösungen',
                currentYear: 'Aktuelles Jahr ({year})',
                lastYear: 'Letztes Jahr ({year})'
            }
        },
        customer: {
            profile: {
                baseData: {
                    title: 'Unternehmen',
                    subtitle: 'Basisdaten',
                    addressLabel: 'Rechnungsadresse',
                    imageLabel: 'Coupon-Bild'
                },
                openingTimes: {
                    title: 'Unternehmen',
                    text: 'Öffnungszeiten',
                },
                qrCode: {
                    title: 'Unternehmen',
                    subtitle: 'QR-Code',
                    alert: 'Wir haben den QR-Code an {mail} gesendet.'
                },
                membership: {
                    title: 'Abonnement',
                    paymentMethodRequiredForPublish: 'Um Coupons zu veröffentlichen, benötigen wir noch deine Zahlungsdaten.',
                    aboNotActive: {
                        title: 'Abo noch nicht aktiv',
                        message: 'Bitte ergänze deine Zahlungsdaten, um das Abo zu aktivieren.',
                    },
                    change: 'Abo wechseln',
                    chooseMembership: 'Wähle dein passendes Angebot',
                    faqTitle: 'Die wichtigsten Fragen dazu'
                },
                payment: {
                    title: 'Zahlungsdaten',
                    button: 'Zahlungsdaten ergänzen',
                    message: 'Für Abonnements die nicht kostenlos sind, benötigen wir deine Zahlungsdaten.',
                    statusLabel: 'Aktueller Status',
                    paymentMethod: 'Zahlungsmethode',
                    cardOrAccount: 'Karte/Konto',
                    noPayment: 'Keine Zahlungsdaten hinterlegt.',
                    newPayment: 'Neue Zahlungsdaten eingeben',
                    info: 'Deine Zahlungsdaten sind für das Veröffentlichen deiner Coupons notwendig',
                    loadingMessage: 'Wir prüfen jetzt deine Zahlungsdaten, einen Moment bitte.'
                },
                stores: {
                    title: 'Filialen',
                    subtitleList: 'Verwalten',
                    subtitleCreate: 'Neue Filiale',
                    subtitleEdit: 'Filiale bearbeiten ({address})',
                    button: 'Filiale hinzufügen'
                }
            },
            registration: {
                company: {
                    title: 'Eine gute Wahl!',
                    message: 'Präsentiere dein Unternehmen bei Coupocket. Dazu sind nur noch wenige Schritte notwendig.',
                    button: 'Basisdaten',
                },
                openingTimes: {
                    title: 'Die Öffnungszeiten deines Unternehmens',
                    text: 'Deinen Kunden wird angezeigt ob und wann du geöffnet hast.',
                    subtitle: 'Öffnungszeiten',
                    label: 'Mein Unternehmen hat Öffnungszeiten'
                },
                membership: {
                    title: 'Angebot<br>auswählen',
                },
                payment: {
                    title: 'Zahlungsdaten<br>eingeben',
                    text: 'Deine Zahlungsdaten sind für das Veröffentlichen deiner Sammelpässe notwendig.',
                },
                finish: {
                    title: 'Herzlichen Glückwunsch',
                    text: 'Deine Unternehmen ist jetzt aktiv!',
                    couponIntro: 'Jetzt Coupons anlegen und profitieren!',
                    bonusCardIntro: 'Jetzt Sammelpässe anlegen und profitieren!',
                    customerStoriesIntro: 'Dein Unternehmen hat mehrere Filialen? Lege jetzt alle Filialen an, um möglichst gut in Coupocket repräsentiert zu werden.'
                }
            },
            coupon: {
                archive: {
                    title: 'Meine archivierten Coupons',
                    empty: 'Keine archivierten Coupons'
                },
                create: {
                    title: 'Für deinen Coupon benötigen wir ein paar Basisdaten',
                    titleLabel: 'Coupon anlegen',
                },
                draft: {
                    title: 'Meine Entwürfe',
                    empty: 'Keine Entwürfe vorhanden'
                },
                wizardFinish: {
                    title: 'Fertig!',
                    text1: 'Der Coupon wurde in deinen Entwürfen gespeichert.',
                    text2: 'Du kannst ihn dort überprüfen, bearbeiten oder, falls du mit allen Inhalte zufrieden bist, zur Überprüfung freigeben,',
                    button: 'Meine Entwürfe ansehen'
                },
                wizardOnline: {
                    title: 'Coupon anlegen',
                    text: 'Du hast einen Onlineshop?<br>Hinterlege die Adresse und den dazugehörigen Einlösecode, damit wir auf diesen verlinken können.',
                    redeemable: 'Mein Coupon ist online einlösbar',
                },
                published: {
                    title: 'Veröffentlichte Coupons',
                    empty: 'Keine Coupons aktiv'
                },
                review: {
                    title: 'Coupons im Prüfprozess',
                    empty: 'Keine Coupons in Prüfung'
                },
                scheduled: {
                    title: 'Meine geplanten Coupons',
                    empty: 'Keine geplanten Coupons'
                }
            },
            publicBonusCard: {
                title: 'Keine Sammelpässe vorhanden',
                button: 'Jetzt Sammelpässe finden'
            }
        },
        general: {
            notificationList: {
                title: 'Deine Nachrichten',
                noMessage: 'Derzeit sind keine Nachrichten vorhanden.'
            },
            coupocketForCompany: {
                title: 'Die beste Gutschein App<br>für dein Unternehmen',
                feature1: 'Gutscheine und Aktionen einstellen',
                feature2: 'Neue Kunden oder Gäste gewinnen',
                feature3: 'Bekanntheit steigern',
                feature4: 'Mehr Umsatz generieren',
                feature5: 'Bis 2 Tage vor Ende der Laufzeit kündbar',
                title2: 'Endecke die Welt<br>des Couponmarketing!',
                message: 'Mit Coupocket wird dein Unternehmen gesehen. Du kannst beliebig viele Rabattaktionen oder andere Gutscheine einstellen. Du hast dabei die volle Kontrolle! Dubestimmst wie oft jeder Gutschein von einem deiner Kunden eingelöst werden darf und in welchem Zeitraum die Aktion gilt. Obendrein hast du die freie Wahl die Anzahl der gesamten Einlösungen zu beschränken oder deine Aktion unlimitiert zur Verfügung zu stellen.',
                title3: 'So funktioniert Coupocket',
                list1: {
                    title: 'Unbegrenzt werben',
                    message: 'Erstelle jederzeit Aktionen und Gutscheine direkt mit deinem Handy.'
                },
                list2: {
                    title: 'Überall und jederzeit verfügbar',
                    message: 'Jeder Coupocket Nutzer sieht deine Aktionen und wird dadurch motiviert, deinen Betrieb zu besuchen. Dadurch wirst du weiter an Bekanntheit gewinnen und zusätzliche Umsätze generieren.'
                },
                list3: {
                    title: 'Deine persönliche digitale Statistik',
                    message: 'Behalte jederzeit den Überblick über deine aktiven und vergangenen Aktionen. Sieh dir an, wie oft ein Gutschein eingelöst wurde. So kannst du jederzeit die Performance deiner Aktivitäten messen.'
                }
            },
            help: {
                title: 'Häufige Fragen',

            },
            introduction: {
                slide1: {
                    title: 'Willkommen bei Coupocket!',
                    message: 'Hier findest du die besten Angebote aus deiner Region',
                    button: 'Weiter'
                },
                slide2: {
                    title: 'Angebote in deiner Nähe!',
                    message: 'Wir würden dir gerne Angebote in deiner Nähe anzeigen.Bitte erlaube uns hierfür den Zugriff deinen Standort, damit du immer für dich relevante Angebote erhältst.',
                    button: 'Standort freigeben',
                    skip: 'Standortfreigabe überspringen'
                },
                slide3: {
                    title: 'Coupocket ist bereit!',
                    message: 'Wir wünschen dir viel Spaß mit Coupocket.',
                    button: 'Jetzt starten'
                },
                authAlert: {
                    title: 'Berechtigung erteilen',
                    message: 'Bitte bestätige im folgenden Dialog, dass wir deinen Standort benutzen dürfen.'
                },
                locationAlert: {
                    title: 'Standort unbekannt',
                    message: 'Wir konnten deinen Standort leider nicht bestimmen. Du kannst Coupocket trotzdem nutzen, die vorgeschlagenen Angebote sind aber eventuell weniger relevant für dich.'
                }
            },
            share: {
                title: 'Freunde einladen',
                message: 'Teile Coupocket mit deinen Freunden',
                button: 'mit Freunden teilen'
            }
        },
        invoice: {
            label: 'Rechnung',
            date: 'Datum',
            amount: 'Betrag'
        },
        user: {
            profile: {
                baseData: {
                    title: 'Mein<br>Profil bearbeiten',
                },
                favorites: {
                    title: 'Meine Favoriten',
                    empty: 'Keine Favoriten vorhanden',
                    button: 'Jetzt Coupons finden'
                }
            },
            affiliate: {
                title: 'Mein Kontostand',
                recruitedMember: '{count} Unternehmen',
                recruitedMemberDescription: 'haben sich über deinen Link angemeldet',
                payingRecruitedMember: '{count1} von {count2}',
                payingRecruitedMemberDescription: 'sind bereits zahlende Kunden bei Coupocket',
                readyForPayoutRecruitedMember: '{count1} von {count2}',
                readyForPayoutRecruitedMemberDescription: 'haben sich für deinen Empfehlungsbonuns qualifiziert',
                accountBalance: '€ {accountBalance} Guthaben',
                accountBalanceDescription: 'sind derzeit auf deinem Coupocket Konto',
                payoutCredit: 'Guthaben auszahlen',
                inviteCompany: 'Unternehmen einladen',
                inviteMoreCompany: 'Weitere Unternehmen einladen',
                emptyReferral: 'Du hast noch keine erfolgreiche Empfehlung.',
                bonusEarned: 'Verdienter Bonus',
                invited: 'Eingeladen',
                registered: 'Registriert',
                eligible: 'Teilnahmeberechtigt',
            },
            pin: {
                title: 'Du hast eine PIN erhalten',
                resend: 'Pin erneut zusenden',
                loadingMessage: 'Einen Moment bitte, die PIN wird überprüft.'
            }
        }

    },
    notification: {
        askPermissionFirstTime: {
            title: 'Benachrichtigungen',
            message: 'Bitte akzeptiere die folgende Berechtigungsanfrage, damit wir dich zeitnah über neue Coupons und Angebote informieren können.'
        },
        askPermissionNextTime: {
            title: 'Benachrichtigungen',
            message: 'Leider können wir dich derzeit nicht über neue Coupons und Angebote informieren. Bitte prüfe die Berechtigungen von Coupocket in deinen iOS Einstellungen:<br>"Einstellungen" > "Mitteilungen",<br> wähle "Coupocket" aus, und tippe auf "Mitteilungen erlauben".'
        },
        confirmDeleteAlert: {
            title: 'Nachricht wirklich löschen?',
            message: 'Der Löschvorgang kann nicht rückgängig gemacht werden.'
        },
        askUsageType: {
            message: 'Wir möchten dich gerne über Angebote und Aktionen informieren. Bitte beschreibe kurz, wie du Coupocket nutzen möchtest.',
            usagePrivate: 'Ausschließlich Privat',
            usageEntrepreneur: 'Privat und als Unternehmer'
        }
    },
    openingTimes: {
        isCurrentlyOpen: 'Jetzt geöffnet',
        isCurrentlyClosed: 'Jetzt geschlossen',
        closed: 'geschlossen',
        usePreviousDayValue: 'Werte vom Vortag übernehmen'
    },
    store: {
        create: 'Filiale anlegen'
    },
    outdated: {
        title: 'Bitte aktualisiere Coupocket, um alle neuen Funktionen verwenden zu können.',
        android: 'Jetzt im Play Store aktualisierien',
        ios: 'Jetzt im App Store aktualisieren',
        button: 'Jetzt aktualisieren',
    },
    altText: {
        selectPrefix: 'Selected phhone number prefix country flag'
    },
    publishAlert: {
        hasNoMembership: {
            title: 'Abonnement notwendig',
            message: 'Um einen {type} zu veröffentlichen musst du ein Abonnement auswählen. Möchtest du dies jetzt tun?',
        },
        hasNoPaymentWithNonFreeMembership: {
            title: 'Zahlungsdaten notwendig',
            message: 'Um einen {type} zu veröffentlichen musst du deine Zahlungsdaten eingeben. Möchtest du dies jetzt tun?',
        }
    },
    reverseRedeemHintAlert: '<p><strong>Kunde scant QR-code</strong></p><p>Der Kunde muss den QR-Code Ihres Unternehmens scannen, um diesen Gutschein/Sammelpass einzulösen</p><p><strong>Unternehmen scant QR/Barcode</strong></p><p>Der Kunde zeigt denn hinterlegten QR/Barcode zum scannen vor.</p>'
};
