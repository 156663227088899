export default {
    sanitize(prefix, number) {
        let sanitizedNumber = number;

        // Remove leading country prefix
        var countryPrefixRegExp = new RegExp('^\\' + prefix);
        sanitizedNumber = sanitizedNumber.replace(countryPrefixRegExp, '');

        var prefixWithLeadingDoubleZero = '00' + prefix.replace('+', '');
        var prefixWithLeadingDoubleZeroRegExp = new RegExp('^' + prefixWithLeadingDoubleZero);
        sanitizedNumber = sanitizedNumber.replace(prefixWithLeadingDoubleZeroRegExp, '');

        // Remove normal leading zeros
        sanitizedNumber = sanitizedNumber.replace(/^0+/, '');

        // Remove every non-numeric character
        sanitizedNumber = sanitizedNumber.replace(/\D/g, '');
        return prefix + sanitizedNumber;
    },
    /**
     * The sanitize method assumes that the number matches the validate method!
     * @param number
     */
    sanitizeWithoutPrefix(number) {
        // Remove every non-numeric character
        number = number.replace(/\D/g, '');
        return `+${number}`;
    },
    validate(phone) {
        return phone.indexOf('+') === 0 && 6 < phone.length;
    }
};
