import {HTTP} from '@/domain/api/http';
import couponApiConverter from '@/domain/api/converter/coupon';

let utility = {
    updateCouponIncluded(context, included) {
        if (included.category) {
            context.commit('updateCategory', included.category);
        }
        if (included.owner) {
            context.commit('customer/updateCustomer', included.owner, {root: true});
        }
        if (included.store) {
            context.commit('userStore/updateStore', included.store, {root: true});
        }
        if (included.redeemState) {
            context.commit('user/updateRedeemState', included.redeemState, {root: true});
        }
    },
};

export const loadCoupon = (context, couponId) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/coupon/' + couponId)
            .then(response => {
                let coupon = couponApiConverter.fromApi(response.data.data);
                let included = couponApiConverter.extractIncludedFromCollection(response.data.included);
                utility.updateCouponIncluded(context, included);
                context.commit('update', coupon);
                resolve(coupon);
            })
            .catch(error => reject(error));
    });
};

export const loadCouponById = async (context, id) => {
    if (!Array.isArray(id)) {
        id = [id];
    }
    let response = await HTTP.get(API_BASE_URL + '/coupon?id=' + encodeURIComponent(id.join(',')));
    let coupons = couponApiConverter.fromApiCollection(response.data.data);
    let included = couponApiConverter.extractIncludedFromCollection(response.data.included);
    utility.updateCouponIncluded(context, included);
    context.commit('updateList', coupons);
    return coupons;
};

export const loadCouponByCustomer = (context, customer) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/customer/' + customer + '/coupon')
            .then(response => {
                let coupons = couponApiConverter.fromApiCollection(response.data.data);
                let included = couponApiConverter.extractIncludedFromCollection(response.data.included);
                utility.updateCouponIncluded(context, included);

                context.commit('updateList', coupons);
                resolve(coupons);
            })
            .catch(error => reject(error));
    });
};

export const loadCurrentUserCouponByState = (context, state) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/user/' + context.rootState.user.user.id + '/coupon/' + state)
            .then(response => {
                let coupons = couponApiConverter.fromApiCollection(response.data.data);
                let included = couponApiConverter.extractIncludedFromCollection(response.data.included);
                utility.updateCouponIncluded(context, included);

                context.commit('updateList', coupons);
                resolve(coupons);
            })
            .catch(error => reject(error));
    });
};

export const loadCurrentUserCouponWithDraftState = (context) => {
    return loadCurrentUserCouponByState(context, COUPON_STATE.DRAFT);
};

export const loadCurrentUserCouponWithReviewState = (context) => {
    return loadCurrentUserCouponByState(context, COUPON_STATE.REVIEW);
};

export const loadCurrentUserCouponWithPublishedState = (context) => {
    return loadCurrentUserCouponByState(context, COUPON_STATE.PUBLISHED);
};

export const loadCurrentUserFavorite = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/user/' + context.rootState.user.user.id + '/favorite')
            .then(response => {
                let favoriteCoupons = response.data.data;

                // Update coupon data
                let coupons = couponApiConverter.fromApiCollection(response.data.included.coupon.data);
                context.commit('updateList', coupons);

                // Update other included data
                let included = couponApiConverter.extractIncludedFromCollection(response.data.included);
                utility.updateCouponIncluded(context, included);

                // Update favorite list
                context.commit('user/updateVisibleFavoriteCoupon', favoriteCoupons, {root: true});

                resolve();
            })
            .catch(error => reject(error));
    });
};

export const loadCategoryAll = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/coupon-category')
            .then(response => {
                context.commit('updateCategory', response.data.data);
                resolve();
            })
            .catch(error => reject(error));
    });
};

export const reserveId = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + '/coupon/reserve-id')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error));
    });
};

export const redeem = (context, data) => {
    return new Promise((resolve, reject) => {
        if (data.token.indexOf('/') > -1) {
            // Invalid token (probably an external link), which causes our API to respond with "No route found"
            // encodeURIComponent sadly doesn't fix the issue: https://github.com/symfony/symfony/issues/21307, so we ignore every token with a / inside
            reject({messageCode: 'error.wrongRedeemToken'});
        }
        HTTP.post(API_BASE_URL + '/coupon/' + encodeURIComponent(data.coupon) + '/redeem/' + encodeURIComponent(data.token))
            .then(response => {
                loadCoupon(context, data.coupon);
                resolve(response.data.data);
            })
            .catch(error => reject(error));
    });
};

export const loadRedeemState = (context, coupon) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/coupon/' + coupon + '/redeem/state')
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => reject(error));
    });
};

export const updateImage = (context, data) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + '/coupon/' + data.id + '/image', data.formData)
            .then(response => {
                loadCoupon(context, data.id);
                resolve(response.data.data);
            })
            .catch(error => reject(error));
    });
};

export const wizard = (context, data) => {
    return new Promise((resolve, reject) => {
        data.fields = couponApiConverter.toApi(data.fields);
        HTTP.patch(API_BASE_URL + '/coupon/' + data.id + '/wizard/' + data.step, data.fields)
            .then(() => {
                loadCoupon(context, data.id);
                resolve()
            })
            .catch(error => reject(error));
    });
};

export const updateFields = (context, data) => {
    return new Promise((resolve, reject) => {
        data.fields = couponApiConverter.toApi(data.fields);

        HTTP.patch(API_BASE_URL + '/coupon/' + data.id, data.fields)
            .then(response => {
                loadCoupon(context, data.id);
                resolve(response.data.data)
            })
            .catch(error => reject(error));
    });
};

export const enableReview = (context, data) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + '/coupon/' + data.id + '/enable-review')
            .then(response => {
                loadCoupon(context, data.id);
                resolve(response.data.data)
            })
            .catch(error => reject(error));
    });
};

export const removeOneCoupon = async (context, coupon) => {
    const response = await HTTP.delete(API_BASE_URL + `/coupon/${coupon.id}`);
    context.commit('removeOneCoupon', coupon);
    return response.data.data;
};

export const loadCouponTemplates = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/coupon/template`)
            .then(response => {
                context.commit('updateCouponTemplate', response.data.data);
                resolve();
            })
            .catch(reject);
    });
};

export const loadCouponLimit = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/coupon/limit')
            .then(response => {
                let limit = response.data.data;
                context.commit('updateCouponLimit', limit);
                resolve(limit);
            })
            .catch(error => reject(error));
    });
};
