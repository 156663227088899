<template>
    <div class="c3-form-field-group c3-form-field-group-select" :class="cssClasses">
        <label :for="name" class="c3-form-label">{{label}}</label>
        <div class="c3-form-field-wrapper c3-form-field-select-wrapper">
            <select :id="name" :name="name" v-model="localValue" class="c3-form-field-select" :class="{'c3-form-field-select-invert':invert}"
                    :autocomplete="autocomplete">
                <option :value="null" v-if="placeholder">{{placeholder}}</option>
                <template v-for="option in options">
                    <optgroup :label="option.label" v-if="option.type === 'optiongroups'">
                        <option v-for="innerOption in option.options" :value="innerOption.value"
                                :selected="isSelected(value, innerOption.value)">
                            {{$t(innerOption.label)}}
                        </option>
                    </optgroup>
                    <option v-else :value="option.value" :selected="isSelected(value, option.value)">{{$t(option.label)}}</option>
                </template>
            </select>
        </div>
        <div v-if="description" class="c3-form-field-group__description">{{description}}</div>

        <div class="c3-form-field-group-error-message" v-if="hasError">{{$t(errorMessage)}}</div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: [String, Number]
            },
            label: {
                type: String
            },
            name: {
                type: String,
            },
            description: {
                type: String
            },
            autocomplete: {
                type: String,
                default: 'off'
            },
            placeholder: {
                type: String
            },
            options: {
                type: Array,
                default: () => []
            },
            disabled: {
                type: Boolean,
                default: false
            },
            hasError: {
                type: Boolean
            },
            errorMessage: {
                type: String,
                default: 'error.invalidSelect'
            },
            invert: {
                type: Boolean
            }
        },
        data() {
            return {};
        },
        methods: {
            isSelected(currentValue, optionValue) {
                return currentValue === optionValue;
            },
        },
        computed: {
            localValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            cssClasses() {
                return {
                    'c3-form-field-group--disabled': this.disabled,
                    'c3-form-field-group--has-error': this.hasError,
                    'c3-form-field-group-input--not-empty': !this.isEmpty,
                    'c3-form-field-group-input--focus': this.hasFocus,
                    'c3-form-field-group-invert c3-form-field-group-select-invert': this.invert,
                };
            }
        },
    };
</script>

<style lang="scss" scoped>

</style>
