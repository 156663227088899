<template>
    <div class="c3-icon-wrapper">
        <svg version="1.1" viewBox="0 0 477.58 320" xmlns="http://www.w3.org/2000/svg">
            <path :fill="color1"
                  d="m449.52 240h-19.459c-4.754 0-8.607-4.119-8.607-9.2v-2.956c0-8.553 2.6-16.694 6.274-24.291a31.427 31.427 0 0 0 2.646-18.881c-1.979-12.119-11.247-22.094-22.587-24.203a27.714 27.714 0 0 0-5.043-0.469c-15.5 0-28.066 13.431-28.066 30a31.085 31.085 0 0 0 2.538 12.366c3.9 9.019 6.818 18.481 6.818 28.416v0.018c0 5.081-3.853 9.2-8.607 9.2h-19.459c-15.501 0-28.066 13.431-28.066 30v10c0 5.522 4.189 10 9.355 10v20c0 5.522 4.189 10 9.355 10h112.26c5.166 0 9.355-4.478 9.355-10v-20c5.166 0 9.355-4.478 9.355-10v-10c1e-3 -16.569-12.565-30-28.065-30zm9.355 70h-112.26v-20h112.26zm9.355-30h-130.97v-10c0-11.028 8.393-20 18.71-20h19.46c9.9 0 17.962-8.612 17.962-19.219 0-10.131-2.441-20.494-7.68-32.609a20.487 20.487 0 0 1-1.676-8.172c0-11.028 8.393-20 18.71-20a18.946 18.946 0 0 1 3.438 0.322c7.379 1.372 13.679 8.131 14.974 16.069a20.948 20.948 0 0 1-1.739 12.566c-4.923 10.177-7.314 19.627-7.314 28.887v2.956c0 10.588 8.057 19.2 17.962 19.2h19.459c10.317 0 18.71 8.972 18.71 20z"/>
            <path :fill="color2"
                  d="m424 0h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm164 92h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
