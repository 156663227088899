<template>
    <router-link :to="{name:'BonusCardDetail',params:{id:item.id}}" class="c3-bonus-card-list-item c3-bonus-card-list-item--is-bonus-card">
        <div class="c3-bonus-card-list-item-content-wrapper">
            <c3-item-media :item="bonusCard" :isFavorite="isFavorite" @toggleFavorite="toggleFavorite"/>
            <div class="c3-bonus-card-list-item-content">
                <div class="c3-bonus-card-list-item-content-company">{{ bonusCard.shop ? bonusCard.shop.name : bonusCard.user.company }}</div>
                <div class="c3-bonus-card-list-item-content-address">{{ address.zip }} {{ address.city }}</div>
                <div class="c3-bonus-card-list-item-content-title">
                    {{ bonusCard.titleWithTemplate }}
                </div>
            </div>
            <div class="c3-bonus-card__list-item-phone" @click="openPhone">
                <c3-icon-phone primaryColor="white"/>
            </div>
            <div class="c3-bonus-card__list-item-bottom-row">
                <div class="c3-bonus-card__list-item-distance" v-if="distance">{{ distance }}</div>
                <div class="c3-bonus-card__list-item-progress">
                    <div v-for="r in currentCycleRedeems" :key="r.id" class="c3-bonus-card__list-item-progress-item c3-bonus-card__list-item-progress-item--redeemed"></div>
                    <div v-if="null !== remainingRedeemCount" v-for="i in remainingRedeemCount" :key="`placeholder-${i}`" class="c3-bonus-card__list-item-progress-item"></div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
    import {BonusCardFavoriteMixin} from '@/domain/mixin/local/bonusCard/favorite';
    import {BonusCardRedeemMixin} from '@/domain/mixin/local/bonusCard/redeem';

    import C3ItemMedia from '@/components/content/bonusCard/ItemMedia.vue';

    export default {
        mixins: [BonusCardFavoriteMixin, BonusCardRedeemMixin],
        components: {
            C3ItemMedia,
        },
        props: {
            item: Object,
            enableEdit: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },
        computed: {
            bonusCard() {
                // Do not mutate the original coupon
                let bonusCard = Object.assign({}, this.item);
                bonusCard.user = this.$store.getters['customer/singleCustomer'](this.item.user, {});
                return bonusCard;
            },
            address() {
                const closestStore = this.$store.getters['userStore/storeById'](this.item.closestStoreId);
                if (closestStore !== null) {
                    return closestStore.address;
                }
                return this.bonusCard.user;
            },
            distance() {
                let distance = null;
                if (this.item.distance) {
                    distance = this.item.distance / 1000;
                    if (distance >= 100) {
                        distance = distance.toFixed(0) + ' km';
                    } else if (distance >= 10) {
                        distance = distance.toFixed(1) + ' km';
                    } else {
                        distance = distance.toFixed(2) + ' km';
                    }
                }
                return distance;
            },
            phone() {
                return 'tel:' + this.bonusCard.user.companyPhone;
            },
        },
        methods: {
            openPhone(e) {
                e.preventDefault();
                window.location = this.phone;
            }
        }
    };
</script>
