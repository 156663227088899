<template>
    <c3-grid-tile-item :label="item.name" :icon="determineIcon" :iconPrimaryColor="iconPrimaryColor" @click="$emit('click')"/>
</template>

<script>
    import C3GridTileItem from '@/components/layout/grid/tile/Item';

    export default {
        components: {
            C3GridTileItem,
        },
        props: {
            item: {
                type: Object
            },
            isActive: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },
        computed: {
            iconPrimaryColor() {
                return this.isActive ? COLORS.QUINARY : COLORS.WHITE;
            },
            determineIcon() {
                return 'c3-icon-' + this.item.icon;
            }
        },
        methods: {},
    };
</script>
<style lang="scss">
    .c3-category-filter-title {
        color: $white-color;
        @include montserratMedium();
    }
</style>