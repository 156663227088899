<template>
    <c3-drawer class="c3-filter-drawer" :name="name" :fullWidth="true" @close="reset">
        <div slot="headerLeft"></div>

        <div class="c3-filter-drawer__header">
            <h3 class="c3-filter-drawer__title">{{$t('coupon.filter.selectCategory')}}</h3>
        </div>

        <c3-grid-tile>
            <template v-for="item in items">
                <c3-filter-drawer-grid-tile-item :item="item" :isActive="isActiveCategory(item.id)" @click="toggleCategoryState(item.id)"/>
            </template>
        </c3-grid-tile>
        <div slot="footer" class="c3-filter-drawer__action-list">
            <c3-button :label="$t('label.reset')" styling="2" class="c3-filter-drawer__action-reset" :class="resetClasses" @click="reset"/>
            <c3-button :label="$t('label.apply')" class="c3-filter-drawer__action-apply" :class="applyClasses" @click="apply"/>
        </div>
    </c3-drawer>
</template>

<script>
    import {C3Object} from '@/domain/utility/object';

    import C3Drawer from '@/components/layout/navigation/Drawer.vue';
    import C3GridTile from '@/components/layout/grid/tile/Index';
    import C3FilterDrawerGridTileItem from '@/components/content/coupon/filterDrawer/GridTileItem.vue';
    import C3Range from '@/components/form/fields/Range';
    import C3Button from '@/components/form/Button';

    const FILTER_INITIAL = {
        activeCategories: {},
    };

    export default {
        components: {
            C3Drawer,
            C3GridTile,
            C3FilterDrawerGridTileItem,
            C3Range,
            C3Button
        },
        props: {},
        data() {
            return {
                name: DRAWER.COUPON_FILTER_DRAWER,
                filter: {
                    activeCategories: {},
                }
            }
        },
        computed: {
            storeFilter() {
                return this.$store.getters['viewCouponList/filter'](this.$route.name);
            },
            activeResetButton() {
                return this.isCategoryFilterActive;
            },
            activeApplyButton() {
                return !C3Object.equalKeys(this.filter.activeCategories, this.storeFilter.activeCategories);
            },
            resetClasses() {
                if (this.activeResetButton) {
                    return 'c3-filter-drawer__action-reset--active';
                }
            },
            applyClasses() {
                if (this.activeApplyButton) {
                    return 'c3-filter-drawer__action-apply--active';
                }
            },
            isCategoryFilterActive() {
                return Object.keys(this.filter.activeCategories).length > 0;
            },
            items() {
                let categories = this.$store.getters['coupon/categories'];
                if (Array.isArray(categories)) {
                    return categories;
                }
                return [];
            }
        },
        methods: {
            async apply() {
                let activeCategories;
                if (this.isCategoryFilterActive) {
                    activeCategories = C3Object.deepClone(this.filter.activeCategories);
                } else {
                    activeCategories = {};
                }

                this.$store.commit(
                    'viewCouponList/updateFilter', {
                        name: this.$route.name,
                        filter: {
                            activeCategories: activeCategories,
                        }
                    }
                );
                this.$store.commit('view/removeLatestDrawer');

                this.$store.commit('view/showLoading');
                await this.$store.dispatch('viewCouponList/loadItems', {name: this.$route.name});
                this.$store.commit('view/hideLoading');
            },
            reset() {
                this.filter = C3Object.deepClone(FILTER_INITIAL);

                this.$store.commit(
                    'viewCouponList/updateFilter', {
                        name: this.$route.name,
                        filter: C3Object.deepClone(FILTER_INITIAL)
                    }
                );

                this.$store.dispatch('viewCouponList/loadItems', {name: this.$route.name});
            },
            isActiveCategory(id) {
                return this.filter.activeCategories.hasOwnProperty(id);
            },
            toggleCategoryState(id) {
                if (this.isActiveCategory(id)) {
                    this.$delete(this.filter.activeCategories, id);
                } else {
                    this.$set(this.filter.activeCategories, id, true);
                }
            },
            loadInitialState() {
                try {
                    this.filter.activeCategories = C3Object.deepClone(this.storeFilter.activeCategories);
                } catch (e) {
                    this.reset();
                }
            }
        },
        created() {
            this.loadInitialState();
        },
        activated() {
            this.loadInitialState();
        }
    };
</script>

<style lang="scss" scoped>
    .c3-filter-drawer {
        & /deep/ {
            .c3-filter-drawer__action-list {
                display: flex;

                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                transition: opacity 0.8s;

                cursor: not-allowed;

                .c3-button {
                    flex: 1;
                    border-radius: 0;
                    height: calc(#{$form-field-height} + env(safe-area-inset-bottom));
                    padding-bottom: calc(env(safe-area-inset-bottom) / 2);
                }

                span {
                    @include montserratMedium();
                }
            }

            .c3-filter-drawer__action-reset,
            .c3-filter-drawer__action-apply {
                pointer-events: none;

                .c3-button-content {
                    transition: opacity 0.4s;
                }
            }

            .c3-filter-drawer__action-reset .c3-button-content {
                opacity: 0.2;
            }

            .c3-filter-drawer__action-apply .c3-button-content {
                opacity: 0.3;
            }

            .c3-filter-drawer__action-reset--active,
            .c3-filter-drawer__action-apply--active {
                cursor: pointer;
                pointer-events: auto;

                .c3-button-content {
                    opacity: 1;
                }
            }
        }
    }

    .c3-filter-drawer__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;

        @media(max-width: $bp-xxs) {
            flex-wrap: wrap;
            justify-content: center;
        }


        & /deep/ {
            .c3-form-field-group-switch {
                margin-top: 0;
            }

            .c3-form-label {
                padding-right: 10px;
                color: $white-color;
                margin-top: 3px;
                font-size: 13px;
            }
        }
    }

    .c3-filter-drawer__title {
        @include montserratBold();
        font-size: 15px;
        color: $white-color;

        @media(max-width: $bp-xxs) {
            width: 100%;
            text-align: center;
        }
    }

    .c3-filter-drawer {
        & /deep/ .c3-navigation-drawer-content {
            padding-bottom: 44px;
        }
    }
</style>
