export default {
  install: function (Vue, options) {
    let guid = 1;

    Vue.mixin({
      beforeCreate() {
        this.guid = 'c3-' + guid;
        guid++;
      }
    });
  },
};