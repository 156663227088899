<template>
    <div class="c3-qr-code-reader">
        <portal to="app_end" v-if="showBrowserQrEncoder">
            <div class="c3-qr-code-reader-browser" :class="{'c3-qr-code-reader-browser--active':showBrowserQrEncoder}">
                <div class="c3-qr-code-reader-browser-close-icon" @click="cancelScan"></div>
                <qrcode-reader @decode="qrCodeDecode"/>
                <div class="c3-qr-code-reader-browser-description">{{$t('coupon.redeem.cameraTooltip')}}</div>
            </div>
        </portal>
    </div>
</template>

<script>
    export default {
        components: {
            'qrcode-reader': () => import(/* webpackChunkName: "qrcode" */ 'vue-qrcode-reader').then(m => m.QrcodeReader)
        },
        props: {
            qrCodeScanActive: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                showBrowserQrEncoder: false
            }
        },
        computed: {},
        methods: {
            qrCodeDecodeNative() {
                window.cordova.plugins.barcodeScanner.scan(
                    (result) => {
                        if (result.cancelled) {
                            this.$emit('close');
                            return;
                        }

                        if (result.text) {
                            console.debug('Encoded QR-Code: ', result);
                            this.$emit('encoded', result.text);
                        } else {
                            this.$c3.errorAlert('error.couponRedeem.qrCodeInvalid.title', 'error.couponRedeem.qrCodeInvalid.message');
                        }
                        this.$emit('close');
                    },
                    (error) => {
                        if (this.isIOS) {
                            this.$c3.errorAlert('error.couponRedeem.iosCameraPermission.title', 'error.couponRedeem.iosCameraPermission.message');
                        } else {
                            this.$c3.errorAlert('error.couponRedeem.qrCodeInvalid.title', 'error.couponRedeem.qrCodeInvalid.message');
                        }
                        console.error(error);
                        this.cancelScan()
                    },
                    {
                        preferFrontCamera: false,
                        showFlipCameraButton: true,
                        showTorchButton: true,
                        prompt: this.$t('coupon.redeem.cameraTooltip'),
                    }
                );
            },
            qrCodeDecode(content) {
                this.$emit('encoded', content);
                this.$emit('close');
            },
            scanCouponQrCode() {
                if (this.isApp) {
                    this.qrCodeDecodeNative();
                } else {
                    this.showBrowserQrEncoder = true;
                }
            },
            cancelScan() {
                this.showBrowserQrEncoder = false;
                if (window.hasOwnProperty('QRScanner')) {
                    QRScanner.cancelScan((status) => {
                        console.debug('Cancel scan', status);
                    });
                }
                this.$emit('cancel');
            }
        },
        watch: {
            qrCodeScanActive: function (value) {
                if (value) {
                    this.scanCouponQrCode();
                } else {
                    this.cancelScan();
                }
            }
        },
        mounted() {
            if (this.qrCodeScanActive) {
                this.scanCouponQrCode();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .c3-qr-code-reader-browser--active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        // Avoid collision between qr-code-reader and close icon
        padding-top: calc(var(--page-content-horizontal-spacing) + 30px + 20px);
        padding-bottom: calc(var(--page-content-horizontal-spacing) + 30px + 20px);

        background-color: rgba(0, 0, 0, 0.85);
        z-index: 5000;
    }

    .c3-qr-code-reader-browser-close-icon {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: var(--page-content-horizontal-spacing);
        padding-right: var(--page-content-horizontal-spacing);

        width: calc(30px + var(--page-content-horizontal-spacing));
        height: calc(30px + var(--page-content-horizontal-spacing));

        z-index: 5020;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            height: 4px;
            width: 30px;
            margin-top: -2px;
            background-color: #fff;
            transform-origin: center center;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    .qrcode-reader {
        overflow: hidden;
    }

    .c3-qr-code-reader-browser-description {
        z-index: 5020;
        color: #fff;
        margin-top: 15px;
        @include openSansSemiBold();
    }
</style>