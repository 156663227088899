<template>
    <div slot="header" class="c3-coupon-list-header">
        <div v-if="title">
            <div v-html="title" class="c3-coupon-list-header__title"></div>
            <div v-if="filter" class="c3-coupon-list-header__filter">
                <div class="c3-coupon-list-header__subtitle">
                    <span v-if="truncatedLocation">
                       {{$t('pages.coupon.list.closeBy')}} <strong>{{truncatedLocation}}</strong>
                    </span>
                    <span v-else @click="showAddressUnknownAlert">
                        {{$t('pages.coupon.list.closeBy')}} <c3-icon-question-circle primaryColor="quinary"/>
                    </span>
                </div>
                <button class="c3-coupon-list__location-button" :class="{'c3-coupon-list__location-button--disabled': !truncatedLocation}" @click="showLocation">
                    <c3-icon-location v-if="truncatedLocation" primaryColor="white"/>
                    <c3-icon-location-slash v-else primaryColor="quinary"/>
                </button>
            </div>
            <h3 v-else v-html="subtitle" class="c3-coupon-list-header__subtitle"></h3>
        </div>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button.vue';
    import C3IconLocation from '@/components/icon/Location';
    import C3IconLocationSlash from '@/components/icon/LocationSlash';

    export default {
        components: {
            C3Button,
            C3IconLocation,
            C3IconLocationSlash
        },
        props: {
            name: {
                type: String,
                required: true
            },
            title: {
                type: String
            },
            subtitle: {
                type: String
            },
            filter: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            activeFilterCount() {
                let filter = this.$store.getters['viewBonusCardList/filter'](this.$route.name);
                let count = Object.keys(filter.activeCategories).length ;
                return count < 10 ? count : '9+';
            },
            truncatedLocation() {
                let location = this.$store.getters['viewBonusCardList/locationName'](this.$route.name);
                if (location) {
                    if (location.length > 12) {
                        location = location.substring(0, 9).trim() + '...';
                    }
                    return location;
                }
                return null;
            },
        },
        methods: {
            showLocation() {
                this.$store.commit('view/showBottomDrawer', window.DRAWER.BONUS_CARD_LOCATION_DRAWER);
            },
            showAddressUnknownAlert() {
                this.$store.commit('view/showLoading');
                let id;
                if (this.isAndroid) {
                    id = 12;
                } else if (this.isIOS) {
                    id = 13;
                } else {
                    id = 14;
                }

                this.$c3.staticContentAlertById(
                    id,
                    async (result) => {
                        if (result.value) {
                            try {
                                this.$store.commit('view/showLoading', this.$t('label.updatePositionLoading'));

                                await Promise.all([
                                    this.$store.dispatch('viewBonusCardList/position', {name: this.$route.name, addressTimeout: 5000}),
                                    new Promise(resolve => setTimeout(resolve, 1500))
                                ]);
                            } catch (e) {
                                this.$c3.staticContentAlertById(15);
                            }
                        }
                        this.$store.commit('view/hideLoading');
                    },
                    {
                        showCloseButton: false,
                        showCancelButton: true,
                        confirmButtonText: this.$t('label.determineCurrentLocation'),
                        cancelButtonText: this.$t('label.close')
                    }
                );
            },
        }
    };
</script>

<style lang="scss" scoped>
    .c3-coupon-list-header {
        padding-bottom: 15px;
        @include horizontalSpacing();
    }

    .c3-coupon-list-header__title {
        @include openSansBold();
        font-size: 20px;
    }

    .c3-coupon-list-header__subtitle {
        margin-top: 5px;
        font-size: 14px;
        @include openSansRegular();

        .c3-icon-wrapper {
            height: 18px;
            position: relative;
            top: 1px;
            margin-left: 4px;
        }
    }

    .c3-coupon-list-header__filter {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 14px;
        @include openSansRegular();
    }

    .c3-coupon-list__location-button,
    .c3-coupon-list__filter-button {
        @include baseButton();
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;

        padding: 0 10px;
        background-color: $quinary-color;

        @include openSansSemiBold();
        font-size: 14px;
        color: $white-color;
    }

    .c3-coupon-list__location-button {
        width: 40px;
        min-width: 40px;
        margin-left: auto;
        margin-right: 6px;

        .c3-icon-wrapper {
            height: 18px;
        }
    }

    .c3-coupon-list__location-button--disabled {
        background-color: $white-color;
    }

    .c3-coupon-list__filter-button {
        width: 78px;
        min-width: 78px;

        position: relative;

        .c3-icon-wrapper {
            height: 18px;
        }
    }

    .c3-filter-button__label {
        line-height: 32px;
    }

    $bubble-size: 20px;
    .c3-filter-button__active-items {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $bubble-size;
        width: $bubble-size;

        position: absolute;
        top: -1 * $bubble-size / 2;
        right: -1 * $bubble-size / 2;

        border-radius: 50%;
        background: $white-color;

        color: $primary-color;
        font-size: 12px;

        box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
    }
</style>

