<template>
    <button :type="type" class="c3-button" :class="buttonClasses" @click="handleClick">
        <div class="c3-button-content-wrapper">
            <div class="c3-button-content" ref="buttonContent">
                <div class="c3-button-icon" v-if="icon">
                    <component :is="icon" :primaryColor="iconPrimaryColor"/>
                </div>
                <span>{{label}}</span>
            </div>
        </div>
    </button>
</template>

<script>

    export default {
        props: {
            type: {
                type: String,
                default: 'button'
            },
            label: {
                type: String
            },
            icon: {
                type: String
            },
            styling: {
                type: String,
                default: '1'
            },
        },
        computed: {
            buttonClasses() {
                let classes = [];
                classes.push('c3-button--style-' + this.styling);

                return classes.join(' ');
            },
            iconPrimaryColor() {
                switch (this.styling) {
                    case '2': {
                        return 'senary';
                    }
                }
                return 'white';
            },
        },
        methods: {
            handleClick() {
                if (this.styling !== 'disabled') {
                    this.$emit('click')
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
    .c3-button {
        @include baseButton();
        @include boxShadow();
        position: relative;
        background-color: $quinary-color;
        font-size: 14px;
        color: $white-color;
    }

    .c3-button-content-wrapper {
        margin: 0 auto;
        text-align: center;
    }

    .c3-button-content {
        display: block;
        white-space: nowrap;
        text-align: center;
    }

    .c3-button-icon {
        height: 17px;
        margin-top: 2px;
        margin-right: 15px;
    }

    .c3-button--style-1 {
        @include openSansSemiBold();
    }

    .c3-button--style-2 {
        @include openSansRegular();
        color: $primary-color;
        background-color: $white-color;
    }

    .c3-button--style-3 {
        @include openSansBold();
        color: $white-color;
        background-color: $octonary-color;
    }

    .c3-button--style-danger {
        @include openSansBold();
        color: $white-color;
        background-color: $error-color;
    }

    .c3-button--style-disabled {
        color: $white-color;
        background-color: $octonary-color;
    }
</style>
