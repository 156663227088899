<template>
    <c3-customer-address :customer="customer" :store="store">
        <template v-if="store.hasOpeningTimes" slot="additionalText">
            <br>
            <strong>{{ this.currentlyOpen ? $t('openingTimes.isCurrentlyOpen') : $t('openingTimes.isCurrentlyClosed') }}</strong>
            <template v-if="nextOpeningTimeEvent">
                <br>
                <span>{{ nextOpeningTimeEvent }}</span>
            </template>
            <template v-if="store.openingTimesComment">
                <br>
                <span>{{ store.openingTimesComment }}</span>
            </template>
        </template>
    </c3-customer-address>
</template>

<script>
    import C3CustomerAddress from '@/components/content/customer/Address';
    import {OpeningTimeMixin} from '@/domain/mixin/local/openingTime';

    export default {
        mixins: [OpeningTimeMixin],
        components: {
            C3CustomerAddress
        },
        props: {
            customer: {
                type: Object,
                required: true
            },
            store: {
                type: Object,
                required: true
            },
        },
    };
</script>
