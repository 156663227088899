<template>
    <div class="c3-navigation-drawer-wrapper" :class="wrapperClass" :style="wrapperStyle">
        <div class="c3-navigation-drawer">
            <div class="c3-navigation-drawer-header">
                <slot name="headerLeft">
                    <div class="c3-navigation-drawer-header-back" @click="closeLatest">
                        <c3-icon-chevron-left primaryColor="white"/>
                    </div>
                </slot>
                <slot name="header">
                    <div class="c3-navigation-drawer-header-close" @click="close">
                        <c3-toggle-icon/>
                    </div>
                </slot>
            </div>
            <div class="c3-navigation-drawer-content">
                <slot></slot>
            </div>
            <div class="c3-navigation-drawer-footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import C3ToggleIcon from '@/components/icon/C3ToggleIcon.vue';

    export default {
        components: {
            C3ToggleIcon
        },
        props: {
            name: {
                type: String,
                required: true
            },
            fullWidth: {
                type: Boolean,
                default: false
            },
            fromSlideDirection: {
                type: String
            },
        },
        data() {
            return {
                zIndex: null
            }
        },
        computed: {
            wrapperStyle() {
                let style = {};

                let newZIndex = this.$store.getters['view/drawerZIndex'](this.name);
                if (-1 === newZIndex) {
                    setTimeout(() => {
                        this.zIndex = newZIndex;
                    }, 400);
                } else {
                    this.zIndex = newZIndex;
                }

                style.zIndex = this.zIndex;
                return style;
            },
            wrapperClass() {
                let classes = [];

                if (this.$store.getters['view/drawerOpen'](this.name)) {
                    classes.push('c3-navigation-drawer-wrapper--active');
                }

                if (this.fullWidth) {
                    classes.push('c3-navigation-drawer-wrapper-full-width');
                }

                if (this.fromSlideDirection) {
                    classes.push('c3-navigation-drawer-wrapper-' + this.fromSlideDirection);
                }

                return classes.join(' ');
            },
        },
        methods: {
            closeLatest() {
                this.$emit('closeLatest');
                this.$store.commit('view/removeLatestDrawer');
            },

            close() {
                this.$emit('close');
                this.$store.commit('view/removeDrawerAll');
            }
        },
    };
</script>
<style lang="scss">
    .c3-drawer-open {
        .c3-main {
            overflow: hidden;
            pointer-events: none;
        }

        &:after {
            opacity: 1;
            transition: opacity 0.2s;
            // Block access to layer below drawer
            pointer-events: auto;
        }
    }
</style>
<style lang="scss" scoped>
    .c3-navigation-drawer-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translate3d(100%, 0, 0);
        pointer-events: none;
        transition: transform 0.4s;
    }

    .c3-navigation-drawer-wrapper-left {
        transform: translate3d(-100%, 0, 0);
    }

    .c3-navigation-drawer-wrapper--active {
        transform: translate3d(0, 0, 0);
        pointer-events: auto;
    }

    .c3-navigation-drawer {
        height: 100%;
        width: 100%;
        overflow: hidden;
        @include montserratRegular();
    }

    .c3-navigation-drawer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        height: var(--navigation-drawer-header-height);
        margin-top: var(--page-header-status-bar-height);

        border-bottom: 1px solid $secondary-color;

        background: $quaternary-color;
    }

    .c3-navigation-drawer-header-back,
    .c3-navigation-drawer-header-close {
        display: flex;
        align-items: center;
        height: 100%;

        padding-left: var(--page-header-horizontal-spacing);
        padding-right: var(--page-header-horizontal-spacing);

        opacity: 0;
        transition: 0s 0.4s;
    }

    .c3-navigation-drawer-wrapper--active {
        .c3-navigation-drawer-header-back,
        .c3-navigation-drawer-header-close {
            opacity: 1;
            transition: 0.4s 0.4s;
        }
    }

    .c3-navigation-drawer-header-back {
        .c3-icon-wrapper {
            height: 30px;
        }
    }

    .c3-navigation-drawer-content {
        height: calc(100% - var(--navigation-drawer-header-height) - var(--page-header-status-bar-height));
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        background: $quaternary-color;
    }

    .c3-navigation-drawer-footer {
        position: relative;
        z-index: 1;
    }
</style>
