export const apiCheck = (state) => {
    return state.apiCheck;
};

export const isFeatureEnabled = (state) => (feature) => {
    try {
        return !!state.featureFlags[feature];
    } catch (e) {
        return false;
    }
};
