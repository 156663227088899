import store from '@/store';

const PLACEHOLDER_SEPARATOR = '###';
const PLACEHOLDER = [
    {
        placeholder: 'USER_ID',
        type: 'replace',
        callback: () => {
            if (store.getters['user/isLoggedIn']) {
                return store.getters['user/user'].id;
            }
            return '';
        }
    },
    {
        placeholder: 'USER_ADVERTISER_ID',
        type: 'replace',
        callback: () => {
            if (store.getters['user/isLoggedIn']) {
                return store.getters['user/user'].secureAdvertiserId;
            }
            return '';
        }
    },
    {
        placeholder: 'APP_URL',
        type: 'replace',
        callback: () => {
            return window.BRANCH_TRACKING.INSTALL_FRIEND;
        }
    },
    {
        placeholder: 'USER_ADVERTISER_URL',
        type: 'replace',
        callback: () => {
            if (store.getters['user/isLoggedIn']) {
                return `${API_BASE_URL}/affiliate/redirect?token=${store.getters['user/affiliateToken']}`;
            }
            return BRANCH_TRACKING.INSTALL_COMPANY;
        }
    }
];

export const singleContent = (state) => (id,) => {
    if (state.content.hasOwnProperty(id)) {
        let content = Object.assign({}, state.content[id]);

        PLACEHOLDER.forEach(item => {
            try {
                switch (item.type) {
                    case 'replace':
                        let fullPlaceholder = PLACEHOLDER_SEPARATOR + item.placeholder + PLACEHOLDER_SEPARATOR;
                        let regExp = new RegExp(fullPlaceholder, 'gi');
                        content.title = content.title.replace(regExp, item.callback());
                        content.text = content.text.replace(regExp, item.callback());
                        break;
                }
            } catch (e) {
                console.error('Placeholder replace failed', e, item, content);
            }
        });

        return content;
    }
    return null;
};

export const countrySelectOptions = (state) => {
    let countryOptions = Object.values(state.countries).map(country => {
        return {
            label: country.name,
            value: country.id
        }
    });

    countryOptions.sort((a, b) => {
        return a.label.localeCompare(b.label);
    });

    return countryOptions;
};