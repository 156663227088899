import moment from 'moment';

function deepCloneSpecialEntries(obj) {
    if (undefined === obj) {
        return undefined;
    }

    if (null === obj) {
        return null;
    }

    if (moment.isMoment(obj)) {
        return obj.clone();
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (Array.isArray(obj)) {
        return obj.map(deepCloneSpecialEntries);
    }

    if ('object' === typeof obj) {
        let clone = {};
        Object.keys(obj).forEach(key => {
            clone[key] = deepCloneSpecialEntries(obj[key]);
        });
        return clone;
    }

    return obj;
}

export const C3Object = {
    deepClone(object, hasSpecialEntries) {
        if (hasSpecialEntries) {
            return deepCloneSpecialEntries(object);
        } else {
            return JSON.parse(JSON.stringify(object));
        }
    },
    equalKeys(object1, object2) {
        if (null === object1 && null === object2) {
            return true;
        }

        if (null === object1 || null === object2) {
            return false;
        }

        let keys1 = Object.keys(object1);
        let keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let i = 0; i < keys1.length; i++) {
            if (!object2.hasOwnProperty(keys1[i])) {
                // object1 has a key which does not exist in object2
                return false;
            }
        }

        return true;
    }
};
