<template>
    <div class="c3-button-list-wrapper">
        <div class="c3-button-list">
            <router-link class="c3-button-list-item" :to="item.to" v-for="(item,index) in button" :key="index">
                <c3-button :label="$t(item.label)" :styling="styling"/>
            </router-link>
        </div>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button';

    export default {
        components: {
            C3Button
        },
        props: {
            button: {
                type: Array,
                default: () => []
            },
            styling: {
                type: String,
                default: '2'
            }
        },
        data() {
            return {}
        },
    };
</script>
<style lang="scss" scoped>
    .c3-button-list {
        margin-top: -10px,
    }

    .c3-button-list-item {
        display: block;
        margin-top: 10px;
    }
</style>