import moment from 'moment';

export default {
    modifiedAndCreatedFromApi: function (object) {
        if (null === object || typeof object !== 'object') {
            return object;
        }

        if (object.hasOwnProperty('modified') && typeof object.modified === 'string') {
            object.modified = moment(object.modified);
        }

        if (object.hasOwnProperty('created') && typeof object.created === 'string') {
            object.created = moment(object.created);
        }

        return object;
    },
    modifiedAndCreatedToApi: function (object) {
        if (null === object || typeof object !== 'object') {
            return object;
        }

        if (object.hasOwnProperty('modified')) {
            delete object.modified;
        }

        if (object.hasOwnProperty('created')) {
            delete object.created;
        }

        return object;
    }
};