<template>
    <div class="c3-download-drawer">
        <div class="c3-download-container">
            <div class="c3-download-drawer__wrapper c3-download-drawer-flex">
                <div class="c3-download-drawer__icon c3-download-drawer-flex">
                    <icon-download v-if="isDownloading" class="c3--downloading" />
                    <icon-download-complete v-if="!isDownloading" />
                </div>
                <div v-if="!isFailed" class="c3-download-drawer__filename">{{ fileName }}.</div>
                <div v-if="!isDownloading && !isFailed" class="c3-download-drawer__action--open" @click="openFile()">{{ $t('label.openFile') }}</div>
                <div v-if="isFailed" class="c3-download-drawer--failed">{{ $t('label.downloadFailed') }}</div>
            </div>
            <div class="c3-download-drawer__action--close c3-download-drawer-flex" @click="close()">
                <icon-close />
            </div>
        </div>
        <div class="c3-safe-area-bottom"></div>
    </div>
</template>

<script>
import IconClose from '@/components/icon/Close'
import IconDownload from '@/components/icon/Download'
import IconDownloadComplete from '@/components/icon/DownloadComplete'
import { openPdf } from '@/utility/media/file';

export default {
    components: {
        IconClose,
        IconDownload,
        IconDownloadComplete
    },
    props: {
      fileName: {
        type: String,
        required: true
      },
      fileUrl: {
        type: String,
        default: null,
      },
      isDownloading: {
        type: Boolean,
        default: false
      },
      isFailed: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      openFile() {
        openPdf(this.fileUrl);
        this.close();
      },
      close() {
        this.$store.commit('invoice/updateDownloadInfo', null);
      }
    },
  };
</script>
<style lang="scss" scoped>
    .c3-download-drawer {
        position: fixed;
        min-height: 60px;
        width: 100%;
        background: $white-color;
        z-index: 5001;
        bottom: 0;
        box-shadow: 0px -15px 20px rgba(0, 0, 0, 0.19);
    }

    .c3-download-drawer__wrapper,
    .c3-download-drawer-flex {
        display: flex;
        align-items: center;
    }

    .c3-download-drawer__filename {
        max-width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 5px;
    }

    .c3-download-drawer__action--open {
        font-size: 16px;
        padding-left: 10px;
        color: $quinary-color;
    }

    .c3-download-drawer__icon {
        color: $quinary-color;
    }

    .c3--downloading ::v-deep svg {
        animation: download 1s infinite ease-in-out;
    }
    
    .c3-download-drawer--failed {
        color: $tertiary-color;
        font-size: 16px;
        padding: 5px;
    }

    @keyframes download {
        0% {
           fill: $quinary-color;
        }
        
        100% {
            fill: #000;
        }
    }

    .c3-download-container {
        padding: var(--spacing-xs);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .c3-safe-area-bottom {
        display: block;
        width: 100%;
        height: 0;
        height: env(safe-area-inset-bottom);
    }
</style>
