let ConfigInternal = function () {
    const release = process.env.VUE_APP_RELEASE;
    // process.env can't contain boolean values, so we check for 'true'
    const isNative = 'true' === process.env.VUE_APP_IS_NATIVE || true === process.env.VUE_APP_IS_NATIVE;
    const environment = process.env.VUE_APP_ENVIRONMENT;
    const version = process.env.VUE_APP_VERSION;
    const apiVersion = process.env.VUE_APP_API_VERSION;
    const apiUrl = process.env.VUE_APP_API_URL;
    const stripeKey = process.env.VUE_APP_STRIPE_KEY;
    const tenant = process.env.VUE_APP_TENANT;
    // process.env can't contain boolean values, so we check for 'true'
    const isBranchDebugEnabled = 'true' === process.env.VUE_APP_BRANCH_DEBUG || true === process.env.VUE_APP_BRANCH_DEBUG;

    const featureFlags = process.env.hasOwnProperty('VUE_APP_FEATURE_FLAGS') ? process.env.VUE_APP_FEATURE_FLAGS.split(',').map((flag) => flag.trim()) : [];
    this.FEATURE_FLAG_COMPANY_PROFILE = 'COMPANY_PROFILE';
    this.FEATURE_FLAG_COUPON = 'COUPON';

    const newCouponDuration = 604800;

    this.getRelease = function () {
        return release;
    };

    this.getEnvironment = function () {
        return environment;
    };

    this.isNative = function () {
        return isNative;
    };

    this.isNativeRealDevice = function () {
        return this.isNative() && !window.device.isVirtual;
    };

    this.getVersion = function () {
        return version;
    };

    this.getApiVersion = function () {
        return apiVersion;
    };

    this.getApiUrl = function () {
        return apiUrl;
    };

    this.getAutocompleteApiUrl = function () {
        return apiUrl;
    };

    this.getStripeKey = function () {
        return stripeKey;
    };

    /**
     *
     * @return {string}
     */
    this.getTenant = function () {
        return tenant;
    }

    this.isBranchDebugEnabled = function () {
        return isBranchDebugEnabled;
    };

    this.getNewCouponDuration = function () {
        return newCouponDuration;
    };

    /**
     * Feature flags are used to enable/disable specific features for beta versions or for differences between sammelpass and coupocket.
     * Possible values:
     * - COMPANY_PROFILE
     * - COUPON
     */
    this.hasFeatureFlag = function (flag) {
        return featureFlags.includes(flag);
    }
};

export const Config = Object.freeze(new ConfigInternal());
