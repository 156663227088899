import moment from 'moment';

function filterByState(coupons, couponState) {
    if (!Array.isArray(coupons)) {
        coupons = Object.values(coupons);
    }
    return coupons.filter(coupon => coupon.state === couponState);
}

export const all = (state) => {
    return Object.values(state.items);
};

export const singleCoupon = (state) => (id, defaultValue) => {
    defaultValue = undefined !== defaultValue ? defaultValue : null;

    if (state.items.hasOwnProperty(id)) {
        return state.items[id];
    }
    return defaultValue;
};

export const collectionByUser = (state) => (userId) => {
    return all(state).filter(item => item.user == userId);
};

export const couponsWithState = (state) => (couponState) => {
    return all(state).filter(coupon => {
        return coupon.state === couponState;
    });
};

export const couponsWithStateAndUser = (state) => (couponState, userId) => {
    return all(state).filter(coupon => {
        return coupon.state === couponState && coupon.user == userId;
    });
};

export const couponsPublished = (state) => {
    return filterByState(state.items, COUPON_STATE.PUBLISHED);
};

export const couponsPublishedAndActive = (state) => {
    let now = moment();
    return filterByState(state.items, COUPON_STATE.PUBLISHED).filter(coupon => coupon.validFrom < now && now < coupon.validUntil);
};

export const couponByStatePublishedAndCustomer = (state) => (customer) => {
    let id = customer;
    if (typeof customer === 'object') {
        id = customer.id;
    }

    return Object.values(state.items).filter(coupon => coupon.state === COUPON_STATE.PUBLISHED && coupon.user === id);
};

export const couponByStatePublishedAndActiveAndCustomer = (state) => (customer) => {
    let id = customer;
    if (typeof customer === 'object') {
        id = customer.id;
    }

    return couponsPublishedAndActive(state).filter(coupon => coupon.state === COUPON_STATE.PUBLISHED && coupon.user === id);
};

export const couponByStatePublishedAndPlannedAndCustomer = (state) => (customer) => {
    let id = customer;
    if (typeof customer === 'object') {
        id = customer.id;
    }
    let now = moment();
    return couponsPublished(state).filter(coupon => coupon.state === COUPON_STATE.PUBLISHED && coupon.user === id && now < coupon.validFrom);
};


export const couponByStatePublishedAndExpiredAndCustomer = (state) => (customer) => {
    let id = customer;
    if (typeof customer === 'object') {
        id = customer.id;
    }
    let now = moment();
    return couponsPublished(state).filter(coupon => coupon.state === COUPON_STATE.PUBLISHED && coupon.user === id && now > coupon.validUntil);
};

export const couponByStateDraftAndCustomer = (state) => (customer) => {
    let id = customer;
    if (typeof customer === 'object') {
        id = customer.id;
    }

    return Object.values(state.items).filter(coupon => coupon.state === COUPON_STATE.DRAFT && coupon.user === id);
};

export const couponByStateReviewAndCustomer = (state) => (customer) => {
    let id = customer;
    if (typeof customer === 'object') {
        id = customer.id;
    }

    return Object.values(state.items).filter(coupon => coupon.state === COUPON_STATE.REVIEW && coupon.user === id);
};

export const couponsPublishedWithUser = (state, getters, rootState, rootGetters) => {
    return filterByState(state.items, COUPON_STATE.PUBLISHED).map(coupon => {
        // Clone coupon to keep the original with coupon.user = USERID
        coupon = Object.assign({}, coupon);
        coupon.user = rootGetters['customer/singleCustomer'](coupon.user);
        return coupon;
    });
};

export const couponsDraft = (state) => {
    return couponsWithState(state)(COUPON_STATE.DRAFT);
};

export const couponsReview = (state) => {
    return couponsWithState(state)(COUPON_STATE.REVIEW);
};

export const collectionByUserAndPublishedState = (state) => (userId) => {
    return couponsWithStateAndUser(state)(COUPON_STATE.PUBLISHED, userId);
};

export const collectionByUserAndDraftState = (state) => (userId) => {
    return couponsWithStateAndUser(state)(COUPON_STATE.DRAFT, userId);
};

export const collectionByUserAndReviewState = (state) => (userId) => {
    return couponsWithStateAndUser(state)(COUPON_STATE.REVIEW, userId);
};

export const category = (state) => (id, defaultValue) => {
    defaultValue = undefined !== defaultValue ? defaultValue : null;

    if (state.categories.hasOwnProperty(id)) {
        return state.categories[id];
    }
    return defaultValue;
};

export const categories = (state) => {
    return Object.values(state.categories);
};

export const couponTemplates = (state) => {
    return state.couponTemplates;
};

export const activeCouponCount = (state) => state.activeCouponCount;

export const maxCouponLimit = (state) => state.maxCouponLimit;
