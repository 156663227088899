<template>
    <div class="c3-tile-grid-wrapper" :class="{'c3-tile-grid-wrapper-invert':invert}">
        <slot name="header"></slot>
        <div class="c3-tile-grid">
            <slot></slot>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            invert: {
                type: Boolean,
            }
        }
    };
</script>
<style lang="scss" scoped>
    .c3-tile-grid-wrapper {
        margin: 0 auto;
        max-width: 320px;

        @media(min-width: $breakpointTablet+1) {
            max-width: 1024px;
        }
    }

    .c3-tile-grid {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1 * $grid-gutter-default;
        margin-top: -1 * $grid-gutter-default;
    }
</style>