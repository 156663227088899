    <template>
        <div class="c3-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path :fill="color1" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"/></svg>
<!--
Font Awesome Pro 5.2.0 by @fontawesome - https://fontawesome.com
License - https://fontawesome.com/license (Commercial License)
-->
        </div>
    </template>
    <script>
        import {IconMixin} from './mixins/icon';

        export default {
            mixins: [IconMixin],
        };
    </script>

