<template>
    <form class="c3-user-login-form c3-user-login-step" @submit.prevent="submit">
        <c3-form-input :placeholder="$t('label.firstname')" v-model="firstName" :validator="$v.firstName" :errorMessage="false"/>
        <c3-form-input :placeholder="$t('label.lastName')" v-model="lastName" :validator="$v.lastName" :errorMessage="false"/>
        <c3-form-input :placeholder="$t('label.email')" type="email" v-model="email" :validator="$v.email" :errorMessage="false"/>

        <div class="c3-user-login-submit">
            <c3-form-submit :label="$t('label.toNext')" :invert="true"/>
            <div class="c3-user-login-registration-additional-information">
                <router-link :to="{name:'Home'}">Home</router-link>
                <span class="c3-user-login-registration-additional-information-separator">|</span>
                <router-link :to="skipRoute">{{ $t('label.skip') }}</router-link>
            </div>
        </div>
    </form>
</template>

<script>
    import {RouterMixin} from '@/domain/mixin/local/router';
    import {required, email} from 'vuelidate/lib/validators'

    export default {
        mixins: [RouterMixin],
        data() {
            return {
                firstName: '',
                lastName: '',
                email: '',
                isLoading: false,
            };
        },
        computed: {
            skipRoute() {
                if (this.hasRedirectAfterLogin) {
                    return this.$route.query.redirectAfterLogin;
                }
                return ROUTER.defaultLoginRedirect;
            }
        },
        methods: {
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$store.commit('view/showLoading');
                this.$store.dispatch('user/updateFields', {firstName: this.firstName, lastName: this.lastName, email: this.email})
                    .then(() => {
                        this.$store.commit('view/hideLoading');
                        if (this.hasRedirectAfterLogin) {
                            this.$router.push(this.$route.query.redirectAfterLogin);
                        } else {
                            this.$router.push(ROUTER.defaultUserRegistrationRedirect);
                        }
                    })
                    .catch(error => {
                        this.$store.commit('view/hideLoading');
                        this.$c3.alert('error.generic.title', 'error.couponCreateError');
                        console.error(error);
                    });
            },
        },
        validations: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                email
            }
        },
    };
</script>