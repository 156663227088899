<template>
    <div class="c3-coupon-item-media">
        <slot name="image">
            <picture v-if="images.length">
                <source v-for="image in images" :media="image.breakPoint" :srcset="image.src" :key="image.src"/>
                <img :src="imageSrc" :alt="item.title" class="c3-coupon-image"/>
            </picture>
        </slot>

        <slot name="badge">
            <div class="c3-coupon__badge" v-if="badgeText">
                <div class="c3-coupon__badge-content">{{badgeText}}</div>
            </div>
        </slot>

        <div class="c3-coupon-item-action-list">
            <slot name="actionList">
                <div class="c3-coupon-item-action-list-item" @click.prevent="$emit('toggleFavorite')" v-if="showFavorite">
                    <c3-icon-c3-heart-full :primaryColor="iconFavoritePrimaryColor" :secondaryColor="iconFavoriteSecondaryColor"/>
                </div>
            </slot>
        </div>

        <div v-if="additionalInformation" class="c3-coupon-item-media-additional-information">{{additionalInformation}}</div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            showFavorite: {
                type: Boolean,
                default: true
            },
            isFavorite: {
                type: Boolean
            },
            isMediaVisible: {
                type: Boolean,
                default: true
            },
            additionalInformation: {
                type: String
            },
            badgeText: {
                type: String
            },
            item: Object
        },
        data() {
            return {}
        },
        computed: {
            iconFavoritePrimaryColor() {
                return this.isFavorite ? 'tertiary' : 'transparent';
            },
            iconFavoriteSecondaryColor() {
                return 'white'
            },
            imageSrc() {
                return MEDIA.COUPON_BASE_URL + '/' + MEDIA.SIZES[MEDIA.SIZES.length - 1] + '/' + this.item.image;
            },
            images() {
                return window.MEDIA.SIZES
                    .map(size => {
                        return {
                            src: MEDIA.COUPON_BASE_URL + '/' + size + '/' + this.item.image,
                            breakPoint: `(max-width: ${size}px)`
                        }
                    });
            },
        }
    };
</script>
<style lang="scss" scoped>
    .c3-coupon-item-media {
        display: block;
        height: 0;
        padding-top: percentage(1 / $image-aspect-ratio-default);

        position: relative;
        overflow: hidden;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: radial-gradient(circle at top right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 50%);
        }
    }

    .c3-coupon-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .c3-coupon__badge {
        width: 130px;

        position: absolute;
        top: 63px;
        left: -28px;

        padding: 5px 0;
        background-color: $quinary-color;

        text-align: center;

        transform: rotate(-45deg);
        transform-origin: 0 0;

        @include boxShadow();
    }

    .c3-coupon__badge-content {
        transform: translateZ(0);
        @include openSansBold();
        font-size: 14px;
        color: $white-color;
    }

    .c3-coupon-item-action-list {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .c3-coupon-item-action-list-item {
        position: relative;
        display: block;
        height: calc(30px + 5px + var(--page-content-horizontal-spacing));
        padding: var(--page-content-horizontal-spacing) var(--page-content-horizontal-spacing) 5px 5px;
    }

    .c3-coupon-item-media-additional-information {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        min-width: 80px;

        padding: 10px 0 10px 10px;

        background-color: $quinary-color;

        @include openSansSemiBold();
        color: $white-color;
        font-size: 12px;
    }
</style>
