<template>
    <div class="c3-icon-wrapper">
        <svg version="1.1" viewBox="0 0 477.58 320" xmlns="http://www.w3.org/2000/svg">
            <path :fill="color1"
                  d="m397.79 160.42a79.791 79.791 0 1 0 79.791 79.791 79.777 79.777 0 0 0-79.791-79.791zm69.5 79.791a69.5 69.5 0 1 1-69.5-69.5 69.493 69.493 0 0 1 69.495 69.5zm-47.911 28.409-26.126-18.982a3.886 3.886 0 0 1-1.576-3.118v-51.352a3.872 3.872 0 0 1 3.861-3.861h4.5a3.872 3.872 0 0 1 3.861 3.861v47.07l22.683 16.505a3.858 3.858 0 0 1 0.837 5.405l-2.638 3.636a3.887 3.887 0 0 1-5.405 0.837z"/>
            <path :fill="color2"
                  d="m424 0h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm164 92h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
