<template>
    <footer class="c3-page-footer">
        <tab-bar :items="tabBarItems"/>
    </footer>
</template>

<script>
    import TabBar from '@/components/layout/navigation/TabBar.vue';

    export default {
        components: {
            TabBar,
        },
        computed: {
            tabBarItems() {
                return [
                    {
                        icon: 'c3-icon-tag',
                        to: {name: 'CouponList'},
                        label: this.$t('footer.coupons')
                    },
                    {
                        icon: 'c3-icon-heart',
                        to: {name: 'UserProfileFavorite'},
                        label: this.$t('footer.favorites')
                    },
                    {
                        icon: 'c3-icon-c3-bonus-card',
                        to: {name: 'BonusCardList'},
                        label: this.$t('footer.bonusCards'),
                    },
                    {
                        icon: 'c3-icon-share-alt',
                        to: {name: 'Share'},
                        label: this.$t('footer.share')
                    },
                    {
                        icon: 'c3-icon-c3-person-navigation',
                        click: () => {
                            this.$store.commit('view/addDrawer', 'mainNavigationDrawer');
                        },
                        label: this.$t('footer.navigation')
                    }
                ];
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-page-footer {
        @include disableSelect();
    }
</style>
