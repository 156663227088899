import moment from 'moment';

export default {
    fromApi: function (invoice) {
        if (invoice.created) {
            invoice.created = moment(invoice.created);
        }
        return invoice;
    },
    fromApiCollection: function (invoices) {
        return invoices.map(invoice => this.fromApi(invoice));
    }
};
