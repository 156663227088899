import baseApiConverter from '@/domain/api/converter/base';
import moment from 'moment';

export const StoreConverter = {
    fromApi: function (item) {
        item = baseApiConverter.modifiedAndCreatedFromApi(item);

        if (item.hasOwnProperty('openingTimes') && null !== item.openingTimes && typeof item.openingTimes === 'object') {
            Object.keys(item.openingTimes).forEach(dayKey => {
                if (!Array.isArray(item.openingTimes[dayKey])) {
                    return;
                }

                item.openingTimes[dayKey].forEach((dayEntry, index) => {
                    if (dayEntry.start) {
                        item.openingTimes[dayKey][index].start = moment(dayEntry.start);
                    }
                    if (dayEntry.end) {
                        item.openingTimes[dayKey][index].end = moment(dayEntry.end);
                    }
                })
            });
        }

        return item;
    },
    fromApiCollection: function (items) {
        return items.map(item => this.fromApi(item));
    },
};
