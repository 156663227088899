<template>
    <div class="c3-navigation-tab-bar">
        <template v-for="item in items">
            <template v-if="!item.disabled">
                <div v-if="item.to" class="c3-navigation-tab-bar-entry" v-ripple>
                    <router-link :to="item.to" class="c3-navigation-tab-bar-link">
                        <div class="c3-navigation-tab-bar-entry-content">
                            <div class="c3-navigation-tab-bar-entry-icon">
                                <component v-if="isActive(item.to)" :is="item.icon" primaryColor="quinary"/>
                                <component v-else :is="item.icon" primaryColor="white"/>
                            </div>
                            <div class="c3-navigation-tab-bar-entry-label" v-if="item.label">{{item.label}}</div>
                        </div>
                    </router-link>
                </div>
                <div v-else-if="item.click" class="c3-navigation-tab-bar-entry" v-ripple>
                    <div @click="item.click" class="c3-navigation-tab-bar-link">
                        <div class="c3-navigation-tab-bar-entry-content">
                            <div class="c3-navigation-tab-bar-entry-icon">
                                <component :is="item.icon" primaryColor="white"/>
                            </div>
                            <div class="c3-navigation-tab-bar-entry-label" v-if="item.label">{{item.label}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        methods: {
            isActive(to) {
                return this.$router.resolve(to).resolved.fullPath === this.$route.fullPath;
            }
        }
    };
</script>
<style lang="scss">

</style>

<style lang="scss" scoped>
    .c3-navigation-tab-bar {
        display: flex;
        height: 100%;
    }

    .c3-navigation-tab-bar-entry {
        position: relative;
        flex: 1;
        overflow: hidden;
    }

    .c3-navigation-tab-bar-link {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
    }

    .c3-navigation-tab-bar-entry-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .c3-navigation-tab-bar-entry-back-button {
        display: flex;
        align-items: center;
        width: 20px;
        margin-right: 15px;
        flex: none;

        & /deep/ .c3-icon-wrapper {
            height: 40px;
        }
    }

    .c3-navigation-tab-bar-entry-icon {
        height: 20px;
    }

    .c3-navigation-tab-bar-entry-label {
        margin: 2px auto 0;
        white-space: nowrap;
        font-size: var(--content-tab-bar-label-font-size);
        color: $third-color;
    }
</style>
