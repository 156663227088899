import {HTTP} from '@/domain/api/http';
import userApiConverter from '@/domain/api/converter/user';
import {loadCoupon} from "../coupon/actions";

export const loadAll = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/customer/')
            .then(response => {
                let customers = userApiConverter.fromApiCollection(response.data.data);
                context.commit('updateCustomer', customers);
                resolve(customers)
            })
            .catch(error => reject(error));
    });
};

export const load = (context, id) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/customer/' + id)
            .then(response => {
                let customer = userApiConverter.fromApi(response.data.data);
                context.commit('updateSingleCustomer', customer);
                resolve(customer)
            })
            .catch(error => reject(error));
    });
};

export const loadByCoupon = (context, couponId) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/coupon/' + couponId + '/customer')
            .then(response => {
                let customer = userApiConverter.fromApi(response.data.data);
                context.commit('updateSingleCustomer', customer);
                resolve(customer)
            })
            .catch(error => reject(error));
    });
};

export const updateImage = (context, data) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + '/customer/' + data.id + '/image', data.formData)
            .then(response => {
                resolve(response.data.data);
            })
            .catch(error => reject(error));
    });
};

export const sendQrCodeMail = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + '/customer/send-qr-code-mail')
            .then(response => resolve())
            .catch(error => reject(error));
    });
};

export const loadQrToken = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/qrtoken')
            .then(response => {
                context.commit('qrToken', response.data.data.token);
                resolve(response.data.data)
            })
            .catch(error => reject(error));
    });
};