import Vue from 'vue';
import VueI18n from 'vue-i18n';
import DE from '@/translations/de';
import EN from '@/translations/en';


Vue.use(VueI18n);

const messages = {
    de: DE,
    en: EN,
};

let i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: messages,
    silentTranslationWarn: true
});

export default i18n;
