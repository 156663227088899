import {Analytics} from '@/domain/analytics';
import {Config} from '@/config';
import {Logger} from '@/domain/logger';

export function AffiliateServiceInternal() {
    let self = this;
    let branch;
    let $store;
    let $router;

    let sessionDataPromise = null;
    let sessionData = null;

    this.init = function (branchInject, $storeInject, $routerInject) {
        branch = branchInject;
        $store = $storeInject;
        $router = $routerInject;

        if (Config.isNative()) {
            branch.setDebug(Config.isBranchDebugEnabled());
            getSessionData(true);
        }
    };

    this.appStart = async function () {
        if (!Config.isNative()) {
            return;
        }
        await getSessionData();
        await this.handleDeepLink();
        await this.updateAdvertiserFromBranch();
    };

    this.appResume = async function () {
        if (!Config.isNative()) {
            return;
        }
        await getSessionData();
        await this.handleDeepLink();
        await this.updateAdvertiserFromBranch();
    };

    /**
     * @param {String} id canonicalIdentifier of branch.io deep link
     * @param {String} og_title og:title tag
     * @param {String} og_description og:description tag
     * @param {String} og_image og:img tag
     * @param {String} feature Feature for internal analytics
     * @param {String} campaign Campaign for internal analytics
     * @param {Array} tags Tags for internal analytics
     * @param {String} route
     * @returns {Promise<String|null>}
     */
    this.createDeepLink = async function (id, route, og_title, og_description, og_image, feature, campaign) {
        return new Promise(async (resolve, reject) => {
            if (!Config.isNative()) {
                return reject('createDeepLink is not supported in non native platforms');
            }

            let timeoutId = setTimeout(() => reject('[AffiliateService] createDeepLink - Timeout after 10s'), 10000);

            try {
                console.debug('[AffiliateService] createDeepLink start', id, sessionData);
                let data = await getSessionData();
                console.debug('[AffiliateService] createDeepLink initSession finished', id, data);
                let universalConfig = {canonicalIdentifier: id};
                let branchUniversalObj = await branch.createBranchUniversalObject(universalConfig);
                console.debug('[AffiliateService] createDeepLink createBranchUniversalObject finished', id);
                let analytics = {
                    channel: 'app',
                    feature: feature,
                    campaign: campaign,
                };

                try {
                    // Used length from https://stackoverflow.com/a/35817780
                    if (og_title && typeof og_title === 'string' && og_title.length > 40) {
                        og_title = og_title.substring(0, 37) + '...';
                    }

                    if (og_description && typeof og_description === 'string' && og_description.length > 300) {
                        og_description = og_description.substring(0, 297) + '...';
                    }
                } catch (e) {
                    console.error('og_title or og_description substring failed', e);
                }


                let properties = {
                    $og_title: og_title,
                    $og_description: og_description,
                    $og_image_url: og_image,
                    $deeplink_path: route,
                    custom_date: Date.now(),
                };

                console.debug('[AffiliateService] createDeepLink generateShortUrl parameter', analytics, properties);

                let result = await branchUniversalObj.generateShortUrl(analytics, properties);
                console.debug('[AffiliateService] createDeepLink result', result);
                clearTimeout(timeoutId);
                resolve(result.url);
            } catch (e) {
                clearTimeout(timeoutId);
                console.error('[AffiliateService] createDeepLink failed', id, feature, campaign, route, e);
                Logger.captureException(e);
                reject('[AffiliateService] createDeepLink failed');
            }
        });
    };

    this.handleDeepLink = async function () {
        if (!Config.isNative()) {
            return;
        }

        let data;
        try {
            console.debug('[AffiliateService] handleDeepLink - start initSession');
            data = await getSessionData();
            console.debug('[AffiliateService] handleDeepLink - finished initSession', data);
            if (data && data.$deeplink_path) {
                $router.push(data.$deeplink_path);
                Analytics.logOpenDeepLink(data);
            }
        } catch (e) {
            console.error('[AffiliateService] handleDeepLink failed', data);
            Logger.captureException(e);
        }
    };

    this.updateAdvertiserFromBranch = async function () {
        if (!Config.isNative()) {
            return;
        }

        try {
            let data = await getSessionData();
            console.debug('[AffiliateService] updateAdvertiserFromBranch - initSession data', data);
            await updateFromFirstReferringParams();
            await updateFromLatestReferringParams();
        } catch (e) {
            console.error('[AffiliateService] initSession failed', e);
            Logger.captureException(e);
        }
    };

    this.updateAdvertiserFromRoute = function ($route) {
        console.debug('updateAdvertiserFromRoute', $route.query);
        try {
            if (isValidAdvertiser($route.query.coupocket_advertiser)) {
                $store.dispatch('user/updateAdvertiser', $route.query.coupocket_advertiser);
            }
        } catch (e) {
            console.error('Advertiser detection failed', e);
        }
    };

    async function getSessionData(force) {
        if (sessionDataPromise !== null) {
            // Avoid concurrent branch.initSession calls
            try {
                console.debug('[AffiliateService] getSessionData - Avoid concurrent call, wait for existing promise', sessionData);
                return await sessionDataPromise;
            } catch (error) {
                console.error('[AffiliateService] getSessionData - Concurrent call promise failed', sessionData);
                return null;
            }
        }

        if (force || null === sessionData) {
            try {
                console.debug('[AffiliateService] getSessionData - Start', sessionData);
                sessionDataPromise = branch.initSession();
                sessionData = await sessionDataPromise;
                console.debug('[AffiliateService] getSessionData - Finished', sessionData);
            } catch (error) {
                // This error is pretty normal, because branch.io has a problem with updating the session on resume.
                // https://github.com/BranchMetrics/android-branch-deep-linking-attribution/issues/808
                // https://github.com/BranchMetrics/cordova-ionic-phonegap-branch-deep-linking-attribution/issues/653
                console.error('[AffiliateService] getSessionData - Failed', sessionData, error);
                // Reset sessionData, otherwise a deepLink could stay inside sessionData forever which would trigger a redirect to the deeplink page on each "resume"-event
                // This would break our QR-Code/Image upload stuff, because they all trigger a "resume" event and then the user would be suddenly redirected to an old deeplink page.
                sessionData = null;
            }
            // Reset promise
            sessionDataPromise = null;
        }

        return sessionData;
    }

    async function updateFromFirstReferringParams() {
        let data = await branch.getFirstReferringParams();
        if (data) {
            // Use first referring params, if no other advertiser is set
            // Otherwise we would overwrite new advertisers with the original param again and again
            if (!isValidAdvertiser($store.state.user.advertiser) && isValidAdvertiser(data.coupocket_advertiser)) {
                $store.dispatch('user/updateAdvertiser', data.coupocket_advertiser);
                Analytics.logOpenDeepLink(data);
            }
        }
    }

    async function updateFromLatestReferringParams() {
        let data = await branch.getLatestReferringParams();
        if (data && isValidAdvertiser(data.coupocket_advertiser)) {
            $store.dispatch('user/updateAdvertiser', data.coupocket_advertiser);
            Analytics.logOpenDeepLink(data);
        }
    }

    function isValidAdvertiser(advertiser) {
        return typeof advertiser === 'string' && advertiser.trim().length > 10;
    }
}

export const AffiliateService = Object.freeze(new AffiliateServiceInternal());
