import moment from 'moment';

export const PublishCouponMixin = {
    data() {
        return { type: 'Coupon' }
    },
    computed: {
        isDraft() {
            return this.coupon.state === COUPON_STATE.DRAFT;
        },
        isInReview() {
            return this.coupon.state === COUPON_STATE.REVIEW;
        },
        isNotYetValid() {
            return this.coupon.state === COUPON_STATE.PUBLISHED && moment() < this.coupon.validFrom;
        },
        isExpired() {
            return this.coupon.state === COUPON_STATE.PUBLISHED && moment() > this.coupon.validUntil;
        },
        hasPermissionToPublish() {
            return this.$store.getters['user/hasMembershipAndPayment'];
        },
        hasFreeMembership() {
            return this.$store.getters['user/hasFreeMembership'];
        },
        hasMembership() {
            return this.$store.getters['user/hasMembership'];
        },
        publishCouponRoute() {
            let currentRouteWithPublishParam = this.$router.resolve({name: this.$route.name, params: this.$route.params, query: {publishCoupon: 1}}).resolved.fullPath;
            const routeName = this.hasMembership ? 'CustomerRegistrationPayment' : 'CustomerRegistrationMembership';
            return {name: routeName, query: {redirectAfterSave: currentRouteWithPublishParam}};
        },
        membershipStatus() {
            const hasPayment = !!this.$store.getters['user/payment'];
            const hasNonFreeMembership = this.$store.getters['user/hasNonFreeMembership'];
            
            if (!hasPayment && hasNonFreeMembership) {
                return 'hasNoPaymentWithNonFreeMembership';
            } else {
                return 'hasNoMembership';
            }
        }
    },
    methods: {
        publishCoupon() {
            // Check if payment is available
            this.$store.commit('view/showLoading');

            Promise.all([
                this.$store.dispatch('user/loadCurrentPayment'),
                this.$store.dispatch('user/loadActiveMemberships'),
            ])
                .finally(() => {
                    this.$store.commit('view/hideLoading');
                    if (this.hasPermissionToPublish || this.hasFreeMembership) {
                        this.askPublishCouponConfirmation()
                    } else {
                        this.$c3.confirmYesNo(
                            this.$t(`publishAlert.${this.membershipStatus}.title`),
                            this.$t(`publishAlert.${this.membershipStatus}.message`, { type: this.$t(this.type) }),
                            () => this.$router.push(this.publishCouponRoute)
                        );
                    }
                });
        },
        askPublishCouponConfirmation() {
            this.$c3.confirmYesNo(
                'Coupon veröffentlichen',
                'Alle Coupons werden auf Missbrauch und Fehler geprüft. Möchtest du deinen Coupon jetzt zur Überprüfung senden?',
                this.sendPublishCoupon
            );
        },
        sendPublishCoupon() {
            this.$store.dispatch('coupon/enableReview', {id: this.coupon.id})
                .then(() => {
                    this.$c3.alert(
                        'Danke für deine Anfrage',
                        'Wir geben den Coupon möglichst bald frei.',
                        () => this.$router.push({name: 'CouponDetail', params: {id: this.coupon.id}})
                    );
                })
                .catch((error) => {
                    this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                    console.error(error);
                })
                .finally(() => this.$store.commit('view/hideLoading'));
        }
    },
    mounted() {
        if (this.$route.query.publishCoupon) {
            this.publishCoupon();
        }
    }
};