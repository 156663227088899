<template>
    <div class="c3-main">
        <div class="c3-page-content c3-page-content-top-padding">
            <c3-coupon-list v-if="items.length" :title="$t('pages.user.profile.favorites.title')" :items="items" :name="$route.name"/>
            <c3-no-item-create-now-with-icon v-else :title="$t('pages.user.profile.favorites.empty')" icon="c3-icon-heart">
                <template slot="button">
                    <router-link :to="{name:'CouponList'}">
                        <c3-button :label="$t('pages.user.profile.favorites.button')"/>
                    </router-link>
                </template>
            </c3-no-item-create-now-with-icon>
        </div>
    </div>
</template>

<script>
    import C3CouponList from '@/components/content/coupon/list/Index';
    import C3NoItemCreateNowWithIcon from '@/components/content/coupon/list/NoItemCreateNowWithicon.vue';
    import C3Button from '@/components/form/Button.vue';

    export default {
        name: 'UserFavoriteCouponList',
        components: {
            C3CouponList,
            C3NoItemCreateNowWithIcon,
            C3Button
        },
        data() {
            return {}
        },
        computed: {
            items() {
                let coupons = [];
                let favorite = this.$store.getters['user/visibleFavoriteCoupon'];
                if (Array.isArray(favorite)) {
                    favorite.forEach(favoriteCoupon => {
                        let coupon = this.$store.getters['coupon/singleCoupon'](favoriteCoupon.coupon);
                        if (null !== coupon) {
                            coupons.push(coupon);
                        }
                    })
                }
                return coupons;
            },
        },
        methods: {
            updateData() {
                this.$store.commit('view/showLoading');
                this.$store.dispatch('coupon/loadCurrentUserFavorite')
                    .finally(() => this.$store.commit('view/hideLoading'));
            }
        },
        activated() {
            this.updateData();
        },
        created() {
            this.updateData();
        },
    };
</script>
<style lang="scss" scoped>
    .c3-main {
        background-color: $nonary-color;
    }
</style>

