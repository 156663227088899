import {HTTP} from '@/domain/api/http';
import {NotificationConverter} from '@/domain/api/converter/notification';
import {NotificationService} from '@/domain/service/notificationService';

export const register = async (context) => {
    try {
        await NotificationService.register();

        let data = NotificationService.getRegistrationData();
        let deviceData = {
            data: {
                token: data.registrationId,
                type: data.registrationType,
                platform: window.device ? window.device.platform : 'browser',
                isCompany: context.rootState.user.flags.isCompany
            }
        };

        context.commit('updatePushId', deviceData.data.token);
        context.commit('updatePushType', deviceData.data.type);

        await HTTP.post(API_BASE_URL + '/notification/register', deviceData);
        loadNotifications(context);
    } catch (e) {
        console.error('Push register error', e);
    }
};

export const markBulkAsRead = (context, notifications) => {
    if (NotificationService.hasRegistrationData()) {
        notifications.forEach(item => context.commit('markAsRead', item));
        return HTTP.post(API_BASE_URL + `/notification/${NotificationService.getRegistrationData().registrationId}/read`, {data: {notifications: notifications.map(item => item.id)}});
    } else {
        console.error('loadNotifications failed, because registrationData is unset');
        return Promise.reject();
    }
};

export const deleteNotification = async (context, {notificationId}) => {
    if (NotificationService.hasRegistrationData()) {
        context.commit('deleteMessage', {messageId: notificationId})
        await HTTP.delete(API_BASE_URL + `/notification-registration/${NotificationService.getRegistrationData().registrationId}/notification/${notificationId}`);
    } else {
        console.error('loadNotifications failed, because registrationData is unset');
        throw new Error('Delete failed, do notification registration found');
    }
};

export const loadNotifications = (context) => {
    return new Promise((resolve, reject) => {
        if (NotificationService.hasRegistrationData() && NotificationService.getRegistrationData().registrationId) {
            HTTP.get(API_BASE_URL + `/notification/${NotificationService.getRegistrationData().registrationId}`)
                .then(response => {
                    context.commit('updateMessage', NotificationConverter.fromApiCollection(response.data.data));
                    resolve();
                })
                .catch(error => reject(error));
        } else {
            console.error('loadNotifications failed, because registrationData is unset', NotificationService.getRegistrationData());
            reject();
        }
    });
};
