export const updateList = (state, items) => {
    if (!Array.isArray(items)) {
        items = Object.values(items);
    }

    items.forEach(item => state.items[item.id] = item);
    state.items = Object.assign({}, state.items);
};

export const updateDownloadInfo = (state, data) => {
    if (state.downloadInfo === null || data === null) {
        state.downloadInfo = data;
    } else {
        state.downloadInfo = {
            ...state.downloadInfo, 
            ...data
        };
    }
}
