function encodeData(data) {
    return Object.keys(data).map(function (key) {
        return [key, data[key]].map(encodeURIComponent).join("=");
    }).join("&");
}

export default {
    install: function (Vue, options) {
        if (typeof Vue.prototype.$c3 !== 'object') {
            Vue.prototype.$c3 = {};
        }

        Vue.prototype.$c3.determineNavigationLink = function (from, to) {
            let mapsUrl = 'https://www.google.com/maps?';

            let uriArguments = {
                saddr: from,
                daddr: to,
            };

            if (uriArguments.saddr && uriArguments.daddr) {
                return mapsUrl + encodeData(uriArguments);
            }
            delete uriArguments.saddr;
            return mapsUrl + encodeData(uriArguments);
        };
    }
};