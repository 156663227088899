import moment from 'moment';
import {BonusCardConfig} from '@/config/bonusCard';

export const BonusCardRedeemMixin = {
    data() {
        return {}
    },
    computed: {
        redeemState() {
            return this.$store.state.bonusCard.bonusCards.redeemState[this.bonusCard.id];
        },
        hasRedeemState() {
            return !!this.redeemState;
        },
        currentCycleLatestRedeem() {
            if (this.currentCycleRedeems.length === 0) {
                return null;
            }
            const latestRedeem = this.currentCycleRedeems[this.currentCycleRedeems.length - 1];
            // Last item in currentCycleRedeems is the latest redeem of the current cycle
            return latestRedeem.created;
        },
        currentCycleRedeems() {
            if (!this.hasRedeemState) {
                return [];
            }

            return this.redeemState.currentCycleRedeems;
        },
        remainingRedeemCount() {
            if (!this.hasRedeemState) {
                return null;
            }

            return this.redeemState.currentCycleRemainingRedeemCount;
        },
        totalBonusCount() {
            return this.hasRedeemState ? this.redeemState.bonusCount : 0;
        },
        isReadyForBonus() {
            return  this.redeemState && 0 === this.redeemState.currentCycleRemainingRedeemCount;
        },
        isRedeemable() {
            if (!this.isPublished || moment() < this.bonusCard.validFrom || moment() > this.bonusCard.validUntil) {
                return false;
            }

            return true;
        },
        isPublished() {
            return this.bonusCard && this.bonusCard.state === BonusCardConfig.STATE_PUBLISHED;
        },
    },
    methods: {
        redeemBonusCard: async function (bonusCardId, qrToken) {
            this.$store.commit('view/showLoading', this.$t('pages.bonusCard.detail.redeemLoading'));
            try {
                let bonusCardRedeem = await this.$store.dispatch('bonusCard/redeem', {bonusCard: bonusCardId, token: qrToken});
                if (bonusCardRedeem.isBonusRedeem) {
                    this.$c3.successAlert(this.$t('pages.bonusCard.detail.collectBonusSuccess.title'), this.$t('pages.bonusCard.detail.collectBonusSuccess.message'));
                } else {
                    this.$c3.successAlert(this.$t('pages.bonusCard.detail.redeemSuccess.title'), this.$t('pages.bonusCard.detail.redeemSuccess.message'));
                }
            } catch (e) {
                this.$c3.handleErrorsWithAlert(e);
                console.error(e);
            }
            this.$store.commit('view/hideLoading');
        },
    },
};
