require('@/constants');

import User from '@/pages/User/Index.vue';
import UserProfileAffiliate from '@/pages/User/Profile/Affiliate.vue';
import UserProfileBaseData from '@/pages/User/Profile/BaseData.vue';
import UserProfileFavorite from '@/pages/User/Profile/FavoriteCouponList.vue';

export default [
    {
        path: '/user',
        name: 'User',
        component: User,
        meta: {
            auth: {
                requiredRole: USER_ROLE.USER,
            }
        },
        children: [
            {
                path: 'affiliate',
                name: 'UserProfileAffiliate',
                component: UserProfileAffiliate
            },
            {
                path: 'base-data',
                name: 'UserProfileBaseData',
                component: UserProfileBaseData,
            },
            {
                path: 'favorite',
                name: 'UserProfileFavorite',
                component: UserProfileFavorite,
            },
        ]
    }
];