<template>
    <div class="c3-main">
        <div class="c3-page-content c3-page-content-no-spacing">
            <div class="c3-profile-page">
                <div class="c3-profile-page-header">
                    <slot name="header">
                        <c3-title-with-subtitle v-if="title || subtitle" :title="title" :subtitle="subtitle"/>
                    </slot>
                </div>
                <div class="c3-profile-page-content">
                    <slot></slot>
                </div>
                <div class="c3-profile-page-footer" v-if="footerButton.length || $slots.footer">
                    <slot name="footer">
                        <c3-button-list :button="footerButton"/>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import C3ButtonList from '@/components/layout/button/list/Index';
    import C3TitleWithSubtitle from '@/components/content/text/TitleWithSubtitle';

    export default {
        components: {
            C3ButtonList,
            C3TitleWithSubtitle
        },
        props: {
            updateUserOnCreated: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
            },
            subtitle: {
                type: String,
            },
            footerButton: {
                type: Array,
                default: () => []
            }
        },
        methods: {},
        created() {
            if (this.updateUserOnCreated) {
                this.$store.commit('view/showLoading');
                this.$store.dispatch('user/loadCurrentUser')
                    .then(() => {
                        this.$emit('userReady');
                        this.$store.commit('view/hideLoading');
                    })
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-main {
        background-color: $background-color-alternative-section;
    }

    .c3-profile-page-header {
        padding: var(--page-content-horizontal-big-spacing) var(--page-content-horizontal-big-spacing) 0 var(--page-content-horizontal-big-spacing);
    }

    .c3-profile-page-content {
        padding: var(--page-content-horizontal-big-spacing);
    }

    .c3-profile-page-footer {
        padding: 15px var(--page-content-horizontal-big-spacing) var(--page-content-horizontal-big-spacing) var(--page-content-horizontal-big-spacing);
    }
</style>