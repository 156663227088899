<template>
    <div>
        <c3-profile-content-page :title="$t('customer.profileTitle')" :subtitle="$t('pages.invoice.label')">
            <div class="c3-invoice__list">
                <template v-if="invoices.length > 0">
                    <c3-item v-for="invoice in invoices" :key="invoice.id" :invoice="invoice"/>
                </template>
                <c3-no-item v-else :title="$t('label.noInvoice')" icon="c3-icon-file-invoice"/>
            </div>
        </c3-profile-content-page>
        <portal to="invoice_download" v-if="downloadInfo!==null">
            <c3-download-drawer
                :fileName="downloadInfo.fileName"
                :fileUrl="downloadInfo.fileUrl"
                :isDownloading="downloadInfo.isDownloading"
                :isFailed="downloadInfo.isFailed"
            />
        </portal>
    </div>
</template>

<script>
    import C3Item from '@/components/content/invoice/Item.vue';
    import C3NoItem from '@/components/content/invoice/NoItem.vue';
    import C3ProfileContentPage from '@/components/content/profile/ContentPage';
    import C3DownloadDrawer from '@/components/content/invoice/DownloadDrawer.vue'

    export default {
        name: 'Invoices',
        components: {
            C3Item,
            C3NoItem,
            C3ProfileContentPage,
            C3DownloadDrawer
        },
        computed: {
            invoices() {
                return this.$store.getters['invoice/all'];
            },
            downloadInfo() {
                return this.$store.getters['invoice/downloadInfo'];
            }
        },
        async beforeMount() {
            if (this.currentUser) {
                this.$store.commit('view/showLoading');
                try {
                    await this.$store.dispatch('invoice/loadAllInvoice', this.currentUser.id)
                } catch (error) {
                    console.error('Load invoice failed', error);
                }
                this.$store.commit('view/hideLoading');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .c3-invoice__list {
        margin-top: -15px;
    }

    .c3-invoice__list-item {
        margin-top: 15px;
    }
</style>
