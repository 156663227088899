<template>
    <div class="c3-page-version-constraint-failed" v-if="!versionConstraint">
        <div>
            {{ $t('outdated.title') }}
            <br><br>
            <c3-external-link v-if="isAndroid" to="https://play.google.com/store/apps/details?id=com.coupocket">
                {{ $t('outdated.android') }}
            </c3-external-link>
            <c3-external-link v-else-if="isIOS" href="https://itunes.apple.com/us/app/coupocket/id1438661478">
                {{ $t('outdated.ios') }}
            </c3-external-link>
            <a v-else @click="reload">
                {{ $t('outdated.button') }}
            </a>
        </div>
    </div>
</template>

<script>
    import C3ExternalLink from '@/components/link/ExternalLink';

    export default {
        components: {
            C3ExternalLink
        },
        computed: {
            versionConstraint() {
                if (!this.$store.getters['general/apiCheck']) {
                    return true;
                }
                return this.$store.getters['general/apiCheck'].versionConstraint;
            },
        },
        methods: {
            reload() {
                window.location.reload(true);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .c3-page-version-constraint-failed {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99999;
        padding: 30px;
        background-color: rgba(255, 255, 255, 0.9);
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
            margin-bottom: 30px;
        }

        a {
            color: $quinary-color;
        }
    }
</style>