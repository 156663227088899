import Vue from 'vue';

export const updateShop = (state, items) => {
    items.forEach(item => {
        Vue.set(state.shops.items, item.id, item);
    });
};

export const removeOneShop = (state, item) => {
    if (state.shops.items.hasOwnProperty(item.id)) {
        Vue.delete(state.shops.items, item.id);
    }
};
