import baseApiConverter from '@/domain/api/converter/base';

export const BonusCardRedeemConverter = {
    fromApi: function (item) {
        item = baseApiConverter.modifiedAndCreatedFromApi(item);
        return item;
    },
    fromApiCollection: function (items) {
        return items.map(item => this.fromApi(item));
    },
    toApi: function (item) {
        return item;
    }
};
