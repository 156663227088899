import Vue from 'vue';

export const updatePageHeaderState = (state, headerState) => {
    state.pageHeaderState = headerState;
};

export const updatePageFooterState = (state, footerState) => {
    state.pageFooterState = footerState;
};

export const updatePageHeaderLeft = (state, pageHeaderLeft) => {
    state.pageHeaderLeft = pageHeaderLeft;
};

export const updatePageHeaderInnerRight = (state, pageHeaderInnerRight) => {
    state.pageHeaderInnerRight = pageHeaderInnerRight;
};

export const updatePageHeaderRight = (state, pageHeaderRight) => {
    state.pageHeaderRight = pageHeaderRight;
};

export const showLoading = (state, message) => {
    if (typeof message === 'string' && message.length) {
        state.loadingMessage = message;
    } else {
        state.loadingMessage = '';
    }
    state.isLoading = true;
};

export const hideLoading = (state) => {
    state.isLoading = false;
};

export const saveScrollPosition = (state, data) => {
    Vue.set(state.scrollPosition, data.name, data.position);
};

export const addDrawer = (state, name) => {
    if (-1 === state.activeDrawers.indexOf(name)) {
        state.activeDrawers.push(name);
        document.body.classList.add('c3-drawer-open');
    }
};

export const removeDrawer = (state, name) => {
    let index = state.activeDrawers.indexOf(name);

    if (index > -1) {
        state.activeDrawers.splice(index, 1);
    }

    if (0 === state.activeDrawers.length) {
        document.body.classList.remove('c3-drawer-open');
    }
};

export const removeLatestDrawer = (state) => {
    if (state.activeDrawers.length) {
        state.activeDrawers.pop();
    }

    if (0 === state.activeDrawers.length) {
        document.body.classList.remove('c3-drawer-open');
    }
};

export const removeDrawerAll = (state) => {
    state.activeDrawers.splice(0, state.activeDrawers.length);
    document.body.classList.remove('c3-drawer-open');
};

export const showBottomDrawer = (state, name) => {
    state.activeBottomDrawer = name;
    document.body.classList.add('c3-bottom-drawer-open');

};

export const hideBottomDrawer = (state) => {
    state.activeBottomDrawer = null;
    document.body.classList.remove('c3-bottom-drawer-open');
};