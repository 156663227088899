<template>
    <div class="c3-page-header-icon-wrapper c3-message-list-icon">
        <c3-icon-envelope primaryColor="white"/>
        <div v-if="activeMessageCount" class="c3-message-list-icon-active-count"><span>{{activeMessageCount}}</span></div>
    </div>
</template>

<script>
    import {Config} from '@/config';

    export default {
        components: {},
        computed: {
            activeMessageCount() {
                let count = this.$store.getters['notification/countUnreadNotification'];
                if (Config.isNativeRealDevice()) {
                    window.FirebasePlugin.setBadgeNumber(count);
                }
                return count > 9 ? '9+' : count;
            }
        },
    };
</script>

<style lang="scss" scoped>
    $bubble-size: 20px;
    .c3-message-list-icon {
        position: relative;
    }

    .c3-icon-wrapper {
        height: 100%;
    }

    .c3-message-list-icon-active-count {
        position: absolute;
        top: -1 * $bubble-size / 2;
        right: -1 * $bubble-size / 2;
        height: $bubble-size;
        width: $bubble-size;
        background: $tertiary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        font-size: 12px;
    }
</style>