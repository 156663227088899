<template>
    <div class="c3-image-wrapper">
        <picture>
            <source v-for="source in sources" :media="source.media" :srcset="source.srcset">
            <img class="c3-image" :src="fallbackSrc"/>
        </picture>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String
            }
        },
        computed: {
            images() {
                return require('@/assets/images/pages/' + this.name + '.jpg').images;
            },
            fallbackSrc() {
                return this.images[this.images.length - 1].path;
            },
            sources() {
                return this.images.map(image => {
                    return {
                        media: '(max-width: ' + image.width + 'px)',
                        srcset: image.path
                    }
                });
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-image-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: percentage(1 / $image-aspect-ratio-default);
    }

    .c3-image {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>