<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path :fill="color1"
                  d="M457.4 9.4l-50.1 50.1C365.4 27.2 312.8 8 255.8 8 119.2 8.1 7.7 119.8 8 256.5S119.2 504 256 504c63.9 0 122.2-24.2 166.2-63.9 5.1-4.6 5.4-12.6.5-17.4l-7.1-7.1c-4.5-4.5-11.7-4.7-16.5-.5-39 35.1-89.4 54.9-143.1 54.9-118 0-214-95.6-214-214 0-118 95.6-214 214-214 46.5 0 90.7 14.9 127 41.7l-53.6 53.6c-20.1 20.1-5.9 54.6 22.6 54.6h128c17.7 0 32-14.3 32-32V32c0-28.5-34.6-42.7-54.6-22.6zM480 160H352L480 32z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
