import {HTTP} from '@/domain/api/http';
import invoiceApiConverter from '@/domain/api/converter/invoice';

export const loadAllInvoice = (context, userId) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/user/' + userId + '/invoice')
            .then(response => {
                const invoices = invoiceApiConverter.fromApiCollection(response.data.data);
                context.commit('updateList', invoices);
                resolve(invoices);
            })
            .catch(error => reject(error));
    });
};

export const loadOneInvoiceAsPdf = async (context, {userId, invoiceId, isNative}) => {
    const response = await HTTP.get(API_BASE_URL + `/user/${userId}/invoice/${invoiceId}/export?format=pdf`, {responseType: 'blob'});
    const blob = new Blob([response.data], {type: 'application/pdf'});
    if (isNative)
        return blob;
    return window.URL.createObjectURL(blob);
};
