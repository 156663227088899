export const pageHeaderTitle = (state) => {
    return state.pageHeaderTitle;
};

export const drawerOpen = (state) => (name) => {
    return state.activeDrawers.indexOf(name) > -1;
};

export const drawerZIndex = (state) => (name) => {
    if (!drawerOpen(state)(name)) {
        return -1;
    }

    let index = state.activeDrawers.indexOf(name);
    return VIEW.DRAWER_BASE_Z_INDEX + (index * 5);
};

export const bottomDrawerOpen = (state) => (name) => {
    return state.activeBottomDrawer === name;
};