import moment from 'moment';

import baseApiConverter from '@/domain/api/converter/base';

export default {
    fromApi: function (redeemState) {
        redeemState = baseApiConverter.modifiedAndCreatedFromApi(redeemState);
        if (redeemState.hasOwnProperty('lastRedeem') && typeof redeemState.lastRedeem === 'string' && redeemState.lastRedeem.length) {
            redeemState.lastRedeem = moment(redeemState.lastRedeem);
        }
        return redeemState;
    },
    fromApiCollection: function (redeemStates) {
        return redeemStates.map(redeemState => this.fromApi(redeemState));
    },
    toApi: function (redeemState) {
        return redeemState;
    }
};