<template>
    <div class="c3-background-image-wrapper">
        <slot>
            <div class="c3-background-image" :style="style"></div>
        </slot>
    </div>
</template>

<script>

  export default {
    props: {
      id: String,
      size: {
        type: Number,
        default: 600
      },
      responsive: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      style () {
        let styles = {};
        if (null !== this.src) {
          styles.backgroundImage = 'url(' + this.src + ')';
        }
        return styles;
      },
      src () {
        let userToken = this.$store.getters['user/token'];
        if (userToken && this.id && this.size) {
          return API_BASE_IMAGE_URL + `/${this.id}/${this.size}/${userToken}`;
        }
        return null;
      }
    },
  };
</script>