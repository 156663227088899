export let CoupocketException = function (title, message) {
    this.message = message;
    this.stack = (new Error()).stack;
    this.getTitle = () => title;
    this.getMessage = () => message;
};

CoupocketException.prototype = Object.create(Error.prototype);
CoupocketException.prototype.name = "CoupocketException";
CoupocketException.prototype.constructor = CoupocketException;
