<template>
    <div></div>
</template>

<script>
    export default {
        async created() {
            try {
                await this.$store.dispatch('user/logout');
                this.$c3.successAlert('', 'success.logout.message');
                if (this.$route.query.redirectAfterLogout) {
                    this.$router.push(this.$route.query.redirectAfterLogout);
                } else {
                    this.$router.push({name: 'Home'});
                }
            } catch (e) {
                console.error('Logout Failed', e);
                this.$c3.errorAlert('error.generic.title', 'error.generic.message');
            }
        }
    };
</script>