import moment from 'moment';

export const CouponStateMixin = {
    computed: {
        isStateCreated() {
            return this.coupon && this.coupon.state === COUPON_STATE.CREATED;
        },
        isStateDraft() {
            return this.coupon && this.coupon.state === COUPON_STATE.DRAFT;
        },
        isStateReview() {
            return this.coupon && this.coupon.state === COUPON_STATE.REVIEW;
        },
        isStatePublished() {
            return this.coupon && this.coupon.state === COUPON_STATE.PUBLISHED;
        },
        isStatePublishedAndExpired() {
            return this.coupon && this.coupon.state === COUPON_STATE.PUBLISHED && this.coupon.validUntil < moment();
        },
        isDeletable() {
            return this.isStateDraft || (this.isStatePublished && !this.isStatePublishedAndExpired);
        }
    },
};
