import {Config} from '@/config';

export default [
    {
        path: '/bonus-card',
        component: () => import(/* webpackChunkName: "BonusCard" */'@/pages/BonusCard/Index'),
        children: [
            {
                path: 'list',
                alias: '/',
                name: 'BonusCardList',
                component: () => import(/* webpackChunkName: "BonusCard" */'@/pages/BonusCard/List'),
                meta: {
                    pageHeaderInnerRight: {
                        icon: 'c3-icon-redo',
                        event: EVENT.REFRESH.NAME
                    }
                }
            },
            {
                path: ':id',
                name: 'BonusCardDetail',
                component: () => import(/* webpackChunkName: "BonusCard" */'@/pages/BonusCard/Detail'),
                meta: {
                    pageHeaderRight: Config.isNative() ? {icon: 'c3-icon-share-alt', event: EVENT.BONUS_CARD_SHARE.NAME} : VIEW.PAGE_HEADER_RIGHT_DEFAULT,
                }
            },
        ]
    },
];
