import Page from '@/pages/Page/Index';

export default [
    {
        path: '/page/:id',
        name: 'Page',
        component: Page,
        meta: {
            pageHeaderRight: {
                icon: 'c3-icon-redo',
                event: EVENT.REFRESH.NAME
            }
        }
    },
];
