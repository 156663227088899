import moment from 'moment';
import {DateUtility} from '@/domain/utility/date';

export const OpeningTimeMixin = {
    data() {
        return {}
    },
    computed: {
        currentlyOpen() {
            return this.determineCurrentOpeningEntry !== null;
        },
        determineCurrentOpeningEntry() {
            return this.determineCurrentEntryInSingleDayEntries(this.determineTodayOpeningTimeEntries);
        },
        nextOpeningTimeEvent() {
            let openingTimes = null;
            try {
                openingTimes = this.determineOpeningTimes;
                if (null === openingTimes) {
                    console.debug('No nextOpeningTimeEvent found', openingTimes);
                    return '';
                }

                if (this.currentlyOpen) {
                    let closedAt = this.determineCurrentOpeningEntry.end;
                    let firstTomorrowOpeningTime = this.determineTomorrowOpeningTimeEntries.length ? this.determineTomorrowOpeningTimeEntries[0] : null;

                    // Check if opening overlaps with tomorrow
                    if (this.isMidnight(closedAt) &&
                        null !== firstTomorrowOpeningTime &&
                        this.isValidOpeningTime(firstTomorrowOpeningTime) &&
                        this.isMidnight(firstTomorrowOpeningTime.start)) {
                        if (this.isMidnight(firstTomorrowOpeningTime.end)) {
                            // Doesn't close today AND tomorrow - Show nothing no close date, because it's confusing for the user
                            return '';
                        }

                        return 'schließt um ' + this.formatTimeShort(firstTomorrowOpeningTime.end) + ' Uhr';
                    }
                    return 'schließt um ' + this.formatTimeShort(closedAt) + ' Uhr';
                } else {
                    // Special handling for today
                    let nextEntry = this.determineNextEntryInSingleDayEntries(this.determineTodayOpeningTimeEntries);
                    if (nextEntry) {
                        return 'öffnet um ' + this.formatTimeShort(nextEntry.start) + ' Uhr';
                    }

                    let todayIndex = this.determineTodayIsoWeekday();
                    let nextIndex = this.determineNextIsoWeekday(todayIndex);

                    // Avoid endless loop on error
                    let count = 0;
                    while (todayIndex !== nextIndex && count < 20) {
                        let weekdayName = DATE.ISO_WEEKDAY_NAME_MAPPING[nextIndex];
                        let dayOpeningTimes = openingTimes[weekdayName];
                        if (weekdayName && Array.isArray(dayOpeningTimes) && dayOpeningTimes[0] && moment.isMoment(dayOpeningTimes[0].start)) {
                            return 'öffnet ' + this.$t('weekdays.short.' + weekdayName) + ', ' + dayOpeningTimes[0].start.format('H:mm') + ' Uhr';
                        }

                        count++;
                        nextIndex = this.determineNextIsoWeekday(nextIndex);
                    }
                }
            } catch (e) {
                console.error('Exception in nextOpeningTimeEvent', e);
            }
            console.debug('No nextOpeningTimeEvent found', openingTimes);
            return '';
        },
        determineTodayOpeningTimeEntries() {
            let openingTimes = this.determineOpeningTimes;
            if (!openingTimes) {
                return [];
            }

            let weekdayName = DATE.ISO_WEEKDAY_NAME_MAPPING[this.determineTodayIsoWeekday()];
            if (!openingTimes.hasOwnProperty(weekdayName) || !Array.isArray(openingTimes[weekdayName])) {
                return [];
            }
            return openingTimes[weekdayName];
        },
        determineTomorrowOpeningTimeEntries() {
            let openingTimes = this.determineOpeningTimes;
            if (!openingTimes) {
                return [];
            }

            let weekdayName = DATE.ISO_WEEKDAY_NAME_MAPPING[this.determineNextIsoWeekday(this.determineTodayIsoWeekday())];
            if (!openingTimes.hasOwnProperty(weekdayName) || !Array.isArray(openingTimes[weekdayName])) {
                return [];
            }
            return openingTimes[weekdayName];
        },
        determineOpeningTimes() {
            if (this.openingTimes) {
                return this.openingTimes;
            }

            if (this.customer && this.customer.openingTimes) {
                return this.customer.openingTimes;
            }

            if (this.coupon && this.coupon.user && this.coupon.user.openingTimes) {
                return this.coupon.user.openingTimes;
            }

            if (this.bonusCard && this.bonusCard.user && this.bonusCard.user.openingTimes) {
                return this.bonusCard.user.openingTimes;
            }

            if (this.store && this.store.openingTimes) {
                return this.store.openingTimes;
            }

            return null;
        }
    },
    methods: {
        determineTodayIsoWeekday() {
            return moment().isoWeekday()
        },
        determinePreviousIsoWeekday(isoWeekdayIndex) {
            if (1 > isoWeekdayIndex - 1) {
                return 7;
            }
            return isoWeekdayIndex - 1;
        },
        determineNextIsoWeekday(isoWeekdayIndex) {
            if (isoWeekdayIndex + 1 > 7) {
                return 1;
            }
            return isoWeekdayIndex + 1;
        },
        isMidnight(date) {
            let time = this.formatTime(date);
            return time === '00:00' || time === '24:00';
        },
        isValidOpeningTime(openingTime) {
            return moment.isMoment(openingTime.start) && moment.isMoment(openingTime.end);
        },
        formatOpeningTime(openingTime) {
            let start = this.formatTime(openingTime.start);
            let end = this.formatTime(openingTime.end);

            return start + ' - ' + end;
        },
        formatTime(date) {
            let time = date.format('HH:mm');
            if (time === '23:59') {
                time = '24:00';
            }
            return time;
        },
        formatTimeShort(date) {
            let time = date.format('H:mm');
            if (time === '23:59') {
                time = '24:00';
            }
            return time;
        },
        determineCurrentEntryInSingleDayEntries(dayEntries) {
            if (!Array.isArray(dayEntries)) {
                return null;
            }
            let minutesOfDay = DateUtility.convertDateToMinutes(moment());

            let currentEntry = null;
            dayEntries.forEach(entry => {
                let startMinutes = DateUtility.convertDateToMinutes(entry.start);
                let endMinutes = DateUtility.convertDateToMinutes(entry.end);
                if (startMinutes <= minutesOfDay && minutesOfDay <= endMinutes) {
                    currentEntry = entry;
                }
            });
            return currentEntry;
        },
        determineNextEntryInSingleDayEntries(dayEntries) {
            let minutesOfDay = DateUtility.convertDateToMinutes(moment());

            let nextEntry = null;
            dayEntries.forEach(entry => {
                let startMinutes = DateUtility.convertDateToMinutes(entry.start);
                if (nextEntry === null && startMinutes > minutesOfDay) {
                    nextEntry = entry;
                }
            });
            return nextEntry;
        }
    },
};
