<template>
    <div class="c3-main" ref="scrollContainer">
        <div class="c3-page-content">
            <c3-coupon-list :items="items" :showStaticItems="true" :name="$route.name">
                <template slot="header">
                    <c3-coupon-list-header :name="$route.name" :title="title" :filter="true"/>
                    <c3-coupon-slider v-show="latestItems.length" :title="$t('pages.coupon.detail.latestCoupons')" :items="latestItems"/>
                </template>
            </c3-coupon-list>
        </div>
    </div>
</template>

<script>
    import C3CouponList from '@/components/content/coupon/list/Index';
    import C3CouponListHeader from '@/components/content/coupon/list/Header';
    import C3CouponSlider from '@/components/content/coupon/slider/Index';


    function chunkArray(array, size) {
        if (!Array.isArray(array) || 0 === array.length) {
            return [];
        }
        // Shallow clone
        array = array.slice();
        let results = [];
        while (array.length) {
            results.push(array.splice(0, size));
        }
        return results;
    }

    export default {
        name: 'CouponList',
        components: {
            C3CouponList,
            C3CouponListHeader,
            C3CouponSlider
        },
        data() {
            return {
                fallbackItems: [],
                fallbackLatestItems: [],
                isLoading: false,
            }
        },
        computed: {
            title() {
                let title = this.$t('pages.coupon.list.title');
                if (this.currentUser.firstName) {
                    title += ',<br>' + this.currentUser.firstName + '!';
                }
                return title;
            },
            items() {
                let items = this.$store.getters['viewCouponList/items'](this.$route.name);
                items = items.map(item => this.fillItem(item));
                if (items.length) {
                    // Update fallback items
                    this.fallbackItems = items;
                } else if (this.isLoading) {
                    // Use fallback items, because store delivers an empty list and we currently load new coupons
                    // This avoids an empty list, after position change (position changed filter, but coupons are not loaded yet)
                    items = this.fallbackItems;
                }
                return items;
            },
            latestItems() {
                let items = this.$store.getters['viewCouponList/latestItems'](this.$route.name);

                let numberOfItems = 5;
                if (window.innerWidth >= 1024) {
                    // Fill width on big screens
                    numberOfItems = 15;
                }

                if (items.length > numberOfItems) {
                    // Build chunks with size NUMBER_OF_ITEMS
                    let numberOfChunks = Math.floor(items.length / numberOfItems);
                    items = items.slice(0, numberOfChunks * numberOfItems);
                    let chunks = chunkArray(items, numberOfItems);
                    // Select random chunk
                    items = chunks[Math.floor(Math.random() * chunks.length)];
                }


                items = items.map(item => this.fillItem(item));
                if (items.length) {
                    // Update fallback items
                    this.fallbackLatestItems = items;
                } else if (this.isLoading) {
                    // Use fallback items, because store delivers an empty list and we currently load new coupons
                    // This avoids an empty list, after position change (position changed filter, but coupons are not loaded yet)
                    items = this.fallbackLatestItems;
                }
                return items;
            }
        },
        methods: {
            async loadData(showLoading, forceReload) {
                this.isLoading = true;
                if (showLoading) {
                    this.$store.commit('view/showLoading', this.$t('coupon.loadingList'));
                }

                try {
                    // Wait for position update, otherwise the position could change AFTER we load the coupons we causes an empty list
                    if (showLoading && this.$store.getters['viewCouponList/useCurrentLocation'](this.$route.name)) {
                        await this.$store.dispatch('viewCouponList/position', {name: this.$route.name, addressTimeout: 2500});
                    } else {
                        await this.$store.dispatch('viewCouponList/position', {name: this.$route.name});
                    }
                } catch (e) {
                    console.error('[Coupon/List] loadData failed', e);
                }

                try {
                    await this.$store.dispatch('viewCouponList/loadItems', {name: this.$route.name, forceReload: forceReload});
                } catch (e) {
                    console.error('[Coupon/List] loadData failed', e);
                }

                this.isLoading = false;
                if (showLoading) {
                    this.$store.commit('view/hideLoading');
                }
            },
            async loadInitial() {
                if (0 === this.items.length) {
                    await this.loadData(true);
                    this.restoreScrollPosition();
                } else {
                    this.restoreScrollPosition();
                    await this.loadData(false);
                }
            },
            fillItem(item) {
                let coupon = this.$store.getters['coupon/singleCoupon'](item.id);
                return {
                    ...coupon,
                    ...item
                };
            },
            restoreScrollPosition() {
                let scrollContainer = this.$refs.scrollContainer;
                if (null !== scrollContainer && this.$store.state.view.scrollPosition[this.$route.name]) {
                    scrollContainer.scrollTop = this.$store.state.view.scrollPosition[this.$route.name];
                }
            }
        },
        mounted() {
            this.loadInitial();
        },
        beforeRouteLeave(to, from, next) {
            let scrollContainer = this.$refs.scrollContainer;
            if (null !== scrollContainer) {
                this.$store.commit('view/saveScrollPosition', {name: [this.$route.name], position: scrollContainer.scrollTop});
            }
            next();
        },
        events: {
            [EVENT.REFRESH.NAME]: {
                async handler() {
                    await this.loadData(true, true);
                    try {
                        // Jump to top
                        this.$refs.scrollContainer.scrollTop = 0;
                    } catch (e) {
                        console.error('Reset scrollPosition to 0 failed', e);
                    }
                },
                priority: EVENT.REFRESH.PRIORITY.COUPON_LIST
            },
        },
    };
</script>
<style lang="scss" scoped>
    .c3-main {
        background-color: $nonary-color;
    }

    .c3-coupon-slider {
        padding-top: calc(var(--page-content-horizontal-spacing) / 2);
        padding-bottom: calc(var(--page-content-horizontal-spacing) / 2);
        border-bottom: calc(var(--page-content-horizontal-spacing) / 2) solid transparent;
        background-clip: padding-box;

        background-color: $eighth-color;
    }

    .c3-page-content /deep/ .c3-coupon-list-title {
        padding-top: 15px;
        padding-bottom: 15px;

        h2 {
            font-weight: 600;
            font-size: 30px;
        }
    }

    .c3-coupon-list-wrapper {
        margin-top: 15px;
    }
</style>
