export const TYPE_NORMAL = 'Normal';
export const TYPE_QR = 'QR';
export const TYPE_BARCODE = 'Barcode';


export const COUPON_TYPES = [
    TYPE_NORMAL,
    TYPE_QR,
    TYPE_BARCODE
];

export const BONUS_CARD_TYPES = [
    TYPE_NORMAL,
    TYPE_QR,
    TYPE_BARCODE
];
