export const currentUserStoreListOrderByZip = (state, getters, rootState, rootGetters) => {
    return getters.storeListOrderByZipByUser(rootGetters['user/user'].id);
};

export const storeListOrderByZipByUser = (state) => (userId) => {
    const stores = Object.values(state.stores.items).filter((item) => item.user === userId);
    stores.sort((a, b) => String(a.address.zip).localeCompare(String(b.address.zip)));
    return stores;
};

export const storeCountByUser = (state, getters) => (userId) => {
    return getters.storeListOrderByZipByUser(userId).length;
}

export const storeById = (state) => (id) => {
    if (state.stores.items.hasOwnProperty(id)) {
        return state.stores.items[id];
    }

    return null;
};
