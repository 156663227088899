import baseApiConverter from '@/domain/api/converter/base';

export default {
    fromApi: function (membership) {
        membership = baseApiConverter.modifiedAndCreatedFromApi(membership);
        return membership;
    },
    fromApiCollection: function (memberships) {
        return memberships.map(membership => this.fromApi(membership));
    },
    toApi: function (membership) {
        return membership;
    }
};