import Vue from 'vue';

export const updateStore = (state, items) => {
    items.forEach(item => {
        Vue.set(state.stores.items, item.id, item);
    });
};

export const removeOneStore = (state, item) => {
    if (state.stores.items.hasOwnProperty(item.id)) {
        Vue.delete(state.stores.items, item.id);
    }
};
