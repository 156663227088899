<template>
    <router-link :to="{name:'CouponDetail',params:{id:item.id}}" class="c3-coupon-slider__item">
        <c3-item-media :item="coupon" :isFavorite="isFavorite" @toggleFavorite="toggleFavorite" :additionalInformation="distance">
            <img :src="imageSrc" slot="image" :alt="coupon.title" class="c3-coupon-image"/>
        </c3-item-media>
        <div class="c3-coupon-slider__item-content">
            <h2 class="c3-coupon-slider__item-title">
                {{ coupon.titleWithTemplate }}
            </h2>
            <div class="c3-coupon-slider__item-company">{{coupon.shop ? coupon.shop.name : coupon.user.company}}</div>
            <div class="c3-coupon-slider__item-address">{{coupon.user.zip}} {{coupon.user.city}}, {{coupon.user.street}}</div>
        </div>
    </router-link>
</template>

<script>
    import {CouponFavoriteMixin} from '@/domain/mixin/local/couponFavorite';

    import C3ItemMedia from '@/components/content/coupon/ItemMedia.vue';

    export default {
        mixins: [CouponFavoriteMixin],
        components: {
            C3ItemMedia,
        },
        props: {
            item: Object,
            enableEdit: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },
        computed: {
            coupon() {
                // Do not mutate the original coupon
                let coupon = Object.assign({}, this.item);
                coupon.user = this.$store.getters['customer/singleCustomer'](this.item.user, {});
                return coupon;
            },
            distance() {
                let distance = null;
                if (this.coupon.distance) {
                    distance = this.coupon.distance / 1000;
                    if (distance >= 100) {
                        distance = distance.toFixed(0) + ' km';
                    } else if (distance >= 10) {
                        distance = distance.toFixed(1) + ' km';
                    } else {
                        distance = distance.toFixed(2) + ' km';
                    }
                }
                return distance;
            },
            imageSrc() {
                return MEDIA.COUPON_BASE_URL + '/375/' + this.coupon.image;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .c3-coupon-slider__item {
        display: block;

        & /deep/ .c3-coupon-item-action-list-item {
            padding-top: 15px;
            padding-right: 15px;
            height: 45px;
        }
    }

    .c3-coupon-item-media {
        overflow: hidden;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @include boxShadow();
    }

    .c3-coupon-slider__item-content {
        padding: 10px 15px 15px 10px;
        background-color: $white-color;

        overflow: hidden;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @include boxShadow();
    }

    .c3-coupon-slider__item-title {
        @include openSansBold();
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .c3-coupon-slider__item-company {
        margin-top: 5px;

        @include openSansSemiBold();
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .c3-coupon-slider__item-address {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>
