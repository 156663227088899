<template>
    <c3-field-wrapper>
            <textarea :value="value" @input="$emit('input', $event.target.value)"
                      :maxLength="maxLength"
                      @focus="hasFocus = true" @blur="hasFocus = false"
                      :id="id" :autocomplete="autocomplete" class="c3-form-field-textarea"
                      :placeholder="placeholder"></textarea>
        <div class="c3-form-field-input__counter" v-if="maxLength">{{value.length}}/{{maxLength}} {{ $t('label.chars') }}</div>
    </c3-field-wrapper>
</template>

<script>
    import BaseField from '@/components/form/fields/BaseField.vue';
    import C3FieldWrapper from '@/components/form/FieldWrapper.vue';
    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';

    export default {
        extends: BaseField,
        mixins: [FormFieldErrorMixin],
        components: {
            C3FieldWrapper,
        },
        props: {
            placeholder: {
                type: String
            },
            errorMessage: {
                type: String,
                default: 'error.invalidValue'
            },
            errorIcon: {
                default: false
            },
            maxLength: {
                type: [String, Number, Boolean],
                default: false,
            }
        },
        data() {
            return {
                hasFocus: false,
                type: 'textarea'
            }
        },
        computed: {
            isEmpty() {
                return 0 === this.value.length;
            },
            cssClasses() {
                return {
                    'c3-form-field-group-input--not-empty': !this.isEmpty,
                    'c3-form-field-group-input--focus': this.hasFocus
                };
            }
        },
    };
</script>

<style lang="scss" scoped>

</style>
