import Vue from 'vue';

export const updateCustomer = (state, customers) => {
    customers.forEach(item => state.customers[item.id] = item);
    state.customers = Object.assign({}, state.customers);
};

export const updateSingleCustomer = (state, customer) => {
    Vue.set(state.customers, customer.id, customer);
};

export const qrToken = (state, qrToken) => {
    state.qrToken = qrToken;
};
