import {Config} from '@/config';

window.API_BASE_URL = Config.getApiUrl();

window.BRANCH_TRACKING = {
    INSTALL_FRIEND: 'https://bn.coupocket.com/2XFMtqxslS',
    INSTALL_COMPANY: 'https://bn.coupocket.com/DK35vLPCkS',
};

console.debug('Determined base constants', Config.getEnvironment(), Config.getApiUrl(), Config.getVersion(), Config.getApiVersion());

window.COLORS = {
    TRANSPARENT: 'transparent',
    WHITE: '#FFFFFF',

    PRIMARY: '#333333',
    SECONDARY: '#F3F2F2',
    TERTIARY: '#E51A1A',
    QUATERNARY: '#222222',
    QUINARY: '#009AAD',
    SENARY: '#707070',
    SEPTENARY: '#72B6AE',
    DISABLED: '#DBDBDB'
};

window.PLATFORM = {
    ANDROID: 'Android',
    BROWSER: 'browser',
    IOS: 'iOS',
};

window.DATE = {
    FORMAT: {
        DATE_SHORT: 'DD.MM.YY',
        DATE_LONG: 'DD.MM.YYYY',
        DATETIME_LONG: 'DD.MM.YYYY - HH:mm',
        DATETIME_SHORT: 'DD.MM.YY HH:mm',
    },
    WEEKDAYS: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    ISO_WEEKDAY_NAME_MAPPING: {
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday',
        7: 'sunday'
    }
};

window.MEDIA = {
    // Order is important! Always small to big
    SIZES: [375, 414, 768, 1024, 1200, 1920],
    SHARE_SIZE: 768,
    COUPON_BASE_URL: window.API_BASE_URL + '/user_media/coupon',
    CUSTOMER_IMAGE_BASE_URL: window.API_BASE_URL + '/user_media/customer',
};

window.EVENT = {
    BACK_BUTTON: {
        NAME: 'C3_BACK_BUTTON',
        PRIORITY: {
            APP: -10
        }
    },
    REFRESH: {
        NAME: 'C3_REFRESH',
        PRIORITY: {
            COUPON_LIST: 10,
            BONUS_CARD_LIST: 10,
            PAGE: 10
        }
    },
    COUPON_FILTER: {
        NAME: 'C3_COUPON_FILTER',
        PRIORITY: {
            COUPON_LIST: 10
        }
    },
    COUPON_ADD: {
        NAME: 'C3_COUPON_ADD',
        PRIORITY: {
            APP: -10
        }
    },
    BONUS_CARD_ADD: {
        NAME: 'C3_BONUS_CARD_ADD',
        PRIORITY: {
            APP: -10
        }
    },
    BONUS_CARD_SHARE: {
        NAME: 'C3_BONUS_CARD_SHARE',
        PRIORITY: {
            BONUS_CARD_DETAIL: 10,
        }
    },
    SHOW_MESSAGE: {
        NAME: 'C3_SHOW_MESSAGE',
        PRIORITY: {
            APP: -10
        }
    }
};

window.VIEW = {
    DRAWER_BASE_Z_INDEX: 4000,
    PAGE_HEADER_LEFT_DEFAULT: {icon: 'c3-icon-chevron-left', event: window.EVENT.BACK_BUTTON.NAME},
    PAGE_HEADER_INNER_RIGHT_DEFAULT: null,
    PAGE_HEADER_RIGHT_DEFAULT: {icon: 'c3-message-list-icon', event: window.EVENT.SHOW_MESSAGE.NAME},
};

window.DRAWER = {
    MAIN_NAVIGATION: 'mainNavigationDrawer',
    CUSTOMER_MY_COUPON_NAVIGATION: 'customerMyCouponNavigation',
    CUSTOMER_MY_BONUS_CARD_NAVIGATION: 'customerMyBonusCardNavigation',
    COUPON_FILTER_DRAWER: 'couponFilterDrawer',
    COUPON_LOCATION_DRAWER: 'couponLocationDrawer',
    BONUS_CARD_LOCATION_DRAWER: 'bonusCardLocationDrawer'
};

window.ROUTER = {
    defaultUserRegistrationRedirect: {name: 'Home'},
    defaultLoginRedirect: {name: 'Home'}
};

window.POSITION = {
    ERROR_STATE: {
        // Errors states of navigator.geolocation: https://developer.mozilla.org/en-US/docs/Web/API/PositionError
        PERMISSION_DENIED: 1,
        POSITION_UNAVAILABLE: 2,
        TIMEOUT: 3
    },
    // Do not use exact coordinate for caching
    // 3 decimals are enough (111m precision) for us: https://en.wikipedia.org/wiki/Decimal_degrees
    PRECISION: 3
};

window.USER_ROLE = {
    USER: 'ROLE_USER',
    CUSTOMER: 'ROLE_CUSTOMER',
    SHOPPING_CENTER: 'ROLE_SHOPPING_CENTER',
    ADMIN: 'ROLE_ADMIN',
};

window.USER = {
    DESCRIPTION_LENGTH: 500,
    OPENING_TIMES_COMMENT_LENGTH: 300
};

window.COUPON = {
    DESCRIPTION_LENGTH: 300,
    ONLINE_REDEEM_URL_LENGTH: 200,
    ONLINE_REDEEM_CODE_LENGTH: 200,
};

window.COUPON_STATE = {
    CREATED: 'created',
    DRAFT: 'draft',
    REVIEW: 'review',
    PUBLISHED: 'published'
};

window.PAGE_STATE = {
    default: 'visible',
    visible: 'visible',
    hidden: 'hidden'
};

window.PAYMENT = {
    TYPE: {
        IBAN: 'iban',
        CREDIT_CARD: 'card'
    }
};

window.SHARE = {
    TYPE: {
        COMPANY: 'COMPANY',
        FRIEND: 'FRIEND'
    },
    CONTENT_ID: {
        COMPANY: 4,
        FRIEND: 3
    }
};

window.MESSAGING = {
    TOPIC: {
        ANONYMOUS_COMPANY: 'anonymous_company'
    }
};
