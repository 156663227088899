<template>
    <div class="c3-icon-wrapper">
        <svg version="1.1" viewBox="0 0 501.3 510.83" xmlns="http://www.w3.org/2000/svg">
            <path :fill="color1" d="m242.25 510.83a44.534 44.534 0 0 1-16.836-3.281 44.572 44.572 0 0 1-14.812-9.844l-197.5-197.5a44.443 44.443 0 0 1-9.7-14.487 44.411 44.411 0 0 1-3.4-17.113v-190.3a44.428 44.428 0 0 1 3.512-17.405 44.483 44.483 0 0 1 9.576-14.209 44.5 44.5 0 0 1 14.207-9.577 44.452 44.452 0 0 1 17.4-3.511h190.3a44.441 44.441 0 0 1 17.074 3.4 44.576 44.576 0 0 1 14.527 9.7l18.258 18.249a108.83 108.83 0 0 0-13.613 28.7l-25.746-25.745a14.774 14.774 0 0 0-10.5-4.4h-190.3a14.79 14.79 0 0 0-10.521 4.374 14.8 14.8 0 0 0-4.371 10.525v190.3a14.978 14.978 0 0 0 4.393 10.5l197.5 197.5a14.824 14.824 0 0 0 10.553 4.349 14.812 14.812 0 0 0 10.549-4.349l190.3-190.3a14.694 14.694 0 0 0 4.34-10.519 14.693 14.693 0 0 0-4.44-10.482l-35.25-35.25a108.71 108.71 0 0 0 28.742-13.643l27.705 27.69a44.585 44.585 0 0 1 9.787 14.811 44.711 44.711 0 0 1 3.262 16.827 44.575 44.575 0 0 1-3.262 16.808 44.239 44.239 0 0 1-9.787 14.756l-190.3 190.3a44.55 44.55 0 0 1-14.812 9.844 44.537 44.537 0 0 1-16.835 3.283z"/>
            <path :fill="color1" d="m375.8 9.9522e-6a125.55 125.55 0 1 0 125.5 125.6 125.47 125.47 0 0 0-125.5-125.6zm109.3 125.6a109.3 109.3 0 1 1-109.3-109.3 109.26 109.26 0 0 1 109.3 109.3zm-75.3 44.7-41.1-29.9a6.112 6.112 0 0 1-2.5-4.9v-80.8a6.124 6.124 0 0 1 6.1-6.1h7.1a6.124 6.124 0 0 1 6.1 6.1v74.1l35.7 26a6.078 6.078 0 0 1 1.3 8.5l-4.2 5.7a6.078 6.078 0 0 1-8.5 1.3z"/>
        </svg>

    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>