<template>
    <c3-profile-form-page :updateUserOnCreated="true" @userReady="initialFillUserFromStore" :title="title" :subtitle="subtitle">
        <c3-form @submit="submit">
            <c3-form-input v-model="user.firstName" :label="$t('label.firstname')" :validator="$v.user.firstName"/>
            <c3-form-input v-model="user.lastName" :label="$t('label.lastName')" :validator="$v.user.lastName"/>
            <c3-form-input v-model="user.email" type="email" :label="$t('label.email')" :validator="$v.user.email"/>

            <c3-form-submit :label="$t('label.profileUpdate')" styling="action"/>
            <c3-form-submit :label="$t('label.deleteAccount')" styling="danger" type="button" @click="deleteAccount"/>
        </c3-form>
    </c3-profile-form-page>
</template>

<script>
    import userFactory from '@/domain/factory/user';
    import C3ProfileFormPage from '@/components/content/profile/FormPage';

    const FIELDS = ['firstName', 'lastName', 'email'];

    export default {
        components: {
            C3ProfileFormPage
        },
        data() {
            return {
                user: userFactory.create(FIELDS)
            }
        },
        computed: {
            title() {
                // Use name in vuex to avoid live update of title
                if (this.$store.getters['user/user'].firstName) {
                    return this.$t('label.profileFrom') + ' ' + this.$store.getters['user/user'].firstName;
                }
                return this.$t('label.myProfile');
            },
            subtitle() {
                // Use name in vuex to avoid live update of title
                if (this.$store.getters['user/user'].phone) {
                    return 'Grunddaten (' + this.$store.getters['user/user'].phone + ')';
                }
                return 'Grunddaten';
            }
        },
        methods: {
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$store.commit('view/showLoading');
                let user = Object.assign({}, this.user);
                this.$store.dispatch('user/updateFields', user)
                    .then(() => {
                        this.$c3.successAlert('', 'success.baseData.message');
                    })
                    .catch((error) => {
                        this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                        console.error(error);
                    })
                    .finally(() => this.$store.commit('view/hideLoading'));
            },
            initialFillUserFromStore() {
                let user = this.$store.getters['user/user'];
                FIELDS.forEach(field => {
                    this.user[field] = user[field];
                });
            },
            deleteAccount() {
                this.$c3.confirm(
                    this.$t('confirm.deleteAccount.title'),
                    this.$t('confirm.deleteAccount.text'),
                    async () => {
                        try {
                            this.$store.commit('view/showLoading');
                            await this.$store.dispatch('user/deleteUserAccount');
                            await this.$store.dispatch('user/logout', true);
                            this.$router.push({name: 'Home'});
                        } catch (e) {
                            this.$c3.errorAlert('error.generic.title', 'error.generic.message');
                        }
                        this.$store.commit('view/hideLoading');
                    },
                    null,
                    ['confirm.deleteAccount.button', 'label.cancel']
                );
            },

        },
        validations: {
            user: userFactory.createValidation(FIELDS)
        },
    };
</script>
<style lang="scss" scoped>
    .c3-user-profile {
        background-color: $eighth-color;
    }

    .c3-page-profile {
        padding-top: 15px;
        padding-left: var(--page-content-horizontal-spacing);
        padding-right: var(--page-content-horizontal-spacing);
    }
</style>