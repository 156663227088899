<template>
    <c3-introduction-slider/>
</template>

<script>
    import C3IntroductionSlider from '@/components/introduction/slider/Index';

    export default {
        components: {C3IntroductionSlider},
    };
</script>

<style lang="scss" scoped>

</style>
