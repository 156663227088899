<template>
    <router-link :to="{name:'Home'}" class="c3-page-content-branding">
        <img src="~@/assets/images/logo.png"/>
    </router-link>
</template>

<script>
    export default {}
</script>
<style lang="scss" scoped>
    .c3-page-content-branding {
        display: block;
        padding-top: 50px;
    }

    img {
        display: block;
        width: 200px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .c3-page-content-branding-header {
        margin-top: 5px;
        @include montserratLight();
        font-size: 33px;
        text-align: center;
        line-height: normal;
    }
</style>
