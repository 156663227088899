import Vue from 'vue';

export const update = (state, item) => {
    Vue.set(state.items, item.id, item);
};

export const updateList = (state, items) => {
    if (!Array.isArray(items)) {
        items = Object.values(items);
    }

    items.forEach(item => state.items[item.id] = item);
    state.items = Object.assign({}, state.items);
};

export const updateCategory = (state, categories) => {
    categories.forEach(item => state.categories[item.id] = item);
    state.categories = Object.assign({}, state.categories);
};

export const removeOneCoupon = (state, item) => {
    if (state.items.hasOwnProperty(item.id)) {
        Vue.delete(state.items, item.id);
    }
};

export const updateCouponTemplate = (state, templates) => {
    Vue.set(state, 'couponTemplates', templates);
};

export const updateCouponLimit = (state, limit) => {
    if (limit.hasOwnProperty('maxCouponLimit')) {
        state.maxCouponLimit = limit.maxCouponLimit;
    }

    if (limit.hasOwnProperty('activeCouponCount')) {
        state.activeCouponCount = limit.activeCouponCount;
    }
};
