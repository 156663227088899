<template>
    <div class="c3-coupon-list-item c3-coupon-list-item--is-static-item">
        <div class="c3-coupon-list-item-content-wrapper">
            <c3-page-image name="share"/>
            <div class="c3-coupon-list-item-content">
                <h2 class="c3-coupon-list-item-title">{{$t('coupon.affiliate.title')}}</h2>
                <div class="c3-coupon-list-item-text">{{$t('coupon.affiliate.message')}}</div>
                <router-link :to="{name:'Affiliate'}" class="c3-coupon-list-item-button">
                    <c3-button :label="$t('coupon.affiliate.button')" styling="2"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import C3PageImage from '@/components/media/PageImage';
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {
            C3PageImage,
            C3Button
        },
        props: {},
        data() {
            return {}
        },
        computed: {},
        methods: {},
    };
</script>
<style lang="scss" scoped>
</style>
