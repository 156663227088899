<template>
</template>

<script>
    export default {
        props: {
            value: {
                type: String
            },
            label: {
                type: String
            },
            description: {
                type: String
            },
            autocomplete: {
                type: String,
            },
            placeholder: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                id: null
            };
        },
        mounted() {
            this.id = 'c3-form-field-' + this._uid;
        }
    };
</script>
