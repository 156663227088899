<template>
    <div class="c3-form-wrapper" :class="wrapperClasses">
        <form class="c3-form" @submit.prevent="submit">
            <slot></slot>
        </form>
    </div>
</template>

<script>

    export default {
        components: {},
        props: {
            padding: {
                type: String,
                default: 'default'
            },
            styling: {
                type: String
            }
        },
        data() {
            return {};
        },
        computed: {
            wrapperClasses() {
                let classes = ['c3-form-wrapper-padding-' + this.padding];
                if (this.styling) {
                    classes.push('c3-form--wrapper-styling-' + this.styling);
                }
                return classes.join(' ');
            }
        },
        methods: {
            submit() {
                this.$emit('submit');
            },
        }
    };
</script>

<style lang="scss" scoped>
</style>
