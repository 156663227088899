<template>
    <div class="c3-crop-image-wrapper">
        <template v-if="thumbnail.objectURL">
            <vue-cropper ref="cropper" :src="thumbnail.objectURL" :containerStyle="{maxHeight:'calc(95vh - 220px)'}"
                         :viewMode="1" :autoCropArea="1" :aspectRatio="1.8" :cropBoxResizable="false" dragMode="move" :cropBoxMovable="false" :rotatable="true"
                         :toggleDragModeOnDblclick="false"/>
            <div class="c3-crop-image-button-wrapper">
                <button class="c3-crop-image-button-rotate" @click="rotateLeft">
                    <c3-icon-undo-alt primaryColor="quinary"/>
                </button>
                <button class="c3-crop-image-button-rotate" @click="rotateRight">
                    <c3-icon-redo-alt primaryColor="quinary"/>
                </button>
                <c3-button class="c3-crop-image-button-cancel" :label="$t('label.cancel')" @click="cancel" styling="2"/>
                <c3-button class="c3-crop-image-button-crop" :label="$t('label.upload')" @click="crop"/>

            </div>
        </template>
    </div>
</template>

<script>
    import {ImageResize} from '@/utility/media/imageResize';

    import VueCropper from 'vue-cropperjs';
    import C3Button from '@/components/form/Button.vue';
    import C3IconUndoAlt from '@/components/icon/UndoAlt';
    import C3IconRedoAlt from '@/components/icon/RedoAlt';

    export default {
        components: {
            VueCropper,
            C3Button,
            C3IconUndoAlt,
            C3IconRedoAlt
        },
        props: {
            submitLabel: {
                type: String,
                required: true
            },
            image: {
                type: File,
                required: true
            }
        },
        data() {
            return {
                thumbnail: {
                    objectURL: null,
                },
            };
        },
        computed: {},
        methods: {
            async crop() {
                this.$store.commit('view/showLoading');
                let blob = await new Promise(resolve => this.$refs.cropper.getCroppedCanvas().toBlob(resolve, 'image/jpeg', 0.85));
                this.$emit('crop', blob);
                this.$store.commit('view/hideLoading');
            },
            cancel() {
                this.$emit('cancel');
            },
            rotateLeft() {
                this.$refs.cropper.rotate(-90);
            },
            rotateRight() {
                this.$refs.cropper.rotate(90);
            },
            async calculateAndSetPreviewImageSrc(newImage) {
                this.$store.commit('view/showLoading');

                let imageResize = new ImageResize(newImage);
                let resizedImage = await imageResize.resizeByWidthFast(2048);
                this.thumbnail.objectURL = resizedImage.objectUrl;

                this.$store.commit('view/hideLoading');
            },
        },
        mounted() {
            this.calculateAndSetPreviewImageSrc(this.image);
        },
        beforeDestroy() {
            // Clean up objectURL to avoid memory leaks
            // https://developer.mozilla.org/de/docs/Web/API/URL/revokeObjectURL
            if (this.thumbnail.objectURL) {
                URL.revokeObjectURL(this.thumbnail.objectURL);
            }
        },
        watch: {
            image(newImage) {
                this.calculateAndSetPreviewImageSrc(newImage);
            },
        }
    };
</script>

<style lang="scss" scoped>
    .c3-crop-image-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 300;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        background-color: rgba(0, 0, 0, 0.8);
    }

    .c3-crop-image-button-wrapper {
        display: flex;
        justify-content: space-between;
        width: 95%;
        max-width: 320px;

        margin-top: 15px;
    }

    .c3-crop-image-button-cancel {
        max-width: 100px;

        & /deep/ .c3-form-field-button {
            background-color: #fff;
            color: $quinary-color;
        }
    }

    .c3-crop-image-button-crop {
        max-width: 100px;
    }

    .c3-crop-image-button-rotate {
        @include baseButton();
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        min-width: 44px;

        .c3-icon-wrapper {
            height: 22px;
        }
    }
</style>
