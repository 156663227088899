import {HTTP} from '@/domain/api/http';
import {BonusCardConverter} from '@/domain/api/converter/bonusCard';

export const createBonusCard = async (context, bonusCard) => {
    bonusCard = BonusCardConverter.toApi(bonusCard);
    let response = await HTTP.post(API_BASE_URL + '/bonus-card', bonusCard);
    return BonusCardConverter.fromApi(response.data.data);
};

export const updateBonusCard = async (context, bonusCard) => {
    let id = bonusCard.id;
    bonusCard = BonusCardConverter.toApi(bonusCard);
    let response = await HTTP.patch(API_BASE_URL + `/bonus-card/${id}`, bonusCard);
    return BonusCardConverter.fromApi(response.data.data);
};

export const updateBonusCardImage = async (context, data) => {
    let response = await HTTP.post(API_BASE_URL + '/bonus-card-file', data);
    return response.data.data;
};

export const enableReview = (context, bonusCard) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + '/bonus-card/' + bonusCard.id + '/enable-review')
            .then(response => {
                let bonusCard = BonusCardConverter.fromApi(response.data.data);
                context.commit('bonusCard/updateBonusCard', [bonusCard], {root: true});
                resolve();
            })
            .catch(error => reject(error));
    });
};

export const loadDraftBonusCard = async (context) => {
    let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/bonus-card/draft`);
    let bonusCards = response.data.data;
    bonusCards = BonusCardConverter.fromApiCollection(bonusCards);
    context.commit('bonusCard/updateBonusCard', bonusCards, {root: true});
    context.commit('updateBonusCardList', {name: 'draft', items: bonusCards.map(item => item.id)});
};

export const loadReviewBonusCard = async (context) => {
    let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/bonus-card/review`);
    let bonusCards = response.data.data;
    bonusCards = BonusCardConverter.fromApiCollection(bonusCards);
    context.commit('bonusCard/updateBonusCard', bonusCards, {root: true});
    context.commit('updateBonusCardList', {name: 'review', items: bonusCards.map(item => item.id)});
};

export const loadScheduledBonusCard = async (context) => {
    let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/bonus-card/scheduled`);
    let bonusCards = response.data.data;
    bonusCards = BonusCardConverter.fromApiCollection(bonusCards);
    context.commit('bonusCard/updateBonusCard', bonusCards, {root: true});
    context.commit('updateBonusCardList', {name: 'scheduled', items: bonusCards.map(item => item.id)});
};

export const loadActiveBonusCard = async (context) => {
    let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/bonus-card/active`);
    let bonusCards = response.data.data;
    bonusCards = BonusCardConverter.fromApiCollection(bonusCards);
    context.commit('bonusCard/updateBonusCard', bonusCards, {root: true});
    context.commit('updateBonusCardList', {name: 'active', items: bonusCards.map(item => item.id)});
};

export const loadArchiveBonusCard = async (context) => {
    let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/bonus-card/archive`);
    let bonusCards = response.data.data;
    bonusCards = BonusCardConverter.fromApiCollection(bonusCards);
    context.commit('bonusCard/updateBonusCard', bonusCards, {root: true});
    context.commit('updateBonusCardList', {name: 'archive', items: bonusCards.map(item => item.id)});
};
