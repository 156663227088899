<template>
    <div class="c3-faq-wrapper">
        <div v-if="title" class="c3-faq-title">{{title}}</div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String
            }
        },
        computed: {},
        methods: {}
    };
</script>
<style lang="scss" scoped>
    .c3-faq-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
        padding: 0 15px;
    }

    .c3-faq-item {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>