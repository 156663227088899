<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.858 141.858">
            <g id="Gruppe_13" data-name="Gruppe 13" transform="translate(-187.518 -398.017)">
                <path :fill="color1" id="tag" class="cls-1"
                      d="M137.963,62.6,79.257,3.9a13.3,13.3,0,0,0-9.4-3.9H13.3A13.3,13.3,0,0,0,0,13.3V69.853a13.3,13.3,0,0,0,3.9,9.4L62.6,137.963a13.3,13.3,0,0,0,18.808,0l56.554-56.554a13.3,13.3,0,0,0,0-18.808Zm-6.269,12.539L75.139,131.694a4.433,4.433,0,0,1-6.269,0L10.164,72.988a4.4,4.4,0,0,1-1.3-3.135V13.3A4.438,4.438,0,0,1,13.3,8.866H69.853a4.4,4.4,0,0,1,3.135,1.3L131.694,68.87a4.439,4.439,0,0,1,0,6.269Z"
                      transform="translate(187.518 398.017)"/>
                <path :fill="color1" id="user-check" class="cls-1"
                      d="M99.013,17.116l-3.645-3.671a2.052,2.052,0,0,0-2.921,0L62.67,42.319l-13.778-13.8a2.075,2.075,0,0,0-2.921,0L42.3,32.161a2.075,2.075,0,0,0,0,2.921l18.146,18.2a3.1,3.1,0,0,0,4.394,0L98.987,20.037a2.052,2.052,0,0,0,.026-2.921Z"
                      transform="matrix(0.966, -0.259, 0.259, 0.966, 186.408, 460.452)"/>
            </g>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>