<template>
    <div class="c3-main">
        <div class="c3-page-content c3-page-content-padding">
            <div class="c3-static-content" v-html="text"></div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            text() {
                let page = this.$store.state.staticContent.pages[this.$route.params.id];
                if (null !== page && typeof page === 'object' && page.content) {
                    return page.content;
                }
                return '';
            }
        },
        methods: {
            async loadPage() {
                this.$store.commit('view/showLoading');
                try {
                    await this.$store.dispatch('staticContent/loadSinglePage', this.$route.params.id);
                } catch (e) {
                    this.$c3.handleErrorsWithAlert(e);
                }
                this.$store.commit('view/hideLoading')
            }
        },
        async created() {
            this.loadPage();
        },
        watch: {
            '$route'() {
                this.loadPage();
            }
        },
        events: {
            [EVENT.REFRESH.NAME]: {
                async handler() {
                    this.loadPage();
                },
                priority: EVENT.REFRESH.PRIORITY.PAGE
            },
        },
    };
</script>
