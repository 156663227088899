<template>
    <svg class="c3-icon" version="1.1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <path :fill="color1"
              d="m15.3 5.4 3.6-2.6 2.5-1 2.2 0.4 2.3 0.6 1.7 1.3 0.7 1.6 0.6 1.5v4l-0.6 2.1-1.2 2.4-1.3 1.7-1.8 2.1-1.4 1.5-1.3 1.4-2.5 2.4-1.2 1.5-1.2 0.8-1.2 1.1-2.5-1.9-1.6-2.1-1.9-1.9-1.9-2.3-1.7-1.6-1.6-2.2-1.6-1.7-0.8-2.5-0.5-2.5v-2.3l0.9-1.5 1.1-1.9 1.7-1 1.8-0.6h4.6l1.3 1.1 1.5 0.8z"/>
        <path :fill="color2"
              d="m21.6 0.98687c-2.4 0-4.8 1.6-6.6 3.3-1.8-1.7-4.1-3.3-6.6-3.3-5.2 0-8.4 3.4-8.4 8.9 0 4.4 3.5 8 3.9 8.3l9.7 10.2c0.7 0.8 2 0.8 2.8 0.1l0.1-0.1 9.7-10.2c0.9-0.9 3.8-4.2 3.8-8.3 0-5.5-3.2-8.9-8.4-8.9zm3.3 15.9-9.7 10.2c-0.1 0.1-0.2 0.1-0.3 0l-9.8-10.2c-1.6-1.6-3.3-4.4-3.3-6.9 0-4.4 2.4-7 6.6-7 2.6-0.1 4.9 2.2 6.6 4 0.9-1 3.6-4.1 6.6-4.1 4.2 0 6.6 2.6 6.6 7 0 2.6-1.6 5.2-3.3 7z"/>
    </svg>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>