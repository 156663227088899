require('./utility/polyfill/promiseFinally');

// Config and constants
require('@/constants');
import {Config} from '@/config';

// Include styles
import 'swiper/css/swiper.css';
import '@/assets/sass/main.scss';

try {
    if (!window.CSS || !CSS.supports('color', 'var(--fake-var)')) {
        import(/* webpackChunkName: "cssVars" */ 'css-vars-ponyfill').then(m => {
            let cssVars = m.default;
            console.debug('M:', m);
            cssVars({
                updateURLs: false,
            });
        });
    }
} catch (e) {
    console.error('css-vars-ponyfill failed', e);
}

// Import vue stuff
import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import globals from './globals';

// Custom stuff, which depends on Vue/store/router
import {Logger} from '@/domain/logger';

globals.registerComponents();
Vue.config.performance = 'production' !== process.env.NODE_ENV;

// Plugins
import i18n from '@/plugins/i18n';
import Vuelidate from 'vuelidate';
import VueEventManager from 'vue-event-manager';
import PortalVue from 'portal-vue';

Vue.use(Vuelidate);
Vue.use(VueEventManager);
Vue.use(PortalVue);

// Custom directives
import {RippeDirective} from '@/directives/ripple';

RippeDirective.init(Vue);

// Own plugins
import C3Alert from '@/plugins/alert';
import C3Error from '@/plugins/error';
import C3ExternalLink from '@/plugins/externalLink';
import C3Navigation from '@/plugins/navigation';
import C3Platform from '@/plugins/platform';
import C3Icon from '@/plugins/icon';
import C3Form from '@/plugins/form';
import C3General from '@/plugins/general';
import C3UniqueId from '@/plugins/uniqueId';
import C3User from '@/plugins/user';

Vue.use(C3Alert);
Vue.use(C3Error);
Vue.use(C3ExternalLink);
Vue.use(C3Navigation);
Vue.use(C3Platform);
Vue.use(C3Icon);
Vue.use(C3Form);
Vue.use(C3General);
Vue.use(C3UniqueId);
Vue.use(C3User);

// Root service
import {AffiliateService} from '@/domain/service/affiliateService';
import {Analytics} from '@/domain/analytics';
import {NotificationService} from '@/domain/service/notificationService';
import {Translator} from '@/domain/translator';

window.startApp = () => {
    Analytics.logAppStart();
    Logger.init(Vue, store);
    AffiliateService.init(window.Branch, store, router);

    store.commit('general/initialize');
    store.commit('user/initialize');
    store.commit('viewCouponList/initialize');
    store.dispatch('general/loadInitial');
    store.dispatch('notification/register');
    NotificationService.addListener('storeLoadNotifications', () => {
        store.dispatch('notification/loadNotifications');
        router.push({name: 'NotificationList'});
    });

    const app = new Vue({
        router,
        store,
        i18n,
        components: {App},
        template: '<App/>',
    });

    // Wait till vue-router is ready, then mount
    // This avoid router-view transitions on page load: https://github.com/vuejs/vue-router/issues/2031
    router.onReady(() => app.$mount('#app'));

    AffiliateService.appStart();

    window.updateTranslationsTimeout = window.updateTranslationsTimeout || setTimeout(Translator.updateCurrentTranslations, 8000);
};

window.resumeApp = () => {
    Analytics.logAppResume();
    AffiliateService.init(window.Branch, store, router);

    store.commit('viewCouponList/initialize');
    store.dispatch('general/apiCheck');
    store.dispatch('coupon/loadCategoryAll');
    store.dispatch('notification/register');
    NotificationService.addListener('storeLoadNotifications', () => {
        store.dispatch('notification/loadNotifications');
        router.push({name: 'NotificationList'});
    });

    setTimeout(() => {
        // Update static content with some delay, because it's not needed for the first screens
        store.dispatch('staticContent/loadContent');
    }, 3000);

    AffiliateService.appResume();

    window.updateTranslationsTimeout = window.updateTranslationsTimeout || setTimeout(Translator.updateCurrentTranslations, 8000);
};

window.pauseApp = () => {
    Analytics.logAppPause();
    if (window.updateTranslationsTimeout) {
        clearTimeout(window.updateTranslationsTimeout);
    }
};

// Init the app directly during development. In production startApp is called by cordova deviceready
if (!Config.isNative()) {
    console.debug('Manually start app, because Config.isNative() returned false');
    window.startApp();
}
