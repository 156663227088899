<template>
    <c3-field-wrapper>
        <input :value="value" @input="$emit('input', $event.target.value)"
               :min="min" :max="max" :step="step"
               @focus="focus" @blur="blur"
               :id="id" type="range" :autocomplete="autocomplete" class="c3-form-field-input c3-form-field-input-range"/>
    </c3-field-wrapper>
</template>

<script>
    import BaseField from '@/components/form/fields/BaseField.vue';
    import C3FieldWrapper from '@/components/form/FieldWrapper.vue';

    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';

    export default {
        extends: BaseField,
        mixins: [FormFieldErrorMixin],
        components: {
            C3FieldWrapper,
        },
        props: {
            value: {
                type: [String, Number]
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 100
            },
            step: {
                type: Number,
                default: 1
            },
            labelStyling: {
                type: String,
                default: 'normal'
            },
            styling: {
                type: String
            },
        },
        data() {
            return {
                hasFocus: false
            }
        },
        computed: {
            isEmpty() {
                return null === this.value || 0 === this.value.length;
            },
            cssClasses() {
                return {
                    'c3-form-field-group--has-error': this.hasError,
                    'c3-form-field-group-input--not-empty': !this.isEmpty,
                    'c3-form-field-group-input--focus': this.hasFocus
                };
            }
        },
        methods: {
            blur() {
                this.hasFocus = false;
                this.$emit('blur');
            },
            focus() {
                this.hasFocus = true;
                this.$emit('focus');
            }
        }
    };
</script>

<style lang="scss">
    $slider-height: 2px;
    $runnable-size: 20px;

    .c3-form-field-input-range {
        width: 100%;
        height: 30px;
        padding: 0;

        background-color: transparent;
        box-shadow: none;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-runnable-track {
            width: 100%;
            height: $slider-height;
            cursor: pointer;
            background: $quinary-color;
        }

        &::-webkit-slider-thumb {
            height: $runnable-size;
            width: $runnable-size;

            border: 1px solid #aaa;
            border-radius: 50%;
            background: $white-color;
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -1 * $runnable-size / 2 + 1px;

            @include boxShadow();
        }

        &:focus::-webkit-slider-runnable-track {
            background: $white-color;
        }

        &::-moz-focus-outer {
            border: 0;
        }

        &::-moz-range-track {
            width: 100%;
            height: $slider-height;
            cursor: pointer;
            background: $quinary-color;
        }

        &::-moz-range-thumb {
            height: $runnable-size;
            width: $runnable-size;
            border-radius: 50%;
            background: $white-color;
            cursor: pointer;
        }
    }
</style>