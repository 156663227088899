<template>
    <div class="c3-coupon-list-item c3-coupon-list-item--is-static-item">
        <div class="c3-coupon-list-item-content-wrapper">
            <img src="~@/assets/images/couponList/affiliate.jpg"/>
            <div class="c3-coupon-list-item-content">
                <h2 class="c3-coupon-list-item-title">{{$t('coupon.noEntries.title')}}</h2>
                <div class="c3-coupon-list-item-text">{{$t('coupon.noEntries.message')}}</div>

                <router-link router-link :to="{name:'Affiliate'}" class="c3-coupon-list-item-button">
                    <c3-button :label="$t('coupon.noEntries.button')" styling="2"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {
            C3Button
        },
    };
</script>
<style lang="scss" scoped>
    .c3-coupon-list-item-content-wrapper {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-color: rgba(0, 0, 0, 0.4);
        }
    }
</style>
