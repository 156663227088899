<template>
    <div class="c3-coupon-list-wrapper">
        <slot name="header">
            <c3-coupon-list-header :name="name" :title="title"/>
        </slot>
        <div class="c3-coupon-list">
            <template v-if="items && items.length > 0">
                <template v-for="(item,index) in items">
                    <template v-if="showStaticItems && index === 3">
                        <component :is="loadStaticItemComponent(staticItemComponentName)" :key="`static-${index}`"/>
                    </template>
                    <slot :item="item">
                        <c3-item :item="item" :key="item.id"/>
                    </slot>
                </template>
            </template>
            <div v-else class="c3-coupon-simple-list--no-entries">
                <slot name="noEntries">
                    <c3-no-item/>
                </slot>
            </div>
        </div>
        <portal to="coupon_filter" v-if="$route.meta && $route.meta.enableCouponFilter">
            <c3-coupon-filter-drawer/>
        </portal>
        <portal to="coupon_location" v-if="$route.meta && $route.meta.enableCouponFilter">
            <c3-coupon-location-drawer/>
        </portal>
    </div>
</template>

<script>
    import C3CouponListHeader from '@/components/content/coupon/list/Header';
    import C3Item from '@/components/content/coupon/list/Item.vue';
    import C3AffiliateItem from '@/components/content/coupon/list/AffiliateItem.vue';
    import C3NoItem from '@/components/content/coupon/list/NoItem.vue';
    import C3CouponFilterDrawer from '@/components/content/coupon/filterDrawer/Index';
    import C3CouponLocationDrawer from '@/components/content/coupon/locationDrawer/Index';

    export default {
        components: {
            C3CouponListHeader,
            C3Item,
            C3AffiliateItem,
            C3NoItem,
            C3CouponFilterDrawer,
            C3CouponLocationDrawer
        },
        props: {
            name: {
                type: String,
                required: true
            },
            title: {
                type: String
            },
            items: {
                type: Array
            },
            showStaticItems: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {staticItemComponents: {}}
        },
        computed: {
            isActiveCustomerAndHasActiveCoupons() {
                if (!this.isLoggedIn || !this.isCustomer) {
                    return false;
                }

                if (!this.$store.getters['user/hasMembershipAndPayment']) {
                    return false;
                }
                return this.$store.getters['coupon/couponByStatePublishedAndActiveAndCustomer'](this.currentUser.id).length > 0;
            },
            staticItemComponentName() {
                if (this.isActiveCustomerAndHasActiveCoupons) {
                    return 'ActiveCustomerItem';
                }
                if (this.isCustomer) {
                    return 'CustomerItem';
                }
                return 'UserItem';
            },
        },
        methods: {
            loadStaticItemComponent(name) {
                if (this.staticItemComponents.hasOwnProperty(name)) {
                    return this.staticItemComponents[name];
                }
                this.$set(this.staticItemComponents, name, () => import(`@/components/content/coupon/list/item/${name}`));
                return this.staticItemComponents[name];
            }
        },
        mounted() {
            if (this.isLoggedIn) {
                // Load user data to determine which static items is shown
                this.$store.dispatch('coupon/loadCurrentUserCouponWithPublishedState');
                this.$store.dispatch('user/loadCurrentPayment');
                this.$store.dispatch('user/loadActiveMemberships');
            }
        },
        events: {
            [EVENT.COUPON_FILTER.NAME]: {
                handler(event, params) {
                    this.$store.commit('view/addDrawer', DRAWER.COUPON_FILTER_DRAWER);
                },
                priority: EVENT.COUPON_FILTER.PRIORITY.COUPON_LIST
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-coupon-list {
        margin-top: calc(-1 * var(--grid-gutter));
        margin-left: calc(-1 * var(--grid-gutter));
    }

    .c3-coupon-list-item {
        margin-top: var(--grid-gutter);
        margin-left: var(--grid-gutter);
    }

    @media(min-width: $breakpointTablet) {
        .c3-coupon-simple-list--no-entries {
            width: 100%;
        }
    }
</style>

