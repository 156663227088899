<template>
    <img class="c3-image" v-if="src" :src="src"/>
</template>

<script>
  export default {
    props: {
      id: String,
      size: {
        type: Number,
        default: 600
      },
      responsive: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      src () {
        return this.getImage(this.id).medium;
      }
    },
    methods: {
      getImage: (id) => {
        return {
          small: window.API_BASE_URL + '/assets/img/' + id + '.jpg',
          medium: window.API_BASE_URL + '/assets/img/' + id + '.jpg',
          big: window.API_BASE_URL + '/assets/img/' + id + '.jpg'
        };
      }
    }
  };
</script>