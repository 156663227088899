import {HTTP} from '@/domain/api/http';
import {ShopConverter} from '@/domain/api/converter/shop'

export const loadShop = async (context) => {
    try {
        let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/shop`);
        let shops = ShopConverter.fromApiCollection(response.data.data);
        context.commit('updateShop', shops);
    } catch (e) {
        console.error(e);
    }
};

export const loadOneShop = async (context, id) => {
    try {
        let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/shop/${id}`);
        let shop = ShopConverter.fromApi(response.data.data);
        context.commit('updateShop', [shop]);
    } catch (e) {
        console.error(e);
    }
};

export const createShop = async (context, shop) => {
    let response = await HTTP.post(API_BASE_URL + `/user/${context.rootState.user.user.id}/shop`, shop);
    shop = ShopConverter.fromApi(response.data.data);
    context.commit('updateShop', [shop]);
};

export const updateShop = async (context, shop) => {
    let id = shop.id;
    delete shop.id;
    let response = await HTTP.patch(API_BASE_URL + `/user/${context.rootState.user.user.id}/shop/${id}`, shop);
    shop = ShopConverter.fromApi(response.data.data);
    context.commit('updateShop', [shop]);
};

export const deleteShop = async (context, shop) => {
    await HTTP.delete(API_BASE_URL + `/user/${context.rootState.user.user.id}/shop/${shop.id}`);
    context.commit('removeOneShop', shop);
    await loadShop(context);
};
