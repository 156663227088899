<template>
    <div class="c3-coupon-list-item">
        <c3-text-with-icon :title="title" :icon="icon"/>
        <slot name="button">
            <router-link v-if="showAddButton" :to="{name:'CouponCreateWizard'}">
                <c3-button :label="$t('coupon.create')"/>
            </router-link>
        </slot>
    </div>
</template>

<script>
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {
            C3TextWithIcon,
            C3Button
        },
        props: {
            title: {
                type: String
            },
            icon: {
                type: String
            },
            showAddButton: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {}
        },
        computed: {},
        methods: {},
    };
</script>
<style lang="scss" scoped>
    .c3-coupon-list-item {
        margin-top: 15px;
        @include horizontalSpacing();

        @media(min-width: $breakpointTablet+1) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c3-content-text-with-icon {
        padding-left: 0;
        padding-right: 0;

        & /deep/ .c3-content-text-with-icon-title {
            margin-top: 15px;
            font-size: 18px;
        }
    }
</style>
