let innerHeight = require('ios-inner-height');

export const ViewportHeight = {
    styleTag: null,
    init: function () {
        var self = this;
        window.addEventListener('resize', function () {
            self.update();
        });
        self.update();
    },
    update: function () {
        let rules = [];
        if (document.body.classList.contains('c3-platform-browser') && document.body.classList.contains('c3-platform-browser-iOS')) {
            // Only iOS browser has the address bar 100vh problem. In cordova is no address bar and android handles 100vh differently
            rules.push(`.c3-viewport-height-outer {height: ${innerHeight()}px;}`);
            rules.push(`.c3-viewport-height-inner {height:${window.innerHeight}px;}`);
        } else if (document.body.classList.contains('c3-platform-iOS')) {
            // Only iOS browser has the address bar 100vh problem. In cordova is no address bar and android handles 100vh differently
            rules.push(`.c3-viewport-height-outer {height: ${innerHeight()}px;}`);
            rules.push(`.c3-viewport-height-inner {height:${window.innerHeight}px;}`);
        } else {
            rules.push(`.c3-viewport-height-outer {height: 100vh;}`);
            rules.push(`.c3-viewport-height-inner {height: 100vh;}`);
        }

        if (!this.styleTag) {
            var style = document.createElement("style");

            // WebKit hack :(
            style.appendChild(document.createTextNode(""));

            document.body.appendChild(style);
            this.styleTag = style;
        }

        rules.forEach(rule => {
            this.styleTag.sheet.insertRule(rule);
        });
    }
};