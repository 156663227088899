<template>
    <div class="c3-notification-list__item" :class="itemClasses" :key="notification.id" @click="handleClick">
        <div class="c3-notification-list__item-date-wrapper">
            <div class="c3-item-corner c3-item-corner-top-left"></div>
            <div class="c3-item-corner c3-item-corner-bottom-left"></div>
            <div class="c3-notification-list__item-date">
                <div class="c3-notification-list__item-date-day">{{ notification.created.format('DD') }}</div>
                <div class="c3-notification-list__item-date-month">{{ notification.created.format('MM') }}</div>
            </div>
            <div class="c3-notification-list__item-delete" @click.stop="remove">
                <c3-icon-trash-alt primaryColor="tertiary"/>
            </div>
        </div>
        <div class="c3-notification-list__item-content">
            <div class="c3-item-corner c3-item-corner-top-right"></div>
            <div class="c3-item-corner c3-item-corner-bottom-right"></div>
            <div class="c3-notification-list__item-title">{{ notification.title }}</div>
            <div class="c3-notification-list__item-body">{{ notification.text }}</div>
        </div>
        <div class="c3-notification-list__item-target">
            <c3-angle-right :primaryColor="iconColor" v-if="notification.targetType && notification.target"/>
        </div>
    </div>
</template>

<script>
    import C3AngleRight from '@/components/icon/AngleRight';
    import C3IconTrashAlt from '@/components/icon/TrashAlt';

    export default {
        props: {
            notification: {
                type: Object,
                required: true
            }
        },
        components: {
            C3AngleRight,
            C3IconTrashAlt
        },
        data() {
            return {}
        },
        computed: {
            itemClasses() {
                return {
                    'c3-notification-list__item--unread': this.notification.unread
                }
            },
            iconColor() {
                return this.notification.unread ? 'quinary' : 'octonary';
            }
        },
        methods: {
            handleClick() {
                if (this.notification.unread) {
                    this.$store.dispatch('notification/markBulkAsRead', [this.notification]);
                }
                if (this.notification.targetType && this.notification.target) {
                    switch (this.notification.targetType) {
                        case 'internalLink':
                            this.$router.push(this.notification.target);
                            break;
                        case 'externalLink':
                            if (this.hasInAppBrowser) {
                                window.cordova.InAppBrowser.open(this.notification.target, '_system');
                            } else {
                                window.open(this.notification.target, '_blank')
                            }
                            break;
                    }
                }
            },
            remove() {
                this.$c3.confirmYesNo(
                    'notification.confirmDeleteAlert.title',
                    'notification.confirmDeleteAlert.message',
                    () => this.$store.dispatch('notification/deleteNotification', {notificationId: this.notification.id})
                );
            },
        },
    };
</script>
<style lang="scss" scoped>
    $corner-size: 6px;

    .c3-notification-list__item {
        display: flex;
        position: relative;
        min-height: 80px;

        margin-top: 10px;

        &:before {
            content: "";
            display: block;
            width: 50px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
            pointer-events: none;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 60px;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
            pointer-events: none;
        }
    }

    .c3-notification-list__item--unread {
        .c3-notification-list__item-date {
            background-color: $quinary-color;
        }
    }

    .c3-item-corner {
        position: absolute;
        display: block;
        height: 10px;
        width: 100%;
        background-clip: content-box;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            height: $corner-size * 4;
            width: $corner-size * 4;
            border-radius: 50%;
            border: $corner-size solid transparent;
        }

        &.c3-item-corner-top-left {
            top: -1 * $corner-size;
            left: 0;
            padding-right: $corner-size;
            border-top-left-radius: 5px;
            background-color: $third-color;

            &:after {
                top: -1 * $corner-size * 2;
                right: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }

        &.c3-item-corner-top-right {
            top: -1 * $corner-size;
            right: 0;
            padding-left: $corner-size;
            background-color: $white-color;

            &:after {
                top: -1 * $corner-size * 2;
                left: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }

        &.c3-item-corner-bottom-right {
            bottom: -1 * $corner-size;
            right: 0;
            padding-left: $corner-size;
            background-color: $white-color;

            &:after {
                bottom: -1 * $corner-size * 2;
                left: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }

        &.c3-item-corner-bottom-left {
            bottom: -1 * $corner-size;
            left: 0;
            padding-right: $corner-size;
            border-bottom-left-radius: 5px;
            background-color: $white-color;

            &:after {
                bottom: -1 * $corner-size * 2;
                right: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }
    }

    .c3-notification-list__item-date-wrapper {
        width: 55px;
        position: relative;
        z-index: 5;
        margin-top: $corner-size;
        margin-bottom: $corner-size;
        background-color: #FFFFFF;
        background-clip: padding-box;
    }

    .c3-notification-list__item-date {
        display: block;
        width: 40px;
        height: 40px;

        position: relative;

        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;

        background-color: $octonary-color;

        color: $third-color;
        text-align: center;

        border-radius: 50%;
    }

    .c3-notification-list__item-date-day {
        position: absolute;
        top: 4px;
        left: 50%;
        transform: translateX(-50%);

        @include montserratSemiBold();
        font-size: 16px;
    }

    .c3-notification-list__item-date-month {
        position: absolute;
        top: 21px;
        left: 50%;
        transform: translateX(-50%);

        @include montserratMedium();
        font-size: 10px;
    }

    .c3-notification-list__item-delete {
        display: flex;
        justify-content: center;
        height: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .c3-notification-list__item-content {
        flex: 1;
        position: relative;
        z-index: 5;
        margin-top: $corner-size;
        margin-bottom: $corner-size;
        padding-bottom: 15px;
        padding-left: 10px;

        background-color: $third-color;
        background-clip: padding-box;
    }

    .c3-notification-list__item-title {
        margin-top: 10px;

        @include montserratBold();
        font-size: 16px;
    }

    .c3-notification-list__item-body {
        @include openSansRegular();
        font-size: 12px;
    }

    .c3-notification-list__item-target {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        z-index: 5;
        background-color: $third-color;

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .c3-icon-wrapper {
            height: 20px;
        }
    }
</style>
