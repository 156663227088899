import {Config} from '@/config';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

let LoggerInternal = function () {
    let self = this;
    const DSN = 'https://f7774f945cb64d50a63138c51ffc0913@sentry.io/1298250';
    const PATH_STRIP_REGEXP = /^.*\/[^\.]+(\.app|CodePush|.*(?=\/))/;

    let store = null;

    this.captureException = (exception) => console.error(exception);
    this.captureMessage = (message, severity) => console.error(message, severity);

    this.init = function (Vue, injectedStore) {
        store = injectedStore;

        if (0 === Config.getEnvironment().indexOf('development')) {
            console.debug('[Logger] Development environment - Do not init Sentry');
            return;
        }

        // Log Vue errors to console, even if Sentry is active
        // https://github.com/getsentry/sentry-javascript/issues/1416
        Vue.config.errorHandler = function (error, vm, info) {
            console.error(error, vm, info);
        };

        Sentry.init({
            release: Config.getRelease(),
            environment: Config.getEnvironment(),
            dsn: DSN,
            debug: false,
            integrations: integrations => {
                return [...integrations, ...determineIntegrations([new Integrations.Vue({Vue: Vue})])];
            },
            beforeSend: beforeSendHandler
        });

        self.captureException = Sentry.captureException;
        self.captureMessage = Sentry.captureMessage;
    };

    /**
     * Normalize frame so sentry shows the original source code in errors
     *
     * @param {Object} frame
     * @returns {Object}
     * @see https://forum.sentry.io/t/sourcemaps-path-on-ios/3357/3
     */
    function normalizeFrame(frame) {
        try {
            //
            if (frame && typeof frame.filename === 'string' && frame.filename.indexOf('file:///') > -1) {
                frame.filename = normalizeUrl(frame.filename);
            }
            console.debug(frame.filename);
        } catch (e) {

        }
        return frame;
    }

    /**
     * Normalize url for easier release mapping (Android & iOS)
     *
     * 1. file:///android_asset/www/static/main.js -> main.js
     * 2. file:///var/containers/Bundle/Application/8C01CC7E-6857-455A-AA10-EDE4224E7384/Coupocket.app/www/static/main.js -> main.js
     *
     * @param {string} url
     * @returns {string}
     */
    function normalizeUrl(url) {

        return url.replace(/^file\:\/\//, '').replace(PATH_STRIP_REGEXP, '');
    }

    function beforeSendHandler(event, hint) {
        if (store.getters['user/isLoggedIn']) {
            // Add user information
            if (null === event.user || typeof event.user !== 'object') {
                event.user = {};
            }
            event.user.id = store.getters['user/user'].id;
        }

        try {
            if (!event.extra) {
                event.extra = {};
            }

            event.extra.appVersion = Config.getVersion();
            event.extra.apiVersion = Config.getApiVersion();

            if (Config.isNative()) {
                event.extra.device = {
                    version: window.device.version,
                    manufacturer: window.device.manufacturer,
                    model: window.device.model,
                    isVirtual: window.device.isVirtual,
                }
            }
        } catch (e) {
            // Avoid crash inside error handling...
        }

        return event;
    }

    function determineIntegrations(additionalIntegrations) {
        additionalIntegrations.push(new Integrations.RewriteFrames({iteratee: (frame) => normalizeFrame(frame)}));
        return additionalIntegrations;
    }
};

export const Logger = new LoggerInternal();
