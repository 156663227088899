<template>
    <c3-drawer :name="name" :fullWidth="true">
        <c3-grid-tile>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'BonusCardWizard'}" :label="$t('label.invest')" icon="c3-icon-plus-square"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerBonusCardListActive'}" :label="$t('label.active')" icon="c3-icon-c3-bonus-card-active"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerBonusCardListReview'}" :label="$t('label.in_examination')" icon="c3-icon-c3-bonus-card-stamp"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerBonusCardListScheduled'}" :label="$t('label.planned')" icon="c3-icon-c3-bonus-card-pending"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerBonusCardListDraft'}" :label="$t('label.drafts')" icon="c3-icon-c3-bonus-card"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerBonusCardListArchive'}" :label="$t('label.archive')" icon="c3-icon-archive"/>
            <!--            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerBonusCardListStatistic'}" label="Bei dir eingelöst" icon="c3-icon-chart-line"/>-->
        </c3-grid-tile>
    </c3-drawer>
</template>

<script>
    import C3Drawer from '@/components/layout/navigation/Drawer.vue';
    import C3GridTile from '@/components/layout/grid/tile/Index';
    import C3GridTileLinkItem from '@/components/layout/grid/tile/LinkItem';
    import C3GridTileItem from '@/components/layout/grid/tile/Item';

    export default {
        components: {
            C3Drawer,
            C3GridTile,
            C3GridTileLinkItem,
            C3GridTileItem
        },
        data() {
            return {
                name: DRAWER.CUSTOMER_MY_BONUS_CARD_NAVIGATION
            }
        },
        methods: {
            handleClick() {
                // Close drawer on click, even if route doesn't change
                this.$store.commit('view/removeDrawerAll')
            },
        },
    };
</script>
