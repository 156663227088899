export default (error) => {
    return {
        process: () => {
            if (error.response && error.response.data && error.response.data.errors) {
                return error.response.data.errors;
            }
            if (error.response) {
                return error.response;
            }
            return [
                {
                    status: 400,
                    code: 'invalidRequest',
                    messageCode: 'error.genericApiMessage'
                }
            ];
        }
    }
};