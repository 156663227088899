import {HTTP} from '@/domain/api/http';

export const loadContent = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/static-content')
            .then(response => {
                context.commit('updateContent', response.data.data);
                resolve();
            })
            .catch(error => reject(error));
    });
};

export const loadBulkContent = (context, ids) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/static-content/${ids.join(',')}/bulk`)
            .then(response => {
                context.commit('updateContent', response.data.data);
                resolve();
            })
            .catch(error => reject(error));
    });
};

export const loadFaq = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/static-content/help')
            .then(response => resolve(response.data.data))
            .catch(error => reject(error));
    });
};

export const loadSingleContent = (context, id) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/static-content/' + id)
            .then(response => {
                context.commit('updateSingleContent', response.data.data);
                resolve();
            })
            .catch(error => reject(error));
    });
};

export const loadCountries = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/country')
            .then(response => {
                context.commit('updateCountries', response.data.data);
                resolve();
            })
            .catch(error => reject(error));
    });
};

export const loadSinglePage = async (context, id) => {
    let response = await HTTP.get(API_BASE_URL + `/page/${id}`);
    context.commit('updateSinglePage', response.data.data);
};

