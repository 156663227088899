<template>
    <div class="c3-faq-item" @click="open">
        <div>{{title}}</div>
    </div>
</template>

<script>
    import {Analytics} from '@/domain/analytics';

    export default {
        props: {
            id: {
                type: Number
            },
            title: {
                type: String,
                required: true
            },
            content: {
                type: String,
                required: true
            }
        },
        computed: {},
        methods: {
            open() {
                this.$c3.htmlAlert('', this.content, null, {showCloseButton: false});
                if (this.id) {
                    Analytics.logFaqOpen(this.id);
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .c3-faq-item {
        display: flex;
        align-items: center;
        min-height: 60px;
        padding: 12px 15px;
        background-color: $secondary-color;
    }
</style>