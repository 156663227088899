import C3Alert from '@/plugins/alert';
import router from '@/router'
import store from '@/store';

export default function (to, from) {
    // Make sure user data is available, necessary for role check and useful for all components
    let isLoggedIn = store.getters['user/isLoggedIn'];
    let user = store.getters['user/user'];
    let promise = null;
    if (isLoggedIn && null === user) {
        // Load user, show loading icon because this causes an AJAX request
        store.commit('view/showLoading');
        promise = store.dispatch('user/loadCurrentUser');
    } else {
        // User is not logged in or user data is already available
        promise = new Promise(resolve => resolve());
    }

    this.checkAccessAndDetermineRedirect = function () {
        return new Promise((resolve) => {
            // wait till user data is available
            promise.then(() => {
                let user = store.getters['user/user'];

                // Iterate over matched routes in reversed order (most specific to least specific)
                // This allows inheritance of meta data, e.g. required roles, redirect routes, etc.
                for (let i = to.matched.length - 1; i >= 0; i--) {
                    let matchedRoute = to.matched[i];

                    if (!matchedRoute.hasOwnProperty('meta') || !matchedRoute.meta.hasOwnProperty('auth')) {
                        continue;
                    }

                    let auth = matchedRoute.meta.auth;

                    // Check if user has a role which makes a redirect necessary.
                    // E.g. show the customer profile instead of the user profile, if the user has role customer
                    if (auth.redirectIfUserHasRole && isLoggedIn && user.roles.indexOf(auth.redirectIfUserHasRole.role) > -1) {
                        resolve(auth.redirectIfUserHasRole.to);
                        return;
                    }

                    // Check if user has permissions for this route
                    if (auth.requiredRole) {

                        // Not logged in, but role is required. Ask user if he wants to login or stay at the current page
                        // The login alert is skipped, if skipLoginAlert=1 is set as query parameter
                        if (!isLoggedIn) {
                            let toPath = router.resolve(Object.assign({}, to)).resolved.fullPath;
                            if (to.query && to.query.skipLoginAlert) {
                                resolve({name: 'Login', query: {redirectAfterLogin: toPath}});
                            } else {
                                C3Alert.confirmLoginRedirect(
                                    () => resolve({name: 'Login', query: {redirectAfterLogin: toPath}}),
                                    () => resolve(false)
                                );
                            }
                            return;
                        }

                        // Role is missing, redirect to missingRoleRoute (if set) or to Home (and log an error)
                        if (!store.getters['user/hasRole'](auth.requiredRole)) {
                            if (auth.missingRoleRoute) {
                                resolve(auth.missingRoleRoute);
                                return;
                            } else {
                                console.error('User is logged in but lacks the role ' + auth.requiredRole + ' and no missingRoleRoute is defined', user);
                                resolve(false);
                                return;
                            }
                        }
                    }
                }
                resolve(true);
            });

            // Handle exceptional error - This shouldn't happen except for some strange connection issues during login/logout
            promise.catch((error) => {
                // Log error, logout user and redirect to Home = Reset the app
                console.error('Missing user, force logout', user, error, from, to);
                store.dispatch('user/logout')
                    .then(() => {
                        isLoggedIn = false;
                        resolve({name: 'Home'});
                    })
            });
        });
    };
};