<template>
    <router-link :to="{name:'CouponDetail',params:{id:item.id}}" class="c3-coupon-list-item c3-coupon-list-item--is-coupon">
        <c3-item-media :item="coupon" :isFavorite="isFavorite" @toggleFavorite="toggleFavorite" :additionalInformation="distance" :badgeText="badgeText"/>
        <div class="c3-coupon-list-item-content">
            <h2 class="c3-coupon-list-item-content-title">
                {{ coupon.titleWithTemplate }}
            </h2>
            <div class="c3-coupon-list-item-content-company">{{ coupon.shop ? coupon.shop.name : coupon.user.company }}</div>
            <template v-if="address">
                {{ address.street }}, {{ address.zip }} {{ address.city }}
            </template>
        </div>
    </router-link>
</template>

<script>
    import {Config} from '@/config';
    import moment from 'moment';

    import {CouponFavoriteMixin} from '@/domain/mixin/local/couponFavorite';

    import C3ItemMedia from '@/components/content/coupon/ItemMedia.vue';

    export default {
        mixins: [CouponFavoriteMixin],
        components: {
            C3ItemMedia,
        },
        props: {
            item: Object,
            enableEdit: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },
        computed: {
            coupon() {
                // Do not mutate the original coupon
                let coupon = Object.assign({}, this.item);
                coupon.user = this.$store.getters['customer/singleCustomer'](this.item.user, {});
                coupon.category = this.$store.getters['coupon/category'](this.item.category, {});
                return coupon;
            },
            address() {
                const closestStore = this.$store.getters['userStore/storeById'](this.item.closestStoreId);
                if (closestStore !== null) {
                    return closestStore.address;
                }
                return this.coupon.user;
            },
            distance() {
                let distance = null;
                if (this.item.distance) {
                    distance = this.item.distance / 1000;
                    if (distance >= 100) {
                        distance = distance.toFixed(0) + ' km';
                    } else if (distance >= 10) {
                        distance = distance.toFixed(1) + ' km';
                    } else {
                        distance = distance.toFixed(2) + ' km';
                    }
                }
                return distance;
            },
            badgeText() {
                try {
                    if (moment.isMoment(this.coupon.publicationDate) && this.coupon.publicationDate.format('X') > (moment().format('X') - Config.getNewCouponDuration())) {
                        return this.$t('coupon.newCouponBadgeText');
                    }
                } catch (e) {
                    console.error('Determine newCouponBadgeText failed', e);
                }
                return '';
            }
        },
    };
</script>
