<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
            <path :fill="color1"
                  d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>