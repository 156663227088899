<template>
    <header class="c3-page-header">
        <div class="c3-page-header-left">
            <div v-if="$store.state.view.pageHeaderLeft" class="c3-page-header-item" @click="pageHeaderLeftClick">
                <component :is="$store.state.view.pageHeaderLeft.icon" primaryColor="white"/>
            </div>
        </div>
        <div class="c3-page-header-center">
            <h1 class="c3-navigation-navigation-bar-title">{{ $t('label.appName')}}</h1>
        </div>
        <div class="c3-page-header-right">
            <div v-if="$store.state.view.pageHeaderInnerRight" class="c3-page-header-item" @click="pageHeaderInnerRightClick">
                <component :is="$store.state.view.pageHeaderInnerRight.icon" primaryColor="white"/>
            </div>
            <div v-if="$store.state.view.pageHeaderRight" class="c3-page-header-item" @click="pageHeaderRightClick">
                <component :is="$store.state.view.pageHeaderRight.icon" primaryColor="white"/>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        methods: {
            pageHeaderLeftClick() {
                if (this.$store.state.view.pageHeaderLeft && this.$store.state.view.pageHeaderLeft.event) {
                    this.$trigger(this.$store.state.view.pageHeaderLeft.event);
                }
            },
            pageHeaderInnerRightClick() {
                if (this.$store.state.view.pageHeaderInnerRight && this.$store.state.view.pageHeaderInnerRight.event) {
                    this.$trigger(this.$store.state.view.pageHeaderInnerRight.event);
                }
            },
            pageHeaderRightClick() {
                if (this.$store.state.view.pageHeaderRight && this.$store.state.view.pageHeaderRight.event) {
                    this.$trigger(this.$store.state.view.pageHeaderRight.event);
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .c3-page-header {
        @include disableSelect();
    }

    .c3-page-header-center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .c3-navigation-navigation-bar-title {
        margin: 0;
        text-align: center;
        @include montserratMedium();
        font-size: 20px;
    }

    .c3-page-header-left,
    .c3-page-header-inner-right,
    .c3-page-header-right {
        position: absolute;
        top: 0;
        display: flex;
        height: 100%;

        .c3-icon-wrapper,
        .c3-page-header-icon-wrapper {
            height: 25px;
        }

        .c3-icon-redo {
            height: 20px;
        }
    }

    .c3-page-header-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .c3-page-header-left {
        left: 0;
        justify-content: flex-start;

        .c3-page-header-item {
            padding-right: 5px;
        }
    }

    .c3-page-header-right {
        right: 0;
        justify-content: flex-end;

        .c3-page-header-item {
            padding-left: 5px;
        }
    }
</style>
