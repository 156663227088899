import {HTTP} from '@/domain/api/http';
import {StoreConverter} from '@/domain/api/converter/store'

export const loadStore = async (context) => {
    try {
        let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/store`);
        let stores = StoreConverter.fromApiCollection(response.data.data);
        context.commit('updateStore', stores);
    } catch (e) {
        console.error(e);
    }
};


export const loadStoreByUser = async (context, {userId}) => {
    try {
        let response = await HTTP.get(API_BASE_URL + `/user/${userId}/store`);
        let stores = StoreConverter.fromApiCollection(response.data.data);
        context.commit('updateStore', stores);
    } catch (e) {
        console.error(e);
    }
};


export const loadOneStore = async (context, id) => {
    try {
        let response = await HTTP.get(API_BASE_URL + `/user/${context.rootState.user.user.id}/store/${id}`);
        let store = StoreConverter.fromApi(response.data.data);
        context.commit('updateStore', [store]);
    } catch (e) {
        console.error(e);
    }
};

export const createStore = async (context, store) => {
    let response = await HTTP.post(API_BASE_URL + `/user/${context.rootState.user.user.id}/store`, store);
    store = StoreConverter.fromApi(response.data.data);
    context.commit('updateStore', [store]);
};

export const updateStore = async (context, store) => {
    let id = store.id;
    delete store.id;
    let response = await HTTP.patch(API_BASE_URL + `/user/${context.rootState.user.user.id}/store/${id}`, store);
    store = StoreConverter.fromApi(response.data.data);
    context.commit('updateStore', [store]);
};

export const deleteStore = async (context, store) => {
    await HTTP.delete(API_BASE_URL + `/user/${context.rootState.user.user.id}/store/${store.id}`);
    context.commit('removeOneStore', store);
    await loadStore(context);
};
