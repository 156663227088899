<template>
    <a :href="href" target="_blank" @click="handleClick">
        <slot>{{href}}</slot>
    </a>
</template>

<script>
    export default {
        components: {},
        props: {
            to: {
                type: String
            },
            nativeBrowser: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {}
        },
        computed: {
            href() {
                if (this.to) {
                    return this.to;
                }
                return this.$route.fullPath;
            },
        },
        methods: {
            handleClick(e) {
                console.debug('Handle external link', this.hasInAppBrowser, this.nativeBrowser, this.to);
                if (this.hasInAppBrowser) {
                    e.preventDefault();
                    if (this.nativeBrowser) {
                        cordova.InAppBrowser.open(this.to, '_system', 'location=yes');
                    } else {
                        cordova.InAppBrowser.open(this.to, '_blank');
                    }
                }
                this.$emit('click');
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
