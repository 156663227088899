import moment from 'moment';

export const CouponRedeemStateMixin = {
    data() {
        return {}
    },
    computed: {
        redeemState() {
            let redeemState = this.$store.getters['user/singleRedeemState'](this.coupon.id);
            if (null !== redeemState && typeof redeemState === 'object') {
                return redeemState;
            }
            return null;
        },
        hasRedeemState() {
            return null !== this.redeemState;
        },
        isRedeemable() {
            if (!this.isPublished || !this.hasRedeemState) {
                return false;
            }

            if (null !== this.remainingRedeem && this.remainingRedeem < 1) {
                return false;
            }

            if (moment() < this.coupon.validFrom || moment() > this.coupon.validUntil) {
                return false;
            }

            return true;
        },
        isOnlineRedeemable() {
            if (!this.isRedeemable) {
                return false;
            }

            if (!this.coupon.isOnlineRedeemable) {
                return false;
            }

            console.debug('this.coupon.isOnlineRedeemable', this.coupon.isOnlineRedeemable);

            return true;
        },
        isPublished() {
            return this.coupon && this.coupon.state === COUPON_STATE.PUBLISHED;
        },
        remainingRedeem() {
            if (!this.hasRedeemState) {
                return null;
            }
            return this.redeemState.remainingRedeemCount;
        },
        lastRedeem() {
            return this.hasRedeemState ? this.redeemState.lastRedeem : null;
        },
        lastRedeemFormatted() {
            if (moment.isMoment(this.lastRedeem)) {
                return this.lastRedeem.format('D.M. - H:mm');
            }
            console.debug('Last redeem: ', this.lastRedeem);
            return '';
        }
    },
    methods: {},
};
