export default {
    createInternationAreaCodeOptions: () => {
        return [
            {
                label: 'Österreich',
                value: '+43',
                code: 'AT',
            },
            {
                label: 'Deutschland',
                value: '+49',
                code: 'DE',
            },
            {
                label: 'Frankreich',
                value: '+33',
                code: 'FR',
            },
            {
                label: 'Italien',
                value: '+39',
                code: 'IT'
            },
            {
                label: 'Liechtenstein',
                value: '+423',
                code: 'LI',
            },
            {
                label: 'Luxemburg',
                value: '+352',
                code: 'LU',
            },
            {
                label: 'Schweiz',
                value: '+41',
                code: 'CH',
            },
        ];
    }
};
