<template>
    <div class="c3-coupon-image-wrapper">
        <img v-if="src" :src="src" :alt="coupon.title" class="c3-coupon-image"/>
        <c3-loader v-else/>
    </div>
</template>

<script>
    export default {
        props: {
            coupon: {
                type: Object,
                required: true
            },
            isVisible: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                viewportWidth: null,
            }
        },
        computed: {
            src() {
                if (null === this.viewportWidth) {
                    // Do not load fallback image, when viewportWidth isn't set
                    return '';
                }
                for (let i = 0; i < MEDIA.SIZES.length; i++) {
                    if (MEDIA.SIZES[i] >= this.viewportWidth) {
                        return MEDIA.COUPON_BASE_URL + '/' + MEDIA.SIZES[i] + '/' + this.fileName;
                    }
                }
                return this.fallbackSrc;
            },
            fallbackSrc() {
                // Use the biggest image as fallback
                return MEDIA.COUPON_BASE_URL + '/' + MEDIA.SIZES[MEDIA.SIZES.length - 1] + '/' + this.fileName;
            },
            fileName() {
                return this.coupon.image;
            },
        },
        methods: {
            handleResize() {
                this.viewportWidth = window.innerWidth;
            }
        },
        mounted() {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        }
    };
</script>
<style lang="scss" scoped>
    .c3-coupon-image-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: percentage(1 / $image-aspect-ratio-default);
    }

    .c3-coupon-image {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .c3-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -22px;
        margin-left: -22px;
    }
</style>