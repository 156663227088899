<template>
    <div class="c3-form-field-group c3-form-field-group-upload" @click="takeImage">
        <div class="c3-form-field-wrapper c3-form-field-wrapper-upload">
            <label class="c3-form-label">{{label}}</label>
            <c3-coupon-image :coupon="coupon" v-if="coupon.image"/>
            <c3-loader v-if="isLoading" :hCenter="true"/>
            <c3-button v-else :label="submitLabel" styling="3"/>
        </div>
        <div class="c3-form-field-group-error-message" v-if="showErrorMessage">{{$t(errorMessage)}}</div>
        <portal to="app_end">
            <c3-crop-image v-if="cropImageInput" :image="cropImageInput" :submitLabel="$t('label.addPicture')" @crop="uploadImage" @cancel="cropImageInput = ''"/>
        </portal>
    </div>
</template>

<script>
    import {FormFieldErrorMixin} from '@/components/form/fields/mixin/error';
    import CameraService from '@/domain/service/cameraService';

    import C3CouponImage from '@/components/content/coupon/Image.vue';
    import C3CropImage from '@/components/media/CropImage';
    import C3Button from '@/components/form/Button.vue';

    export default {
        mixins: [FormFieldErrorMixin],
        components: {
            C3CouponImage,
            C3CropImage,
            C3Button
        },
        props: {
            value: {
                validator: prop => typeof prop === 'string' || prop === null,
                required: true
            },
            label: {
                type: String,
            },
            errorMessage: {
                type: String,
                default: 'error.invalidImage'
            },
            coupon: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                isLoading: false,
                cropImageInput: ''
            }
        },
        computed: {
            submitLabel() {
                if (this.coupon.image) {
                    return this.$t('label.changeImage');
                }
                return this.$t('label.uploadNewImage');
            }
        },
        methods: {
            takeImage() {
                this.$store.commit('view/showLoading');
                // Prevent endless loading, because we can't detect "cancel" event
                let timeoutId = setTimeout(() => this.$store.commit('view/hideLoading'), 2500);
                CameraService().takePicture()
                    .then(file => {
                        clearTimeout(timeoutId);
                        this.$store.commit('view/showLoading');
                        this.cropImageInput = file;
                    })
                    .catch((error => {
                        clearTimeout(timeoutId);
                        this.$store.commit('view/hideLoading');
                        console.error('TakePicture failed', error);
                        this.$c3.errorAlert('', 'error.fileTooLarge')
                    }));
            },
            uploadImage(file) {
                let formData = new FormData();
                formData.append('imageFile', file, 'couponImage.jpg');
                this.isLoading = true;
                this.cropImageInput = '';
                this.$emit('input', null);
                this.$store.dispatch('coupon/updateImage', {id: this.coupon.id, formData: formData})
                    .then((file) => {
                        this.$emit('input', file.filename);
                    })
                    .catch(this.$c3.handleErrorsWithAlert)
                    .finally(() => this.isLoading = false);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .c3-form-field-upload-preview {
        margin-top: 15px;

        img {
            width: 100%;
            height: auto;
        }
    }
</style>
