export const RippeDirective = {
    init(vueInstance) {
        vueInstance.directive('ripple', {
            inserted: function (el) {
                el.classList.add('c3-ripple');

                el.addEventListener("webkitAnimationEnd", function () {
                    el.classList.remove('c3-ripple--active');
                });
                el.addEventListener("animationend", function () {
                    el.classList.remove('c3-ripple--active');
                });

                el.addEventListener(
                    'click',
                    function () {
                        el.classList.add('c3-ripple--active');
                    },
                    {passive: true}
                );
            }
        })
    }
};