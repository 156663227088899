<template>
    <div class="c3-main c3-page-general-affiliate">
        <div class="c3-page-content c3-page-content-bottom-padding">
            <c3-page-image name="share"/>
            <div class="c3-page-content-horizontal-padding">
                <div class="c3-page-general-affiliate-friend">
                    <c3-text-with-icon :title="$t('pages.general.share.title')" icon="c3-icon-share-alt-square">
                        {{ $t('pages.general.share.message') }}
                    </c3-text-with-icon>
                    <a ref="inviteFriendsLink" :href="inviteFriendsHref" @click="inviteFriend" class="c3-share-link">
                        <c3-button :label="$t('pages.general.share.button')"/>
                    </a>
                </div>
                <div class="c3-page-general-affiliate-company">
                    <c3-text-with-icon v-if="affiliateDescription" :title="affiliateDescription.title" icon="c3-icon-money-bill">
                        <div v-html="affiliateDescription.text"></div>
                    </c3-text-with-icon>
                    <router-link :to="{name:'Affiliate'}" class="c3-share-link">
                        <c3-button :label="$t('label.more')"/>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ShareMixin} from '@/domain/mixin/local/share';

    import C3PageImage from '@/components/media/PageImage';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3Button from '@/components/form/Button';

    export default {
        mixins: [ShareMixin],
        name: 'Share',
        components: {
            C3PageImage,
            C3TextWithIcon,
            C3Button
        },
        data() {
            return {}
        },
        computed: {
            affiliateDescription() {
                return this.$store.getters['staticContent/singleContent'](18);
            },
        },
        methods: {}
    };
</script>
<style lang="scss" scoped>
    .c3-page-content {
        @media (min-width: $breakpointTablet) {
            max-width: 768px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c3-image-wrapper {
        @media (min-width: $breakpointTablet) {
            display: none;
        }
    }

    .c3-content-text-with-icon {
        padding-left: 0;
        padding-right: 0;

        & /deep/ .c3-content-text-with-icon-title {
            margin-top: 5px;
        }
    }

    .c3-page-general-affiliate-company {
        margin-top: 30px;
    }

    .c3-share-link {
        display: block;
    }
</style>