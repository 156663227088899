import {Config} from '@/config';

export default {
    install: function (Vue, options) {
        Vue.mixin({
            computed: {
                tenant() {
                    return Config.getTenant();
                },
                isTenantCoupocket() {
                    return this.tenant === 'coupocket';
                },
                isTenantSammelpass() {
                    return this.tenant === 'sammelpass';
                }
            }
        })
    },
};
