<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path :fill="color1" d="M480,128a64,64,0,1,0-64-64A64,64,0,0,0,480,128Zm0-96a32,32,0,1,1-32,32A32,32,0,0,1,480,32Zm32,128H448a63.55,63.55,0,0,0-50.15,24.51,62.7,62.7,0,0,1,32.67,13.41c5.1-3.44,10.88-5.92,17.48-5.92h64a32,32,0,0,1,32,32V352H512V480H448V393.8l-12,11.81a65.12,65.12,0,0,1-20,13.44V480a32,32,0,0,0,32,32h64a32,32,0,0,0,32-32V384a32,32,0,0,0,32-32V224A64,64,0,0,0,512,160ZM128,393.81V480H64V352H32V224a32,32,0,0,1,32-32h64c6.6,0,12.39,2.49,17.49,5.92a62.6,62.6,0,0,1,32.71-13.34A63.57,63.57,0,0,0,128,160H64A64,64,0,0,0,0,224V352a32,32,0,0,0,32,32v96a32,32,0,0,0,32,32h64a32,32,0,0,0,32-32V419.07a65.42,65.42,0,0,1-20-13.46ZM96,128A64,64,0,1,0,32,64,64,64,0,0,0,96,128Zm0-96A32,32,0,1,1,64,64,32,32,0,0,1,96,32ZM472.89,304a32,32,0,0,0-9.55-22.8l-56.89-56C385.64,204.71,352,220.52,352,248v40H224V248c0-27.41-33.82-43.11-54.45-22.8l-56.89,56a32,32,0,0,0,0,45.6l56.89,56c21.3,21,54.45,4,54.45-22.8V320H352v40c0,27,33.36,43.57,54.45,22.8l56.89-56A32,32,0,0,0,472.89,304ZM192,360l-56.89-56L192,248Zm192,0V248l56.89,56Z"/>
        </svg>
    </div>
</template>
<script>
    import { IconMixin } from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
