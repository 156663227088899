import baseApiConverter from '@/domain/api/converter/base';

export const NotificationConverter = {
    fromApi: function (notification) {
        notification = baseApiConverter.modifiedAndCreatedFromApi(notification);
        return notification;
    },
    fromApiCollection: function (notificationCollection) {
        return notificationCollection.map(notification => this.fromApi(notification));
    },
    toApi: function (notification) {
        return notification;
    }
};