import Vue from 'vue';
import {DateUtility} from '@/domain/utility/date';
import store from '@/store';
import {LocalStorage} from '@/domain/utility/localStorage';

const storage = LocalStorage('viewBonusCardList');

const STORAGE_FILTER = 'filter';

export const initialize = (state) => {
    if (storage.has(STORAGE_FILTER)) {
        state.filter = storage.get(STORAGE_FILTER);
    }
};

export const createList = (state, name) => {
    if (!state.filter.hasOwnProperty(name)) {
        Vue.set(
            state.filter,
            name,
            {
                useCurrentLocation: true,
                locationName: null,
                locationCenter: null,
                locationRadius: 30000
            }
        );
    }
};

export const updateFilter = (state, data) => {
    // Make sure filter exists
    createList(state, data.name);

    Vue.set(state.filter, data.name, Object.assign(state.filter[data.name], data.filter));
    storage.set(STORAGE_FILTER, state.filter);
};

export const updateItems = (state, data) => {
    let filterHash = store.getters['viewBonusCardList/filterHash'](data.name);
    state.lastItemsUpdate[filterHash] = DateUtility.time();
    Vue.set(state.items, filterHash, data.items);
};
