export default {
    label: {
        invest: 'Invest',
        active: 'Active',
        in_examination: 'In examination',
        planned: 'Planned',
        drafts: 'Drafts',
        archive: 'Archive',
        redeemed: 'Redeemed with you',
        login: 'login',
        logout: 'logout',
        send: 'send',
        next: 'next',
        save: 'save',
        update: 'update',
        publish: 'publish',
        moreAboutCompany: 'more about the company',
        showAllCoupons: 'show all coupons',
        showAllBonusCard: 'show all collective passes',
        apply: 'apply',
        reset: 'reset',
        remove: 'Remove',
        saveAsDraft: 'Save as draft',
        cancel: 'cancel',
        ok: 'OK',
        yes: 'yes',
        no: 'no',
        download: 'download',
        share: 'share',
        tos: 'tos',
        noInvoice: 'No Invoice',
        privacyPolicy: 'privacyPolicy',
        call: 'call',
        email: 'email',
        website: 'website',
        lastUpdate: 'Last updated',
        location: 'location',
        membership: 'membership',
        pleaseSelectOption: 'Please choose',

        updatePositionLoading: 'We\'ll determine your location, just a moment ...',

        // Form steps
        step1Of2: 'Step 1 of 2',
        step2Of2: 'Step 2 of 2',

        step1Of3: 'Step 1 of 3',
        step2Of3: 'Step 2 of 3',
        step3Of3: 'Step 3 of 3',

        continueToStep2: 'Next to step 2',
        continueToStep3: 'go to step 3',

        // Payment
        creditCard: 'creditCard',
        upload: 'Upload',
        redeem: 'Redeem',
        tos: {
            terms: 'I accept the terms and conditions',
            policy: 'I accept the privacy policy'
        },
        firstname: 'First name',
        lastName: 'Last Name',
        profileUpdate: 'Update profile',
        myProfile: 'My profile',
        profileFrom: 'Profile from',
        saveBillingAddress: 'Save billing address',
        bonusCardFrom: 'Collection Passports from',
        from: 'from',
        until: 'to',
        priceWithTax: 'Price excl. VAT',
        activeUntil: 'active until',
        close: 'Close',
        closeBtn: 'Close',
        determineCurrentLocation: 'Determine Current Location',
        appName: 'Coupocket',
        uploadNewImage: 'Upload new image',
        changeImage: 'Change image',
        noEntries: 'No items found.',
        zipPlaceholder: 'City or ZIP code',
        noCategoryTitle: 'No categories found.',
        after: 'after that',
        me: 'I',
        moreCategory: 'More<br>Categories',
        contact: 'Contact',
        qrCode: 'QR code',
        sendToContact: 'Send to contact',
        couponCode: 'Coupon code',
        toNext: 'Next step',
        skip: 'Skip',
        createNow: 'Create now',
        paymentType: 'Payment method',
        savePayment: 'Save payment data',
        holder: 'Holder',
        cardHolder: 'Cardholder',
        accountHolder: 'Account holder',
        pin: 'PIN',
        chooseCategory: 'Choose a category',
        more: 'Learn more',
        all: 'All',
        my: 'My',
        chars: 'Characters',
        openFile: 'Open',
        downloadFailed: 'Failed',
        downloadComplete: 'Download Complete',
        templateTitle: 'Your title will be',
        chooseTemplate: 'Choose Template',
        noTemplate: 'No template',
        phone: 'Phone',
        deleteAccount: 'Delete account',
        optionsType: {
            Normal: 'Customer scans QR code',
            QR: 'Company scans QR code',
            Barcode: 'Company scans Barcode'
        }
    },
    navigation: {
        home: 'Home',
        login: 'login',
        logout: 'logout',
        imprint: 'imprint',
        tos: 'tos',
        help: 'help',
        dataPrivacy: 'dataPrivacy',
        user: {
            registerCompany: 'Register company',
            earnMoney: 'earn money',
            affiliate: 'Affiliate'
        },
        customer: {
            company: 'My company',
            openingTimes: 'opening hours',
            stores: 'stores',
            profile: 'profile',
            coupons: 'My coupons',
            bonusCards: 'My collective passports',
            myQrCode: 'My QR code',
            paymentMethod: 'paymentMethod',
            invoices: 'Invoices',
        },
        shoppingCenter: {
            management: 'management',
        }
    },
    footer: {
        coupons: 'Coupons',
        favorites: 'favorites',
        bonusCards: 'bonusCards',
        share: 'share',
        navigation: 'menu',
    },
    weekdays: {
        long: {
            monday: 'monday',
            tuesday: 'tuesday',
            wednesday: 'wednesday',
            thursday: 'thursday',
            friday: 'friday',
            saturday: 'saturday',
            sunday: 'sunday'
        },
        short: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su'
        }
    },
    user: {
        pleaseEnterPhoneNumberForLogin: 'Please enter your phone number to log in.',
        sendLoginPinLoadingMessage: 'One moment please, we are now sending the login PIN.',
        loginCode: 'Login-Code',
        sentLoginPin: 'We have sent you a login PIN via SMS.',
        phoneLabel: 'My phone number',
        impersonate: {
            title: 'Choose your user',
            name: 'Name',
            firm: 'Company',
        }
    },
    customer: {
        company: 'Company name',
        name: 'Name',
        address: 'address',
        zip: 'zip',
        city: 'city',
        country: 'country',
        companyPhone: {
            label: 'Telephone (in the company)',
            description: 'International format. Example: +439876',
        },
        companyEmail: 'Email (in the company)',
        website: 'Website (optional)',
        vatId: 'UID-Nummer (optional)',
        openingTimesComment: 'Special opening times',

        alternateBillingAddress: 'Different billing address',

        sendQrCodeAsMail: 'Send to me by email',

        profileTitle: 'company',
        baseData: 'company',
        openingTime: 'opening hours',

        editBaseData: 'Edit company',
        editOpeningTime: 'Edit opening times',
        goToCustomerStoreList: 'My branches',

        store: {
            address: 'address',
            zip: 'zip',
            city: 'city',
            country: 'country',
            phone: {
                label: 'Telephone (in the branch)',
                description: 'International format. Example: +439876',
            },
            email: 'E-Mail (in the branch)',
            hasOpeningTimes: 'This branch has opening times',
        },

        payment: {
            sepaMandate: 'I authorize Coupocket GmbH and their payment provider Stripe to collect payments from my account by direct debit. At the same time, I instruct my bank to redeem the direct debits drawn on my account by Coupocket GmbH. I can request reimbursement of the debited amount within eight weeks, starting with the debit date. The terms agreed with my bank apply.'
        },
        cancelAlert: {
            title: '',
            message: 'We have received your cancellation. Your current subscription ends on <strong>% {membershipExpirationDate} </strong>. Any outstanding costs will be debited automatically until then. <br> <br> It can take 1-2 days for the cancellation to become visible in the app.'
        }
    },
    bonusCard: {
        name: 'Collective passport',
        image: 'image',
        title: 'Name / Title',
        shop: 'Shop',
        template: 'Template',
        description: 'description',
        titleExample: 'Example: Pizza 10 + 1',
        legalNotice: 'Additional validity notes',
        validFrom: 'Valid from',
        validUntil: 'Date of Expiry',
        necessaryRedeemCount: 'Number of stamps up to the bonus',
        necessaryRedeemCountExplanation: 'Example: 6 stamps - the user must collect 6 stamps before receiving the bonus (6 + 1).',
        necessaryRedeemCountReadOnlyAfterPublished: 'The number of stamps cannot be changed after publication.',

        loadingList: 'Update collective passes',
        create: 'Create a new collective pass',
        type: 'Scanning-process',
        content: 'Enter your code number',
        contentHint: 'Enter the code of your barcode, or the content of the QR-code',
        publishAlert: {
            title: 'Subscription required',
            message: 'In order to publish a collective pass you have to select a membership and enter your payment details. Do you want to do this now?'
        },
        listItems: {
            activeCustomer: {
                title: 'Create any number of collective passes',
                message: 'More collective passes reach more users',
                button: 'Create a new coupon'
            },
            customer: {
                title: 'Create a collective pass for <br> {company} <br> now',
                message: 'Reach new customers in your area',
                button: 'Create collective passport'
            },
            user: {
                title: 'Are you an entrepreneur?',
                message: 'Reach new customers in your area',
                button: 'Register company'
            },
        },
        affiliate: {
            title: 'No collection pass near you?',
            message: 'Invite companies and earn money',
            button: 'This is how it works'
        },
        noEntries: {
            title: 'No collection passport found',
            message: 'Invite companies and earn money',
            explanation: 'This is how it works'
        },
        limitReachedAlert: {
            title: 'Bonus card Limit has exceeded',
            message: 'Upgrade your subscription to add new Bonus cards',
        },
    },
    coupon: {
        title: 'Name / Title',
        shop: 'Shop',
        template: 'Template',
        description: 'description',
        category: 'category',
        validity: 'validity',
        online: 'Online',
        validFrom: 'Valid from',
        validUntil: 'Date of Expiry',
        totalCount: 'Total number of coupons',
        perUserCount: 'Coupons per user',
        legalNotice: 'Additional validity notes',
        onlineRedeemUrl: 'Link to the online shop',
        onlineRedeemCode: 'Redemption code',
        noFavorites: 'You haven\'t saved any favorites yet.',
        valid: 'Valid',
        create: 'Create new coupon',
        type: 'Scanning-process',
        content: 'Enter your code number',
        contentHint: 'Enter the code of your barcode, or the content of the QR-code',
        listItems: {
            activeCustomer: {
                title: 'Create as many coupons as you want',
                message: 'More coupons reach more users',
                button: 'Create a new coupon'
            },
            customer: {
                title: 'Create a coupon for <br> {company} <br> now',
                message: 'Reach new customers in your area',
                button: 'Create coupon'
            },
            user: {
                title: 'Are you an entrepreneur?',
                message: 'Reach new customers in your area',
                button: 'Register company'
            },
        },
        affiliate: {
            title: 'No coupon near you?',
            message: 'Get € 50 for every referral!',
            button: 'This is how it works'
        },

        noEntries: {
            title: 'No coupons found',
            message: 'Get € 50 for every referral!',
            button: 'This is how it works'
        },
        redeem: {
            cameraTooltip: 'Please scan the entrepreneur\'s QR code'
        },
        newCouponBadgeText: 'NEW',
        filter: {
            filterButton: 'Filter',
            selectCategory: 'Choose categories',
            onlyLatestCoupons: 'Show only new ones',
            locationRadius: "Within a radius of"
        },
        loadingList: 'Update coupons',
        confirmDeleteAlert: {
            title: 'Delete coupon',
            message: 'Do you really want to delete the coupon? This action can not be undone!'
        },
        limitReachedAlert: {
            title: 'Coupon Limit has exceeded',
            message: 'Upgrade your subscription to add new Coupons',
        },
    },
    locationDrawer: {
        title: 'No location available',
        button: 'Determine now',
        subtitle: 'Determine my location'
    },
    voucher: {
        loadingRedeem: 'One moment please, we\'re checking your voucher',
        redeemSuccessful: {
            title: '',
            message: 'The redemption was successful, the discounted tariffs are now available for selection'
        }
    },
    shop: {
        email: 'Store email'
    },
    error: {
        generic: {
            title: 'error',
            message: 'An unexpected error occurred, please try again later'
        },
        noConnection: {
            title: 'No Internet connection',
            message: 'Please check your internet connection and try again'
        },
        rateLimit: {
            title: 'Too many inquiries',
            message: 'You have sent too many requests to our server. Please wait a few minutes before continuing your journey.'
        },
        noConnectionLogin: {
            title: 'No Internet connection',
            message: 'Unfortunately we could not send you a login PIN because there is no internet connection.'
        },
        loginPinRateLimit: {
            title: '',
            message: 'You have requested too many login PINs. Please wait until% {nextRequest} before trying again.'
        },
        smsDeliveryFailed: {
            title: 'Sending SMS failed',
            message: 'We couldn\'t send you an SMS with a login PIN, please check your phone number'
        },
        couponRedeem: {
            iosCameraPermission: {
                title: 'QR code cannot be read',
                message: 'Unfortunately we could not read the QR code. Please try again or check the Coupocket camera permissions in your iOS settings. "Settings"> "Coupocket"> "Camera"'
            },
            qrCodeInvalid: {
                title: 'QR code cannot be read',
                message: 'Couldn\'t read QR code, please try again'
            }
        },
        invalidUserStateForBonusCardRedeem: {
            title: 'Stamping failed',
            message: 'The collective pass or its owner are not active, which is why stamping is not possible'
        },
        noGeocodingResult: {
            title: 'No location found',
            message: 'Unfortunately no location could be found for the search "{query}". Please check the address you entered and your internet connection.',
        },
        atLeastOneStoreRequired: {
            title: 'The branch could not be deleted',
            message: 'Possible reasons are that every customer needs at least one branch or a failed connection to the server.',
        },
        genericMessage: 'We couldn\'t connect to our server, please check your internet connection.',
        genericApiMessage: 'We couldn\'t connect to our server, please check your internet connection.',
        wrongRedeemToken: 'Invalid QR code, no redemption possible',
        invalidLoginPin: 'Unfortunately, this login PIN is invalid.',
        invalidPhoneNumber: 'Please enter a valid phone number',
        invalidValue: 'Please enter a valid value',
        invalidVoucher: 'The voucher code is unfortunately invalid',
        invalidSelect: 'Please select a value',
        invalidCategory: 'Please select a category',
        invalidShop: 'Please select a shop',
        invalidUrl: 'Please enter the link including http: // or https: //',
        invalidImage: 'Please select an image',
        invalidDate: 'Please enter a valid date',
        invalidDateTime: 'Please enter valid values ​​(date and time)',
        invalidEmail: 'Please enter a valid email',
        invalidAddress: 'Please enter a valid address',
        invalidUserData: 'Please check the entered user data.',
        invalidVatId: 'Please enter a valid VAT number or leave the field blank if you do not have a VAT number',
        invalidForm: 'Please check the entered data',
        redeemLimitReached: 'You have already redeemed the coupon ',
        fileTooLarge: 'The selected file is unfortunately too big. Please zoom out or choose a different file before uploading.',
        policyError: 'Please accept our terms and conditions and privacy policy',
        couponCreateError: 'Sorry, your data could not be saved. Please check your internet connection',
        desktopDeviceError: 'Sorry, this view is not available on mobile. Please open Coupocket on your desktop/notebook',
        shopDeleteError: 'Shop cannot be deleted because there are still active coupons',
        invalidEmailError: 'Could not send mail. Please check if you have a valid email address in your company profile',
        couponWizardError: 'Sorry, an error occurred, please try again later',
        invalidFields: 'Please correct the incorrect fields.',
        invalidOpeningHours: 'Please enter valid opening hours',
        invalidPin: 'Invalid PIN',
        invalidPaymentDetails: 'Please enter valid payment details'
    },
    success: {
        baseData: {
            message: 'Data saved'
        },
        dataUpdated: {
            title: 'success',
            message: 'Your data has been updated'
        },
        paymentDataUpdated: {
            title: 'success',
            message: 'Your payment details have been saved'
        },
        logout: {
            message: 'Successfully logged out'
        },
        emailSent: 'Email was sent',
        openingHoursUpdated: 'Opening hours updated'
    },
    confirm: {
        login: {
            title: 'Registration required',
            message: 'You have to register for this promotion. Do you want to continue or stay on the current page?'
        },
        sendPin: {
            title: 'Check number',
            message: 'The login PIN is sent to {phone}. Is the number correct?'
        },
        tos: {
            title: 'Leave this page?',
            message: 'You have not yet accepted the terms and conditions and privacy policy. Do you really want to leave the site?'
        },
        store: {
            deleteBranch: {
                title: 'Delete branch really?',
                message: 'This process cannot be undone!'
            }
        },
        bonusCardDelete: {
            title: 'Delete collective passport',
            message: 'Do you really want to delete the collective passport? This operation cannot be undone!'
        },
        logout: {
            message: 'Do you really want to log out?'
        },
        shop: {
            title: 'Really delete?',
            message: 'Do you really want to delete {name} ? This is only possible if the store has no active coupons.'
        },
        membership: {
            message: 'In order to publish coupons, we still need your payment details. Do you want to provide them now?',
            text: 'Do you really want to select {title}?'
        },
        deleteAccount: {
            title: 'Delete account',
            text: 'Are you sure you want to delete this account? Active memberships and created coupons will be deleted.',
            button: 'Confirm Deletion'
        }
    },
    pages: {
        bonusCard: {
            detail: {
                description: 'description',
                legalNotice: 'Important informations',
                alreadyReceivedBonuses: 'Bonuses already received',
                notYetActive: 'Not yet active',
                expired: 'Expired',
                inReview: 'In examination',
                publish: 'Publish collective passport',
                redeemLoading: 'One moment please, we\'re checking the QR code.',
                redeem: 'Get the stamp',
                redeemSuccess: {
                    title: '',
                    message: 'The collective pass was successfully stamped.'
                },
                collectBonus: 'Get your bonus now',
                collectBonusSuccess: {
                    title: '',
                    message: 'Redeemed successfully. Have fun with your bonus!'
                },
                share: {
                    loadingMessagePrepareLink: 'One moment please, we\'re generating a link to share ...',
                    createDeepLinkFailed: {
                        title: '',
                        message: 'Unfortunately, we could not generate a link to this collective pass, the connection may have been interrupted. Please try again.'
                    },
                    subject: 'Redeem now at collect pass',
                },
                explanation: 'This is how it works',
            },
            list: {
                title: 'Collective passes for you'
            },
            draftList: {
                title: 'My designs',
                empty: 'No drafts available'
            },
            reviewList: {
                title: 'Collective passes in the checking process',
                empty: 'No collective passes under examination'
            },
            scheduledList: {
                title: 'My planned collective passes',
                empty: 'No planned collective passes'
            },
            activeList: {
                title: 'Published collective passports',
                empty: 'No collective passes active'
            },
            archiveList: {
                title: 'Archivierte Sammelpässe',
                empty: 'Archived collective passports'
            },
            wizardBaseData: {
                title: 'Create collective passport',
                text: 'In order to publish your collective passport, we need some basic data'
            },
            stastistic: {
                title: 'Coupons redeemed with you',
                button: 'Export current view',
                tableColumn1: 'Coupon',
                tableColumn2: 'From',
                tableColumn3: 'Date',
                empty: 'No matches found for the current filter settings.',
                alert: 'We have sent you an overview of the redemptions by e-mail',
                last50: 'Last 50 redemptions',
                currentYear: 'Current year ({year})',
                lastYear: 'Last year ({year})'
            }
        },
        coupon: {
            list: {
                title: 'Coupons for you',
                closeBy: 'Near',
            },
            detail: {
                title: 'Coupon',
                shareCoupon: {
                    loadingMessagePrepareCouponLink: 'One moment please, we\'re generating a link to share ...',
                    createDeepLinkFailed: {
                        title: '',
                        message: 'Unfortunately we could not generate a link to this coupon, the connection may have been interrupted. Please try again.'
                    },
                    subject: 'Redeem now at Coupocket',
                },
                latestCoupons: 'Latest coupons',
                editNotAllowed: 'The coupon is currently being checked or has already expired and can therefore not be edited.',
                redeem: 'Redeem this coupon',
                redeemOnline: 'Redeem online',
                publish: 'Publish coupon',
                expired: 'Expired',
                notYetActive: 'Not yet active',
                alreadyRedeemed: 'Already redeemed',
                lastRedeemedOn: 'Last redeemed on',
                info: 'Important info',
                confirm: {
                    text: 'Your coupon code',
                    shop: 'To the Online Shop'
                },
                explanation: 'This is how it works'
            },
            empty: {
                title: 'No coupons available',
                button: 'Find coupons now',
                text: 'No coupon found'
            },
            stastistic: {
                title: 'Coupons redeemed with you',
                button: 'Export current view',
                tableColumn1: 'Coupon',
                tableColumn2: 'Shop',
                tableColumn3: 'From',
                tableColumn4: 'Date',
                empty: 'No matches found for the current filter settings.',
                alert: 'We have sent you an overview of the redemptions by e-mail',
                last50: 'Last 50 redemptions',
                currentYear: 'Current year ({year})',
                lastYear: 'Last year ({year})'
            }
        },
        customer: {
            profile: {
                baseData: {
                    title: 'company',
                    subtitle: 'Basisdaten',
                    addressLabel: 'Billing address',
                    imageLabel: 'Coupon image'
                },
                openingTimes: {
                    title: 'company',
                    text: 'opening hours',
                },
                qrCode: {
                    title: 'company',
                    subtitle: 'QR-Code',
                    alert: 'We have sent the QR code to {mail}.'
                },
                membership: {
                    title: 'subscription',
                    paymentMethodRequiredForPublish: 'In order to publish coupons, we still need your payment details.',
                    aboNotActive: {
                        title: 'Subscription not yet active',
                        message: 'Please complete your payment details to activate the subscription.',
                    },
                    change: 'Change subscription',
                    chooseMembership: 'Choose your suitable offer',
                    faqTitle: 'The most important questions about it'
                },
                payment: {
                    title: 'Payment data',
                    button: 'Complete payment data',
                    message: 'We need your payment data, if your membership is not free.',
                    statusLabel: 'Current status',
                    paymentMethod: 'Payment method',
                    cardOrAccount: 'Card/Account',
                    noPayment: 'No payment data stored.',
                    newPayment: 'Enter new payment data',
                    info: 'Your payment details are necessary for publishing your coupons',
                    loadingMessage: 'We are checking your payment details now, one moment please.'
                },
                payment: 'Payment details',
                stores: {
                    title: 'Branches',
                    subtitleList: 'Administer',
                    subtitleCreate: 'New branch',
                    subtitleEdit: 'Edit branch ({address})',
                    button: 'Add branch'
                }
            },
            registration: {
                company: {
                    title: 'A good choice!',
                    message: 'Present your company on Coupocket. Only a few steps are necessary.',
                    button: 'Basic data',
                },
                openingTimes: {
                    title: 'The opening hours of your company',
                    text: 'Your customers will see if and when you are open.',
                    subtitle: 'Opening hours',
                    label: 'My company has opening hours'
                },
                membership: {
                    title: 'Select offer <br>',
                },
                payment: {
                    title: 'Enter payment details <br>',
                    text: 'Your payment details are necessary for the publication of your collective passports.',
                },
                finish: {
                    title: 'Congratulations',
                    text: 'Your company is active now!',
                    couponIntro: 'Create coupons now and benefit!',
                    bonusCardIntro: 'Create collective passes now and benefit!',
                    customerStoriesIntro: 'Your company has several branches? Now create all the branches to be represented as well as possible in Coupocket.'
                }
            },
            coupon: {
                archive: {
                    title: 'My archived coupons',
                    empty: 'No archived coupons'
                },
                create: {
                    title: 'For your coupon we need some basic data',
                    titleLabel: 'Create coupon',
                },
                draft: {
                    title: 'My drafts',
                    empty: 'No designs available'
                },
                wizardFinish: {
                    title: 'Ready!',
                    text1: 'The coupon has been saved in your drafts.',
                    text2: 'You can review it there, edit it or, if you are satisfied with all the content, release it for review,',
                    button: 'My designs view'
                },
                wizardOnline: {
                    title: 'Create coupon',
                    text: 'You have an online store?<br>Put the address and the corresponding redemption code so that we can link to it.',
                    redeemable: 'My coupon is redeemable online',
                },
                published: {
                    title: 'Published coupons',
                    empty: 'No coupons active'
                },
                review: {
                    title: 'Coupons in the review process',
                    empty: 'No coupons under review'
                },
                scheduled: {
                    title: 'My scheduled coupons',
                    empty: 'No scheduled coupons'
                }
            },
            publicBonusCard: {
                title: 'No collective passports available',
                button: 'Find collection passports now'
            }
        },
        general: {
            notificationList: {
                title: 'Your messages',
                noMessage: 'There are no messages at the moment.'
            },
            coupocketForCompany: {
                title: 'The best coupon app<br>for your business',
                feature1: 'Set coupons and promotions',
                feature2: 'Attract new customers or guests',
                feature3: 'Increase awareness',
                feature4: 'Generate more sales',
                feature5: 'Can be cancelled up to 2 days before the end of the term',
                title2: 'Discover the world<br>of coupon marketing!',
                message: 'With Coupocket, your business will be seen. You can post as many discount promotions or other coupons as you want. You have full control! You decide how many times each coupon can be redeemed by one of your customers and in which period the promotion is valid. In addition, you have the free choice to limit the number of total redemptions or to make your promotion available without limits.',
                title3: 'How Coupocket works',
                list1: {
                    title: 'Advertise unlimitedly',
                    message: 'Create promotions and coupons directly from your cell phone at any time.'
                },
                list2: {
                    title: 'Available anywhere and anytime',
                    message: 'Every Coupocket user will see your promotions and will be motivated to visit your business. As a result, you will continue to gain awareness and generate additional sales.'
                },
                list3: {
                    title: 'Your personal digital statistics',
                    message: 'Keep track of your active and past promotions at all times. See how many times a coupon has been redeemed. You can measure the performance of your activities at any time.'
                }
            },
            help: {
                title: 'Frequently asked questions',
            },
            introduction: {
                slide1: {
                    title: 'Welcome to Coupocket!',
                    message: 'Here you can find the best offers from your region',
                    button: 'Next'
                },
                slide2: {
                    title: 'Offers near you!',
                    message: 'We would like to show you offers in your area. Please allow us to access your location, so that you always receive relevant offers for you.',
                    button: 'Share location',
                    skip: 'Skip location sharing'
                },
                slide3: {
                    title: 'Coupocket is ready!',
                    message: 'We wish you a lot of fun with Coupocket.',
                    button: 'Start now'
                },
                authAlert: {
                    title: 'Grant authorization',
                    message: 'Please confirm in the following dialog that we may use your location.'
                },
                locationAlert: {
                    title: 'Location unknown',
                    message: 'Unfortunately, we could not determine your location. You can still use Coupocket, but the suggested offers may be less relevant for you.'
                }
            },
            share: {
                title: 'Invite friends',
                message: 'Share Coupocket with your friends',
                button: 'Share with friends'
            }
        },
        invoice: {
            label: 'Rechnung',
            date: 'Datum',
            amount: 'Betrag'
        },
        user: {
            profile: {
                baseData: {
                    title: 'Edit my profile',
                },
                favorites: {
                    title: 'My favorites',
                    empty: 'No favorites available',
                    button: 'Find coupons now'
                }
            },
            affiliate: {
                title: 'My account balance',
                recruitedMember: '{count} companies',
                recruitedMemberDescription: 'have registered via your link',
                payingRecruitedMember: '{count1} of {count2}',
                payingRecruitedMemberDescription: 'are already paying customers at Coupocket',
                readyForPayoutRecruitedMember: '{count1} of {count2}',
                readyForPayoutRecruitedMemberDescription: 'have qualified for your referral bonus',
                accountBalance: '€ {accountBalance} credit',
                accountBalanceDescription: 'are currently on your Coupocket account',
                payoutCredit: 'Pay out credit',
                inviteCompany: 'Invite company',
                inviteMoreCompany: 'Invite more companies',
                emptyReferral: 'You don\'t have a successful referral yet.',
                bonusEarned: 'Earned Bonus',
                invited: 'Invited',
                registered: 'Registred',
                eligible: 'Eligible',
            },
            pin: {
                title: 'You have received a PIN',
                resend: 'Resend pin',
                loadingMessage: 'One moment please, the PIN is being checked.'
            }
        }
    },
    notification: {
        askPermissionFirstTime: {
            title: 'Notifications',
            message: 'Please accept the following authorization request so that we can inform you about new coupons and offers in a timely manner.'
        },
        askPermissionNextTime: {
            title: 'Notifications',
            message: 'Unfortunately, we are currently unable to inform you about new coupons and offers. Please check Coupocket\'s permissions in your iOS settings: <br> "Settings"> "Notifications", <br> select "Coupocket" and tap on "Allow notifications".'
        },
        confirmDeleteAlert: {
            title: 'Are you sure you want to delete the message?',
            message: 'The deletion cannot be undone.'
        },
        askUsageType: {
            message: 'We would like to inform you about offers and promotions. Please describe briefly how you would like to use Coupocket.',
            usagePrivate: 'Exclusively private',
            usageEntrepreneur: 'Private and as an entrepreneur'
        }
    },
    openingTimes: {
        isCurrentlyOpen: 'now opened',
        isCurrentlyClosed: 'Now closed',
        closed: 'closed',
        usePreviousDayValue: 'Adopt values from previous day'
    },
    store: {
        create: 'Create a branch'
    },
    outdated: {
        title: 'Please update Coupocket to use all the new features.',
        android: 'Update now in Play Store',
        ios: 'Update now in the App Store',
        button: 'Update now',
    },
    altText: {
        selectPrefix: 'Selected phhone number prefix country flag'
    },
    publishAlert: {
        hasNoMembership: {
            title: 'Subscription required',
            message: 'To publish a {type} you must select a subscription. Do you want to do this now?',
        },
        hasNoPaymentWithNonFreeMembership: {
            title: 'Payment data required',
            message: 'To publish a {type} you need to enter your payment details. Do you want to do this now?',
        }
    },
    reverseRedeemHintAlert: '<p><strong>Customer scans QR code</strong></p><p>The Customer has to scan you company QR code in order to redeem this coupon/Bonuscard</p><p><strong>Company scans QR/Barcode</strong></p><p>The Application will generate QR code/Barcode, customer has to show this in your companys code scanners.</p>'
};
