require('@/constants');

import CustomerPublicCouponList from '@/pages/CustomerPublic/CouponList.vue';
import CustomerPublicBonusCardList from '@/pages/CustomerPublic/BonusCardList.vue';


export default [
    {
        path: '/customer-public/coupon-list/:id',
        name: 'CustomerPublicCouponList',
        component: CustomerPublicCouponList,
    },
    {
        path: '/customer-public/bonus-card-list/:id',
        name: 'CustomerPublicBonusCardList',
        component: CustomerPublicBonusCardList,
    },
];
