<template>
    <div class="c3-icon-wrapper">
        <svg version="1.1" viewBox="0 0 487.11 320.17" xmlns="http://www.w3.org/2000/svg">
            <path :fill="color1"
                  d="m424 0h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm164 92h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72z"/>
            <path :fill="color2"
                  d="m464.21 160.77a2.874 2.874 0 0 0-2.529 1.6211l-67.225 126.47-56.599-32.678c-1.44-0.831-3.267-0.3416-4.098 1.0977l-8.172 14.152c-0.831 1.4394-0.341 3.2667 1.098 4.0977l73.957 42.699c0.493 0.2847 1.032 0.405 1.56 0.3946a2.874 2.874 0 0 0 3.073-1.5801l5.605-10.545 2.031-3.5196c0.173-0.2988 0.274-0.6156 0.336-0.9355l67.784-127.52a3.416 3.416 0 0 0-1.1-4.4531l-14.152-8.875a2.874 2.874 0 0 0-1.569-0.4277z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
