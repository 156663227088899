export const FormFieldErrorMixin = {
    props: {
        validator: {
            type: Object,
        },
        hasError: {
            type: Boolean,
            default: false
        },
        errorIcon: {
            type: Boolean,
            default: true
        },
        errorMessage: {
            type: [String, Boolean],
            default: 'error.invalidValue'
        },
    },
    data() {
        return {}
    },
    computed: {
        isFaulty() {
            return (this.validator && this.validator.$error) || this.hasError;
        },
        showErrorIcon() {
            return this.isFaulty && this.errorIcon;
        },
        showErrorMessage() {
            return this.isFaulty && this.errorMessage;
        }
    },
    methods: {},
};