<template>
    <div class="c3-main">
        <div class="c3-page-content c3-page-content-no-spacing">
            <div class="c3-profile-page">
                <div class="c3-profile-page-header">
                    <slot name="header">
                        <c3-title-with-subtitle v-if="title || subtitle" :title="title" :subtitle="subtitle"/>
                    </slot>
                </div>
                <div class="c3-profile-page-form">
                    <slot></slot>
                </div>
                <div class="c3-profile-page-footer">
                    <slot name="footer">
                        <c3-button-list :button="footerButton"/>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import C3ButtonList from '@/components/layout/button/list/Index';
    import C3TitleWithSubtitle from '@/components/content/text/TitleWithSubtitle';

    export default {
        components: {
            C3ButtonList,
            C3TitleWithSubtitle
        },
        props: {
            updateUserOnCreated: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
            },
            subtitle: {
                type: String,
            },
            footerButton: {
                type: Array,
                default: () => []
            }
        },
        methods: {},
        async created() {
            if (this.updateUserOnCreated) {
                this.$store.commit('view/showLoading');
                try {
                    await Promise.all([this.$store.dispatch('user/loadCurrentUser'), this.$store.dispatch('staticContent/loadCountries')]);
                    this.$emit('userReady');
                } catch (e) {
                    console.error('[FormPage.vue] updateUserOnCreated failed', e);
                }
                this.$store.commit('view/hideLoading');
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-main {
        background-color: $background-color-alternative-section;
    }

    .c3-profile-page {
        @media(min-width: $breakpointTablet+1) {
            max-width: 430px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c3-profile-page-header {
        padding: var(--page-content-horizontal-big-spacing) var(--page-content-horizontal-big-spacing) 0 var(--page-content-horizontal-big-spacing);
    }

    .c3-profile-page-footer {
        padding: 15px var(--page-content-horizontal-big-spacing) var(--page-content-horizontal-big-spacing) var(--page-content-horizontal-big-spacing);
    }
</style>