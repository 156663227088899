/**
 *
 * @param {File} file
 */
export const ImageResize = function (file) {
    const QUALITY = 0.85;

    /**
     *
     * @param {Number} width
     * @returns {Promise<Object>}
     */
    this.resizeByWidthFast = async function (width) {
        const objectUrl = URL.createObjectURL(file);
        const img = await createImgElement(objectUrl);

        const aspectRatio = img.width / img.height;
        const height = width / aspectRatio;

        let canvasRaw = createCanvasElement(width, height);
        canvasRaw.getContext('2d').drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
        const blob = await new Promise(resolve => canvasRaw.toBlob(resolve, 'image/jpeg', QUALITY));
        const resizeObjectUrl = URL.createObjectURL(blob);

        URL.revokeObjectURL(objectUrl);

        return {
            objectUrl: resizeObjectUrl,
            width: width,
            height: height
        }
    };

    function createCanvasElement(width, height) {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        return canvas;
    }

    async function createImgElement(src) {
        const img = document.createElement('img');
        img.src = src;
        await new Promise((resolve, reject) => {
            img.onerror = reject;
            img.onabort = reject;
            img.onload = resolve;
        });
        return img;
    }
};