import Vue from 'vue';

export const updateContent = (state, data) => {
    data.forEach(singleContent => state.content[singleContent.id] = singleContent);
    state.content = Object.assign({}, state.content);
};

export const updateSingleContent = (state, singleContent) => {
    Vue.set(state.content, singleContent.id, singleContent);
};

export const updateCountries = (state, data) => {
    data.forEach(country => state.countries[country.id] = country);
    state.countries = Object.assign({}, state.countries);
};

export const updateSinglePage = (state, item) => {
    Vue.set(state.pages, item.id, item);
};
