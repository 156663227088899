<template>
    <div class="c3-list-icon-wrapper">
        <ul class="c3-list-icon">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {},
        computed: {},
        methods: {}
    };
</script>
<style lang="scss" scoped>
    ul {
        list-style: none;
        padding: 0;
    }
</style>