import Vue from 'vue';

export const updateBonusCard = (state, items) => {
    items.forEach(item => {
        Vue.set(state.bonusCards.items, item.id, item);
    });
};

export const removeOneBonusCard = (state, item) => {
    if (state.bonusCards.items.hasOwnProperty(item.id)) {
        Vue.delete(state.bonusCards.items, item.id);
    }
};

export const updateRedeemState = (state, items) => {
    items.forEach(item => {
        Vue.set(state.bonusCards.redeemState, item.bonusCard, item);
    });
};

export const updateFavorite = (state, items) => {
    items.forEach(item => {
        Vue.set(state.bonusCards.favoritesByBonusCard, item.bonusCard, item);
    });
};

export const replaceFavorite = (state, items) => {
    state.bonusCards.favoritesByBonusCard = {};
    updateFavorite(state, items);
};

export const addOneFavorite = (state, item) => {
    Vue.set(state.bonusCards.favoritesByBonusCard, item.bonusCard, item);
};

export const removeOneFavorite = (state, item) => {
    if (state.bonusCards.favoritesByBonusCard.hasOwnProperty(item.bonusCard)) {
        Vue.delete(state.bonusCards.favoritesByBonusCard, item.bonusCard);
    }
};

export const removeOneFavoriteByBonusCard = (state, bonusCardId) => {
    if (state.bonusCards.favoritesByBonusCard.hasOwnProperty(bonusCardId)) {
        Vue.delete(state.bonusCards.favoritesByBonusCard, bonusCardId);
    }
};

export const updateBonusCardTemplate = (state, templates) => {
    state.bonusCards.bonusCardTemplates = templates;
}

export const updateBonusCardLimit = (state, limit) => {
    if (limit.hasOwnProperty('maxBonusCardLimit')) {
        state.bonusCards.maxBonusCardLimit = limit.maxBonusCardLimit;
    }

    if (limit.hasOwnProperty('activeBonusCardCount')) {
        state.bonusCards.activeBonusCardCount = limit.activeBonusCardCount;
    }
};
