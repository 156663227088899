<template>
    <div class="c3-form-field-group-phone-number" :class="{'c3-form-field-group-phone-number--has-error':validator.$error}">
        <div ref="phoneNumberPrefixSelectGroup" class="c3-form-field-group" :class="{'c3-form-field-group--has-error':prefixHasError}">
            <v-select ref="phoneNumberPrefixSelect" v-model="phone.prefix" :options="options" :clearable="false" :searchable="false" @change="emitUpdate" @input="emitUpdate">
                <template slot="selected-option" slot-scope="selectedOption">
                    <div @click="togglePrefixSelect">
                        <img class="c3-country-flag" :src="countryFlagSrc(selectedOption.code)" :alt="$t('altText.selectPrefix')"/>
                        <span>{{ selectedOption.label }}</span>
                    </div>
                </template>
                <template slot="option" slot-scope="option">
                    <img class="c3-country-flag" :src="countryFlagSrc(option.code)" alt="Phone number prefix country flag"/>
                    <span>{{ option.label }}</span>
                </template>
            </v-select>
        </div>
        <div class="c3-form-row">
            <input type="text" :value="phone.prefix.value" readonly class="c3-form-field-input c3-form-field-phone-number-prefix-preview" @click="togglePrefixSelect"/>
            <c3-form-input type="tel" v-model="phone.number" placeholder="Nummer" @input="emitUpdate" :errorMessage="false" :hasError="numberHasError"/>
        </div>
        <div class="c3-form-field-group-phone-number-error-message" v-if="errorMessage">
            {{$t(errorMessage)}}
        </div>
    </div>
</template>

<script>
    import phoneNumberService from '@/domain/service/phoneNumberService';
    import phoneNumberFactory from '@/domain/factory/phoneNumber';

    export default {
        props: {
            value: {
                type: String
            },
            label: {
                type: String
            },
            name: {
                type: String,
            },
            autocomplete: {
                type: String,
            },
            validator: {
                type: Object,
            },
            errorIcon: {
                type: Boolean,
                default: true
            },
            errorMessage: {
                type: [String, Boolean],
                default: 'error.invalidPhoneNumber'
            },
            invert: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                phone: {
                    prefix: '',
                    number: '',
                },
                options: phoneNumberFactory.createInternationAreaCodeOptions()
            }
        },
        computed: {
            numberHasError() {
                return this.validator.$error && this.phone.number.length < 5;
            },
            prefixHasError() {
                return this.validator.$error && this.phone.prefix.value.length < 3;
            }
        },
        methods: {
            togglePrefixSelect() {
                this.$refs.phoneNumberPrefixSelect.toggleDropdown({target: this.$refs.phoneNumberPrefixSelect.$refs.toggle});
            },
            emitUpdate() {
                this.$emit('input', this.phone.prefix.value + this.phone.number);
                this.$emit('sanitizedInput', phoneNumberService.sanitize(this.phone.prefix.value, this.phone.number));
            },
            countryFlagSrc(code) {
                if (typeof code !== 'string') {
                    return '';
                }
                code = code.toLowerCase();
                return require('@/assets/images/countryFlags/' + code + '.png');
            }
        },
        mounted() {
            this.phone.prefix = this.options[0];
            const { query: { phone } } = this.$route;
            if (phone) {
                const prefix = this.options.find(option => phone.indexOf(option.value) > -1);
                if (!prefix)
                    return;
                this.phone.prefix = prefix
                this.phone.number = phone.replace(prefix.value, '');
            }
        }
    };
</script>

<style lang="scss" scoped>
    /deep/ .c3-form-field-group-error-message {
        display: none;
    }

    .c3-form-row {
        display: flex;
    }

    .c3-form-field-phone-number-prefix-preview {
        color: #aaa;
        text-align: center;
        width: 60px;
        margin-top: 15px;
        margin-right: 15px;
    }
</style>
