import moment from "moment";

export const isLoggedIn = (state) => {
    return state.token !== null;
};

export const isImpersonate = (state) => {
    return state.tokenOriginalUser !== null;
};

export const token = (state) => {
    return state.token;
};

export const position = (state) => {
    return state.position;
};

export const positionAsCoordinate = (state) => {
    if (null === state.position.coordinates.latitude || null === state.position.coordinates.longitude) {
        return null;
    }
    return {latitude: state.position.coordinates.latitude, longitude: state.position.coordinates.longitude};
};

export const isDevicePosition = (state) => {
    try {
        let accuracy = state.position.coordinates.accuracy;
        return accuracy !== 'IP' && accuracy < 5000;
    } catch (e) {
        return false;
    }
};

export const user = (state) => {
    return state.user;
};

export const originalUser = (state) => {
    return state.originalUser;
};

export const payment = (state) => {
    return state.payment;
};

export const hasMembershipAndPayment = (state) => {
    return state.memberships.length && state.payment;
};

export const hasMembership = (state) => {
    return state.memberships.length > 0;
};

export const hasNonFreeMembership = (state) => {
    const nonFreeMembership = state.memberships.filter(membership => {
        const isNotExpired = moment().isSameOrBefore(membership.validUntil);
        return membership.type.price > 0 && isNotExpired;
    });

    return nonFreeMembership.length > 0;
};

export const hasFreeMembership = (state) => {
    return state.memberships.some(membership => {
        const isNotExpired = moment().isSameOrBefore(membership.validUntil);
        return membership.type.price === 0 && isNotExpired;
    });
};

export const singleRedeemState = (state) => (coupon) => {
    return state.redeemStates[coupon];
};

export const visibleFavoriteCoupon = (state) => {
    return state.visibleFavoriteCoupon;
};

export const isFavoriteByCoupon = (state) => (coupon) => {
    if (!Array.isArray(state.visibleFavoriteCoupon)) {
        return null;
    }

    return !!state.visibleFavoriteCoupon.some(item => item.coupon === coupon);
};

export const hasAcceptedContracts = (state) => {
    return state.user && state.user.tos && state.user.privacyPolicy;
};

export const hasUserImpersonates = (state) => {
    return state.user && state.user.hasUserImpersonates;
};

export const hasRole = (state) => (role) => {
    if (!state.user || !Array.isArray(state.user.roles)) {
        return false;
    }
    return state.user.roles.indexOf(role) > -1;
};

export const isCustomer = (state) => {
    return hasRole(state)(USER_ROLE.CUSTOMER);
};

export const isAdmin = (state) => {
    return hasRole(state)(USER_ROLE.ADMIN);
};


export const affiliateToken = (state) => state.affiliateToken;

export const registerdAffiliates = (state) => {
    return state.affiliates.filter(item => item.state === 'registered');
};

export const eligibleAffiliates = (state) => {
    return state.affiliates.filter(item => item.state === 'published');
}

export const earnedAffiliateBonus = (state, getters) => {
    if (!getters.eligibleAffiliates.length) {
        return 0;
    }
    return getters.eligibleAffiliates.map(item => item.commission).reduce((c, s) => c + s);
}

export const affiliates = (state) => state.affiliates;
