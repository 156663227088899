import {Config} from '@/config';
import axios from 'axios';
import store from '@/store';
import unauthorizedHandler from '@/domain/api/error/unauthorizedHandler';
import notFoundHandler from '@/domain/api/error/notFoundHandler';
import networkHandler from '@/domain/api/error/networkHandler';
import genericHandler from '@/domain/api/error/genericHandler';
import rateLimitHandler from '@/domain/api/error/rateLimitHandler';

export const HTTP = axios.create({
    baseURL: window.API_BASE_URL,
    timeout: 20000,
});

HTTP.interceptors.request.use((config) => {
    // Add token, if user is logged in
    if (store.getters['user/isLoggedIn'] && typeof config.headers.Authorization === 'undefined') {
        config.headers.Authorization = store.getters['user/token'];
    }

    if (store.state.notification.pushId) {
        config.headers['Coupocket-Platform'] = window.device ? window.device.platform : 'browser';
        config.headers['Coupocket-Push-Type'] = store.state.notification.pushType;
        config.headers['Coupocket-Push-Id'] = store.state.notification.pushId;
    }

    config.headers['Coupocket-Api-Version'] = Config.getApiVersion();
    config.headers['Coupocket-App-Version'] = Config.getVersion();

    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept = 'application/json';

    return config;
});

HTTP.interceptors.response.use(
    (response) => response,
    (error) => {
        // Do something with response error
        if (error.response && error.response.status === 401) {
            return Promise.reject(unauthorizedHandler(error).process());
        }
        if (error.response && error.response.status === 404) {
            return Promise.reject(notFoundHandler(error).process());
        }
        if (error.response && error.response.status === 429) {
            return Promise.reject(rateLimitHandler(error).process());
        }
        if (typeof error.message === 'string' && error.message === 'Network Error') {
            return Promise.reject(networkHandler(error).process());
        }
        return Promise.reject(genericHandler(error).process());
    }
);
