import {HTTP} from '@/domain/api/http';
import couponApiConverter from '@/domain/api/converter/coupon';
import userApiConverter from '@/domain/api/converter/user';
import {BonusCardFavoriteConverter} from '@/domain/api/converter/bonusCardFavorite';

export const apiCheck = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/api/check')
            .then(response => {
                context.commit('updateApiCheck', response.data.data);
                resolve();
            })
            .catch(error => {
                console.error(error);
                reject();
            });
    });
};

export const loadInitial = (context) => {
    return new Promise((resolve, reject) => {
        let url = '/initial';
        if (context.rootState.user.user && context.rootState.user.user.id) {
            url += '/' + context.rootState.user.user.id;
        }

        HTTP.get(API_BASE_URL + url)
            .then(response => {
                context.commit('updateApiCheck', response.data.data.apiCheck);
                context.commit('updateFeatureFlags', response.data.data.featureFlags);

                if (response.data.data.user) {
                    context.commit('user/updateUser', userApiConverter.fromApi(response.data.data.user), {root: true});
                }

                context.commit('staticContent/updateContent', response.data.data.staticContent, {root: true});

                context.commit('customer/updateCustomer', response.data.data.customer, {root: true});

                context.commit('coupon/updateCategory', response.data.data.couponCategory, {root: true});

                context.commit('coupon/updateList', couponApiConverter.fromApi(response.data.data.coupon), {root: true});

                if (Array.isArray(response.data.data.favorite)) {
                    context.commit('user/updateVisibleFavoriteCoupon', response.data.data.favorite, {root: true});
                }

                if (Array.isArray(response.data.data.bonusCardFavorite)) {
                    context.commit('bonusCard/replaceFavorite', BonusCardFavoriteConverter.fromApiCollection(response.data.data.bonusCardFavorite), {root: true});
                }

                resolve();
            })
            .catch(error => {
                console.error(error);
                reject();
            });

    });
};
