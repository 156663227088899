export const countNotification = (state) => {
    return state.message.length;
};

export const countUnreadNotification = (state) => {
    return state.message.filter(item => true === item.unread).length;
};

export const findNotification = (state) => {
    return state.message;
};