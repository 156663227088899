<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.77 24.18">
            <g transform="translate(-51 -871)">
                <path :fill="color1"
                      d="M14.81,13.6c-1.355,0-2.007.756-4.231.756S7.707,13.6,6.347,13.6A6.349,6.349,0,0,0,0,19.948v1.965a2.267,2.267,0,0,0,2.267,2.267H18.89a2.267,2.267,0,0,0,2.267-2.267V19.948A6.349,6.349,0,0,0,14.81,13.6Zm4.836,8.312a.758.758,0,0,1-.756.756H2.267a.758.758,0,0,1-.756-.756V19.948a4.839,4.839,0,0,1,4.836-4.836c.926,0,1.847.756,4.231.756s3.306-.756,4.231-.756a4.839,4.839,0,0,1,4.836,4.836ZM10.579,12.09A6.045,6.045,0,1,0,4.534,6.045,6.044,6.044,0,0,0,10.579,12.09Zm0-10.579A4.534,4.534,0,1,1,6.045,6.045,4.541,4.541,0,0,1,10.579,1.511Z"
                      transform="translate(51 871)"/>
                <line :stroke="color1" x2="11.657" transform="translate(71.114 875.5)"/>
                <line :stroke="color1" x2="10.449" transform="translate(72.321 881.5)"/>
                <line :stroke="color1" x2="8.27" transform="translate(74.5 887.5)"/>
            </g>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
