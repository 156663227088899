<template>
    <div class="c3-slider">
        <div class="c3-slider__body" ref="sliderBody">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="c3-slider__item-content">
                        <div class="c3-slider__item-title">{{ $t('pages.general.introduction.slide1.title') }}</div>
                        <div class="c3-slider__item-description">{{ $t('pages.general.introduction.slide1.message') }}</div>
                        <c3-button :label="$t('pages.general.introduction.slide1.button')" styling="2" @click="slideNext"/>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="c3-slider__item-content">
                        <div class="c3-slider__item-title">{{ $t('pages.general.introduction.slide2.title') }}</div>
                        <div class="c3-slider__item-description">{{ $t('pages.general.introduction.slide2.message') }}</div>
                        <c3-button :label="$t('pages.general.introduction.slide2.button')" styling="2" @click="updateLocation"/>
                        <div class="c3-slider__item-skip-location" @click="slideNext">{{ $t('pages.general.introduction.slide2.skip') }}</div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="c3-slider__item-content">
                        <div class="c3-slider__item-title">{{ $t('pages.general.introduction.slide3.title') }}</div>
                        <div class="c3-slider__item-description">{{ $t('pages.general.introduction.slide3.message') }}</div>
                        <c3-button :label="$t('pages.general.introduction.slide3.button')" styling="2" @click="finish"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const Swiper = require('swiper').default;

    import {Location} from '@/domain/location';
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {
            C3Button
        },
        data() {
            return {
                swiper: null
            }
        },
        mounted() {
            this.swiper = new Swiper(this.$refs.sliderBody, {
                slidesPerView: 1,
                resistance: true,
                resistanceRatio: 0,
                hashNavigation: true
            });
        },
        methods: {
            slideNext() {
                this.swiper.slideNext();
            },
            async updateLocation() {
                await this.$c3.alert('pages.general.introduction.authAlert.title', 'pages.general.introduction.authAlert.message');
                this.$store.commit('view/showLoading', this.$t('label.updatePositionLoading'));
                try {
                    await Location.getCurrentPosition(6000, 6000);
                    await this.$store.dispatch('viewCouponList/position', {name: 'CouponList', addressTimeout: 3000});
                    await this.$store.dispatch('viewCouponList/loadItems', {name: 'CouponList', forceReload: true});
                } catch (error) {
                    await this.$c3.alert('pages.general.introduction.locationAlert.title', 'pages.general.introduction.locationAlert.message')
                }
                this.$store.commit('view/hideLoading');
                this.slideNext();
            },
            finish() {
                this.$store.commit('general/updateHasSeenIntroductionSlides', true);
                this.$router.push({name: 'Home'});
            },
        }
    };
</script>

<style lang="scss" scoped>
    .c3-slider {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: $quinary-color;
    }

    .c3-slider__body {
        width: 100%;
        height: 100%;
        max-height: 800px;
        max-width: 580px;
        overflow: hidden;
    }

    .swiper-wrapper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        width: 100%;
        height: 100%;
    }

    .c3-slider__item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        @include horizontalSpacing();
        text-align: center;
    }

    .c3-slider__item-title {
        @include title();
        color: $white-color;
    }

    .c3-slider__item-description {
        margin-top: 10px;
        color: $white-color;
    }

    .c3-button {
        margin-top: 30px;
    }

    .c3-slider__item-skip-location {
        margin-top: 10px;
        color: $white-color;
        opacity: 0.8;
    }
</style>
