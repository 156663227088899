<template>
    <form class="c3-user-login-form c3-user-login-step" @submit.prevent="submit">
        <h2>{{ $t('pages.user.pin.title') }}</h2>
        <c3-form-input v-model="pin" type="number" :placeholder="$t('label.pin')" :validator="$v.pin" :errorMessage="false"/>
        <div class="c3-user-login-submit">
            <c3-form-submit :label="$t('label.login')" :invert="true"/>
            <div class="c3-user-login-registration-additional-information">
                <router-link :to="{name:'Home'}">Home</router-link>
                <span class="c3-user-login-registration-additional-information-separator">|</span>
                <div @click="resendLoginPin">{{ $t('pages.user.pin.resend') }}</div>
            </div>
        </div>
    </form>
</template>

<script>
    import {RouterMixin} from '@/domain/mixin/local/router';
    import {LoginMixin} from '@/domain/mixin/local/login';

    import {required, minLength, maxLength} from 'vuelidate/lib/validators'

    export default {
        mixins: [RouterMixin, LoginMixin],
        data() {
            return {
                pin: '',
                sanitizedPhoneNumber: this.$route.params.phone
            };
        },
        methods: {
            submit() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.login();
                }
            },
            async login() {
                this.$store.commit('view/showLoading', this.$t('pages.user.pin.loadingMessage'));
                let data = {phone: this.sanitizedPhoneNumber, pin: parseInt(this.pin)};
                if (this.$store.state.user.advertiser) {
                    data.advertiser = this.$store.state.user.advertiser;
                }

                try {
                    const user = await this.$store.dispatch('user/login', data);
                    const impersonateUsers = await this.$store.dispatch('user/loadImpersonateList');

                    if (!user.privacyPolicy || !user.tos) {
                        this.$router.push({name: 'RegistrationToS', query: this.getRedirectAfterLoginQueryObject()});
                        return;
                    }

                    if (impersonateUsers.length === 0) {
                        if (this.hasRedirectAfterLogin) {
                            this.pushRedirectAfterLogin();
                        } else {
                            this.$router.push(ROUTER.defaultLoginRedirect);
                        }
                        return;
                    }
                    
                    this.$router.push({name: 'LoginImpersonate', query: this.getRedirectAfterLoginQueryObject()});
                } catch (error) {
                    this.$c3.alert('error.generic.title', 'error.invalidPin');
                    console.error(error);
                } finally {
                    this.$store.commit('view/hideLoading');
                }
            }
        },
        created() {
            if (this.$store.getters['user/isLoggedIn']) {
                this.$router.push(ROUTER.defaultLoginRedirect);
            }
        },
        validations: {
            pin: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(8),
            }
        },
    };
</script>
