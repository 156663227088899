import {Analytics} from '@/domain/analytics';
import {Config} from '@/config';
import {HTTP} from '@/domain/api/http';
import paymentApiConverter from '@/domain/api/converter/payment';
import membershipApiConverter from '@/domain/api/converter/membership';
import userApiConverter from '@/domain/api/converter/user';
import couponApiConverter from '@/domain/api/converter/coupon';

export const initialize = (context) => {
    return new Promise((resolve, reject) => {
        context.commit('initialize');
        resolve();
    });
};

export const loadCurrentUser = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/user/' + context.state.user.id)
            .then(response => {
                let user = userApiConverter.fromApi(response.data.data);
                context.commit('updateUser', user);
                resolve(user)
            })
            .catch(error => reject(error));
    });
};

/**
 * Load the original user while impersonating another user:
 *
 * @param context
 * @return {Promise<unknown>}
 */
export const loadOriginalUser = async (context) => {
    if (context.state.tokenOriginalUser === null) {
        throw new Error('Loading the original user is only possible while impersonating another user');
    }
    return (await HTTP.get(API_BASE_URL + '/user/current', {headers: {Authorization: context.state.tokenOriginalUser}})).data.data;
};

export const loadAffiliateState = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/user/${context.state.user.id}/affiliate`)
            .then((response) => resolve(response.data.data))
            .catch(error => reject(error));
    });
};

export const loadCurrentPayment = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/user/${context.state.user.id}/payment`)
            .then(response => {
                let payment = paymentApiConverter.fromApi(response.data.data);
                context.commit('updatePayment', payment);
                resolve(payment);
            })
            .catch(error => {
                if (error.status === 404) {
                    // No error, user has no payment data
                    resolve();
                }
                reject(error);
            });
    });
};

export const updateAdvertiser = async (context, advertiser) => {
    try {
        console.debug('updateAdvertiser', advertiser, context.getters['isLoggedIn']);
        if (!context.getters['isLoggedIn']) {
            // Can't update advertiser on server, if user is not logged in
            return;
        }

        // Get advertiserId from advertiser token
        let advertiserId = Number.parseInt(advertiser);

        if (context.state.user && context.state.user.advertiser !== advertiserId) {
            context.commit('updateAdvertiser', context.state.user.advertiser, advertiserId);

            Analytics.affiliateAdvertiserUpdate({advertiser: advertiser, advertiserId: advertiserId});

            // Advertiser ID changed, update on server
            let response = await HTTP.patch(API_BASE_URL + `/user/${context.state.user.id}/affiliate/${advertiser}`);
            let user = userApiConverter.fromApi(response.data.data);
            context.commit('updateUser', user);
        }
    } catch (e) {
        console.error('updateAdvertiser with advertiser ' + advertiser + ' failed: ' + JSON.stringify(e));
    }
};

export const updatePaymentData = (context, data) => {
    return new Promise((resolve, reject) => {
        HTTP.put(API_BASE_URL + `/user/${context.state.user.id}/payment`, data)
            .then(() => resolve())
            .catch(error => reject(error));
    });
};

export const updateBillingAddress = (context, data) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + `/user/${context.state.user.id}/billing/address`, {data: data})
            .then(() => resolve())
            .catch(error => reject(error));
    });
};

export const loadActiveMemberships = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/user/${context.state.user.id}/membership/active`)
            .then(response => {
                let memberships = membershipApiConverter.fromApiCollection(response.data.data);
                context.commit('updateMemberships', memberships);
                resolve();
            })
            .catch(error => {
                if (error.status === 404) {
                    // No error, user has no memberships
                    context.commit('updateMemberships', []);
                    resolve();
                }
                reject(error);
            });
    });
};

export const updateMembership = (context, { type, instantUpdate }) => {
    return new Promise((resolve, reject) => {
        HTTP.put(API_BASE_URL + `/user/${context.state.user.id}/membership/${type}`, { instantUpdate })
            .then(() => resolve())
            .catch(error => reject(error));
    });
};

export const sendLoginCode = (context, phoneNumber) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + '/authorization/send-pin/' + phoneNumber)
            .then(resolve)
            .catch(reject);
    });
};

export const login = (context, data) => {
    return new Promise((resolve, reject) => {
        Analytics.logUserLoginBefore();
        HTTP.post(API_BASE_URL + '/authorization/login', data)
            .then(response => {
                let user = userApiConverter.fromApi(response.data.data.user);
                context.commit('updateUser', user);
                context.commit('updateToken', response.data.data.token);
                context.dispatch('notification/loadNotifications', null, {root: true});

                // Authorization endpoint returns only the public profile of a user (current limitation or our API), so we load the user profile again
                loadCurrentUser(context);

                try {
                    if (Config.isNativeRealDevice()) {
                        window.FirebasePlugin.setUserId(response.data.data.user.id);
                        window.FirebasePlugin.setCrashlyticsUserId(response.data.data.user.id);
                    }
                } catch (e) {
                    console.error('FirebasePlugin.setUserId failed', e);
                }
                Analytics.logUserLoginAfter();
                resolve(user);
            })
            .catch(error => reject(error));
    });
};

export const loadImpersonateList = async (context) => {
    if (context.state.tokenOriginalUser === null) {
        return (await HTTP.get(`${API_BASE_URL}/authorization/list-users-for-impersonation`)).data.data;
    }

    console.debug('ALREADY IMPERSONATED!', context.state.tokenOriginalUser);
    return (await HTTP.get(`${API_BASE_URL}/authorization/list-users-for-impersonation`, {headers: {Authorization: context.state.tokenOriginalUser}})).data.data;
};

export const impersonateUser = async (context, {userId}) => {
    const isImpersonate = context.getters['isImpersonate'];

    let userBeforeImpersonate = null
    let tokenBeforeImpersonate = null
    let headers = {}

    if (isImpersonate) {
        headers = {
            headers: {
                'Authorization': context.state.tokenOriginalUser
            }
        };
        userBeforeImpersonate = await context.dispatch('loadOriginalUser');
        tokenBeforeImpersonate = context.state.tokenOriginalUser;
    } else {
        userBeforeImpersonate = await context.dispatch('loadCurrentUser');
        tokenBeforeImpersonate = context.state.token;
    }

    const response = await HTTP.get(`${API_BASE_URL}/authorization/${userId}/impersonate`, headers);
    context.commit('reset', null, {root: true});
    let user = userApiConverter.fromApi(response.data.data.user);
    context.commit('updateUser', user);
    context.commit('updateToken', response.data.data.token);
    context.commit('updateTokenOriginalUser', tokenBeforeImpersonate);
    context.commit('updateOriginalUser', userBeforeImpersonate);
    await context.dispatch('general/loadInitial', null, {root: true});
    await context.dispatch('notification/loadNotifications', null, {root: true});

    // Authorization endpoint returns only the public profile of a user (current limitation of our API), so we load the user profile again
    await context.dispatch('loadCurrentUser');

    try {
        if (Config.isNativeRealDevice()) {
            window.FirebasePlugin.setUserId(response.data.data.user.id);
            window.FirebasePlugin.setCrashlyticsUserId(response.data.data.user.id);
        }
    } catch (e) {
        console.error('FirebasePlugin.setUserId failed', e);
    }
    Analytics.logUserLoginAfter();

    return user;
};

export const restoreOriginalUser = async (context) => {
    const tokenOriginaluser = context.state.tokenOriginalUser;
    const originalUser = context.state.originalUser;
    context.commit('reset', null, {root: true});
    context.commit('updateToken', tokenOriginaluser);
    context.commit('updateUser', originalUser);
    context.commit('updateTokenOriginalUser', null);
    context.commit('updateOriginalUser', null);
    const user = await context.dispatch('loadCurrentUser')
    await context.dispatch('general/loadInitial', null, {root: true});
    await context.dispatch('notification/loadNotifications', null, {root: true});

    try {
        if (Config.isNativeRealDevice()) {
            window.FirebasePlugin.setUserId(user.id);
            window.FirebasePlugin.setCrashlyticsUserId(user.id);
        }
    } catch (e) {
        console.error('FirebasePlugin.setUserId failed', e);
    }
    Analytics.logUserLoginAfter();

    return user;
};


export const logout = async (context, ignoreServerLogout = false) => {
    let currentAuthToken = context.state.token;
    const hasSeenIntroductionSlides = context.rootState.general.hasSeenIntroductionSlides;
    await Analytics.logUserLogout();
    // Instant local logout
    context.commit('reset', null, {root: true});
    // Load initial data again (for anonymous user) and register for notifications
    context.dispatch('general/loadInitial', null, {root: true});
    context.dispatch('notification/register', null, {root: true});
    context.commit('general/updateHasSeenIntroductionSlides', hasSeenIntroductionSlides, {root: true});

    try {
        if (Config.isNativeRealDevice()) {
            window.FirebasePlugin.setUserId(null);
            window.FirebasePlugin.setCrashlyticsUserId(null);
        }
    } catch (e) {
        console.error('FirebasePlugin.setUserId failed', e);
    }

    // Additional (optional) server logout
    if (!ignoreServerLogout) {
        await HTTP.post(API_BASE_URL + '/authorization/logout', null, {headers: {Authorization: currentAuthToken}});
    }
    context.dispatch('notification/loadNotifications', null, {root: true});
};

export const wizard = (context, data) => {
    return new Promise((resolve, reject) => {
        data.user = userApiConverter.toApi(data.user);
        HTTP.patch(API_BASE_URL + '/user/wizard/' + data.step, data.user)
            .then((response) => {
                let user = userApiConverter.fromApi(response.data.data);
                context.commit('updateUser', user);
                resolve(user);
            })
            .catch(error => reject(error));
    });
};

export const addFavoriteByCoupon = (context, coupon) => {
    context.commit('addSingleFavoriteCouponByCoupon', coupon);
    return new Promise((resolve, reject) => {
        HTTP.put(API_BASE_URL + '/user/' + context.state.user.id + '/favorite/' + coupon).then((response) => {
            // Update entry with favoriteCoupon from ID from server
            context.commit('addSingleFavoriteCoupon', response.data.data);
            resolve();
        }).catch(reject);
    });
};

export const removeFavoriteByCoupon = (context, coupon) => {
    context.commit('removeSingleFavoriteCouponByCoupon', coupon);
    return new Promise((resolve, reject) => {
        HTTP.delete(API_BASE_URL + '/user/' + context.state.user.id + '/favorite/' + coupon).then(resolve).catch(reject);
    });
};


function buildFilter(filter) {
    let filterParts = [];
    if (filter.date !== 'recent') {
        filterParts.push(`filter[dateRange]=${encodeURIComponent(filter.date)}`);
    }

    if (filter.shop !== 'all') {
        filterParts.push(`filter[shop]=${encodeURIComponent(filter.shop)}`);
    }

    if (filterParts.length) {
        return '?' + filterParts.join('&');
    }
    return '';
}

/**
 * Load redeems where the user is the owner of the coupon
 *
 * @param context
 * @param {string} filter
 * @returns {Promise}
 */
export const loadRedeemedOwnerCoupons = (context, filter) => {
    return new Promise((resolve, reject) => {
        let url = API_BASE_URL + `/user/${context.state.user.id}/redeem`;
        url += buildFilter(filter);

        HTTP.get(url)
            .then(response => {
                context.commit('updateOwnerRedeem', response.data.data);
                context.commit('coupon/updateList', couponApiConverter.fromApiCollection(response.data.included.coupon), {root: true});
                resolve();
            })
            .catch(reject);
    });
};

/**
 * Load redeems where the user is the owner of the coupon
 *
 * @param context
 * @param {string} filter
 * @returns {Promise}
 */
export const sendRedeemedOwnerCouponsMail = (context, filter) => {
    return new Promise((resolve, reject) => {
        let url = API_BASE_URL + `/user/${context.state.user.id}/redeem/export`;
        url += buildFilter(filter);

        HTTP.post(url).then(resolve).catch(reject);
    });
};

export const updateFields = (context, fields) => {
    fields = userApiConverter.toApi(fields);
    return new Promise((resolve, reject) => {
        HTTP.patch(API_BASE_URL + '/user/current', {fields: fields})
            .then(response => {
                let user = userApiConverter.fromApi(response.data.data);
                context.commit('updateUser', user);
                resolve(user)
            })
            .catch(error => reject(error));
    });
};

export const enableCustomerRole = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.patch(API_BASE_URL + '/user/current/enable-customer-role')
            .then(response => {
                let user = userApiConverter.fromApi(response.data.data);
                context.commit('updateUser', user);
                resolve(user)
            })
            .catch(error => reject(error));
    });
};

export const loadAffiliates = async(context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/affiliate/list`)
            .then(response => {
                let affiliates = response.data.data;
                context.commit('updateAffiliates', affiliates);
                resolve(affiliates);
            })
            .catch(error => {
                reject(error);
            });
    });

}


export const createAffiliateLink = async (context, phone) => {
    return new Promise((resolve, reject) => {
        HTTP.post(API_BASE_URL + `/affiliate/${phone}`)
            .then(response => {
                let affiliate = response.data.data;
                context.commit('updateAffiliateToken', affiliate.token);
                resolve(affiliate);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const deleteUserAccount =  async (context) => {
    const token = context.state.token;
    return new Promise((resolve, reject) => {
        HTTP.delete(API_BASE_URL + '/user', { headers: { Authorization: token }}).then(resolve).catch(reject);
    });
};
