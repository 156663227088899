    <template>
        <div class="c3-icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path :fill="color1" d="M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zM240 64h120.9l50.8 96H240V64zM87.1 64H208v96H36.3l50.8-96zM224 448H32s.1-181.1.1-256H416c0 74.9.1 256 .1 256H224zm90.2-210.8c-13.9-11.6-47.7-28.5-90.2 14.5-42.6-43-76.4-26.1-90.2-14.5-27.5 23-28.9 64.2-4.3 89.1l84.7 85.6c5.5 5.5 14.3 5.5 19.8 0l84.7-85.6c24.5-24.9 23-66.1-4.5-89.1zm-18.4 66.5L224 376.4l-71.8-72.6c-10.2-10.3-12.3-30 2.1-42 13.7-11.5 31.2-3.4 38.4 3.7l31.4 31.7 31.4-31.7c7.1-7.2 24.6-15.2 38.4-3.7 14.2 12 12.2 31.6 1.9 41.9z"/></svg>
<!--
Font Awesome Pro 5.2.0 by @fontawesome - https://fontawesome.com
License - https://fontawesome.com/license (Commercial License)
-->
        </div>
    </template>
    <script>
        import {IconMixin} from './mixins/icon';

        export default {
            mixins: [IconMixin],
        };
    </script>

