<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path :fill="color1"
                  d="M128 288c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zm0-160c35.29 0 64 28.71 64 64s-28.71 64-64 64-64-28.71-64-64 28.71-64 64-64zm61.61 214.58c2.73 2.52 10.45 6.69 22.56 8.5l42.24-60.29c15.47-22.12 32.69-42.73 50.76-62.52l154.55 107c11.11-7.99 23.08-12.79 35.44-14.37L327.4 204.75c23.24-22.91 48.13-44.19 74.91-63.31 10.97-7.83 24.91-10.69 38.47-7.77l100.38 18.08c8.59 1.53 17-4.19 18.59-12.91 1.56-8.7-4.22-17.03-12.91-18.59l-99.88-17.97a80.012 80.012 0 0 0-63.28 13.12c-60.56 43.25-112.88 96.09-155.5 157.03l-45.38 64.81c2.29 1.76 4.62 3.32 6.81 5.34zM632 384h-24c-26.04 0-45.8-8.42-56.09-17.9-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C461.8 375.58 442.04 384 416 384s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C269.8 375.58 250.04 384 224 384s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C77.8 375.58 58.04 384 32 384H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h24c38.62 0 72.72-12.19 96-31.84 23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84h24c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
