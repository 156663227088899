import moment from 'moment';
import $router from '@/router';
import $store from '@/store';
import {Crypto} from '@/utility/crypto';
import {HTTP} from '@/domain/api/http';
import {LocalStorage} from '@/domain/utility/localStorage';

const analyticsLocalStorage = LocalStorage('analytics');

let AnalyticsInternal = function () {
    const ORIGIN = 'app';

    const EVENT_APP_START = 'app-start';
    const EVENT_APP_RESUME = 'app-resume';
    const EVENT_APP_PAUSE = 'app-pause';

    const EVENT_USER_LOGIN_BEFORE = 'user-login-before';
    const EVENT_USER_LOGIN_AFTER = 'user-login-after';
    const EVENT_USER_LOGOUT = 'user-logout';

    const EVENT_PAGE_OPEN = 'page-open';

    const EVENT_BONUS_CARD_SHARE_INIT = 'bonus-card-share-init';
    const EVENT_BONUS_CARD_SHARE = 'bonus-card-share';

    const EVENT_COUPON_REDEEM_INIT = 'coupon-redeem-init';
    const EVENT_COUPON_REDEEM = 'coupon-redeem';
    const EVENT_COUPON_SHARE_INIT = 'coupon-share-init';
    const EVENT_COUPON_SHARE = 'coupon-share';
    const EVENT_COUPON_EXPLANATION = 'coupon-explanation';
    const EVENT_COUPON_FAVORITE_ADD = 'coupon-favorite-add';
    const EVENT_COUPON_FAVORITE_REMOVE = 'coupon-favorite-remove';

    const EVENT_FAQ_OPEN = 'faq-open';

    const EVENT_SHARE_FRIEND = 'share-friend';
    const EVENT_SHARE_COMPANY = 'share-company';
    const EVENT_OPEN_DEEPLINK = 'open-deeplink';

    const EVENT_AFFILIATE_ADVERTISER_UPDATE = 'affiliate-advertiser-update';


    const INSTANT_SEND_EVENTS = [EVENT_APP_PAUSE, EVENT_USER_LOGIN_BEFORE, EVENT_USER_LOGIN_AFTER, EVENT_USER_LOGOUT];

    let self = this;

    let bufferedEvents = [];

    this.logAppStart = async function () {
        await logEvent(EVENT_APP_START);
    };

    this.logAppResume = async function () {
        await logEvent(EVENT_APP_RESUME);
    };

    this.logAppPause = async function () {
        await logEvent(EVENT_APP_PAUSE);
    };

    this.logUserLoginBefore = async function () {
        await logEvent(EVENT_USER_LOGIN_BEFORE);
    };

    this.logUserLoginAfter = async function () {
        await logEvent(EVENT_USER_LOGIN_AFTER);
    };

    this.logUserLogout = async function () {
        await logEvent(EVENT_USER_LOGOUT);
    };

    this.logPageOpen = async function (data) {
        await logEvent(EVENT_PAGE_OPEN, data);
    };

    this.logRedeemCoupon = async function (couponId) {
        await logEvent(EVENT_COUPON_REDEEM, {id: couponId});
    };

    this.logRedeemCouponInit = async function (couponId) {
        await logEvent(EVENT_COUPON_REDEEM_INIT, {id: couponId});
    };

    this.logShareBonusCardInit = async function (bonusCardId) {
        await logEvent(EVENT_BONUS_CARD_SHARE_INIT, {id: bonusCardId});
    };

    this.logShareBonusCard = async function (bonusCardId, url) {
        await logEvent(EVENT_BONUS_CARD_SHARE, {id: bonusCardId, url: url});
    };

    this.logShareCouponInit = async function (couponId) {
        await logEvent(EVENT_COUPON_SHARE_INIT, {id: couponId});
    };

    this.logShareCoupon = async function (couponId, url) {
        await logEvent(EVENT_COUPON_SHARE, {id: couponId, url: url});
    };

    this.logCouponExplanation = async function (couponId) {
        await logEvent(EVENT_COUPON_EXPLANATION, {id: couponId});
    };

    this.logAddCouponFavorite = async function (couponId) {
        await logEvent(EVENT_COUPON_FAVORITE_ADD, {id: couponId});
    };

    this.logRemoveCouponFavorite = async function (couponId) {
        await logEvent(EVENT_COUPON_FAVORITE_REMOVE, {id: couponId});
    };

    this.logFaqOpen = async function (faqId) {
        await logEvent(EVENT_FAQ_OPEN, {id: faqId});
    };

    this.logShareFriend = async function () {
        await logEvent(EVENT_SHARE_FRIEND);
    };

    this.logShareCompany = async function () {
        await logEvent(EVENT_SHARE_COMPANY);
    };

    this.logOpenDeepLink = async function (data) {
        await logEvent(EVENT_OPEN_DEEPLINK, data)
    };

    this.affiliateAdvertiserUpdate = async function (data) {
        await logEvent(EVENT_AFFILIATE_ADVERTISER_UPDATE, data)
    };

    this.getAnalyticsId = function () {
        if (!analyticsLocalStorage.has('id')) {
            analyticsLocalStorage.set('id', Crypto.generateUuid() + '_' + Crypto.generateUuid());
        }
        return analyticsLocalStorage.get('id');
    };

    this.restoreAnalyticsId = function (id) {
        if (!analyticsLocalStorage.has('id')) {
            analyticsLocalStorage.set('id', id);
        }
    };

    async function logEvent(name, data) {
        if (null !== data && typeof data !== 'object' && !Array.isArray(data)) {
            data = null;
        }

        bufferedEvents.push({
            analyticsId: self.getAnalyticsId(),
            name: name,
            data: data,
            page: determineCurrentPage(),
            occurredAt: moment().format(),
            location: determineLocation()
        });

        if (bufferedEvents.length >= 15 || INSTANT_SEND_EVENTS.indexOf(name) > -1) {
            await sendBufferedEvents();
        }
    }

    async function sendBufferedEvents() {
        try {
            let events = bufferedEvents.slice();
            bufferedEvents = [];
            await HTTP.post(API_BASE_URL + `/a/e`, {origin: ORIGIN, data: {events: events, device: getDeviceFields()}});
        } catch (e) {

        }
    }

    function determineCurrentPage() {
        return $router.currentRoute.fullPath;
    }

    function determineLocation() {
        try {
            let userPosition = $store.getters['user/position'];
            if (moment.isMoment(userPosition.lastUpdate) && userPosition.coordinates && 'IP' !== userPosition.coordinates.accuracy) {
                return {
                    latitude: userPosition.coordinates.latitude,
                    longitude: userPosition.coordinates.longitude,
                    accuracy: userPosition.coordinates.accuracy,
                    determinedAt: userPosition.lastUpdate.format()
                }
            }
        } catch (e) {
            console.error('[Analytics] determineLocation failed', e);
        }

        return null;
    }

    function getDeviceFields() {
        if (window.device) {
            return {
                platform: window.device.platform,
                version: window.device.version,
                browser: window.navigator.userAgent,
                manufacturer: window.device.manufacturer,
                model: window.device.model,
                uuid: window.device.uuid,
                serial: window.device.serial,
                isVirtual: window.device.isVirtual,
            };
        }
        return {
            platform: window.PLATFORM.BROWSER,
            version: null,
            browser: window.navigator.userAgent,
            manufacturer: null,
            model: null,
            uuid: null,
            serial: null,
            isVirtual: false,
        };
    }
};

export const Analytics = new AnalyticsInternal();
