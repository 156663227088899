<template>
    <form class="c3-user-login-form c3-user-login-step" @submit.prevent="submit">
        <c3-form-switch v-model="tos" styling="light">
            <template slot="labelBefore">{{ $t('label.tos.terms') }}</template>
        </c3-form-switch>
        <c3-form-switch v-model="privacyPolicy" styling="light">
            <template slot="labelBefore">{{ $t('label.tos.policy') }}</template>
        </c3-form-switch>

        <div class="c3-user-login-submit">
            <c3-form-submit :label="$t('label.next')" :invert="true"/>
            <div class="c3-user-login-registration-additional-information">
                <router-link :to="{name:'Home'}">Home</router-link>
                <span class="c3-user-login-registration-additional-information-separator">|</span>
                <c3-external-link :to="privacyPolicyHref">{{$t('label.privacyPolicy')}}</c3-external-link>
                <span class="c3-user-login-registration-additional-information-separator">|</span>
                <c3-external-link :to="tosHref">{{$t('navigation.tos')}}</c3-external-link>
            </div>
        </div>
    </form>
</template>
<script>
    import {RouterMixin} from '@/domain/mixin/local/router';

    import C3Button from '@/components/form/Button';
    import C3ExternalLink from '@/components/link/ExternalLink';

    export default {
        mixins: [RouterMixin],
        components: {C3Button, C3ExternalLink},
        data() {
            return {
                privacyPolicy: false,
                tos: false
            };
        },
        computed: {
            tosHref() {
                return API_BASE_URL + '/static-content/1/html';
            },
            privacyPolicyHref() {
                return API_BASE_URL + '/static-content/2/html';
            },
        },
        methods: {
            submit() {
                if (!this.tos || !this.privacyPolicy) {
                    this.$c3.alert(this.$t('error.generic.title'), this.$t('error.policyError'));
                    return;
                }

                this.$store.commit('view/showLoading');
                this.$store.dispatch('user/wizard', {step: 'tos', user: {privacyPolicy: this.privacyPolicy, tos: this.tos}})
                    .then(() => {
                        this.$router.push({name: 'RegistrationName', query: this.getRedirectAfterLoginQueryObject()});
                    })
                    .catch(error => {
                        this.$c3.alert(this.$t('error.generic.title'), this.$t('error.policyError'));
                        console.error(error);
                    })
                    .finally(() => this.$store.commit('view/hideLoading'));
            },
        },
        created() {
            if (!this.isLoggedIn) {
                this.$router.push({name: 'Login'});
            }
            if (this.$store.getters['user/hasAcceptedContracts']) {
                this.$router.push({name: 'RegistrationName'});
            }
        },
        beforeRouteLeave(to, from, next) {
            if (!this.$store.getters['user/hasAcceptedContracts'] && to.name !== 'Logout') {
                this.$c3.confirmYesNo(
                    this.$t('confirm.tos.title'),
                    this.$t('confirm.tos.message'),
                    () => next({name: 'Logout', query: {redirectAfterLogout: to.fullPath}}),
                    () => next(false),
                );
            } else {
                next();
            }
        }
    };
</script>
<style lang="scss" scoped>
    .c3-user-login-form {
        margin-top: 40px;
    }

    h2 {
        margin-top: 15px;
        font-size: 16px;
        text-align: center;
    }

    .c3-tos-button-list {
        padding-top: 30px;

        .c3-button {
            margin-top: 15px;
        }
    }

    .c3-user-login-submit {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 40px;
    }
</style>
