import Vue from 'vue';

export const updatePushId = (state, id) => {
    state.pushId = id;
};

export const updatePushType = (state, type) => {
    state.pushType = type;
};

export const updateMessage = (state, message) => {
    state.message = message;
};

export const deleteMessage = (state, {messageId}) => {
    state.message.forEach((item, index) => {
        if (item.id === messageId) {
            Vue.delete(state.message, index);
        }
    });
};

export const markAsRead = (state, singleMessage) => {
    state.message.forEach(item => {
        if (item.id === singleMessage.id) {
            item.unread = false;
        }
    })
};
