import moment from 'moment';
import redeemStateConverter from '@/domain/api/converter/redeemState';
import userConverter from '@/domain/api/converter/user';
import {StoreConverter} from '@/domain/api/converter/store';

export default {
    fromApi: function (coupon) {
        // DB uses already m:n for coupon categories, but app should use 1:n for the moment
        if (Array.isArray(coupon.categories)) {
            if (coupon.categories.length) {
                coupon.category = parseInt(coupon.categories[0]);
            } else {
                coupon.category = null;
            }
        }
        delete coupon.categories;

        if (coupon.publicationDate) {
            coupon.publicationDate = moment(coupon.publicationDate);
        }

        if (coupon.validFrom) {
            coupon.validFrom = moment(coupon.validFrom);
        }

        if (coupon.validUntil) {
            coupon.validUntil = moment(coupon.validUntil);
        }

        return coupon;
    },
    fromApiCollection: function (coupons) {
        if (!Array.isArray(coupons)) {
            coupons = Object.values(coupons);
        }
        return coupons.map(coupon => this.fromApi(coupon));
    },
    extractIncluded: function (included) {
        let data = {};
        if (typeof included === 'object') {
            if (included.category) {
                data.category = included.category.data;
            }
            if (included.owner) {
                data.owner = userConverter.fromApiCollection(included.owner.data);
            }
            if(included.store) {
                data.store = StoreConverter.fromApiCollection(included.store.data);
            }
            if (included.redeemState) {
                data.redeemState = redeemStateConverter.fromApiCollection(included.redeemState.data);
            }
        }
        return data;
    },
    extractIncludedFromCollection: function (included) {
        return this.extractIncluded(included);
    },
    toApi: function (coupon) {
        // Do not change original object
        coupon = Object.assign({}, coupon);

        if (coupon.validFrom instanceof Date) {
            coupon.validFrom = moment(coupon.validFrom);
        }

        if (moment.isMoment(coupon.validFrom)) {
            coupon.validFrom = moment(coupon.validFrom);
            coupon.validFrom.hour(0);
            coupon.validFrom.minute(0);
            coupon.validFrom.second(0);
            coupon.validFrom = coupon.validFrom.format('DD.MM.YYYY HH:mm');
        }

        if (coupon.validUntil instanceof Date) {
            coupon.validUntil = moment(coupon.validUntil);
        }

        if (moment.isMoment(coupon.validUntil)) {
            coupon.validUntil = moment(coupon.validUntil);
            coupon.validUntil.hour(23);
            coupon.validUntil.minute(59);
            coupon.validUntil.second(59);
            coupon.validUntil = coupon.validUntil.format('DD.MM.YYYY HH:mm');
        }

        // DB uses already m:n for coupon - categories, but app should use 1:n for the moment
        if (coupon.hasOwnProperty('category')) {
            coupon.categories = [coupon.category];
            delete coupon.category;
        }
        return coupon;
    }
};
