import {HTTP} from '@/domain/api/http';

export const loadAvailableType = (context, user) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/user/${user}/membership/available`)
            .then(response => {
                context.commit('updateAvailableType', response.data.data);
                resolve();
            })
            .catch(reject);
    });
};