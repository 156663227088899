import router from '@/router'
import store from '@/store';

export default (error) => {
    return {
        process: async() => {
            let currentRoute = router.currentRoute;
            if (currentRoute.name !== 'Login' && currentRoute.name !== 'LoginPin') {
                // Redirect to login
                let isLoggedIn = store.getters['user/isLoggedIn'];
                let redirectAfterLogin = router.resolve({name: currentRoute.name, params: currentRoute.params}).resolved.fullPath;
                let query = {redirectAfterLogin};

                if (isLoggedIn) {
                    const user = store.getters['user/originalUser'] || store.getters['user/user'];
                    const {phone} = user;
                    query = { phone, ...query };
                    await store.dispatch('user/logout', true);
                }

                router.push({ name: 'Login', query });
                return
            }
            return error;
        }
    }
};