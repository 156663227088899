<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.712 18.411">
            <g transform="translate(-65 -353.13)">
                <path d="M510.6,1137.31l9.706-17.061,9.723,17.061Z" transform="translate(-444.946 -766.725)"/>
                <path d="M20.478,15.822a1.727,1.727,0,0,1-1.5,2.589H1.728a1.727,1.727,0,0,1-1.5-2.589L8.861.862a1.727,1.727,0,0,1,2.99,0l8.628,14.96Zm-.995.575L10.854,1.438a.576.576,0,0,0-1,0L1.227,16.4a.576.576,0,0,0,.5.863h17.26A.576.576,0,0,0,19.484,16.4Zm-9.128-3.021a1.007,1.007,0,1,0,1.007,1.007A1.007,1.007,0,0,0,10.356,13.376ZM9.942,5.753h.826A.432.432,0,0,1,11.2,6.2l-.252,4.041a.431.431,0,0,1-.431.414h-.323a.432.432,0,0,1-.431-.414L9.511,6.2A.432.432,0,0,1,9.942,5.753Zm.413,7.623a1.007,1.007,0,1,0,1.007,1.007A1.007,1.007,0,0,0,10.356,13.376Z"
                      transform="translate(65 353.131)"/>
            </g>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
<style lang="scss">
    .a {
        fill: #d94040;
    }

    .b {
        fill: #fff;
    }
</style>

