import moment from 'moment';

export const LoginMixin = {
    data() {
        return {}
    },
    computed: {},
    methods: {
        resendLoginPin() {
            this.sendLoginPin(true, true);
        },
        sendLoginPinAndRedirectToPinForm() {
            this.sendLoginPin(true, false);
        },
        sendLoginPin(showLoading, showSuccess) {
            if (showLoading) {
                this.$store.commit('view/showLoading', this.$t('user.sendLoginPinLoadingMessage'));
            }

            this.$store.dispatch('user/sendLoginCode', this.sanitizedPhoneNumber)
                .then(() => {
                    if (showSuccess) {
                        this.$c3.alert('', 'user.sentLoginPin');
                    } else {
                        this.$router.push({name: 'LoginPin', params: {phone: this.sanitizedPhoneNumber}, query: this.getRedirectAfterLoginQueryObject()});
                    }
                })
                .catch(error => {
                    console.error('smsDeliveryFailed', error);
                    if (Array.isArray(error) && error.length) {
                        let firstError = error[0];

                        if (-1 === firstError.status) {
                            this.$c3.errorAlert('error.noConnectionLogin.title', 'error.noConnectionLogin.message');
                            return;
                        }
                        if (429 === firstError.status && moment.isMoment(firstError.nextRequest)) {
                            let nextRequestFormatted = moment(firstError.nextRequest).add(1, 'minute').format('HH:mm');
                            this.$c3.errorAlert(
                                'error.loginPinRateLimit.title',
                                this.$t('error.loginPinRateLimit.message', {nextRequest: nextRequestFormatted})
                            );
                            return;
                        }
                    }
                    this.$c3.errorAlert('error.smsDeliveryFailed.title', 'error.smsDeliveryFailed.message');
                })
                .finally(() => {
                    if (showLoading) {
                        this.$store.commit('view/hideLoading');
                    }
                });
        }
    },
};