import store from '@/store';

function getFilterEntry(state, listName, filterName) {
    // Make sure filter exists
    store.commit('viewBonusCardList/createList', listName);

    return state.filter[listName][filterName];
}

export const filter = (state) => (listName) => {
    // Make sure filter exists
    store.commit('viewBonusCardList/createList', listName);

    return state.filter[listName];
};

export const filterHash = (state) => (listName) => {
    // Make sure filter exists
    store.commit('viewBonusCardList/createList', listName);

    let filter = state.filter[listName];

    let position = 'unknown';
    if (filter.locationCenter) {
        // Reduce coordinate precision for more cache hits
        let latitude = filter.locationCenter.latitude.toFixed(POSITION.PRECISION);
        let longitude = filter.locationCenter.longitude.toFixed(POSITION.PRECISION);
        position = latitude + ',' + longitude;
    }

    return [
        'filter',
        listName,
        filter.useCurrentLocation,
        position,
        filter.locationRadius
    ].join('---');
};

export const items = (state) => (listName) => {
    try {
        let hash = filterHash(state)(listName);
        if (state.items.hasOwnProperty(hash) && Array.isArray(state.items[hash])) {
            return state.items[hash];
        }
    } catch (e) {
        console.error('[viewBonusCardList/getters/items] failed', e);
    }
    return [];
};

export const lastItemsUpdate = (state) => (listName) => {
    let hash = filterHash(state)(listName);
    if (state.lastItemsUpdate.hasOwnProperty(hash)) {
        return state.lastItemsUpdate[hash];
    }
    return null;
};

export const locationName = (state) => (name) => {
    return getFilterEntry(state, name, 'locationName');
};

export const useCurrentLocation = (state) => (name) => {
    return getFilterEntry(state, name, 'useCurrentLocation');
};

export const locationCenter = (state) => (name) => {
    return getFilterEntry(state, name, 'locationCenter');
};

export const locationRadius = (state) => (name) => {
    return getFilterEntry(state, name, 'locationRadius');
};
