import moment from 'moment';

export default (error) => {
    return {
        process: () => {
            if (error.response && Array.isArray(error.response.data.errors)) {
                return error.response.data.errors.map(error => {
                    if (error.nextRequest) {
                        error.nextRequest = moment(error.nextRequest);
                    }
                    return error;
                });
            }
            return [
                {
                    status: 429,
                    code: 'rateLimit',
                    titleCode: 'error.rateLimit.title',
                    messageCode: 'error.rateLimit.message',
                    nextRequest: null
                }
            ];
        }
    }
};