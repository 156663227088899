import axios from 'axios';
import $store from '@/store';
import moment from 'moment';
import {HTTP} from '@/domain/api/http';

let LocationInternal = function () {
    this.getCurrentPosition = async function (timeout, addressTimeout) {
        let position;

        // Determine position in parallel and then use the best result
        let highAccuracyPromise = getCurrentHighAccuracyPosition(timeout);
        let lowAccuracyPromise = getCurrentLowAccuracyPosition(timeout);
        let ipPositionPromise = getCurrentIpPosition(timeout);

        console.debug('[Location] Wait high accuracy position');
        position = await highAccuracyPromise;
        if (null !== position) {
            console.debug('[Location] Found high accuracy position', position);
            $store.commit('user/updatePosition', position);
            await updateAddress(position.coordinates, addressTimeout);
            return position;
        }

        console.debug('[Location] Wait low accuracy position');
        position = await lowAccuracyPromise;
        if (null !== position) {
            console.debug('[Location] Found low accuracy position', position);
            $store.commit('user/updatePosition', position);
            await updateAddress(position.coordinates, addressTimeout);
            return position;
        }

        console.debug('[Location] Wait IP position');
        position = await ipPositionPromise;
        if (null !== position) {
            console.debug('[Location] Found IP position', position);
            $store.commit('user/updatePosition', position);
            await updateAddress(position.coordinates, addressTimeout);
            return position;
        }

        throw new Error('[Location] getCurrentPosition failed - timeout: ' + timeout + ' addressTimeout: ' + addressTimeout);
    };

    async function getCurrentHighAccuracyPosition(timeout) {
        try {
            let position = await getCurrentGeolocationPosition({enableHighAccuracy: true, timeout: timeout});
            position = convertToObject(position);
            return position;
        } catch (e) {
            return null;
        }
    }

    async function getCurrentLowAccuracyPosition(timeout) {
        try {
            let position = await getCurrentGeolocationPosition({enableHighAccuracy: false, timeout: timeout});
            position = convertToObject(position);
            return position;
        } catch (e) {
            return null;
        }
    }

    async function getCurrentIpPosition(timeout) {
        let timeoutId;

        try {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            timeoutId = setTimeout(() => source.cancel('Timeout'), timeout);

            let response = await HTTP.get(API_BASE_URL + '/location/address/by-ip', {cancelToken: source.token});
            if (null === response.data.data.coordinate) {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                return null;
            }
            let position = {
                coordinates: {
                    accuracy: 'IP',
                    latitude: response.data.data.coordinate.latitude,
                    longitude: response.data.data.coordinate.longitude,
                },
                lastUpdate: moment()
            };

            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            return position;
        } catch (e) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            return null;
        }
    }

    async function updateAddress(coordinates, timeout) {
        if (timeout) {
            // Wait for timeout ms and then continue
            console.debug('[Location] updateAddress timeout set to ' + timeout + 'ms');
            await Promise.race([$store.dispatch('geocoding/address', coordinates), new Promise(resolve => setTimeout(resolve, timeout))]);
        } else {
            await $store.dispatch('geocoding/address', coordinates);
        }
    }

    /**
     * Convert to regular reduced object - Saves memory and fixes JSON stringify problems (https://www.npmjs.com/package/geoposition-to-object)
     *
     * @param position
     */
    function convertToObject(position) {
        return {
            coordinates: {
                accuracy: position.coords.accuracy,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            },
            lastUpdate: moment(position.timestamp)

        }
    }

    function getCurrentGeolocationPosition(options) {
        return new Promise((resolve, reject) => {
            // getCurrentPosition timeout fails in some cases (at least on first app start?)
            // Set custom timeout which rejects the request after x milliseconds
            if (options && options.timeout) {
                setTimeout(reject, options.timeout);
            } else {
                setTimeout(reject, 8000);
            }
            window.navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }
};

export const Location = new LocationInternal();