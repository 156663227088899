<template>
    <div class="c3-grid-tile-item" @click="$emit('click')">
        <slot>
            <div class="c3-grid-tile-item-inner-wrapper">
                <div class="c3-grid-tile-item-icon">
                    <component v-if="icon" :is="icon" :primaryColor="primaryColor" :secondaryColor="secondaryColor"/>
                </div>
                <div class="c3-grid-tile-item-content" v-if="label">{{label}}</div>
                <div class="c3-grid-tile-item-content" v-else-if="html" v-html="html"></div>
            </div>
        </slot>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
            },
            iconPrimaryColor: {
                type: String,
                default: null
            },
            iconSecondaryColor: {
                type: String,
                default: null
            },
            label: {
                type: String
            },
            html: {
                type: String
            },
            to: {},
        },
        components: {},
        computed: {
            primaryColor() {
                if (null === this.iconPrimaryColor) {
                    return COLORS.WHITE;
                }
                return this.iconPrimaryColor;
            },
            secondaryColor() {
                if (null === this.iconSecondaryColor) {
                    return COLORS.WHITE;
                }
                return this.iconSecondaryColor;
            },
        },
        methods: {},
        watch: {},
    };
</script>
<style lang="scss" scoped>
    .c3-grid-tile-item {
        width: calc(50% - #{$grid-gutter-default});
        height: 130px;

        margin-top: $grid-gutter-default;
        margin-left: $grid-gutter-default;

        @media(min-width: $breakpointTablet+1) {
            width: calc(25% - #{$grid-gutter-default});
        }
    }

    .c3-grid-tile-item-inner-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        height: 100%;

        padding-top: 30px;

        @include disableSelect();
    }

    .c3-grid-tile-item-icon {
        height: 35px;
    }

    .c3-grid-tile-item-content {
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        @include montserratLight();
        font-size: 14px;
        text-align: center;
        color: $white-color;
    }
</style>