<template>
    <div class="c3-loader" :class="cssClasses"></div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: 'm',
            },
            color: {
                type: String,
                default: 'quinary'
            },
            overlay: {
                type: Boolean,
                default: false
            },
            hCenter: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            cssClasses() {
                let classes = [];
                if (this.size) {
                    classes.push('c3-loader-color-' + this.color);
                }
                if (this.color) {
                    classes.push('c3-loader-size-' + this.size);
                }
                if (this.overlay) {
                    classes.push('c3-loader-overlay');
                }
                if (this.hCenter) {
                    classes.push('c3-loader-horizontal-center');
                }
                return classes.join(' ');
            }
        },
    };
</script>

<style scoped lang="scss">
    $sizes: (
            xs:16px,
            s:22px,
            m:44px,
            l:66px,
            xl:88px,
    );

    @each $name, $color in $spinnerColors {
        .c3-loader-color-#{$name} {
            background: $color;
        }
    }

    @each $name, $size in $sizes {
        .c3-loader-size-#{$name} {
            width: $size;
            height: $size;
        }
    }

    .c3-loader {
        border-radius: 100%;
        -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
        animation: sk-scaleout 1.0s infinite ease-in-out;
    }

    .c3-loader-horizontal-center {
        margin-left: auto;
        margin-right: auto;
    }

    @-webkit-keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0)
        }
        100% {
            -webkit-transform: scale(1.0);
            opacity: 0;
        }
    }

    @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        100% {
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
            opacity: 0;
        }
    }
</style>