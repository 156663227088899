export default (error) => {
    return {
        process: () => {
            return [
                {
                    status: -1,
                    code: 'noConnection',
                    titleCode: 'error.noConnection.title',
                    messageCode: 'error.noConnection.message'
                }
            ];
        }
    }
};