import JsBarcode from 'jsbarcode';
import { TYPE_QR } from '@/types';

export const ReverseRedeemMixin = {
    data() {
        return { isCoupon: true, data: null }
    },
    watch: {
        coupon(value) {
            this.isCoupon = true;
            this.data = value;
        },
        bonusCard(value) {
            this.isCoupon = false;
            this.data = value;
        },
    },
    computed: {
        qrCode() {
            const slug = this.isCoupon ? 'coupon' : 'bonus-card';
            const url = API_BASE_URL + `/${slug}/${this.data.id}/qr-code`;
            return `<img src="${url}"/>`;
        },
        barCode() {
            return '<canvas id="barcode" style="width:100%;"></canvas>';
        }
    },
    methods: {
        showReverseRedeemCode() {
            if (this.data.type === TYPE_QR) {
                this.$c3.htmlAlert('', this.qrCode, null, {showCloseButton: false});
                return;
            }

            this.$c3.htmlAlert('', this.barCode, null, {showCloseButton: false});
            JsBarcode("#barcode", this.data.content);
        },
    },
};
