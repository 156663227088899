require('@/constants');

import Affiliate from '@/pages/General/Affiliate.vue';
import CoupocketForCompany from '@/pages/General/CoupocketForCompany.vue';
import Share from '@/pages/General/Share.vue';
import NotificationList from '@/pages/General/NotificationList';
import Help from '@/pages/General/Help.vue';
import Invoices from '@/pages/General/Invoices.vue';
import ToS from '@/pages/General/ToS.vue';
import Imprint from '@/pages/General/Imprint.vue';
import PrivacyPolicy from '@/pages/General/PrivacyPolicy.vue';
import Logout from '@/pages/General/Logout.vue';
import Introduction from '@/pages/General/Introduction.vue';

export default [
    {
        path: '/',
        name: 'Home',
        redirect: {name: 'CouponList'},
    },
    {
        path: '/affiliate',
        name: 'Affiliate',
        component: Affiliate,
    },
    {
        path: '/coupocket-for-company',
        name: 'CoupocketForCompany',
        component: CoupocketForCompany,
    },
    {
        path: '/share',
        name: 'Share',
        component: Share,
    },
    {
        path: '/notification-list',
        name: 'NotificationList',
        component: NotificationList
    },
    {
        path: '/invoice',
        name: 'Invoices',
        component: Invoices,
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/tos',
        name: 'ToS',
        component: ToS,
    },
    {
        path: '/imprint',
        name: 'Imprint',
        component: Imprint,
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },
    {
        path: '/introduction',
        name: 'Introduction',
        component: Introduction,
        meta: {
            pageHeaderState: PAGE_STATE.hidden,
            pageFooterState: PAGE_STATE.hidden,
        }
    }
];
