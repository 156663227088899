import {Analytics} from '@/domain/analytics';

export const ShareMixin = {
    data() {
        return {}
    },
    computed: {
        inviteFriendsHref() {
            return this.determineMailToShareHrefFromStaticContent(SHARE.CONTENT_ID.FRIEND);
        },
        inviteCompanyHref() {
            return this.determineMailToShareHrefFromStaticContent(SHARE.CONTENT_ID.COMPANY);
        }
    },
    methods: {
        inviteFriend(e) {
            this.share(SHARE.TYPE.FRIEND, e);
            Analytics.logShareFriend();
        },
        inviteCompany(e) {
            if (!this.isLoggedIn) {
                let redirectAfterLogin = {
                    name: 'LoginForm',
                    query: {
                        'redirectAfterLogin':
                        this.$router.resolve({name: this.$route.name, params: this.$route.params, query: {inviteAction: SHARE.TYPE.COMPANY}}).resolved.fullPath
                    }
                };

                e.preventDefault();

                this.$c3.confirm(
                    'Unternehmen einladen',
                    'Du bist nicht angemeldet, weshalb wir dir keine 50 € bei einer erfolgreichen Empfehlung auszahlen können. Möchtest du Coupocket trotzdem teilen oder dich zuerst anmelden?',
                    () => this.$router.push(redirectAfterLogin),
                    (result) => {
                        // Do not share on close or backdrop
                        if (result && result.dismiss && result.dismiss === 'cancel') {
                            this.share(SHARE.TYPE.COMPANY);
                            Analytics.logShareCompany();
                        }
                    },
                    ['label.login', 'Trotzdem teilen']
                );
            } else {
                this.share(SHARE.TYPE.COMPANY, e);
                Analytics.logShareCompany();
            }
        },
        determineMailToShareHrefFromStaticContent(id) {
            let content = this.$store.getters['staticContent/singleContent'](id);
            if (content) {
                let subject = encodeURIComponent(content.title);
                let body = encodeURIComponent(content.text);

                return `mailto:?to=&subject=${subject}&body=${body}`;
            }
            return '';
        },
        share(type, event) {
            let content = this.$store.getters['staticContent/singleContent'](SHARE.CONTENT_ID[type]);
            if (window.plugins && window.plugins.socialsharing) {
                try {
                    let options = {
                        subject: content.title,
                        message: content.text,
                    };
                    window.plugins.socialsharing.shareWithOptions(options);
                    if (event && event.preventDefault) {
                        event.preventDefault();
                    }
                } catch (e) {
                    console.error('Sharing failed', e, type, event);
                }
            } else if (!event || !event.preventDefault) {
                switch (type) {
                    case SHARE.TYPE.FRIEND:
                        window.location = this.inviteFriendsHref;
                        break;
                    case SHARE.TYPE.COMPANY:
                        window.location = this.inviteCompanyHref;
                        break;
                }
            }
        },
        triggerShareOnLoad() {
            if (this.$route.query && this.$route.query.inviteAction && this.$route.query.inviteAction !== SHARE.TYPE.COMPANY) {
                this.share(this.$route.query.inviteAction);
            }
        }
    },
    activated() {
        this.triggerShareOnLoad();
    },
    mounted() {
        this.triggerShareOnLoad();
    }
};
