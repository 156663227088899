export default {
    bonusCards: {
        list: {
            draft: [],
            review: [],
            scheduled: [],
            active: [],
            archive: []
        }
    }
};
