<template>
    <div class="c3-bottom-drawer" :class="wrapperClass">
        <div class="c3-bottom-drawer__inner">
            <div class="c3-bottom-drawer__header">
                <slot name="headerLeft"></slot>
                <slot name="headerRight">
                    <div class="c3-bottom-drawer-close" @click="close">
                        <c3-toggle-icon/>
                    </div>
                </slot>
            </div>
            <div class="c3-bottom-drawer__content">
                <slot>Hi!</slot>
            </div>
            <div class="c3-bottom-drawer__footer">
                <slot name="footer">Huhu</slot>
            </div>
        </div>
    </div>
</template>

<script>
  import C3ToggleIcon from '@/components/icon/C3ToggleIcon.vue';

  export default {
    components: {
      C3ToggleIcon,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
      fromSlideDirection: {
        type: String,
      },
    },
    data() {
      return {
        zIndex: null,
      };
    },
    computed: {
      wrapperClass() {
        let classes = [];

        if (this.$store.getters['view/bottomDrawerOpen'](this.name)) {
          classes.push('c3-bottom-drawer--active');
        }

        return classes.join(' ');
      },
    },
    methods: {
      close() {
        this.$emit('close');
        this.$store.commit('view/hideBottomDrawer');
      },
    },
  };
</script>
<style lang="scss">
    .c3-bottom-drawer-open {
        .c3-main {
            overflow: hidden;
            pointer-events: none;
        }

        &:after {
            opacity: 1;
            transition: opacity 0.2s;
            // Block access to layer below drawer
            pointer-events: auto;
        }
    }
</style>
<style lang="scss" scoped>
    .c3-bottom-drawer {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        pointer-events: none;
        z-index: map-get($z-index, bottomDrawer);
        visibility: hidden;
        transition: visibility 0s 0.4s;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-color: rgba(0, 0, 0, 0.7);
            opacity: 0;

            transition: opacity 0.4s;
        }
    }

    .c3-bottom-drawer__inner {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translate3d(0, 100%, 0);

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        padding: var(--page-header-horizontal-spacing);

        background-color: $nonary-color;
        transition: transform 0.4s;
    }

    .c3-bottom-drawer--active {
        pointer-events: auto;
        visibility: visible;
        transition: visibility 0s 0s;

        &:before {
            opacity: 1;
        }

        .c3-bottom-drawer__inner {
            transform: translate3d(0, 0, 0);
        }
    }

    .c3-navigation-drawer {
        height: 100%;
        width: 100%;
        overflow: hidden;
        @include montserratRegular();
    }

    .c3-bottom-drawer-close {
        $size: 20px;

        position: absolute;
        top: 0;
        right: 0;
        height: $size;

        padding-top: 15px;
        padding-left: var(--page-header-horizontal-spacing);
        padding-right: var(--page-header-horizontal-spacing);

        transition: 0s 0.4s;


        & /deep/ {
            .c3-toggle-icon {
                height: $size;
                width: $size;
            }

            .c3-toggle-icon-bar {
                background-color: $senary-color;
            }
        }
    }
</style>
