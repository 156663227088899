<template>
    <div class="c3-main c3-page-general-affiliate">
        <div class="c3-page-content">
            <c3-page-image name="share"/>
            <div v-if="affiliateDescriptionShort" class="c3-affiliate-description-short-header">
                <c3-text-with-icon :title="affiliateDescriptionShort.title" icon="c3-icon-money-bill"/>
            </div>
            <div class="c3-page-content-horizontal-padding">
                <div v-if="affiliateDescriptionShort" class="c3-affiliate-description-short">
                    <div class="page" v-html="affiliateDescriptionShort.text"></div>
                </div>

                <template v-if="isLoggedIn">
                    <h2>{{ $t('label.phone') }}</h2>
                    <div class="c3-page-phone-input">
                        <div class="c3-page-phone-input-wrapper">
                            <c3-form-select-phone-number v-model="phone" @sanitizedInput="val => sanitizedPhoneNumber = val" :validator="$v.phone" :errorMessage="false" :invert="true"/>
                        </div>
                    </div>
                    <a @click="generateAffiliateLink" class="c3-share-link">
                        <c3-button :label="$t('pages.user.affiliate.inviteCompany')"/>
                    </a>
                </template>
                <template v-else>
                    <a :href="inviteCompanyHref" @click="inviteCompany" class="c3-share-link">
                        <c3-button :label="$t('pages.user.affiliate.inviteCompany')"/>
                    </a>
                </template>

                <div v-if="affiliateDescriptionLong" class="c3-affiliate-description-long">
                    <h2>{{affiliateDescriptionLong.title}}</h2>
                    <div v-html="affiliateDescriptionLong.text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ShareMixin} from '@/domain/mixin/local/share';

    import C3PageImage from '@/components/media/PageImage';
    import C3TextWithIcon from '@/components/content/text/TextWithIcon';
    import C3Button from '@/components/form/Button';
    import phoneNumberService from '@/domain/service/phoneNumberService';

    export default {
        name: 'Affiliate',
        mixins: [ShareMixin],
        components: {
            C3PageImage,
            C3TextWithIcon,
            C3Button
        },
        data() {
            return {
                phone: null,
                sanitizedPhoneNumber: ''
            }
        },
        computed: {
            affiliateDescriptionShort() {
                return this.$store.getters['staticContent/singleContent'](18);
            },
            affiliateDescriptionLong() {
                return this.$store.getters['staticContent/singleContent'](17);
            },
            isLoggedIn() {
                return this.$store.getters['user/isLoggedIn'];
            }
        },
        methods: {
            async generateAffiliateLink() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.$store.commit('view/showLoading');
                    try {
                        const affiliate = await this.$store.dispatch('user/createAffiliateLink', this.sanitizedPhoneNumber);
                        this.$store.commit('view/hideLoading');
                        this.share(SHARE.TYPE.COMPANY);
                    } catch(e) {
                        this.$store.commit('view/hideLoading');
                        this.$c3.handleErrorsWithAlert(e);   
                    }
                }
            }
        },
        validations: {
            phone: {
                phoneNumberServiceValidator: phoneNumberService.validate
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-page-content {
        background: #F3F2F2;
    }

    .c3-page-phone-input {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .c3-page-phone-input-wrapper {
        max-width: 300px;
    }

    .c3-page-general-affiliate {
        text-align: center;
    }

    .c3-affiliate-description-short-header {
        position: relative;
        z-index: 1;
        margin-bottom: 30px;
        @include horizontalSpacing();

        .c3-content-text-with-icon {
            margin-top: -70px;
            background-color: $white-color;
            @include boxShadow();
            padding-left: 15px;
            padding-right: 15px;

            & /deep/ .c3-content-text-with-icon-title {
                margin-top: 5px;
                font-size: 18px;
            }
        }
    }

    .c3-share-link {
        display: block;
    }

    .c3-user-affiliate-link {
        margin-top: 10px;
    }

    .c3-affiliate-description-short {
        margin-bottom: 30px;
    }

    .c3-affiliate-description-long {
        margin-top: 30px;
        margin-bottom: 20px;

        h2 {
            margin-bottom: 15px;
        }
    }

    .c3-raffle-description {
        margin-bottom: 30px;
    }
</style>
