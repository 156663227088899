import Vue from 'vue';
import {LocalStorage} from '@/domain/utility/localStorage';

const userStorage = LocalStorage('user');

const STORAGE_FLAGS = 'flags';
const STORAGE_TOKEN = 'token';
const STORAGE_TOKEN_ORIGINAL_USER = 'token_original_user';
const STORAGE_USER = 'user';
const STORAGE_USER_ORIGINAL = 'user_original';
const STORAGE_POSITION = 'position';

export const initialize = (state) => {
    if (userStorage.has(STORAGE_TOKEN)) {
        state.token = userStorage.get(STORAGE_TOKEN);
    }
    if (userStorage.has(STORAGE_USER)) {
        state.user = userStorage.get(STORAGE_USER);
    }
    if (userStorage.has(STORAGE_POSITION)) {
        state.position = userStorage.get(STORAGE_POSITION);
    }
    if (userStorage.has(STORAGE_FLAGS)) {
        state.flags = userStorage.get(STORAGE_FLAGS);
    }
    if (userStorage.has(STORAGE_TOKEN_ORIGINAL_USER)) {
        state.tokenOriginalUser = userStorage.get(STORAGE_TOKEN_ORIGINAL_USER);
    }
    if (userStorage.has(STORAGE_USER_ORIGINAL)) {
        state.originalUser = userStorage.get(STORAGE_USER_ORIGINAL);
    }
};

export const updatePosition = (state, position) => {
    if (position.error) {
        state.position.error = position.error;
    } else {
        state.position = position;
        userStorage.set(STORAGE_POSITION, position);
    }
};

export const updateAdvertiser = (state, advertiser) => {
    state.advertiser = advertiser;
};

export const updatePayment = (state, payment) => {
    state.payment = payment;
};

export const updateMemberships = (state, memberships) => {
    state.memberships = memberships;
};

export const updateToken = (state, token) => {
    state.token = token;
    if (null === token) {
        userStorage.remove(STORAGE_TOKEN);
    } else {
        userStorage.set(STORAGE_TOKEN, token);
    }
};

export const updateTokenOriginalUser = (state, token) => {
    console.debug('updateTokenOriginalUser', token);
    state.tokenOriginalUser = token;
    if (null === token) {
        userStorage.remove(STORAGE_TOKEN_ORIGINAL_USER);
    } else {
        userStorage.set(STORAGE_TOKEN_ORIGINAL_USER, token);
    }
};

export const updateUser = (state, user) => {
    state.user = user;
    if (null === user) {
        userStorage.remove(STORAGE_USER);
    } else {
        userStorage.set(STORAGE_USER, user);
    }
};

export const updateOriginalUser = (state, user) => {
    state.originalUser = user;
    if (null === user) {
        userStorage.remove(STORAGE_USER_ORIGINAL);
    } else {
        userStorage.set(STORAGE_USER_ORIGINAL, user);
    }
};

export const updateOwnerRedeem = (state, ownerRedeem) => {
    if (Array.isArray(ownerRedeem)) {
        Vue.set(state, 'ownerRedeem', ownerRedeem);
    } else {
        console.error('Called updateOwnerRedeem with invalid value', ownerRedeem);
    }
};

export const updateVisibleFavoriteCoupon = (state, visibleFavoriteCoupon) => {
    if (Array.isArray(visibleFavoriteCoupon)) {
        Vue.set(state, 'visibleFavoriteCoupon', visibleFavoriteCoupon);
    } else {
        console.error('Called updateVisibleFavoriteCoupon with invalid value', visibleFavoriteCoupon);
    }
};

export const addSingleFavoriteCoupon = (state, favoriteCoupon) => {
    if (Array.isArray(state.visibleFavoriteCoupon)) {
        // Remove existing entries (by coupon id, because there can be local entries without a favoriteCoupon ID)
        state.visibleFavoriteCoupon = state.visibleFavoriteCoupon.filter(item => item.coupon !== favoriteCoupon.coupon);
        // Prepend element so "new" favorite is on top
        state.visibleFavoriteCoupon.unshift(favoriteCoupon);
    } else {
        Vue.set(state, 'visibleFavoriteCoupon', [favoriteCoupon]);
    }
};

export const addSingleFavoriteCouponByCoupon = (state, coupon) => {
    let favoriteCoupon = {id: null, coupon: coupon, user: state.user.id};
    if (Array.isArray(state.visibleFavoriteCoupon)) {
        // Remove existing entries (by coupon id, because there can be local entries without a favoriteCoupon ID)
        state.visibleFavoriteCoupon = state.visibleFavoriteCoupon.filter(item => item.coupon !== favoriteCoupon.coupon);
        // Prepend element so "new" favorite is on top
        state.visibleFavoriteCoupon.unshift(favoriteCoupon);
    } else {
        Vue.set(state, 'visibleFavoriteCoupon', [favoriteCoupon]);
    }
};

export const removeSingleFavoriteCouponByCoupon = (state, coupon) => {
    if (Array.isArray(state.visibleFavoriteCoupon)) {
        state.visibleFavoriteCoupon = state.visibleFavoriteCoupon.filter(item => item.coupon !== coupon);
    }
};

export const updateRedeemState = (state, redeemStates) => {
    redeemStates.forEach(redeemState => updateSingleRedeemState(state, redeemState));
};

export const updateSingleRedeemState = (state, redeemState) => {
    Vue.set(state.redeemStates, redeemState.coupon, redeemState);
};

export const updateNoCouponOnHomeAlertShown = (state, noCouponOnHomeAlertShown) => {
    state.flags.noCouponOnHomeAlertShown = noCouponOnHomeAlertShown;
    userStorage.set(STORAGE_FLAGS, state.flags);
};

export const updateIsCompany = (state, isCompany) => {
    state.flags.isCompany = isCompany;
    userStorage.set(STORAGE_FLAGS, state.flags);
};

export const updateHasSeenNotificationPermissionDialog = (state, hasSeenNotificationPermissionDialog) => {
    state.flags.hasSeenNotificationPermissionDialog = hasSeenNotificationPermissionDialog;
    userStorage.set(STORAGE_FLAGS, state.flags);
};

export const updateAffiliates = (state, affiliates) => {
    state.affiliates = affiliates;
};

export const updateAffiliateToken = (state, affiliateToken) => {
    state.affiliateToken = affiliateToken;
}
