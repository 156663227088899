<template>
    <div class="c3-icon-wrapper">
        <svg version="1.1" viewBox="0 0 448 320" xmlns="http://www.w3.org/2000/svg">
            <path :fill="color1"
                  d="m424 0h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm164 92h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm-156-100h-80a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm-4 100h-72v-72h72zm244-100a24.038 24.038 0 0 0-24 24v80a24.038 24.038 0 0 0 24 24h80a24.038 24.038 0 0 0 24-24v-80a24.038 24.038 0 0 0-24-24zm4 28h72v72h-72z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
