export const RouterMixin = {
    computed: {
        hasRedirectAfterLogin() {
            try {
                return this.$route.query.redirectAfterLogin && this.$route.query.redirectAfterLogin.length > 0;
            } catch (e) {
                return false;
            }
        },
        hasRedirectAfterLogout() {
            try {
                return this.$route.query.redirectAfterLogout && this.$route.query.redirectAfterLogout.length > 0;
            } catch (e) {
                return false;
            }
        }
    },
    methods: {
        pushRedirectAfterLogin() {
            if (this.hasRedirectAfterLogin) {
                this.$router.push(this.$route.query.redirectAfterLogin);
            } else {
                console.error('redirectAfterLogin is not set, so push is not possible', this.$route);
            }
        },
        getRedirectAfterLoginQueryObject(currentQueryObject) {
            if (typeof currentQueryObject !== 'object') {
                currentQueryObject = {};
            }

            if (!this.hasRedirectAfterLogin) {
                return currentQueryObject;
            }
            return Object.assign(currentQueryObject, {redirectAfterLogin: this.$route.query.redirectAfterLogin});
        },
        pushRedirectAfterLogout() {
            if (this.hasRedirectAfterLogout()) {
                this.$router.push(this.$route.query.redirectAfterLogout);
            } else {
                console.error('redirectAfterLogin is not set, so push is not possible', this.$route);
            }
        },
        getRedirectAfterLogoutQueryObject(currentQueryObject) {
            if (typeof currentQueryObject !== 'object') {
                currentQueryObject = {};
            }

            if (!this.hasRedirectAfterLogout) {
                return currentQueryObject;
            }
            return Object.assign(currentQueryObject, {redirectAfterLogout: this.$route.query.redirectAfterLogout});
        },
    }
};