<template>
    <div class="c3-notification-list">
        <template v-for="notification in notifications">
            <c3-notification-list-item :notification="notification"/>
        </template>
    </div>
</template>

<script>
    import C3NotificationListItem from '@/components/content/notification/list/Item';

    export default {
        props: {
            notifications: {
                type: Array,
                required: true
            }
        },
        components: {
            C3NotificationListItem
        },
        data() {
            return {
                isLoading: true,
            }
        },
    };
</script>
<style lang="scss" scoped>
    .c3-notification-list {
        margin-top: -10px;
        padding-left: var(--page-content-horizontal-spacing);
        padding-right: var(--page-content-horizontal-spacing);
    }
</style>