<template>
    <li class="c3-list-icon-item">
        <c3-icon-check-circle primaryColor="quinary"/>
        <div class="c3-list-icon-item-content">
            <slot></slot>
        </div>
    </li>
</template>

<script>
    export default {
        props: {},
        computed: {},
        methods: {}
    };
</script>
<style lang="scss" scoped>
    .c3-list-icon-item {
        display: flex;
        padding-bottom: 8px;

        &:last-child {
            padding-bottom: 0;
        }
    }

    .c3-list-icon-item-content {
    }

    .c3-icon-wrapper {
        height: 18px;
        padding-right: 10px;
    }
</style>