<template>
    <div class="c3-user-login-impersonate">
        <h2 class="c3-user-login-impersonate__title">{{ $t('user.impersonate.title') }}</h2>
        <div class="c3-user-login-impersonate__list">
            <div v-for="impersonateUser in impersonateUserOptions" class="c3-user-login-impersonate__list-item" @click="impersonate(impersonateUser)">
                <span class="c3-user-login-impersonate__phone">{{ impersonateUser.phone }}</span>
                <table v-if="hasImpersonateUserAdditionalData" class="c3-user-login-impersonate__user-table">
                    <tbody>
                    <template v-if="impersonateUser.firstName || impersonateUser.lastName">
                        <tr class="c3-user-login-impersonate__user-table-row">
                            <th>{{ $t('user.impersonate.name') }}:</th>
                            <td>{{ impersonateUser.firstName }} {{ impersonateUser.lastName }}</td>
                        </tr>
                    </template>
                    <template v-if="impersonateUser.company">
                        <tr>
                            <th>{{ $t('user.impersonate.firm') }}:</th>
                            <td>{{ impersonateUser.company }}</td>
                        </tr>
                    </template>
                    </tbody>
                </table>
                <div class="c3-user-login-impersonate__login">
                    {{ $t('label.login') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {RouterMixin} from '@/domain/mixin/local/router';

    export default {
        mixins: [RouterMixin],
        data() {
            return {
                impersonateUserOptions: []
            };
        },
        methods: {
            hasImpersonateUserAdditionalData(user) {
                return user.firstName || user.lastName || user.company;
            },
            async impersonate(user) {
                if (this.isImpersonate && this.$store.getters['user/originalUser'].id === user.id) {
                    console.debug('[pages][user][login][impersonate] Switch back to original user', this.$store.getters['user/originalUser'].id);
                    this.$store.commit('view/showLoading');
                    await this.$store.dispatch('user/restoreOriginalUser');
                    this.$store.commit('view/hideLoading');
                } else if (this.currentUser.id !== user.id) {
                    console.debug('[pages][user][login][impersonate] Impersonate user', user.id);
                    this.$store.commit('view/showLoading');
                    await this.$store.dispatch('user/impersonateUser', {userId: user.id});
                    this.$store.commit('view/hideLoading');
                } else {
                    console.debug('[pages][user][login][impersonate] Do not impersonate anyone')
                }

                if (this.hasRedirectAfterLogin) {
                    this.pushRedirectAfterLogin();
                } else {
                    this.$router.push(ROUTER.defaultLoginRedirect);
                }
            },
        },
        async created() {
            if (!this.isLoggedIn) {
                this.$router.push({name: 'LoginForm'});
                return;
            }
            this.$store.commit('view/showLoading');
            const impersonateUsers = await this.$store.dispatch('user/loadImpersonateList');
            if (this.isImpersonate) {
                const originalUser = await this.$store.dispatch('user/loadOriginalUser');
                this.impersonateUserOptions = [originalUser, ...impersonateUsers];
            } else {
                const currentUser = await this.$store.dispatch('user/loadCurrentUser');
                this.impersonateUserOptions = [currentUser, ...impersonateUsers];
            }
            this.$store.commit('view/hideLoading');
        },
    };
</script>

<style lang="scss">
    .c3-user-login-impersonate__title {
        margin-bottom: var(--spacing-m);
    }

    .c3-user-login-impersonate__list {
        max-height: 300px;
        margin-top: calc(-1 * var(--spacing-xxs));
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .c3-user-login-impersonate__list-item {
        margin-top: var(--spacing-xxs);
        padding: var(--spacing-xs);
        border: 1px solid $white-color;
    }

    .c3-user-login-impersonate__user-table-row {
        vertical-align: top;
    }

    .c3-user-login-impersonate__phone {
        @include openSansBold();
        font-size: var(--font-size-l);
    }

    .c3-user-login-impersonate__login {
        @include openSansBold();
        text-align: right;
    }
</style>
