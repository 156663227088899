<template>
    <div class="c3-bonus-card-image-wrapper">
        <picture v-if="images.length">
            <source v-for="image in images" :media="image.breakPoint" :srcset="image.src" :key="image.src"/>
            <img :src="imageSrc" :alt="bonusCard.title" class="c3-bonus-card-image"/>
        </picture>
        <c3-loader v-else/>
    </div>
</template>

<script>
    import {BonusCardConfig} from '@/config/bonusCard';

    export default {
        props: {
            bonusCard: {
                type: Object,
                required: true
            },
            isVisible: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                viewportWidth: null,
            }
        },
        computed: {
            imageSrc() {
                return BonusCardConfig.getMediaBaseUrl() + '/' + MEDIA.SIZES[MEDIA.SIZES.length - 1] + '/' + this.fileName;
            },
            images() {
                return window.MEDIA.SIZES
                    .map(size => {
                        return {
                            src: BonusCardConfig.getMediaBaseUrl() + '/' + size + '/' + this.fileName,
                            breakPoint: `(max-width: ${size}px)`
                        }
                    });
            },
            fileName() {
                return typeof this.bonusCard.image === 'string' ? this.bonusCard.image : this.bonusCard.image.publicName;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .c3-bonus-card-image-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: percentage(1 / $image-aspect-ratio-default);
    }

    .c3-bonus-card-image {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .c3-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -22px;
        margin-left: -22px;
    }
</style>
