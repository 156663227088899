<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path :fill="color1" d="M568.28 192.08h-.04c-22.64.1-92 4.29-158.85 42.35-19.43-81.58-60.36-153.88-112.52-199.29-2.4-2.09-5.63-3.14-8.85-3.14s-6.44 1.04-8.85 3.14C227 80.55 186.05 152.85 166.6 234.44 99.76 196.37 30.4 192.19 7.75 192.08h-.04c-4.39 0-7.76 3.41-7.72 7.82.23 27.94 7.14 126.2 88.77 199.41 58.23 56.73 131.07 74.8 176.96 80.46.01.3 44.52.3 44.52 0 45.89-5.66 118.73-23.73 176.96-80.46 81.64-73.2 88.54-171.47 88.77-199.41.07-4.41-3.29-7.82-7.69-7.82zM110.13 375.47c-55.22-49.52-71.61-112.28-76.32-149.92 40.84 4.1 110.91 19.31 166.44 73.44 25.37 22.72 44.4 49.96 58.17 83.28 8.5 20.57 13.57 41.3 13.57 65.82-42.77-1.15-114.58-26.6-161.86-72.62zm111.46-100.34c-8.51-8.3-17.45-15.42-26.46-22.15 15.13-71.58 48.71-138.15 92.9-182.33 44.17 44.17 77.74 110.74 92.85 182.32-9.02 6.73-17.95 13.85-26.47 22.15-33.12 29.65-53.36 63.34-66.41 94.91-13.05-31.57-33.29-65.26-66.41-94.9zM464.9 376.37c-44.61 43.46-116.13 70.51-160.9 71.71 0-24.31 4.98-45.03 13.57-65.82 13.77-33.33 32.8-60.57 59.17-84.22 54.78-53.42 124.71-68.46 165.44-72.52-4.74 37.68-21.29 100.62-77.28 150.85z"/>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
