import {CoupocketException} from '@/domain/exception/coupocketException';

export default {
    install(Vue, options) {

        if (typeof Vue.prototype.$c3 !== 'object') {
            Vue.prototype.$c3 = {};
        }

        let instance = null;
        Vue.mixin({
            beforeCreate() {
                if (null === instance) {
                    instance = this;
                }
            }
        });

        Vue.prototype.$c3.handleErrorsWithAlert = function (errors) {
            if (errors instanceof CoupocketException) {
                instance.$c3.errorAlert(errors.getTitle(), errors.getMessage());
                return;
            }

            if (null !== errors && typeof errors === 'object' && (errors.detail || errors.messageCode)) {
                errors = [errors];
            }

            if (Array.isArray(errors)) {
                errors.forEach(error => {
                    if (null !== error && typeof error === 'object' && error.detail) {
                        instance.$c3.errorAlert('', error.detail);
                    } else if (null !== error && typeof error === 'object' && error.messageCode) {
                        instance.$c3.errorAlert('', instance.$t(error.messageCode));
                    } else {
                        instance.$c3.errorAlert('', instance.$t('error.genericMessage'));
                    }
                });
            } else {
                instance.$c3.errorAlert('', instance.$t('error.genericMessage'));
            }
        };
    }
};
