<template>
    <form class="c3-user-login-form c3-user-login-step" @submit.prevent="submit">
        <h2>{{ $t('user.phoneLabel') }}</h2>
        <c3-form-select-phone-number v-model="phone" @sanitizedInput="val => sanitizedPhoneNumber = val" :validator="$v.phone" :errorMessage="false" :invert="true"/>
        <div class="c3-user-login-submit">
            <c3-form-submit :label="$t('label.next')" :invert="true"/>
            <div class="c3-user-login-registration-additional-information">
                <router-link :to="{name:'Home'}">{{ $t('navigation.home') }}</router-link>
            </div>
        </div>
    </form>
</template>

<script>
    import phoneNumberService from '@/domain/service/phoneNumberService';
    import {RouterMixin} from '@/domain/mixin/local/router';
    import {LoginMixin} from '@/domain/mixin/local/login';

    export default {
        mixins: [RouterMixin, LoginMixin],
        data() {
            return {
                phone: '',
                sanitizedPhoneNumber: '',
            };
        },
        methods: {
            submit() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.$c3.confirmYesNo(
                        'confirm.sendPin.title',
                        this.$t('confirm.sendPin.message', { phone: this.sanitizedPhoneNumber }),
                        this.sendLoginPinAndRedirectToPinForm
                    )
                }
            },
        },
        created() {
            if (this.$store.getters['user/isLoggedIn']) {
                if (this.hasRedirectAfterLogin) {
                    this.pushRedirectAfterLogin();
                } else {
                    this.$router.push(ROUTER.defaultLoginRedirect);
                }
            }
        },
        validations: {
            phone: {
                phoneNumberServiceValidator: phoneNumberService.validate
            }
        },
    };
</script>