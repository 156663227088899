<template>
    <c3-drawer :name="name" :fullWidth="true">
        <c3-grid-tile>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CouponCreateWizard'}" :label="$t('label.invest')" icon="c3-icon-plus-square"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerCouponListPublished'}" :label="$t('label.active')" icon="c3-icon-c3-coupon-check"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerCouponListReview'}" :label="$t('label.in_examination')" icon="c3-icon-search"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerCouponListScheduled'}" :label="$t('label.planned')" icon="c3-icon-c3-coupon-clock"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerCouponListDraft'}" :label="$t('label.drafts')" icon="c3-icon-tag"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerCouponListArchive'}" :label="$t('label.archive')" icon="c3-icon-archive"/>
            <c3-grid-tile-link-item @click="handleClick" :to="{name:'CustomerCouponListStatistic'}" :label="$t('label.redeemed')" icon="c3-icon-chart-line"/>
        </c3-grid-tile>
    </c3-drawer>
</template>

<script>
    import C3Drawer from '@/components/layout/navigation/Drawer.vue';
    import C3GridTile from '@/components/layout/grid/tile/Index';
    import C3GridTileLinkItem from '@/components/layout/grid/tile/LinkItem';
    import C3GridTileItem from '@/components/layout/grid/tile/Item';

    export default {
        components: {
            C3Drawer,
            C3GridTile,
            C3GridTileLinkItem,
            C3GridTileItem
        },
        data() {
            return {
                name: DRAWER.CUSTOMER_MY_COUPON_NAVIGATION
            }
        },
        methods: {
            handleClick() {
                // Close drawer on click, even if route doesn't change
                this.$store.commit('view/removeDrawerAll')
            },
        },
    };
</script>
