<template>
    <div class="c3-invoice__list-item">
        <div class="c3-invoice__list-item-invoice-number">{{ invoice.invoiceNumber }}</div>
        <div class="c3-invoice__list-item-date">{{ $t('pages.invoice.date') }}: {{ formatDate(invoice.created) }}</div>
        <div class="c3-invoice__list-item-amount">{{ $t('pages.invoice.amount') }}: {{ formatAmount(invoice.totalAmount) }} €</div>
        <c3-button class="c3-invoice__list-item-download" @click="download(invoice)" :label="$t('label.download')"/>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button';
    import {DateUtility} from "@/domain/utility/date";
    import { saveToFile } from '@/utility/media/file';
    import { notifyDownload } from '@/utility/notification' 

    export default {
        components: {C3Button},
        props: {
            invoice: {
                type: Object,
                required: true,
            }
        },
        methods: {
            formatDate(date) {
                return DateUtility.formatDateShort(date);
            },
            formatAmount(amount) {
                return (amount / 100).toFixed(2);
            },
            async download(invoice) {
                try {
                    this.$store.commit('view/showLoading');
                    const isNative = this.isNative;
                    const url = await this.$store.dispatch('invoice/loadOneInvoiceAsPdf', {userId: this.currentUser.id, invoiceId: invoice.id, isNative});
                    const fileName = `${this.$t('pages.invoice.label')}_${invoice.invoiceNumber}.pdf`;
                    if (isNative) {
                        this.$store.commit('invoice/updateDownloadInfo', {
                            fileName,
                            fileUrl: null,
                            isDownloading: true,
                            isFailed: false
                        });
                        const fileInfo = await saveToFile(fileName, url, this.isIOS);
                        console.debug(fileInfo)
                        this.$store.commit('invoice/updateDownloadInfo', {
                            fileUrl: fileInfo.localUrl,
                            isDownloading: false,
                            isFailed: false
                        });
                        notifyDownload(fileName, `${this.$t('label.downloadComplete')}.${fileInfo.size}`, { url: fileInfo.localUrl })
                    } else {
                        const hiddenElement = document.createElement('a');
                        hiddenElement.href = url;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = fileName;
                        hiddenElement.click();
                    }
                } catch (e) {
                    console.error(e);
                    if (this.isNative) {
                        this.$store.commit('invoice/updateDownloadInfo', {
                            isDownloading: false,
                            isFailed: true, 
                        });
                    }
                    this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                }
                this.$store.commit('view/hideLoading');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c3-invoice__list-item {
        border: 1px solid $seventh-color;
        border-radius: 5px;
        padding: 15px;
        text-align: left;
    }

    .c3-invoice__list-item-invoice-number {
        @include openSansSemiBold();
        margin-bottom: 10px;
    }

    .c3-invoice__list-item-download {
        display: block;
        margin-top: 15px;
        padding: 10px 40px;
        cursor: pointer;
    }
</style>
