let BonusCardConfigInternal = function () {
    this.STATE_DRAFT = 'draft';
    this.STATE_REVIEW = 'review';
    this.STATE_PUBLISHED = 'published';
    this.STATE_DELETED = 'deleted';

    this.getPossibleNecessaryRedeemCounts = function () {
        return [3, 4, 5, 6, 8, 9, 10, 12, 15];
    };

    this.getMaxTitleLength = function () {
        return 32;
    };

    this.getMaxDescriptionLength = function () {
        return 160;
    };

    this.getMaxLegalNoticeLength = function () {
        return 160;
    };

    this.getMediaBaseUrl = function () {
        return window.API_BASE_URL + '/user_media/bonusCard';
    }
};

export const BonusCardConfig = Object.freeze(new BonusCardConfigInternal());
