<template>
    <div class="c3-main c3-page-general-imprint">
        <div class="c3-page-content c3-page-content-padding">
            <div class="c3-static-content" v-html="text"></div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            text() {
                let content = this.$store.getters['staticContent/singleContent'](5);
                if (null !== content && typeof content === 'object') {
                    return content.text;
                }
                return '';
            }
        },
        created() {
            this.$store.commit('view/showLoading');
            this.$store.dispatch('staticContent/loadSingleContent', 5)
                .catch((error) => {
                    this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                    console.error(error);
                })
                .finally(() => this.$store.commit('view/hideLoading'));
        }
    };
</script>
<style lang="scss" scoped>
</style>
