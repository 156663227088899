export default {
  install: function(Vue, options) {
    Vue.mixin({
      computed: {
        getPlatform() {
          if (window.device) {
            return window.device.platform;
          }
          return window.PLATFORM.BROWSER;
        },
        isIOS() {
          return this.getPlatform === window.PLATFORM.IOS;
        },
        isAndroid() {
          return this.getPlatform === window.PLATFORM.ANDROID;
        },
        isApp() {
          return !this.isBrowser;
        },
        isNative() {
          return this.isApp;
        },
        isBrowser() {
          return this.getPlatform === window.PLATFORM.BROWSER;
        },
      },
    });
  },
};
