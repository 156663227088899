import {HTTP} from '@/domain/api/http';
import userApiConverter from '@/domain/api/converter/user';
import {BonusCardConverter} from '@/domain/api/converter/bonusCard';
import {BonusCardFavoriteConverter} from '@/domain/api/converter/bonusCardFavorite';
import {BonusCardRedeemConverter} from '@/domain/api/converter/bonusCardRedeem';

export const loadOneBonusCard = async (context, id) => {
    let response = await HTTP.get(API_BASE_URL + `/bonus-card/${id}`);

    let bonusCard = BonusCardConverter.fromApi(response.data.data);
    let bonusCardRedeemStates = extractRedeemStatesFromIncluded(response.data.included);

    updateBonusCardIncluded(context, response);
    context.commit('updateRedeemState', bonusCardRedeemStates);
    context.commit('updateBonusCard', [bonusCard]);
};

export const loadBonusCardById = async (context, id) => {
    id = Array.isArray(id) ? id : [id];

    let response = await HTTP.get(API_BASE_URL + '/bonus-card?id=' + encodeURIComponent(id.join(',')));

    let bonusCards = BonusCardConverter.fromApiCollection(response.data.data);
    let bonusCardRedeemStates = extractRedeemStatesFromIncluded(response.data.included);

    updateBonusCardIncluded(context, response);
    context.commit('updateRedeemState', bonusCardRedeemStates);
    context.commit('updateBonusCard', bonusCards);
};

export const loadBonusCardByCustomer = async (context, customer) => {
    let response = await HTTP.get(API_BASE_URL + '/customer/' + customer + '/bonus-card');

    let bonusCards = BonusCardConverter.fromApiCollection(response.data.data);
    let bonusCardRedeemStates = extractRedeemStatesFromIncluded(response.data.included);

    updateBonusCardIncluded(context, response);
    context.commit('updateRedeemState', bonusCardRedeemStates);
    context.commit('updateBonusCard', bonusCards);
};

export const loadFavorite = async (context) => {
    let response = await HTTP.get(API_BASE_URL + '/user/' + context.rootState.user.user.id + '/bonus-card-favorite');

    let favoriteCoupons = BonusCardFavoriteConverter.fromApiCollection(response.data.data);
    context.commit('replaceFavorite', favoriteCoupons);
};

export const addOneFavoriteByBonusCard = async (context, bonusCardId) => {
    let dummyBonusCardFavorite = {id: 'dummy-' + bonusCardId, user: context.rootState.user.user.id, bonusCard: bonusCardId};
    context.commit('addOneFavorite', dummyBonusCardFavorite);
    let response = await HTTP.put(API_BASE_URL + '/user/' + context.rootState.user.user.id + `/bonus-card-favorite/${bonusCardId}`);
    let bonusCardFavorite = BonusCardFavoriteConverter.fromApi(response.data.data);
    // Remove dummy
    context.commit('removeOneFavorite', dummyBonusCardFavorite);
    context.commit('updateFavorite', [bonusCardFavorite]);
};

export const removeOneFavoriteBonusCard = async (context, bonusCardId) => {
    context.commit('removeOneFavoriteByBonusCard', bonusCardId);
    await HTTP.delete(API_BASE_URL + '/user/' + context.rootState.user.user.id + `/bonus-card-favorite/${bonusCardId}`);
};

export const redeem = async (context, data) => {
    let token = data.token;
    let bonusCard = data.bonusCard;
    if (typeof token !== 'string' || !token.match(/[a-z0-9]/i)) {
        // Invalid token (probably an external link), which causes our API to respond with "No route found"
        // encodeURIComponent sadly doesn't fix the issue: https://github.com/symfony/symfony/issues/21307
        throw {messageCode: 'error.wrongRedeemToken'};
    }

    let response = await HTTP.post(API_BASE_URL + '/bonus-card/' + encodeURIComponent(bonusCard) + '/redeem/' + encodeURIComponent(token));
    await loadOneBonusCard(context, bonusCard);
    return response.data.data;
};

export const removeOneBonusCard = async (context, bonusCard) => {
    let response = await HTTP.delete(API_BASE_URL + `/bonus-card/${bonusCard.id}`);
    context.commit('removeOneBonusCard', bonusCard);
    return response.data.data;
};

function extractRedeemStatesFromIncluded(included) {
    if (included && included.redeemState && included.redeemState.data) {
        let bonusCardRedeemStates = included.redeemState.data;
        return bonusCardRedeemStates.map(bonusCardRedeemState => {
            bonusCardRedeemState.currentCycleRedeems = BonusCardRedeemConverter.fromApiCollection(bonusCardRedeemState.currentCycleRedeems);
            return bonusCardRedeemState;
        });
    }

    return [];
}

function updateBonusCardIncluded(context, response) {
    const included = BonusCardConverter.extractIncludedFromCollection(response.data.included);
    if (included.store) {
        context.commit('userStore/updateStore', included.store, {root: true});
    }
    if (included.user) {
        context.commit('customer/updateCustomer', included.user, {root: true});
    }
}

export const loadBonusCardTemplates = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + `/bonus-card/template`)
            .then(response => {
                context.commit('updateBonusCardTemplate', response.data.data);
                resolve();
            })
            .catch(reject);
    });
};

export const loadBonusCardLimit = (context) => {
    return new Promise((resolve, reject) => {
        HTTP.get(API_BASE_URL + '/bonus-card/limit')
            .then(response => {
                let limit = response.data.data;
                context.commit('updateBonusCardLimit', limit);
                resolve(limit);
            })
            .catch(error => reject(error));
    });
};
