
export const notifyDownload = (title, text, data) => {
    if (cordova.plugins.notification) {
        cordova.plugins.notification.local.schedule({
            id: (new Date()).getTime(),
            title,
            text,
            foreground: true,
            smallIcon: 'res://notification_icon',
            ...data
        });
    }
}
