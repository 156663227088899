<template>
    <div class="c3-icon-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#009AAD">
            <g>
                <rect fill="none" height="24" width="24"/>
            </g>
            <g>
                <g>
                    <polygon points="20.13,5.41 18.72,4 9.53,13.19 5.28,8.95 3.87,10.36 9.53,16.02"/><rect height="2" width="14" x="5" y="18"/>
                </g>
            </g>
        </svg>
    </div>
</template>
<script>
    import {IconMixin} from './mixins/icon';

    export default {
        mixins: [IconMixin],
    };
</script>
