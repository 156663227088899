<template>
    <div v-if="isVisible" class="c3-form-field-error-icon">
        <img src="~@/assets/images/form/errorIcon.svg"/>
    </div>
</template>

<script>
    export default {
        props: {
            isVisible: {
                type: Boolean
            }
        },
    };
</script>