import * as deepmerge from 'deepmerge'
import i18n from '@/plugins/i18n';
import {HTTP} from '@/domain/api/http';
import {Logger} from '@/domain/logger';

let TranslatorInternal = function () {
    let self = this;

    // Alias i18n t function, keep this context with bind
    this.t = i18n.t.bind(i18n);

    this.getCurrentLocale = function () {
        try {
            let lang = window.navigator.language;
            if (lang.length > 2) {
                lang = lang.substring(0, 2);
            }
            return lang;
        } catch (e) {
            return 'de';
        }
    };

    this.updateCurrentTranslations = async function () {
        return await self.updateTranslations(self.getCurrentLocale());
    };

    this.updateTranslations = async function (locale) {
        let response;
        let translation;
        try {
            response = await HTTP.get(API_BASE_URL + `/translation/${locale}`);

            if (!response || !response.data || !response.data.data || typeof response.data.data !== 'object') {
                throw Error('UpdateTranslations failed, because API served invalid translations');
            }

            // Get current translations
            translation = i18n.getLocaleMessage(locale);
            if (!translation || typeof translation !== 'object') {
                translation = {};
            }
            let translationKeyCount = Object.keys(translation);

            // Merge with new translations
            translation = deepmerge(translation, response.data.data);

            if (Object.keys(translation) < translationKeyCount) {
                // Merged translations has less keys than the original, something went wrong here
                throw Error('UpdateTranslations failed, because essential keys are missing after merge.');
            }

            i18n.setLocaleMessage(locale, translation);
            console.debug('Updated translations for ' + locale);

            return true;
        } catch (e) {
            console.error('API response', response);
            console.error('Merge translation result', translation);
            Logger.captureException(e);
            return false;
        }
    }
};

export const Translator = Object.freeze(new TranslatorInternal());